const ANALYTICS_EVENTS = {
    CHECKOUT_PAGE_EMAIL_ALREADY_KNOWN: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "email_already_exist_screen"
    },
    CHECKOUT_PAGE_BOOKING_INITIATED_TD_FLOW: {
        event: "booking_initated",
        eventCategory: "bi_2_ph",
        eventAction: "td_yes"
    },
    CHECKOUT_PAGE_BOOKING_INITIATED_NO_TD_FLOW: {
        event: "booking_initated",
        eventCategory: "bi_2_ph",
        eventAction: "td_no"
    },
    BI_TO_PHONE_VARIANT_ASSIGNED: {
        event: "experiment",
        eventCategory: "bi_2_ph",
        eventAction: "variant_assigned"
    },
    BI_TO_PHONE_EXPOSURE_EVENT: {
        event: "experiment",
        eventCategory: "bi_2_ph",
        eventAction: "variant_assigned"
    }
};

export default ANALYTICS_EVENTS;
