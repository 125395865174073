import React, { useState } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import nameValidator from "../../../utils/validators/name-validator";
import Button from "../../shared/button";
import InputText from "../../shared/input-text";
import CloseButton from "./images/close-icon.svg";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import { contactUsFormMetaData, contactUsFormQuerySources } from "../../../utils/helpers/contactus-form-metadata";
import { useLocation } from "react-router";
import { mobileNumberValidatorAU } from "../../../utils/validators/mobile-number-validator";
import isCarDetailsPage from "../../../utils/helpers/is-car-details-page";
import InputMobile from "../../shared/input-mobile";
import { PHONE_COUNTRY_CODE } from "../../../constants/app-constants";

const initialFormValue = {
    name: ""
};

const CallbackUsModal = ({
    firstName = "",
    lastName = "",
    email = "",
    submitContactUsConnect,
    trackEvents = trackDesktopCustomEventsAU,
    handleEmailUsModal,
    carLocation,
    centralPosition = false,
    setMobileNumber = () => {},
    onSubmitCallback = () => {}
}) => {
    const location = useLocation();
    const [formInput, setFormInput] = useState({
        ...initialFormValue,
        ...({name: `${firstName ? `${firstName } ${ lastName}` : ""}`}),
        ...email && ({email})
    });
    const [isLoading, setLoading] = useState(false);

    const trackButtonEvent = () => {
        trackEvents(AU_DESKTOP_EVENTS.SUPPORT_WIDGET_FORM_SUBMIT_EMAIL_SELECT);
    };

    const handleFormInput = (id, event) => {
        const value = event.target ? event.target.value : event.value;

        if (id === "name") {

            const alphabeticValue = value.replace(/[^a-zA-Z\s]/g, "");
            setFormInput({ ...formInput, [id]: alphabeticValue });
        } else if (id === "phone") {

            const numericValue = value.replace(/\D/g, "");
            setFormInput({ ...formInput, phoneNumber: numericValue });
        }
    };

    const resetForm = () => {
        setFormInput(initialFormValue);
    };

    const onSubmit = async () => {
        const payload = {
            ...formInput,
            name: formInput.name.toLowerCase(),
            sourceUrl: location.pathname,
            ...contactUsFormMetaData({
                querySource: contactUsFormQuerySources.helpSupportButton
            }),
            ...(isCarDetailsPage() && {carLocation})
        };
        try {
            setLoading(true);
            await submitContactUsConnect(payload);
            setMobileNumber(formInput.phoneNumber);
            setLoading(false);
            resetForm();
            trackButtonEvent();
            onSubmitCallback();
        } catch (error) {
            setLoading(false);
        }
        if (centralPosition)handleEmailUsModal();
    };

    const formData = [
        {
            component: InputText,
            placeholder: "Your full name (optional)",
            showPlaceHolderAsLabel: false,
            id: "name",
            text: "Name",
            validator: nameValidator,
            eventLabel: "name",
            required: false,
            label: "Name"
        }
        // {

        //     component: InputText,
        //     placeholder: "e.g. 04XX XXX XXX",
        //     showPlaceHolderAsLabel: false,
        //     id: "phoneNumber",
        //     text: "e.g. 04XX XXX XXX",
        //     validator: mobileNumberValidatorAU,
        //     eventLabel: "phoneNumber",
        //     required: true,
        //     errorMessage: "Invalid phone number",
        //     label: "Contact no"
        // }

    ];

    const isDisabled = !mobileNumberValidatorAU(formInput.phoneNumber);

    return (
        <div styleName={centralPosition ? "styles.helpModalWrapperCentral" :  "styles.helpModalWrapper"}>
            <div styleName={"styles.helpHeading"}>
                <p styleName={"styles.helpSupport"}>
                    <React.Fragment>
                    Request a callback
                    </React.Fragment>
                </p>
                <img src={CloseButton} onClick={() => handleEmailUsModal(false)} />
            </div>
            <div styleName={"styles.formWrapper"}>
                {formData.map((item) => (
                    <div styleName={"styles.inputField"} key={item.id}>
                        <p styleName={"styles.fieldLabel"}>{item.label}<span>{item.required ? "*" : ""}</span></p>
                        <item.component
                            value={
                                // eslint-disable-next-line no-nested-ternary
                                item.id === "reasonForEnquiry" ?
                                    formInput[item.id] ? { value: formInput[item.id], label: formInput[item.id] } : null :
                                    formInput[item.id]
                            }
                            rest={item.id === "additionalDetails" && { value: formInput[item.id] }}
                            maxHeight={item.id === "additionalDetails" && { maxHeight: item.maxHeight }}
                            placeholder={item.placeholder ? item.placeholder : ""}
                            onChange={(event) => handleFormInput(item.id, event)}
                            {...item}

                        />

                    </div>
                )

                )}
                <p styleName={"styles.fieldLabel"}>Contact no<span>*</span></p>
                <div styleName={"styles.inputFieldPhone"}>
                    <InputMobile
                        id="phone"
                        placeholder={`+${PHONE_COUNTRY_CODE} - Your mobile number`}
                        value={formInput.phoneNumber}
                        onChange={(event) => handleFormInput(event.target.id, event)}
                        validator={mobileNumberValidatorAU}
                    />
                </div>
                <p styleName={"styles.operationalTiming"}>We are operational between 8 AM - 8 PM</p>
                <Button disabled={isDisabled || isLoading} isLoading={isLoading} onClick={onSubmit} text="SUBMIT" />
            </div>
        </div>
    );
};

CallbackUsModal.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    submitContactUsConnect: PropTypes.func,
    trackEvents: PropTypes.func,
    handleEmailUsModal: PropTypes.func,
    handleHelpModal: PropTypes.func,
    showChatEmailModalVariant: PropTypes.bool,
    carLocation: PropTypes.string,
    isSellCarPage: PropTypes.bool,
    onSubmitCallback: PropTypes.func,
    setMobileNumber: PropTypes.func,
    centralPosition: PropTypes.bool
};

export default CallbackUsModal;
