/* eslint-disable max-statements */
import React, { useState, useEffect } from "react";
import styles from "./styles.css";

import PropTypes from "prop-types";
import AddOnsPlan from "../add-ons-plan/component";
import { trackDesktopCustomEventsAU, trackMobileCustomEventsAU } from "../../../../tracking";
// import { LOAN_PROVIDER, NUMBER } from "../../../../constants/app-constants";
import MyBookingPostBcCarCard from "../../my-booking-post-bc-car-card";
import WarrantyModal from "./warranty-modal/component";
import loadable from "@loadable/component";
const PostBcVasCongratulationScreen = loadable(() => import("../../post-bc-vas-congratulation-screen/component"));
import PostBcVasNeedHelpCard from "../../post-bc-vas-need-help-card/component";
import { getMyBookingCarDetailsURL } from "../../../../utils/helpers/get-detail-page-url";
import { tasksRoutes } from "../../../../constants/au.desktop/tasks-routes";
import PLATINUM_COVER_GA_EVENTS from "../add-ons/tracking";
import { LOAN_PROVIDER, NUMBER } from "../../../../constants/app-constants";
import { EXPERIMENT_TYPE } from "../../../../constants/optimize-constants";
import { SIGN_CONTRACT_STATUS, VERIFY_LICENCE_STATES } from "../../../../constants/checkout-constants";
import arrowIcon from "./images/arrow.svg";
import ToastSuccess from "../add-ons-expanded-section/toast-success";
let previousExpandedSection = null;

const AddOnsPlans = ({
    planDetails,
    servicingLocation,
    content,
    userFirstName,
    history,
    orderId,
    postBcStepStatus,
    paymentOption,
    financeProvider,
    drivingLicenceStatus,
    signContractData
}) => {
    const { appointmentId } = content;
    const [expandedSection, setExpandedSection] = useState(null);
    const [showKnowMoreModal, setShowKnowMoreModal] = useState(null);
    const [showAddonsNudge, setShowAddonsNudge] = useState(false);
    const isFinanceUser = financeProvider === LOAN_PROVIDER.CARS24;

    const isInitialRender = React.useRef(true); // Flag to track the first render

    const plans = planDetails?.optionsList?.map((plan) => {
        return {
            product: plan,
            // eslint-disable-next-line react-hooks/rules-of-hooks
            ref: React.useRef(null),
            key: plan.categoryKey,
            isStepSkipped: plan?.addedPackage?.length === 0 && plan?.isStepCompleted,
            isStepAdded: plan?.addedPackage?.length > 0
        };
    });

    const lastPlanIndex = plans?.findIndex((plan) => !plan?.isStepSkipped && !plan?.isStepAdded);

    const getPlanByKey = (key) => plans?.find((plan) => plan.key === key);

    const availableKeys = plans?.map((plan) => plan?.key);

    const { documentStatus } = signContractData?.length > 0 ? signContractData[0] : {};

    const proceedToNextScreen = () => {
        if (isFinanceUser) {
            const { relativeURL } = getMyBookingCarDetailsURL(appointmentId, tasksRoutes.viewTask.route);
            history.push(relativeURL);
        } else if (drivingLicenceStatus && drivingLicenceStatus !== VERIFY_LICENCE_STATES.UPLOAD_PENDING) {
            if (documentStatus === SIGN_CONTRACT_STATUS.SIGNED) {
                const { relativeURL } = getMyBookingCarDetailsURL(appointmentId, tasksRoutes.viewTask.route);
                history.replace(relativeURL);
            } else {
                const { relativeURL } = getMyBookingCarDetailsURL(appointmentId, "sign-contract", null, EXPERIMENT_TYPE.VARIANT_B);
                history.replace(relativeURL);
            }
        } else {
            const { relativeURL } = getMyBookingCarDetailsURL(appointmentId, "user-id", null, EXPERIMENT_TYPE.VARIANT_B);
            history.replace(relativeURL);
        }
    };

    // eslint-disable-next-line max-statements
    useEffect(() => {
        if (expandedSection) {
            const trackData = expandedSection
                ? getPlanByKey(expandedSection)?.openEvent
                : getPlanByKey(previousExpandedSection)?.closeEvent;
            trackMobileCustomEventsAU("", trackData);
            previousExpandedSection = expandedSection;

            if (isInitialRender?.current) {
                isInitialRender.current = false; // Skip the first render
                return;
            }

            const scrollToElement = (ref) => {
                const topOffset = NUMBER.ONE_HUNDRED_THIRTY_FOUR;
                const elementPosition = ref?.current?.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.scrollY - topOffset;
                window.scrollTo({ top: offsetPosition });
            };

            const planRef = getPlanByKey(expandedSection)?.ref;
            scrollToElement(planRef);
        }

        if (expandedSection === "last") {
            window.setTimeout(() => {
                proceedToNextScreen();
            }, NUMBER.TWO_THOUSAND);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandedSection]);

    useEffect(() => {
        trackDesktopCustomEventsAU("", PLATINUM_COVER_GA_EVENTS.ADD_ONS_HOME_PAGE.LAND);

        if (lastPlanIndex !== -1 && expandedSection === null) {
            setExpandedSection(plans?.[lastPlanIndex]?.key);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (showAddonsNudge) {
            window.setTimeout(() => {
                setShowAddonsNudge(false);
            }, NUMBER.TWO_THOUSAND);
        }
    }, [showAddonsNudge]);

    return (
        <div styleName={"styles.outer"}>
            <div styleName={"styles.leftColumn"}>
                <div styleName={"styles.wrapper"}>
                    {plans?.map((plan, index) => (
                        <div styleName={"styles.cardContainer"} ref={plan.ref} key={plan.key}>
                            <AddOnsPlan
                                isFinanceUser={isFinanceUser}
                                isExpanded={expandedSection === plan.key}
                                setExpandedSection={setExpandedSection}
                                handleKnowMoreModal={() => setShowKnowMoreModal(plan.key)}
                                planDetails={plan}
                                expandedSection={expandedSection}
                                itemNumber={index + 1}
                                isStepSkipped={plan?.isStepSkipped}
                                isStepAdded={plan?.isStepAdded}
                                isDisabled={index > lastPlanIndex && lastPlanIndex !== -1}
                                showAddonsNudge={showAddonsNudge}
                                setShowAddonsNudge={setShowAddonsNudge}
                            />
                        </div>
                    ))}
                    <PostBcVasCongratulationScreen
                        availableKeys={availableKeys}
                        carName={`${content?.make} ${content?.model}`}
                        userName={userFirstName}
                        orderId={orderId}
                        userType={paymentOption}
                        financeProvider={financeProvider}
                    />
                    <WarrantyModal
                        isOpen={!!showKnowMoreModal}
                        planKey={showKnowMoreModal}
                        modalHeading={getPlanByKey(expandedSection)?.product?.title}
                        onClose={() => setShowKnowMoreModal(null)}
                        servicingLocation={servicingLocation}
                    />
                </div>
                {postBcStepStatus?.step?.addOns === "complete" && <button styleName={"styles.margin"} onClick={proceedToNextScreen}>Proceed to next step<img src={arrowIcon} /></button>}
            </div>
            <div styleName={"styles.rightColumn"}>
                <MyBookingPostBcCarCard showNumberAnimation />
                <div styleName={"styles.cardWrapper"}>
                    <PostBcVasNeedHelpCard isFinanceUser={isFinanceUser} />
                </div>
                {showAddonsNudge && <div styleName={"styles.toastWrapper"}>
                    <ToastSuccess isFinanceUser={isFinanceUser} />
                </div>}
            </div>
        </div>
    );
};

AddOnsPlans.propTypes = {
    handleKnowMoreModal: PropTypes.func,
    planDetails: PropTypes.object,
    servicingLocation: PropTypes.string,
    paymentOption: PropTypes.string,
    content: PropTypes.object,
    userFirstName: PropTypes.string,
    history: PropTypes.object,
    orderId: PropTypes.string,
    postBcStepStatus: PropTypes.object,
    financeProvider: PropTypes.string,
    drivingLicenceStatus: PropTypes.string,
    signContractData: PropTypes.object
};

export default AddOnsPlans;
