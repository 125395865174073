/* eslint-disable max-statements */
import React, {forwardRef} from "react";
import styles from "./styles.css";
import Close from "./images/close.svg";
import BlueCross from "./images/icon-close.svg";
import WhiteCross from "./images/white-cross.svg";
import Cancel from "./images/cancel.svg";
import GreyCross from "./images/grey-cross.svg";
import OrangeCross from "./images/orange-close.svg";
import OrangeGradient from "./images/orange-gradient.svg";
import PropTypes from "prop-types";
import DarkBlueClose from "./images/close-dark-blue.svg";

const CloseButton = forwardRef(({
    type = "default",
    onClickHandler = () => {}
}, ref) => {
    let icon = Close;
    if (type === "blue") {
        icon = BlueCross;
    }
    if (type === "white") {
        icon = WhiteCross;
    }
    if (type === "cancel") {
        icon = Cancel;
    }
    if (type === "grey") {
        icon = GreyCross;
    }
    if (type === "orange") {
        icon = OrangeCross;
    }
    if (type === "orangeGradient") {
        icon = OrangeGradient;
    }
    if (type === "darkBlue") {
        icon = DarkBlueClose;
    }
    return (
        <img ref={ref} className="imageParentWrapper" styleName={"styles.closeImage"} src={icon} alt="close" onClick={onClickHandler} />
    );
});
CloseButton.propTypes = {
    type: PropTypes.string,
    onClickHandler: PropTypes.func
};
export default CloseButton;
