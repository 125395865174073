export const DESKTOP_EVENT_CATEGORY = {
    CARS24_HOMEPAGE: "Cars24_home_page",
    ALL_PAGES: "Cars24_au_all_pages",
    CHECKOUT_PAGE: "Cars24_checkout_page"
};
export const DESKTOP_EVENT_ACTION = {
    ATF_INTERACTION: "ATF_interactions",
    MAKE_SELECTED: "make_selected",
    MODEL_SELECTED: "model_selected",
    BROWSE_LATEST_CAR: "browse_latest_car",
    SOCIAL_MEDIA: "social_media_text_selected",
    USEFUL_LINKS: "useful_links",
    ORDER_SUMMARY: "order_summary",
    COOKIES: "cookies",
    USER_AGREEMENT: "user_agreement",
    NEXT: "next",
    BACK: "back",
    SELECT_CAR: "select_car",
    SEE_FULL_DETAILS: "see_full_details",
    CTA_SELECTED: "cta_selected",
    CONFIRMATION_POP_UP: "confirmation_pop_up",
    SELL_CAR_LINKS: "sell_car_links"
};

export const DESKTOP_EVENT_KEY = {
    CHECKOUT_PAGE_NEXT: "Cars24_checkout_page_next",
    CHECKOUT_PAGE_BACK: "Cars24_checkout_page_back"

};

export const AU_DESKTOP_EVENTS = {
    CAR_CLICK: "carClick",
    CAR_IMPRESSION: "carsImpression",
    CAR_DETAILS: "carDetails",
    START_PURCHASE: "startPurchase",
    CHECKOUT: "checkout",
    PURCHASE_SUCCESS: "purchaseSuccess",
    DRIVA_DEPOSIT_OPEN: "drivaDepositOpen",
    DRIVA_TENURE_OPEN: "drivaTenureOpen",
    DRIVA_BALLOON_OPEN: "drivaBalloonOpen",
    DRIVA_ORDER_SUMMARY: "drivaOrderSummary",
    BYOF_ORDER_SUMMARY: "byofOrderSummary",
    CHECKOUT_ORDER_SUMMARY: "checkoutOrderSummary",
    HEADER_LOGO: "headerLogo",
    HEADER_WISHLIST_OPEN: "headerWishlistOpen",
    HEADER_EMAIL_SELECTED: "headerEmailSelected",
    HEADER_PHONE_SELECTED: "headerPhoneSelected",
    RESUME_JOURNEY_WISHLIST: "resumeJourneyWishlist",
    RESUME_JOURNEY_BUYING: "resumeJourneyBuying",
    RESUME_JOURNEY_BC: "resumeJourneyBC",
    RESUME_JOURNEY_TESTDRIVE: "resumeJourneyTestDrive",

    SELL_ORDER_VALUATION_NUDGE: "sellOrderValuationNudge",
    SELL_ORDER_BOOK_INSPECTION_NUDGE: "sellOrderBookInspection",

    // GA4 eccomerce impression
    VIEW_ITEM_LIST: "ga4_view_item_list",
    SELECT_ITEM: "ga4_select_item",
    VIEW_ITEM: "ga4_view_item",
    BEGIN_CHECKOUT: "ga4_begin_checkout",
    PURCHASE: "ga4_purchase",
    ADD_TO_CART: "ga4_add_to_cart",

    FOOTER_LINKS: "footerLinks",
    FOOTER_COUNTRY: "footerCountry",

    HAMBURGER_MENU_OPEN: "hamburgerMenuOpen",
    HAMBURGER_MENU_OPTIONS: "hamburgerMenuOptions",
    HAMBURGER_MENU_CLOSE: "hamburgerMenuClose",

    LOGIN_EMAIL_ENTERED: "loginEmailEntered",
    LOGIN_METHOD: "loginMethod",
    LOGIN_SUCCESSFULL_GOOGLE: "loginSuccessfullGoogle",
    LOGIN_SUCCESSFULL_FACEBOOK: "loginSuccessfullFacebook",
    LOGIN_SUCCESSFULL_APPLE: "loginSuccessfullApple",
    LOGIN_VERIFY_OTP: "loginVerifyOtp",
    LOGIN_VERIFY: "loginVerify",
    LOGIN_CLOSE: "loginClose",
    LOGIN_VERIFY_CLOSE: "loginVerifyClose",
    LOGIN_FOOTER_CTA: "loginFooterCta",

    LOGIN_INITIATED: "Login initiated",
    LOGIN_MODE_SELECTED: "Login mode selected",
    LOGIN_SUCCESS: "Login success",

    OTP_ENTERED: "OTP Entered",
    OTP_VERIFICATION_SUCCESS: "OTP Verification Success",
    OTP_VERIFICATION_FAILED: "OTP Verification Failed",

    HOMEPAGE_SEE_CARS_LISTING: "homepageSeeCarsListing",
    HOMEPAGE_LOAD_MORE: "homepageLoadMore",
    HOMEPAGE_BANNER_CHANGE: "homepageBannerChange",
    HOMEPAGE_CAR_LISTING_CARD_OPEN: "homepageCarListingCardOpen",
    HOMEPAGE_GET_IN_TOUCH: "homepageGetInTouch",

    LISTING_PAGE_CARD_OPEN: "listingPageCardOpen",
    LISTING_PAGE_LOAD_MORE: "listingPageLoadMore",

    FILTERS_SEARCH_SUGGESTED_BRANDS: "filtersSearchSuggestedBrands",
    FILTERS_SEARCH_SUGGESTED_MODELS: "filtersSearchSuggestedModels",
    FILTERS_OPEN: "filtersOpen",
    FILTERS_CLOSE: "filtersClose",
    FILTERS_SHOW_CARS: "filtersShowCars",
    FILTERS_PRICE: "filtersPrice",
    FILTERS_KM: "filtersKM",
    FILTERS_YEAR: "filtersYear",
    FILTERS_MORE_FUEL_TYPE_ON: "filtersMoreFuelTypeOn",
    FILTERS_MORE_FUEL_TYPE_OFF: "filtersMoreFuelTypeOff",
    FILTERS_MORE_TRANSMISSION_TYPE_ON: "filtersMoreTransmissionTypeOn",
    FILTERS_MORE_TRANSMISSION_TYPE_OFF: "filtersMoreTransmissionTypeOff",
    FILTERS_MORE_BODY_TYPE_ON: "filtersMoreBodyTypeOn",
    FILTERS_MORE_BODY_TYPE_OFF: "filtersMoreBodyTypeOff",
    FILTERS_MAKE_MODEL_SEARCH: "filtersMakeModelSearch",
    FILTERS_MAKE_ON: "filtersMakeOn",
    FILTERS_MAKE_OFF: "filtersMakeOff",
    FILTERS_MODEL_ON: "filtersModelOn",
    FILTERS_MODEL_OFF: "filtersModelOff",
    FILTERS_MORE_SORT_TAB_OPEN: "filtersMoreSortTabOpen",
    FILTERS_MORE_FILTER_TAB_OPEN: "filtersMoreFilterTabOpen",
    FILTERS_SORT_ATTRIBUTE_SELECTED: "filtersSortAttributeSelected",
    FILTERS_CLEAR: "filtersClear",
    FILTERS_CLEAR_ALL: "filtersClearAll",

    // CLP Filter Revamp
    FILTER_REVAMP_FILTER_COLOR: "filter_colour",
    FILTER_REVAMP_FILTER_TESTDRIVE: "filter_test_drive",
    FILTER_REVAMP_FILTER_MODEL_SELECTED_ON: "filter_model_on",
    FILTER_REVAMP_FILTER_PRICE_SELECTED: "filter_price",
    FILTER_REVAMP_FILTER_TRANSMISSION_SELECTED: "filter_transmission",
    FILTER_REVAMP_FILTER_YEAR_SELECTED: "filter_year",
    FILTER_REVAMP_FILTER_FUEL_TYPE_SELECTED: "filter_fuel_type",
    FILTER_REVAMP_FILTER_BODY_TYPE_SELECTED: "filter_body_type",
    FILTER_REVAMP_FILTER_KM_SELECTED: "filter_km",
    FILTER_REVAMP_FILTER_DRIVETRAIN_SELECTED: "filter_drivetrain",
    FILTER_REVAMP_FILTER_FEATURES_SELECTED: "filter_features",
    FILTER_REVAMP_FILTER_SEAT_SELECTED: "filter_seat",
    FILTER_REVAMP_SORT_ATTRIBUTE_SELECTED_SELECTED: "sort_attribute_selected",
    FILTER_REVAMP_CLEAR_ALL_FILTERS_SELECTED: "clear_all_filters",
    FILTER_REVAMP_FILTER_DESELECTED_CONTAINER: "filter_deselected_container",
    FILTER_REVAMP_FILTER_DESELECTED_PILL: "filter_deselected_pill",
    FILTER_REVAMP_FILTER_CITYNAME_SELECTED: "filter_city_name",

    CAR_DETAILS_CAR_FEATURE_SELECTED_OPEN: "featureSelectedOpen",
    CAR_DETAILS_CAR_FEATURE_SELECTED_CLOSE: "featureSelectedClose",
    CAR_DETAILS_CARS24_BENEFITS_OPEN: "cars24BenifitsOpen",
    CAR_DETAILS_CARS24_BENEFITS_CLOSE: "cars24BenifitsClose",
    CAR_DETAILS_QUALITY_SPECIFICATION_LINKS: "qualitySpecificationLinks",
    CAR_DETAILS_QUALITY_SPECIFICATION_TABS: "qualitySpecificationTab",
    HOW_IT_WORKS: "how_it_works",
    CAR_DETAILS_SIMILAR_CARS: "similarCars",
    CAR_DETAILS_QUALITY_LINKS: "qualityLinks",
    CAR_DETAILS_CAR_SPECIFICATION: "carSpecification",
    CAR_DETAILS_CAR_SPECIFICATION_SELECTED_OPEN: "specificationSelectedOpen",
    CAR_DETAILS_CAR_SPECIFICATION_SELECTED_CLOSE: "specificationSelectedClose",
    CAR_DETAILS_IMAGE_GALLERY_VIEW: "imageGalleryView",
    CAR_DETAILS_IMAGE_GALLERY_360VIEW: "imageGallery360View",
    CAR_DETAILS_IMAGE_GALLERY_SIDEBAR: "imageGallerySidebar",
    CAR_DETAILS_IMAGE_GALLERY_NAVIGATION: "imageGalleryNavigation",
    CAR_DETAILS_ATF_INTERACTIONS: "atfInteractions",
    BUY_ONLINE_CTA_CLICKED: "buy_online",
    CAR_DETAILS_PRICE_BREAK_DOWN: "priceBreakDown",
    CAR_DETAILS_PRICE_BREAK_DOWN_CLOSE: "priceBreakDownClose",
    CAR_DETAILS_IMAGE_GALLERY_VIEW_CLOSE: "imageGalleryViewClose",
    CAR_DETAILS_PRICE_BREAK_DOWN_GET_STARTED: "priceBreakDownGetStarted",
    CAR_DETAILS_NAVIGATION_ITEMS_CLICKED: "carDetailsNavigationItemsClicked",
    CAR_DETAILS_IMAGE_GALLERY_AB: "carDetailsImageGalleryAb",
    CAR_DETAILS_FEATURE_SEARCH: "carDetailsFeatureSearch",
    CARS24_DETAIL_PAGE: "cars24DetailPage",

    INSPECTION_ATTRIBUTE_OPEN: "inspectionAttributeOpen",
    INSPECTION_ATTRIBUTE_CLOSE: "inspectionAttributeClose",
    INSPECTION_REPORT_INSPECTION_SECTION_VIEWED: "inspection_report_inspection_section_viewed",
    INSPECTION_REPORT_WEAR_AND_TEAR_GUIDE: "inspection_report_wear_and_tear_guide",
    INSPECTION_REPORT_CARS24_WARRANTY: "inspection_report_cars24_warranty",
    INSPECTION_SECTION_IMAGES: "inspection_section_images",

    WISHLIST_CTA_SELECTED: "wishlistCtaSelected",
    WISHLIST_CTA_DESELECTED: "wishlistCtaDeselected",
    WISHLIST_CARD_SELECTED: "wishlistCardSelected",
    WISHLIST_SEE_ALL_CARS: "wishlistSeeAllCars",
    WISHLIST_CLOSE: "wishlistClose",

    CHECKOUT_NEXT: "checkoutNext",
    CHECKOUT_BACK: "checkoutBack",
    WHATSAPP_CONSENT_USED: "whatsappConsentUsed",
    CHECKOUT_PHONE_VERIFY: "checkoutPhoneVerify",
    CHECKOUT_PERSONAL_DETAILS: "checkoutPersonalDetails",
    CHECKOUT_DELIVERY_DETAILS: "checkoutDeliveryDetails",
    CHECKOUT_DELIVERY_DETAILS_WITH_ADDRESS: "checkoutDeliveryDetailsWithAddress",
    CHECKOUT_CHIP_NAVIGATION: "checkoutChipNavigation",
    CHECKOUT_ORDER_CONFIRMATION: "checkoutOrderConfirmation",
    CHECKOUT_ORDER_CONFIRMATION_PENDING: "checkoutOrderConfirmationPending",
    CHECKOUT_ORDER_CONFIRMATION_SUMMARY_POPUP: "checkoutOrderConfirmationSummaryPopup",
    CHECKOUT_HEADER_CALL: "checkoutHeaderCall",
    CHECKOUT_PAYMENT_FAILURE: "checkoutPaymentFailure",
    CHECKOUT_PAYMENT_OPTIONS: "checkoutPaymentOptions",

    DRIVA_VALUE_PROP: "drivaValueProp",
    DRIVA_DEPOSIT_INPUT: "drivaDepositInput",
    DRIVA_DEPOSIT_SLIDER: "drivaDepositSlider",
    DRIVA_LOAN_TENURE_OPTION: "drivaLoanTenureOption",
    DRIVA_BALLOON_CLICK: "drivaBalloonClick",
    DRIVA_BALLOON_OPTION: "drivaBalloonOption",
    DRIVA_LOAN_TYPE: "drivaLoanType",
    DRIVA_LOAN_TYPE_ADDITIONAL: "drivaLoanTypeAdditional",
    DRIVA_NAME_DETAILS: "drivaNameDetails",
    DRIVA_DOB: "drivaDOB",
    DRIVA_RESIDENCY: "drivaResidency",
    DRIVA_RESIDENCY_VISA: "drivaResidencyVisa",
    DRIVA_ADDRESS: "drivaResidencyVisa",
    DRIVA_ADDRESS_TENURE: "drivaResidencyVisa",
    DRIVA_LIVING_SITUATION: "drivaResidencyVisa",
    DRIVA_EMPLOYMENT_STATUS: "drivaResidencyVisa",
    DRIVA_SELECT_OFFER: "drivaSelectOffer",
    DRIVA_CUSTOMIZE: "drivaCustomize",
    DRIVA_REJECTION: "drivaRejection",
    DRIVA_ORDER_CONFIRMATION: "drivaOrderConfirmation",
    DRIVA_ORDER_CONFIRMATION_PENDING: "drivaOrderConfirmationPending",
    DRIVA_ORDER_CONFIRMATION_SUMMARY_POPUP: "drivaOrderConfirmationSummaryPopup",
    DRIVA_OFFER_SCREEN: "drivaOfferScreen",
    DRIVA_OFFER_SELECT_SCREEN: "drivaOfferSelectScreen",
    DRIVA_PRE_APPROVED_SCREEN: "drivaPreApprovedScreen",

    IN_HOUSE_FINANCE_RELATIONSHIP_STATUS: "InhouseFinanceRealtionshipStatus",
    IN_HOUSE_FINANCE_INCOME_DETAILS: "InhouseFinanceIncomeDetails",
    IN_HOUSE_FINANCE_NO_OF_DEPENDANTS: "InhouseFinanceNoOfDependants",

    PRE_APPROVED_PROVIDER: "preApprovedProvider",
    PRE_APPROVED_PROVIDER_SLIDER: "preApprovedProviderSlider",
    PRE_APPROVED_SUBMIT: "preApprovedSubmit",
    PRE_APPROVED_THANK_YOU: "preApprovedThankYou",
    PRE_APPROVED_BACK: "preApprovedBack",

    FINANCE_OK_GOT_IT: "Okay_got_it",
    FINANCE_TAB: "Finance_Tab",
    FINANCE_SHOW_CARS: "Finance_Show_cars",
    REPYAMENT_LOW_TO_HIGH: "Repyament_Low_to_high",
    REPYAMENT_HIGH_TO_LOW: "Repyament_High_to_low",

    MY_BOOKINGS_BUTTON_CLICK: "myBookingsButtonClick",
    MY_SELL_ORDERS: "mySellOrders",

    LEARN_MORE_OPEN: "learnMoreOpen",
    LEARN_MORE_CLOSE: "learnMoreClose",
    LEARN_MORE_CALL_US: "learnMoreCallUs",

    TERMS_OF_USE_EMAIL_CLICK: "termsOfUseEmailClick",
    PRIVACY_POLICY_LINK_CLICK: "privacyPolicyLinkClick",
    PRIVACY_POLICY_DRIVA_CLICK: "privacyPolicyDrivaClick",
    CONTACT_US_INPUT: "contactUsInput",
    CONTACT_US_BUTTON: "contactUsButton",
    CAREER_LINKEDIN: "careerLinkedin",
    COOKIES: "cookies",
    USER_AGREEMENT: "user_agreement",
    COMING_SOON_LISTING_INFO: "comingSoonListingInfoIcon",
    COMING_SOON_DETAIL_INFO: "comingSoonDetailInfoIcon",
    COMING_SOON_NOTIFY_ME: "comingSoonNotifyMe",
    COMING_SOON_THANK_YOU: "comingSoonThankYouPopup",

    RESERVATION_NOTIFY_ME_CTA: "reservation_notify_me_cta",
    RESERVATION_NOTIFY_ME_TOOLTIP: "reservation_notify_me_tooltip",
    RESERVATION_NOTIFY_ME_TOOLTIP_POPUP: "reservation_notify_me_tooltip_popup",
    RESERVATION_NOTIFY_ME: "reservation_notify_me",
    RESERVATION_REMOVE_FROM_WISHLIST: "reservation_remove_from_wishlist",
    RESERVATION_REMOVE_FROM_WISHLIST_POPUP: "reservation_remove_from_wishlist_popup",

    STATE_WISE_PRICING_INFO_ICON: "car_card_egc_info_icon",
    STATE_WISE_PRICING_INFO_ICON_DETAILS: "car_card_egc_info_icon_details",
    STATE_WISE_PRICING_STATE_SELECT: "price_break_down_state_selected",
    STATE_WISE_PRICE_BREAKDOWN_GET_STARTED: "price_break_down_get_started",
    TRADE_IN_OPT_IN_SCREEN: "opt_in_screen",
    TRADE_IN_REVIEW_CAR_DETAIL_METHOD: "review_car_detail_method",
    TRADE_IN_REGO_NUMBER_ENTERED: "rego_number_entered",
    TRADE_IN_STATE_SELECTED: "state_selected",
    TRADE_IN_VIN_ENTERED: "vin_entered",
    TRADE_IN_YEAR_SELECTED: "year_selected",
    TRADE_IN_KBB_CAR_OUTPUT_CONFIRMATION: "kbb_car_ouptut_confirmation",
    TRADE_IN_CAR_OWNER: "car_owner",
    TRADE_IN_MAJOR_ACCIDENT: "major_accident",
    TRADE_IN_PROCEED_WITHOUT_TRADE_IN: "proceed_without_trade_in",
    TRADE_IN_ODOMETER_READING: "odometer_reading",
    TRADE_IN_DASHBOARD_WARNING_LIGHTS: "dashboard_warning_lights",
    TRADE_IN_AIR_CONDITIONING: "air_conditioning",
    TRADE_IN_EXTERIOR_DAMAGE: "exterior_damage",
    TRADE_IN_INTERIOR_DAMAGE: "interior_damage",
    TRADE_IN_EXTERIOR_DAMAGE_OTHERS_INPUT: "exterior_damage_others_input",
    TRADE_IN_SERVICE_LOG: "service_log",
    TRADE_IN_SERVICE_LOG_STATUS: "service_log_status",
    TRADE_IN_NUMBER_OF_KEYS: "number_of_keys",
    TRADE_IN_OWE_FINANCE: "owe_finance",
    TRADE_IN_COLLECT_FINANCE_PAYMENT: "collect_finance_payment",
    TRADE_IN_CONDITIONS_OF_EVALUATION_SELECTED: "conditions_of_evaluation_selected",
    TRADE_IN_OFFER_ACCEPTED: "offer_accepted",
    TRADE_IN_NEXT: "next",
    TRADE_IN_BACK: "back",
    TRADE_IN_OFFER_SCREEN: "offer_screen",
    TRADE_IN_EXPIRY_SCREEN: "expiry_screen",
    TRADE_IN_VARIANT_SELECTED: "trade_in_variant_selected",

    // Trade-in top funnel
    TRADE_IN_TOP_FUNNEL_NEXT: "trade_in_top_funnel_next",
    TRADE_IN_TOP_FUNNEL_BACK: "trade_in_top_funnel_back",
    TRADE_IN_TOP_FUNNEL_VARIANT_NOT_RETURN: "trade_in_top_funnel_variant_not_return",
    TRADE_IN_TOP_FUNNEL_CATEGORY: "Trade_in_top_of_the_funnel",
    TRADE_IN_TOP_FUNNEL_GET_OFFER: "trade_in_top_funnel_Get_offer",
    TRADE_IN_TOP_FUNNEL_GET_STARTED: "Trade_in_top_of_the_funnel_get_started",
    TRADE_IN_TOP_FUNNEL_REVIEW_CAR_DETAIL_METHOD: "trade_in_top_funnel_review_car_detail_method",
    TRADE_IN_TOP_FUNNEL_REGO_NUMBER_ENTERED: "trade_in_top_funnel_rego_number_entered",
    TRADE_IN_TOP_FUNNEL_STATE_SELECTED: "trade_in_top_funnel_state_selected",
    TRADE_IN_TOP_FUNNEL_VIN_ENTERED: "trade_in_top_funnel_vin_entered",
    TRADE_IN_TOP_FUNNEL_YEAR_SELECTED: "trade_in_top_funnel_year_selected",
    TRADE_IN_TOP_FUNNEL_VARIANT_SELECTED: "trade_in_top_funnel_variant_selected",
    TRADE_IN_TOP_FUNNEL_DONT_AGREE_ASSESSMENT_MODEL: "trade_in_top_funnel_don't_agree_with_our_assessment_model",
    TRADE_IN_TOP_FUNNEL_VIEW_CARS: "trade_in_top_funnel_view_cars",
    TRADE_IN_TOP_FUNNEL_TRADE_IN_PRICE_TOGGLE_CLP: "trade_in_top_funnel_trade_in_price_toggle_clp",
    TRADE_IN_TOP_FUNNEL_TRADE_IN_PRICE_TOGGLE_CDP: "trade_in_top_funnel_trade_in_price_toggle_cdp",
    TRADE_IN_TOP_FUNNEL_MY_ACCOUNT: "trade_in_top_funnel_my_account",
    TRADE_IN_TOP_FUNNEL_TRADE_IN: "trade_in_top_funnel_trade_in",
    TRADE_IN_TOP_FUNNEL_HEADER_TRADE_IN_YOUR_CAR: "trade_in_top_funnel_header_trade_in_your_car",
    PROCEED_AFTER_PARTS_SELECTED: "proceed_after_parts_selected",

    // Trade-in make-model select
    VARIANT_NOT_RETURN: "variant_not_returned",
    NO_TRADEIN_MAKE_SELECTED: "no_trade_in_make_selected",
    NO_TRADEIN_MODEL_SELECTED: "no_trade_in_model_selected",
    NO_TRADEIN_YEAR_SELECTED: "no_trade_in_year_selected",
    NO_TRADEIN_FUEL_SELECTED: "no_trade_in_fuel_selected",
    NO_TRADEIN_BODY_SELECTED: "no_trade_in_body_selected",
    NO_TRADEIN_TRANSMISSION_SELECTED: "no_trade_in_transmission_selected",
    NO_TRADEIN_VARIANT_SELECTED: "no_trade_in_variant_selected",
    NO_TRADEIN_DRIVETRAIN_SELECTED: "no_trade_in_driveTrain_selected",

    HOMEPAGE_CATEGORY: "HOMEPAGE_CATEGORY",
    KBB_PRICE_PROMISE: "KBB_price_promise",
    KBB_SHOW_MORE: "KBB_show_more",
    KBB_INFO_ICON: "KBB_info_icon",
    HEADER_PAGE_SELECTED: "header_page_selected",

    PAY_ONLINE_METHOD: "pay_online_method",
    PAY_PARTIAL_INFO_MODEL: "pay_partial_info_model",
    PAY_ONLINE_CTA_SELECTED: "pay_online_cta_selected",
    PAY_SPLIT_SLIDER: "pay_split_slider",
    MY_BOOKING_TASKS: "my_booking_tasks",
    SPLIT_ORDER_SUMMARY: "split_order_summary",
    SPLIT_ORDER_SUMMARY_POST_PAYMENT: "split_order_summary_post_payment",
    THREE_HUNDRED_CATEGORY: "three_hundred_category",
    THREE_HUNDRED_SEARCH: "three_hundred_search",
    THREE_HUNDRED_CTA: "three_hundred_cta",

    DELIVERY_SCREEN: "delivery_screen",
    PICK_UP: "pick_up",
    PICK_UP_HOME_DELIVERY: "Pick_up_home_delivery",
    DOCUMENT_VERIFIED: "document_verified",
    CONTRACT_OF_SALE: "contract_of_sale",
    NUDGE_CARD: "nudge_card",
    BLACK_FRIDAY_BANNER_CLICKED: "black_friday_banner_clicked",
    SHOW_EASTER_EGG: "show_eater_egg",
    SHOW_EASTER_EGG_TERMS: "show_eater_egg_terms",

    SHOW_KBB_FAN: "show_kbb_fan",
    HIDE_KBB_FAN: "hide_kbb_fan",

    // LOAN_QUOTES
    LOAN_QUOTES_SCREEN: "Loan quotes screen",

    PRE_APPROVAL_SERVICEABILITY_ERROR_SCREEN: "preApprovalServiceabilityErrorScreen",
    PRE_APPROVAL_OTHER_PAYMENT_METHODS: "preApprovalOtherPaymentMethods",
    PRE_APPROVAL_VIEW_OTHER_CARS: "preApprovalViewOtherCars",
    PRE_APPROVAL_CLOSE_LOAN_TERMS_MODAL: "preApprovalUpdateLoanTermsModal",
    PRE_APPROVAL_UPDATE_LOAN_TERMS: "preApprovalUpdateLoanTerms",
    PRE_APPROVAL_EDIT_LOAN_TERMS: "preApprovalEditLoanTerms",
    PRE_APPROVAL_NEXT: "preApprovalNext",
    PRE_APPROVAL_BACK: "preApprovalBack",
    PREAPPROVAL_DRIVA_VALUE_PROP: "preapprovaldrivaValueProp",
    PREAPPROVAL_DRIVA_DEPOSIT_INPUT: "preapprovaldrivaDepositInput",
    PREAPPROVAL_DRIVA_DEPOSIT_SLIDER: "preapprovaldrivaDepositSlider",
    PREAPPROVAL_DRIVA_LOAN_TENURE_OPTION: "preapprovaldrivaLoanTenureOption",
    PREAPPROVAL_DRIVA_BALLOON_CLICK: "preapprovaldrivaBalloonClick",
    PREAPPROVAL_DRIVA_BALLOON_OPTION: "preapprovaldrivaBalloonOption",
    PREAPPROVAL_DRIVA_LOAN_TYPE: "preapprovaldrivaLoanType",
    PREAPPROVAL_DRIVA_LOAN_TYPE_ADDITIONAL: "preapprovaldrivaLoanTypeAdditional",
    PREAPPROVAL_DRIVA_NAME_DETAILS: "preapprovaldrivaNameDetails",
    PREAPPROVAL_DRIVA_DOB: "preapprovaldrivaDOB",
    PREAPPROVAL_DRIVA_RESIDENCY: "preapprovaldrivaResidency",
    PREAPPROVAL_DRIVA_RESIDENCY_VISA: "preapprovaldrivaResidencyVisa",
    PREAPPROVAL_DRIVA_ADDRESS: "preapprovaldrivaResidencyVisa",
    PREAPPROVAL_DRIVA_ADDRESS_TENURE: "preapprovaldrivaResidencyVisa",
    PREAPPROVAL_DRIVA_LIVING_SITUATION: "preapprovaldrivaResidencyVisa",
    PREAPPROVAL_DRIVA_EMPLOYMENT_STATUS: "preapprovaldrivaResidencyVisa",
    PREAPPROVAL_DRIVA_SELECT_OFFER: "preapprovaldrivaSelectOffer",
    PREAPPROVAL_DRIVA_CUSTOMIZE: "preapprovaldrivaCustomize",
    PREAPPROVAL_DRIVA_REJECTION: "preapprovaldrivaRejection",
    PREAPPROVAL_DRIVA_ORDER_CONFIRMATION: "preapprovaldrivaOrderConfirmation",
    PREAPPROVAL_DRIVA_ORDER_CONFIRMATION_PENDING: "preapprovaldrivaOrderConfirmationPending",
    PREAPPROVAL_DRIVA_ORDER_CONFIRMATION_SUMMARY_POPUP: "preapprovaldrivaOrderConfirmationSummaryPopup",
    PREAPPROVAL_APPLY_NOW: "preapprovalapplynow",
    PREAPPROVAL_GET_STARTED: "preapprovalgetstarted",
    PRICE_DETAILS_SECTION_FULL_PAY: "pricedetailssectionfullpay",
    PREAPPROVAL_VIEW_CARS: "preapprovalviewcars",
    PRE_APPROVAl_SUCCESS: "preapprovalsuccess",
    PRE_APPROVAl_REJECTED: "preapprovalrejected",
    PRE_APPROVAl_EDIT_MY_ACCOUNT: "preapprovalmyaccountedit",
    PRE_APPROVAl_EDIT_TAB_SELECTED: "preapprovaledittabselected",
    PRE_APPROVAl_EDIT_COMPLETED: "preapprovaleditCompleted",
    PRE_APPROVAl_EDIT_REJECTION: "preapprovaleditrejection",
    PRE_APPROVAl_EDIT_EXPIRED: "preapprovaleditexpired",

    PREAPPROVAL_IN_HOUSE_FINANCE_VALUE_PROP: "preapprovalinHouseFinanceValueProp",
    PREAPPROVAL_IN_HOUSE_FINANCE_DEPOSIT_INPUT: "preapprovalinHouseFinanceDepositInput",
    PREAPPROVAL_IN_HOUSE_FINANCE_DEPOSIT_SLIDER: "preapprovalinHouseFinanceDepositSlider",
    PREAPPROVAL_IN_HOUSE_FINANCE_LOAN_TENURE_OPTION: "preapprovalinHouseFinanceLoanTenureOption",
    PREAPPROVAL_IN_HOUSE_FINANCE_BALLOON_CLICK: "preapprovalinHouseFinanceBalloonClick",
    PREAPPROVAL_IN_HOUSE_FINANCE_BALLOON_OPTION: "preapprovalinHouseFinanceBalloonOption",
    PREAPPROVAL_IN_HOUSE_FINANCE_LOAN_TYPE: "preapprovalinHouseFinanceLoanType",
    PREAPPROVAL_IN_HOUSE_FINANCE_LOAN_TYPE_ADDITIONAL: "preapprovalinHouseFinanceLoanTypeAdditional",
    PREAPPROVAL_IN_HOUSE_FINANCE_NAME_DETAILS: "preapprovalinHouseFinanceNameDetails",
    PREAPPROVAL_IN_HOUSE_FINANCE_DOB: "preapprovalinHouseFinanceDOB",
    PREAPPROVAL_IN_HOUSE_FINANCE_RESIDENCY: "preapprovalinHouseFinanceResidency",
    PREAPPROVAL_IN_HOUSE_FINANCE_RESIDENCY_VISA: "preapprovalinHouseFinanceResidencyVisa",
    PREAPPROVAL_IN_HOUSE_FINANCE_ADDRESS: "preapprovalinHouseFinanceResidencyVisa",
    PREAPPROVAL_IN_HOUSE_FINANCE_ADDRESS_TENURE: "preapprovalinHouseFinanceAddressTenure",
    PREAPPROVAL_IN_HOUSE_FINANCE_LIVING_SITUATION: "preapprovalinHouseFinanceLivingSituation",
    PREAPPROVAL_IN_HOUSE_FINANCE_EMPLOYMENT_STATUS: "preapprovalinHouseFinanceEmploymentStatus",
    PREAPPROVAL_IN_HOUSE_FINANCE_SELECT_OFFER: "preapprovalinHouseFinanceSelectOffer",
    PREAPPROVAL_IN_HOUSE_FINANCE_CUSTOMIZE: "preapprovalinHouseFinanceCustomize",
    PREAPPROVAL_IN_HOUSE_FINANCE_REJECTION: "preapprovalinHouseFinanceRejection",
    PREAPPROVAL_IN_HOUSE_FINANCE_ORDER_CONFIRMATION: "preapprovalinHouseFinanceOrderConfirmation",
    PREAPPROVAL_IN_HOUSE_FINANCE_ORDER_CONFIRMATION_PENDING: "preapprovalinHouseFinanceOrderConfirmationPending",
    PREAPPROVAL_IN_HOUSE_FINANCE_ORDER_CONFIRMATION_SUMMARY_POPUP: "preapprovalinHouseFinanceOrderConfirmationSummaryPopup",
    PREAPPROVAL_IN_HOUSE_FINANCE_OFFER_SCREEN: "preapprovalinHouseFinanceSelectOfferScreen",
    PREAPPROVAL_IN_HOUSE_FINANCE_MOBILE_NUMBER_ENTERED: "preapprovalinHouseFinanceMobileNumberEntered",
    PREAPPROVAL_IN_HOUSE_FINANCE_EMAIL_ID_ENTERED: "preapprovalinHouseFinanceEmailEntered",
    PREAPPROVAL_IN_HOUSE_FINANCE_INCOME_DETAILS: "preapprovalinHouseFinanceIncomeDetails",
    PREAPPROVAL_IN_HOUSE_FINANCE_RELATIONSHIP_STATUS: "preapprovalinHouseFinanceRelaionshipStatus",
    PREAPPROVAL_IN_HOUSE_FINANCE_NO_OF_DEPENDANTS: "preapprovalinHouseFinanceNoofdependants",
    PREAPPROVAL_IN_HOUSE_FINANCE_EMPLOYMENT_STATUS_TENURE: "preapprovalinHouseFinanceEmploymentStatusTenure",
    PREAPPROVAL_IN_HOUSE_FINANCE_EMPLOYMENT_INDUSTRY_CONTUATION: "preapprovalinHouseFinanceEmploymentIndustryContuation",
    IN_HOUSE_COMMERCIAL_LOAN: "inHouseCommercialLoan",
    LOCATION_STATE_LISTING: "location_state_listing",
    LOCATION_STATE_DETAIL: "location_state_detail",

    //cf filter
    FINANCE_FILTER_SHOW_CARS: "finance_filter_show_cars",
    FINANCE_FILTER_TAB_SELECED: "finance_filter_tab_selected",
    FINANCE_FILTER_SELECTIONS: "finance_filter_selection",

    //cf calculator
    PRICE_CALCULATOR_TAB_SELECTED: "price_calculator_tab_selected",
    PRICE_CALCULATOR_SELECTIONS: "price_calculator_selections",
    PRICE_CALCULATOR_SECTION_START_PURCHASE: "price_calculator_section_start_purchase",

    SWIPE_IN_GALLERY: "b2cSwipeInGallery",
    CAR_GALLERY_VIEW: "cargalleryview",
    LOGIN_SUCCESS_FACEBOOK: "login_success_facebook",
    LOGIN_SUCCESS_GOOGLE: "login_success_google",
    LOGIN_SUCCESS_APPLE: "login_success_apple",
    LOGIN_SUCCESS_EMAIL: "login_success_email",
    DOWNLOAD_APP_FROM_TOP_BANNER: "download_app_from_top_banner",
    DOWNLOAD_APP_FOOTER: "download_app_footer",
    DOWNLOAD_APP_FROM_TOP_BANNER_CLOSE: "download_app_from_top_banner_close",
    SEO_EXIT_INTENT: "seo_exit_intent",
    FILTERS_MORE_SEATS_ON: "filtersMoreSeatseOn",
    FILTERS_MORE_SEATS_OFF: "filtersMoreSeatseOff",
    LISTING_CONTENT_READ_MORE: "listing_content_read_more",
    LISTING_CONTENT_READ_LESS: "listing_content_read_less",
    DOWNLOAD_APP_BANNER: "download_app_banner",
    PERFECT_CAR_MAKE: "perfect_car_make",
    PERFECT_CAR_MODEL: "perfect_car_model",
    PERFECT_CAR_BODYTYPE: "perfect_car_bodytype",
    PERFECT_FUELTYPE: "perfect_fueltype",
    PERFECT_SEE_ALL_CARS: "perfect_see_all_cars",
    BANNER_VARIANT_A: "BANNER_VARIANT_A",
    BANNER_VARIANT_B: "BANNER_VARIANT_B",
    BANNER_NUMBER_ENTERED: "banner_number_entered",
    BANNER_SMS_BUTTON_CLICKED: "banner_sms_button_clicked",
    BANNER_NUMBER_INVALID: "banner_number_invalid",
    PERFECT_TAB_CLICKED: "perfect_tab_clicked",
    COUPON_CODE_ENTERED: "coupon_code_entered",
    COUPON_APPLY_BUTTON: "coupon_apply_button",
    COUPON_REMOVE: "coupon_remove",
    DRIVA_REDIRECTION_LINK: "driva_redirection_link",
    IN_HOUSE_REDIRECTION_LINK: "in_house_redirection_link",
    HOW_TO_BUY_FAQ_LINK: "how_to_buy_faq_link",
    PRICE_FILTER_SELECTED_ON_SCROLL: "price_filter_selected_on_scroll",
    CARS24_COVER_PAGE_MODAL: "CARS24_COVER_PAGE_MODAL",
    CARS24_COVER: "CARS24_COVER",
    PLAN_SELECTED: "PLAN_SELECTED",
    ADD_AND_PROCEED: "ADD_AND_PROCEED",
    SKIP: "SKIP",
    CARS24_COVER_DETAIL_PAGE: "CARS24_COVER_DETAIL_PAGE",
    CARS24_COVER_EW_COMPARISON: "CARS24_COVER_EW_COMPARISON",
    CARS24_COVER_EW_COVERAGE_DETAILS: "CARS24_COVER_EW_COVERAGE_DETAILS",
    CARS24_COVER_PAGE_VIEW_VARIANT_A: "CARS24_COVER_PAGE_VIEW_VARIANT_A",
    CARS24_COVER_PAGE_VIEW_VARIANT_B: "CARS24_COVER_PAGE_VIEW_VARIANT_B",
    CARS24_COVER_ADD_TO_LOAN: "CARS24_COVER_ADD_TO_LOAN",
    CARS24_COVER_PAY_OUTRIGHT: "CARS24_COVER_PAY_OUTRIGHT",
    //CARS24_COVER_EW_ADD: " CARS24_COVER_EW_ADD",
    //CARS24_COVER_EW_SKIP: " CARS24_COVER_EW_PROCEED",
    //CARS24_COVER_EW_PLAN: " CARS24_COVER_EW_ADD",
    GET_VALUATION: "GET_VALUATION",
    GET_VALUATION_VIEWED: "GET_VALUATION_VIEWED",
    HEADER_BUY_A_CAR: "HEADER_BUY_A_CAR",
    POPULAR_MAKE: "POPULAR_MAKE",
    VIEW_ALL_MAKES: "VIEW_ALL_MAKES",
    VIEW_ALL_MODELS: "VIEW_ALL_MODELS",
    POPULAR_MODEL: "POPULAR_MODEL",
    BODY_TYPE_SELECTED: "BODY_TYPE_SELECTED",
    FUEL_TYPE_SELECTED: "FUEL_TYPE_SELECTED",
    C2BCARDLISTING: "C2BCARDLISTING",
    SELL_OR_TRADE_IN_CAR: "SELL_OR_TRADE_IN_CAR",
    CAR_VALUE_CALCULATOR: "CAR_VALUE_CALCULATOR",
    SELL_CITY_WISE_PAGE: "CAR_VALUE_CALCULATOR",

    LISTING_PAGE: "listing_page",
    CARS24_AU_ALL_PAGES: "cars24_au_all_pages",
    LISTING_PAGE_SEARCH_AUTO_SUGGESTION_APPLIED: "search_auto_suggestion_applied",
    LISTING_PAGE_POPULAR_SEARCH: "popular_search",
    LISTING_PAGE_RECENTLY_SEARCHED: "recently_searched",

    DETAIL_NAME: "Detail_Name",
    DELIVERY: "Delivery",
    NEED_DIFFERENT_DATE: "need_different_date",
    NOTIFY_ME: "notify_me",
    GOT_IT: "got_it",
    TEST_DRIVE_TC: "test_drive_TC",
    OPEN: "open",
    CLOSE: "close",
    ORDER_SUMMARY: "order_summary",
    VIEW_RECEIPT: "view_receipt",
    MAP_LINK_CLICKED: "map_link_clicked",
    TEST_DRIVE_DATE_MODEL: "test_drive_date_model",
    NOTIFY_ME_POP_UP: "notify_me_pop_up",
    PAYABLE_LATER: "payable_later",
    ORDER_SUMMARY_POST_PAYMENT: "order_summary_post_payment",
    BACK_TO_YOUR_CAR: "back_to_your_car",
    CROSS: "cross",
    CONFIRM: "confirm",
    CANCEL: "cancel",
    CTA_SELECTED: "cta_selected",
    SELECT_CAR: "select_car",
    SEE_FULL_DETAILS: "see_full_details",
    CONFIRMATION_POP_UP: "confirmation_pop_up",
    ddd: "Cars24_ddd",
    FINANCE_BANNER: "FINANCE_BANNER",
    FINANCE_BANNER_GET_PRE_APPROVAL_CTA: "GET_PRE_APPROVAL_CTA",
    FINANCE_BANNER_SELECT_MAKE: "SELECT_MAKE",
    FINANCE_BANNER_SELECT_MODEL: "SELECT_MODEL",
    FINANCE_BANNER_SHOW_ALL_CARS: "SHOW_ALL_CARS",
    PRE_APPROVAL_CTA: "pre_approval_cta",

    GREEN_ID_BUTTON_CLICK: "GREEN_ID_BUTTON_CLICK",
    TEST_DRIVE_CANCEL_POP_UP: "TEST_DRIVE_CANCEL_POP_UP",
    TEST_DRIVE_CANCELLED_POP_UP: "TEST_DRIVE_CANCELLED_POP_UP",
    SELL_ORDER_BOOKING: "sell_order_booking",
    TEST_DRIVE_BUTTON_CLICKED: "test_drive_button_clicked",
    WHATSAPP_CHAT_WIDGET_CLICKED: "whatsapp_chat_widget_clicked",
    CARS24_AU_ALL: "Cars24_au_all_pages",

    MY_SELL_ORDERS_SCREEN: "my_sell_orders_screen",
    BOOK_VIRTUAL_INSPECTION: "book_virtual_inspection",
    FINISH_VALUATION_FORM: "finish_valuation_FORM",
    MENU_YOUR_ORDERS: "MENU_YOUR_ORDERS",
    CAR_COMPARE_CDP: "car_compare_cdp",
    CLICK_ADD_TO_LOAN: "Click_Add_to_Loan",
    VIEW_FINANCE: "View_Finance",
    FINANCE: "Finance",
    LOAN_OFFERS_TOP_PLENTI: "Loan_offers_Top_Plenti",
    LOAN_OFFERS_OTHER_OPEN: "Loan_offers_Other_Open",
    LENDER_DETAILS_PLENTI: "Lender_details_Plenti",
    VIEW_CARS_BY_MAKE: "VIEW_CARS_BY_MAKE",
    VIEW_CARS_BY_MODEL: "VIEW_CARS_BY_MODEL",
    CARS24_COVER_WARRANTY: "Cars24_cover",
    HOME_PAGE_NUDGES: "Home_page_nudges",
    MY_TASKS_SUMMARY: "My_tasks_summary",
    STAGE_2_FORMS: "stage_2_forms",
    CONTINUEPURCHASE: "continuepurchase",

    EW_Screen_Pop_up: "EW_Screen Pop up",
    EW_Screen_Pop_up_cash: "EW_Screen Pop up_cash",
    EW_Screen_Pop_up_finance: "EW_Screen Pop up_finance",
    EW_Screen_Pop_up_BYO: "EW_Screen Pop up_BYO",
    EW_Coverage_Details_pop_up: "EW_Coverage_Details_pop up",
    EW_Compare_pop_up: "EW_Compare_pop up",
    EW_CARS24_COVER: "CARS24 COVER",

    ACCOUNT_DELETION_IN_PROGRESS: "ACCOUNT_DELETION_IN_PROGRESS",
    LISTING_DISCOUNT_VISIBLE: "discount_visible",
    DETAIL_DISCOUNT_VISIBLE: "discount_visible",
    LISTING_BELOW_MARKET_CLICKED: "below_market_clicked",
    DETAIL_BELOW_MARKET_CLICKED: "below_market_clicked",

    CAR_LOCATION: "car_location",
    PAYMENT_MODE_SCREEN_NON_PREAPPROVED: "payment_mode_screen_non_preapproved",
    TRADE_IN_REJECTION_CONTINUE: "trade_in_rejection_continue",
    PRE_APPROVAL_FLOW: "pre_approval_flow",
    EDIT_FLOW: "edit_flow",
    SHOW_MINIMUM_SPLIT_VARIANT: "show_minimum_split_variant",
    PRE_APPROVED_LOAN_LIMIT: "pre_approved_loan_limit",
    PRE_APPROVED_LOAN_VIEW_CARS: "pre_approved_loan_view_cars",
    FILTER_REVAMP_FEATURES: "filter_features",
    FILTERS_KM_SUGGESTION: "filters_KM_Suggestion",
    FILTERS_YEAR_SUGGESTION: "filters_Year_Suggestion",
    FILTERS_PRICE_SUGGESTION: "filters_Price_Suggestion",
    DRIVE_AWAY_PRICE_CTA: "drive_away_price_CTA",
    PRE_APPROVED_LOAN_OFFERS: "pre_approved_loan_offers",
    SORT_APPLIED: "sort_applied",
    CDP_DELIVERY_TIMELINE: "cdp_delivery_timeline",

    // SUPPORT WIDGET
    SUPPORT_WIDGET_HELP_ICON_AB: "help_icon_a/b",
    SUPPORT_WIDGET_HELP_ICON_CLICKED: "help_icon_clicked",
    SUPPORT_WIDGET_CTA_SELECT: "Help_Interface_CTA_Selected",
    SUPPORT_WIDGET_FORM_SUBMIT_EMAIL_SELECT: "Email_selected",
    DIRECT_BANK_TRANSFER: "direct_bank_transfer",
    UPLOAD_PHOTO: "upload_photo",
    SUBMIT_PHOTO: "submit_photo",

    // STAGE TWO
    HELP_BUTTON: "Help button",

    // user login events GA4
    SIGNIN: "signin",
    SIGNOUT: "signout",
    CAR_SEARCH: "search",

    ZERO_DP_HOMEPAGE: "zero_dp_homepage",
    ZERO_DP_PRE_APPROVAL: "zero_dp_pre_approval",
    ZERO_DP_CLP: "zero_dp_clp",
    ZERO_DP_CDP: "zero_dp_cdp",
    ZERO_DP_CHECKOUT: "zero_dp_checkout",
    ZERO_DP_LOAN_OFFERS_SCREEN: "zero_dp_loan_offers_screen",
    ZERO_DP_STAGE2: "zero_dp_stage2",
    "EMAIL_ALREADY_EXIST_SCREEN": "email_already_exist_screen",
    "LOGIN_OPTION_SELECTED": "login_option_selected",
    "VERIFICATION_MODAL": "verification_modal",
    "TRADEIN_SCREEN": "tradein_screen",
    "BASIC_DETAILS_SCREEN": "basic_details_screen",
    BUY_ONLINE_BASIC_DETAILS_SCREEN_FORM_FILLED: "filled_personal_details_buy_online",
    BUY_ONLINE_BASIC_DETAILS_SCREEN_COMPLETED: "basic_details_completed_buy_online",
    "EXIT_INTENT": "exit_intent",
    "POST_BC_SCREEN": "post_bc_screen",
    GUEST_CAR_RESERVED: "Guest_car_reserved",
    GUEST_EMAIL_VERIFICATION: "Guest_Email_verification",
    GUEST_CAR_RESERVE_AT_NON_ZERO: "GUEST_CAR_RESERVE_AT_NON_ZERO",
    GUEST_CAR_RESERVE_AT_ZERO: "Guest_car reserve at zero",
    // WINDOW TINTING
    PRE_APPROVED_NO: "Pre_approved_No",
    PRE_APPROVED_YES: "Pre_approved_Yes",
    ORDER_SUMMARY_FINANCE: "Order summary_Finance",
    ORDER_SUMMARY_CASH: "Order summary_Cash",
    HELP_SUPPORT_ICON_CLICKED: "HELP_SUPPORT_ICON_CLICKED",
    MY_ACCOUNT_SELL_OR_TRADE_IN_SCREEN: "sell_or_trade_in_screen",
    MY_ACCOUNT_CTA_CLICKED: "my_account_cta_clicked",
    MY_ACCOUNT_SELL_OR_TRADE_IN: "my_account_sell_or_trade_in",
    S20_VIEWER_AND_GALLERY: "s20_viewer_and_gallery",
    SEARCH_NO_RESULT_SCREEN: "search_no_result_screen",
    DESKTOP_CLP_REVAMP: "desktop_clp_revamp",

    CALLBACK_REQUEST_MODAL_OPENED: "call back request pop up",
    CALLBACK_REQUEST_LINK_CLICKED: "call back request link",
    DELIVERY_HUB_ADDRESS_CLICKED: "hub address",
    ORDER_SUMMARY_PAGE: "Order summary page",

    CARS24_CHECKOUT_PAGE: "Cars24_checkout_page",
    FIND_CAR_DELIVERY_CHECKOUT: "Cars24_checkout_page",
    CDP_NO_SERVICE_OLD_TIMER: "CDP_NO_SERVICE_OLD_TIMER",
    CDP_NO_SERVICE_OLD_TIMER_VIEWED: "CDP_NO_SERVICE_OLD_TIMER_VIEWED",

    FINANCE_LANDING_PAGE_USER_LANDED: "Finance landing page_user_landed",
    FINANCE_LANDING_PAGE_CHECK_PRE_APPROVAL: "Finance landing page_check_pre_approval",
    FINANCE_LANDING_PAGE_BROWSE_CARS: "Finance landing page_browse_cars",
    FINANCE_LANDING_PAGE_WHAT_HAPPENS_AFTER_LINK: "Finance landing page_what_happens_after_link",
    FINANCE_LANDING_PAGE_TOP_CAPSULES: "Finance landing page_top_capsules",
    FINANCE_LANDING_PAGE_CALCULATOR: "Finance landing page_calculator",
    NUDGES_FINANCE_KNOW_MORE_FINANCE: "Nudges_finance_know_more_finance",
    MENU_FINANCING_CLICKED: "Menu_financing_clicked",
    BYOF_LANDING_PAGE: "BYOF_LANDING PAGE",
    FF_VOLUME_EXPERIMENT: "FF_volume_experiment",
    TESTDRIVE_CDP_LOCATION: "testdrive_cdp_location",
    CDP_BI_CTA: "cdp_bi_cta",
    ESTIMATED_VALUATION_RANGE_VARIANT: "estimated_valuation_range_variant",
    EW_CHECKOUT_NO_SERVICE_OLD_TIMER: "EW_CHECKOUT_NO_SERVICE_OLD_TIMER",

    TEST_DRIVE_V2_TEST_DRIVE_BANNER_SEEN: "test drive banner seen",
    TEST_DRIVE_V2_CTA_SELECTED: "cta_selected",
    TEST_DRIVE_V2_ATF_INTERACTIONS: "ATF_interactions",
    TEST_DRIVE_V2_DETAIL_PERSONAL: "filled_personal_details_test_drive",
    TEST_DRIVE_V2_BASIC_DETAILS_FILLED: "basic_details_completed_test_drive",
    TEST_DRIVE_V2_BACK: "back",
    TEST_DRIVE_V2_CHECKOUT: "checkout",
    TEST_DRIVE_V2_CHECKOUT_WHATSAPP_CONSENT: "checkout_whatsapp_consent",
    TEST_DRIVE_V2_NEXT: "next",
    TEST_DRIVE_V2_FINANCE_BACK: "finance (back)",
    TEST_DRIVE_V2_FINANCE_NEXT: "finance next",
    TEST_DRIVE_V2_BOOK_SLOT_BACK: "book slot back",
    TEST_DRIVE_V2_BOOK_SLOT_NEXT: "book slot next",
    TEST_DRIVE_V2_DELIVERY_HUB_ADDRESS: "Delivery Hub address",
    TEST_DRIVE_V2_BOOKING_DAY_SELECTED: "Booking day selected",
    TEST_DRIVE_V2_BOOKING_TIME_SELECTED: "Booking time selected",
    TEST_DRIVE_V2_EDIT_PICKUP_LOCATION_CLICKED: "edit pickup location clicked",
    TEST_DRIVE_V2_EDIT_PICKUP_POPUP_INTERACTION: "edit pickup popup interaction",
    TEST_DRIVE_V2_BOOKING_CONFIRM_POPUP_SEEN: "Booking Confirm popup seen",
    TEST_DRIVE_V2_BOOKING_RESCHEDULE_POPUP_SEEN: "Booking Res popup seen",
    TEST_DRIVE_V2_BOOKING_CANCEL_POPUP_SEEN: "Booking Cancel popup seen",
    TEST_DRIVE_V2_BOOKING_RESCHEDULE_POPUP_CLOSED: "Booking Reschedule popup closed",
    TEST_DRIVE_V2_BOOKING_CANCEL_POPUP_CLOSED: "Booking Cancel popup closed",
    TEST_DRIVE_V2_BOOKING_CONFIRMATION_BACK: "booking confirmation (back)",
    TEST_DRIVE_V2_BOOKING_CONFIRMATION_BANNER_SEEN: "booking confirmation banner seen",
    TEST_DRIVE_V2_BANNER_CLICKED: "banner clicked",
    TEST_DRIVE_V2_BOOKING_CONFIRMATION_BUY_ONLINE: "booking confirmation (buy online)",
    TEST_DRIVE_V2_BOOKING_CONFIRMATION_RESCHEDULE: "booking confirmation (reschedule)",
    TEST_DRIVE_V2_BOOKING_CONFIRMATION_CANCEL: "booking confirmation (cancel)",
    TEST_DRIVE_V2_BOOKING_CANCELLATION: "booking cancellation",
    TEST_DRIVE_V2_TD_POPUP_INTERACTION: "TD pop up interaction",
    TEST_DRIVE_V2_TD_USER_TYPE: "Non TG/TG Test/TG Control",
    TEST_DRIVE_LOCATION_EXPOSURE: "test-drive-location",
    TEST_DRIVE_V2_TD_STRIP_INTERACTION: "TD Strip Interaction",
    TEST_DRIVE_V2_TD_STRIP_POPUP_CLOSED: "TD Strip Popup Closed",
    TEST_DRIVE_V2_PRELIMINARY_SCREEN: "Self booking screen opened",

    PAYMENT_MODE_PAGE_LANDED: "Payment_mode_page_landed",
    PAYMENT_MODE_PAGE_CLICKED: "Payment_mode_page_clicked",
    NEXT_STEPS_POPUP_TYPE: "Next_steps_pop_up_type",
    LOAN_QUOTES_PAGE: "Loan quotes page",

    INTEREST_RATE_MATCH: "Interest Rate Match",

    RECENTLY_VIEWED_CAR_CLICK: "recently_viewed_car_click",
    RECOMMENDED_CAR_CLICK: "recommended_car_click",
    RECENTLY_ADDED_CAR_CLICK: "recently_added_car_click",
    PICKS_FOR_YOU_CAR_CLICK: "picks_for_you_car_click",
    CARS_BY_PRICE_CLICK: "cars_by_price_click",
    CARS_BY_BODYTYPE_CLICK: "cars_by_bodytype_click",
    GALLERY_OPENED: "gallery_opened",

    INFO_ICON_REPAYMENT: "Infoicon_repayment",

    WEB_CUSTOM_STATE_EXPERIMENT: "web-custom-state-experiment",
    SELL_TRADE_BANNER: "sell_trade_banner",
    DESKTOP_CDP_REVAMP: "web-desktop-cdp-revamp",
    TD_UUSERSEEN_SLOTS: "TD_UserSeen_Slots",
    TD_AVAILABLE_SLOTS: "TD_Available_Slots",
    TEST_DRIVE_VIEW_BOOKING: "Test drive view booking",
    MY_BOOKINGS_TEST_DRIVE_BOOK: "book test drive",
    MY_BOOKINGS_VIEW_SIMILAR_CAR: "View similar clicked",
    MY_BOOKINGS_NOTIFY_ME: "Notify me clicked",
    MY_BOOKINGS_LANDING: "My Bookings opened",
    CAR_DETAILS_COMPARE_NOTIFY_IF_AVAILABLE: "compareNotifyIfAvailable",
    CAR_DETAILS_COMPARE_REMOVE_FROM_WISHLIST: "compareRemoveFromWishlist",
    BUY_NOW: "Buy Now",

    SELL_SURVEY_VIEWED: "sell_survey_viewed",
    SELL_SURVEY_TAGGED: "sell_survey_tagged",
    POPULAR_CATEGORIES_SEEN: "popular categories seen",

    PRE_BC_CAR_CARE_PACKS_LANDING_PAGE: "Pre BC_car_care_packs_landing page",
    PRE_BC_CAR_CARE_PACKS_VAS_STORE: "Pre BC_car_care_packs_VAS_store",
    PRE_BC_CAR_CARE_PACKS_CART: "Pre BC_car_care_packs_Cart",
    PRE_BC_CAR_CARE_PACKS_PRODUCT_DETAIL_PAGE: "Pre BC_car_care_packs_product detail _page",
    MMVY_DETIAL_ENTERED: "mmvy_detail_entered"
};

export default {
    [AU_DESKTOP_EVENTS.HEADER_LOGO]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "Cars24_logo"
    },
    [AU_DESKTOP_EVENTS.LOAN_QUOTES_SCREEN]: {
        event: "custom_event",
        eventCategory: "Loan quotes screen"
    },
    [AU_DESKTOP_EVENTS.HEADER_WISHLIST_OPEN]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "header_wishlist_open"
    },
    [AU_DESKTOP_EVENTS.HEADER_EMAIL_SELECTED]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "header_email_selected"
    },
    [AU_DESKTOP_EVENTS.HEADER_PHONE_SELECTED]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "header_phone_selected"
    },
    [AU_DESKTOP_EVENTS.CARS24_DETAIL_PAGE]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page"
    },

    // footer events

    [AU_DESKTOP_EVENTS.FOOTER_LINKS]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "useful_links"
    },
    [AU_DESKTOP_EVENTS.FOOTER_COUNTRY]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "country_selector"
    },

    // hamburger events

    [AU_DESKTOP_EVENTS.HAMBURGER_MENU_OPEN]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "hamburger_menu_open",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.HAMBURGER_MENU_OPTIONS]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "hamburger_menu_options"
    },
    [AU_DESKTOP_EVENTS.HAMBURGER_MENU_CLOSE]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "hamburger_menu_close",
        eventLabel: "NA"
    },

    // login events

    [AU_DESKTOP_EVENTS.LOGIN_EMAIL_ENTERED]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "login_email_id_entered"
    },
    [AU_DESKTOP_EVENTS.LOGIN_METHOD]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "login_method"
    },
    [AU_DESKTOP_EVENTS.LOGIN_SUCCESSFULL_GOOGLE]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "Successful_google_login"
    },
    [AU_DESKTOP_EVENTS.LOGIN_VERIFY_OTP]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "login_verify_otp"
    },
    [AU_DESKTOP_EVENTS.LOGIN_VERIFY]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "login_verify"
    },
    [AU_DESKTOP_EVENTS.LOGIN_CLOSE]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "login_close"
    },
    [AU_DESKTOP_EVENTS.LOGIN_VERIFY_CLOSE]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "login_verify_close"
    },
    [AU_DESKTOP_EVENTS.LOGIN_FOOTER_CTA]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "login_footer_cta"
    },

    [AU_DESKTOP_EVENTS.LOGIN_INITIATED]: {
        event: "custom_event",
        eventCategory: "Login",
        eventAction: "Login initiated"
    },
    [AU_DESKTOP_EVENTS.LOGIN_MODE_SELECTED]: {
        event: "custom_event",
        eventCategory: "Login",
        eventAction: "Login mode selected"
    },
    [AU_DESKTOP_EVENTS.LOGIN_SUCCESS]: {
        event: "custom_event",
        eventCategory: "Login",
        eventAction: "Login success"
    },

    [AU_DESKTOP_EVENTS.OTP_ENTERED]: {
        event: "custom_event",
        eventCategory: "Login",
        eventAction: "OTP Entered"
    },
    [AU_DESKTOP_EVENTS.OTP_VERIFICATION_FAILED]: {
        event: "custom_event",
        eventCategory: "Login",
        eventAction: "OTP Verification Failed"
    },
    [AU_DESKTOP_EVENTS.OTP_VERIFICATION_SUCCESS]: {
        event: "custom_event",
        eventCategory: "Login",
        eventAction: "OTP Verification Success"
    },
    // homepage events

    [AU_DESKTOP_EVENTS.HOMEPAGE_GET_IN_TOUCH]: {
        event: "custom_event",
        eventCategory: "Cars24_home_page",
        eventAction: "get_in_touch"
    },
    [AU_DESKTOP_EVENTS.HOMEPAGE_CAR_LISTING_CARD_OPEN]: {
        event: "custom_event",
        eventCategory: "Cars24_home_page",
        eventAction: "car_listing_card_open",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.HOMEPAGE_SEE_CARS_LISTING]: {
        event: "custom_event",
        eventCategory: "Cars24_home_page",
        eventAction: "see_cars_listing"
    },
    [AU_DESKTOP_EVENTS.HOMEPAGE_LOAD_MORE]: {
        event: "custom_event",
        eventCategory: "Cars24_home_page",
        eventAction: "load_more"
    },
    [AU_DESKTOP_EVENTS.HOMEPAGE_BANNER_CHANGE]: {
        event: "custom_event",
        eventCategory: "Cars24_home_page",
        eventAction: "Value_prop_banners"
    },
    [AU_DESKTOP_EVENTS.RESUME_JOURNEY_WISHLIST]: {
        event: "custom_event",
        eventCategory: "Cars24_home_page",
        eventAction: "resume_journey_wishlist"
    },
    [AU_DESKTOP_EVENTS.RESUME_JOURNEY_BUYING]: {
        event: "custom_event",
        eventCategory: "Cars24_home_page",
        eventAction: "resume_journey_buying"
    },
    [AU_DESKTOP_EVENTS.RESUME_JOURNEY_BC]: {
        event: "custom_event",
        eventCategory: "Cars24_home_page",
        eventAction: "resume_journey_bc"
    },
    [AU_DESKTOP_EVENTS.RESUME_JOURNEY_TESTDRIVE]: {
        event: "custom_event",
        eventCategory: "Cars24_home_page",
        eventAction: "resume_journey_testdrive'"
    },

    // listing page events

    [AU_DESKTOP_EVENTS.LISTING_PAGE_CARD_OPEN]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "car_listing_card_open"
    },
    [AU_DESKTOP_EVENTS.LISTING_PAGE_LOAD_MORE]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "load_more"
    },

    // filters events
    [AU_DESKTOP_EVENTS.FILTERS_SEARCH_SUGGESTED_BRANDS]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "search_suggested_brands"
    },
    [AU_DESKTOP_EVENTS.FILTERS_SEARCH_SUGGESTED_MODELS]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "search_suggested_models"
    },
    [AU_DESKTOP_EVENTS.FILTERS_OPEN]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_open"
    },
    [AU_DESKTOP_EVENTS.FILTERS_CLOSE]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_close"
    },
    [AU_DESKTOP_EVENTS.FILTERS_SHOW_CARS]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_show_cars"
    },
    [AU_DESKTOP_EVENTS.FILTERS_PRICE]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_price"
    },
    [AU_DESKTOP_EVENTS.FILTERS_KM]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_km"
    },
    [AU_DESKTOP_EVENTS.FILTERS_YEAR]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_year"
    },
    [AU_DESKTOP_EVENTS.FILTERS_MORE_FUEL_TYPE_ON]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_more_fuel_type_on"
    },
    [AU_DESKTOP_EVENTS.FILTERS_MORE_FUEL_TYPE_OFF]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_more_fuel_type_off"
    },
    [AU_DESKTOP_EVENTS.FILTERS_MORE_TRANSMISSION_TYPE_ON]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_more_transmission_type_on"
    },
    [AU_DESKTOP_EVENTS.FILTERS_MORE_TRANSMISSION_TYPE_OFF]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_more_transmission_type_off"
    },
    [AU_DESKTOP_EVENTS.FILTERS_MORE_BODY_TYPE_ON]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_more_body_type_on"
    },
    [AU_DESKTOP_EVENTS.FILTERS_MORE_BODY_TYPE_OFF]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_more_body_type_off"
    },
    [AU_DESKTOP_EVENTS.FILTERS_MAKE_MODEL_SEARCH]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_make_model_search"
    },
    [AU_DESKTOP_EVENTS.FILTERS_MAKE_ON]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_make_on"
    },
    [AU_DESKTOP_EVENTS.FILTERS_MAKE_OFF]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_make_off"
    },
    [AU_DESKTOP_EVENTS.FILTERS_MODEL_ON]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_model_on"
    },
    [AU_DESKTOP_EVENTS.FILTERS_MODEL_OFF]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_model_off"
    },
    [AU_DESKTOP_EVENTS.FILTERS_MORE_SORT_TAB_OPEN]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_more_sort_tab_open"
    },
    [AU_DESKTOP_EVENTS.FILTERS_MORE_FILTER_TAB_OPEN]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_more_filter_tab_open"
    },
    [AU_DESKTOP_EVENTS.FILTERS_SORT_ATTRIBUTE_SELECTED]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "sort_attribute_selected"
    },
    [AU_DESKTOP_EVENTS.FILTERS_CLEAR]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "clear_filter"
    },
    [AU_DESKTOP_EVENTS.FILTERS_CLEAR_ALL]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "clear_all_filters"
    },

    // car details events

    [AU_DESKTOP_EVENTS.HOW_IT_WORKS]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "how_it_works"
    },

    [AU_DESKTOP_EVENTS.CAR_DETAILS_QUALITY_SPECIFICATION_TABS]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "Quality_promise_tab_selected"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_QUALITY_SPECIFICATION_LINKS]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "Quality_promise_link_selected"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_SIMILAR_CARS]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "similar_cars_selected"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_CAR_SPECIFICATION]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "about_this_model_specifications"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_QUALITY_LINKS]: {
        event: "custom_event",
        eventCategory: "Cars24_car_quality",
        eventAction: "redirection_link"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_CAR_SPECIFICATION_SELECTED_OPEN]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "specification_selected_open"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_CAR_SPECIFICATION_SELECTED_CLOSE]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "specification_selected_close"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_CAR_FEATURE_SELECTED_OPEN]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "feature_selected_open"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_CAR_FEATURE_SELECTED_CLOSE]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "feature_selected_close"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_CARS24_BENEFITS_OPEN]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "CARS24_benefits_selected_open"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_CARS24_BENEFITS_CLOSE]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "CARS24_benefits_selected_closed"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_IMAGE_GALLERY_VIEW]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "Image_gallery_view"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_IMAGE_GALLERY_360VIEW]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "Image_gallery_view_360view"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_IMAGE_GALLERY_SIDEBAR]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "Image_gallery_sidebar"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_IMAGE_GALLERY_NAVIGATION]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "Image_gallery_navigation"
    },
    [AU_DESKTOP_EVENTS.BUY_ONLINE_CTA_CLICKED]: {
        event: "cdp_cta_selected",
        eventCategory: "Cars24_detail_page",
        eventAction: "cta_selected",
        eventLabel: "buy_online"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_ATF_INTERACTIONS]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "ATF_interactions"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_PRICE_BREAK_DOWN]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "price_break_down"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_PRICE_BREAK_DOWN_CLOSE]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "price_break_down_close"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_IMAGE_GALLERY_VIEW_CLOSE]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "Image_gallery_close"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_PRICE_BREAK_DOWN_GET_STARTED]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "price_break_down_get_started"
    },
    // 300 point inspection events

    [AU_DESKTOP_EVENTS.INSPECTION_ATTRIBUTE_OPEN]: {
        event: "custom_event",
        eventCategory: "300_pt_inspection_shown",
        eventAction: "Attribute_selected_open"
    },
    [AU_DESKTOP_EVENTS.INSPECTION_ATTRIBUTE_CLOSE]: {
        event: "custom_event",
        eventCategory: "300_pt_inspection_shown",
        eventAction: "Attribute_selected_close"
    },

    // wishlist events

    [AU_DESKTOP_EVENTS.WISHLIST_CTA_SELECTED]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "wishlist_cta_selected"
    },
    [AU_DESKTOP_EVENTS.WISHLIST_CTA_DESELECTED]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "wishlist_cta_deselected"
    },
    [AU_DESKTOP_EVENTS.WISHLIST_CARD_SELECTED]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "wishlist_card_selected"
    },
    [AU_DESKTOP_EVENTS.WISHLIST_SEE_ALL_CARS]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "wishlist_browse_cars"
    },
    [AU_DESKTOP_EVENTS.WISHLIST_CLOSE]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "wishlist_close"
    },

    // checkout events

    [AU_DESKTOP_EVENTS.CHECKOUT_NEXT]: {
        event: "custom_event",
        eventCategory: "Cars24_ddd",
        eventAction: "next"
    },
    [AU_DESKTOP_EVENTS.CHECKOUT_BACK]: {
        event: "custom_event",
        eventCategory: "Cars24_ddd",
        eventAction: "back"
    },
    [AU_DESKTOP_EVENTS.WHATSAPP_CONSENT_USED]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "checkout_whatsapp_consent"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVAL_SERVICEABILITY_ERROR_SCREEN]: {
        event: "custom_event",
        eventCategory: "Pre_approval_error",
        eventAction: "Car_serviceability",
        eventLabel: "{{Error}}"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVAL_OTHER_PAYMENT_METHODS]: {
        event: "custom_event",
        eventCategory: "Pre_approval_error",
        eventAction: "Car_serviceability",
        eventLabel: "{{Other payment methods}}"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVAL_VIEW_OTHER_CARS]: {
        event: "custom_event",
        eventCategory: "Pre_approval_error",
        eventAction: "Car_serviceability",
        eventLabel: "{{View_Other_Cars}}"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVAL_CLOSE_LOAN_TERMS_MODAL]: {
        event: "custom_event",
        eventCategory: "Pre_approval_error",
        eventAction: "Car_serviceability",
        eventLabel: "{{Close_popup}}"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVAL_UPDATE_LOAN_TERMS]: {
        event: "custom_event",
        eventCategory: "Pre_approval_error",
        eventAction: "Car_serviceability",
        eventLabel: "{{Loan terms_Update}}"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVAL_EDIT_LOAN_TERMS]: {
        event: "custom_event",
        eventCategory: "Pre_approval_error",
        eventAction: "Car_serviceability",
        eventLabel: "{{Edit loan terms}}"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVAL_NEXT]: {
        event: "custom_event",
        eventCategory: "Pre_approval_page",
        eventAction: "next"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVAL_BACK]: {
        event: "custom_event",
        eventCategory: "Pre_approval_page",
        eventAction: "back"
    },
    [AU_DESKTOP_EVENTS.CHECKOUT_PHONE_VERIFY]: {
        event: "custom_event",
        eventCategory: "Cars24_ddd",
        eventAction: "phone_verify"
    },
    [AU_DESKTOP_EVENTS.CHECKOUT_PERSONAL_DETAILS]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "Detail Personal"
    },
    [AU_DESKTOP_EVENTS.CHECKOUT_DELIVERY_DETAILS]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "Detail delivery"
    },
    [AU_DESKTOP_EVENTS.CHECKOUT_DELIVERY_DETAILS_WITH_ADDRESS]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "delivery_screen"
    },
    [AU_DESKTOP_EVENTS.CHECKOUT_CHIP_NAVIGATION]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "Navigation_bar_chips"
    },
    [AU_DESKTOP_EVENTS.CHECKOUT_ORDER_SUMMARY]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "order_summary"
    },
    [AU_DESKTOP_EVENTS.CHECKOUT_ORDER_CONFIRMATION]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "order_confirmation"
    },
    [AU_DESKTOP_EVENTS.CHECKOUT_ORDER_CONFIRMATION_PENDING]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "payment_pending"
    },
    [AU_DESKTOP_EVENTS.CHECKOUT_ORDER_CONFIRMATION_SUMMARY_POPUP]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "order_summary_post_payment"
    },
    [AU_DESKTOP_EVENTS.CHECKOUT_HEADER_CALL]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "contact"
    },
    [AU_DESKTOP_EVENTS.CHECKOUT_PAYMENT_FAILURE]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "payment_failure"
    },
    [AU_DESKTOP_EVENTS.CHECKOUT_PAYMENT_OPTIONS]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "payment_method"
    },

    // driva flow

    [AU_DESKTOP_EVENTS.DRIVA_VALUE_PROP]: {
        event: "custom_event",
        eventCategory: "Driva_value_prop",
        eventAction: "cta_selected"
    },
    [AU_DESKTOP_EVENTS.DRIVA_DEPOSIT_INPUT]: {
        event: "custom_event",
        eventCategory: "Driva_deposit",
        eventAction: "deposit_input"
    },
    [AU_DESKTOP_EVENTS.DRIVA_DEPOSIT_SLIDER]: {
        event: "custom_event",
        eventCategory: "Driva_deposit",
        eventAction: "deposit_slider"
    },
    [AU_DESKTOP_EVENTS.DRIVA_LOAN_TENURE_OPTION]: {
        event: "custom_event",
        eventCategory: "Driva_loan_tenure",
        eventAction: "option_selected"
    },
    [AU_DESKTOP_EVENTS.DRIVA_BALLOON_CLICK]: {
        event: "custom_event",
        eventCategory: "balloon",
        eventAction: "cta_selected"
    },
    [AU_DESKTOP_EVENTS.DRIVA_BALLOON_OPTION]: {
        event: "custom_event",
        eventCategory: "Driva_balloon",
        eventAction: "balloon_amount_selected"
    },
    [AU_DESKTOP_EVENTS.DRIVA_LOAN_TYPE]: {
        event: "custom_event",
        eventCategory: "Driva_business_purpose",
        eventAction: "option_selected"
    },
    [AU_DESKTOP_EVENTS.DRIVA_LOAN_TYPE_ADDITIONAL]: {
        event: "custom_event",
        eventCategory: "Driva_business_purpose",
        eventAction: "business_yes_additional_questions"
    },
    [AU_DESKTOP_EVENTS.DRIVA_NAME_DETAILS]: {
        event: "custom_event",
        eventCategory: "Driva_personal_detail",
        eventAction: "Name_details_selected"
    },
    [AU_DESKTOP_EVENTS.DRIVA_DOB]: {
        event: "custom_event",
        eventCategory: "Driva_DOB",
        eventAction: "DOB_selected"
    },
    [AU_DESKTOP_EVENTS.DRIVA_RESIDENCY]: {
        event: "custom_event",
        eventCategory: "Driva_residency_status",
        eventAction: "option_selected"
    },
    [AU_DESKTOP_EVENTS.DRIVA_RESIDENCY_VISA]: {
        event: "custom_event",
        eventCategory: "Driva_residency_status",
        eventAction: "visa_class_number"
    },
    [AU_DESKTOP_EVENTS.DRIVA_ADDRESS]: {
        event: "custom_event",
        eventCategory: "Driva_address",
        eventAction: "Address_selected"
    },
    [AU_DESKTOP_EVENTS.DRIVA_ADDRESS_TENURE]: {
        event: "custom_event",
        eventCategory: "Driva_address_length",
        eventAction: "Tenure_selected"
    },
    [AU_DESKTOP_EVENTS.DRIVA_LIVING_SITUATION]: {
        event: "custom_event",
        eventCategory: "Driva_living_situation",
        eventAction: "option_selected"
    },
    [AU_DESKTOP_EVENTS.DRIVA_EMPLOYMENT_STATUS]: {
        event: "custom_event",
        eventCategory: "Driva_employment_status",
        eventAction: "option_selected"
    },
    [AU_DESKTOP_EVENTS.DRIVA_SELECT_OFFER]: {
        event: "custom_event",
        eventCategory: "Driva_pre_approved",
        eventAction: "cta_selected"
    },
    [AU_DESKTOP_EVENTS.DRIVA_CUSTOMIZE]: {
        event: "custom_event",
        eventCategory: "Driva_customize",
        eventAction: "variable_selected"
    },
    [AU_DESKTOP_EVENTS.DRIVA_REJECTION]: {
        event: "custom_event",
        eventCategory: "Driva_rejection",
        eventAction: "cta_selected"
    },
    [AU_DESKTOP_EVENTS.DRIVA_ORDER_SUMMARY]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "Driva_order_summary"
    },
    [AU_DESKTOP_EVENTS.DRIVA_ORDER_CONFIRMATION]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "Driva_order_confirmation"
    },
    [AU_DESKTOP_EVENTS.DRIVA_ORDER_CONFIRMATION_PENDING]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "Driva_order_confirmation_pending" // TODO @Raghav !!!
    },
    [AU_DESKTOP_EVENTS.DRIVA_ORDER_CONFIRMATION_SUMMARY_POPUP]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "Driva_order_summary_post_payment"
    },
    [AU_DESKTOP_EVENTS.DRIVA_PRE_APPROVED_SCREEN]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "driva_pre_approved_screen"
    },
    [AU_DESKTOP_EVENTS.DRIVA_OFFER_SCREEN]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "driva_offer_screen"
    },
    [AU_DESKTOP_EVENTS.DRIVA_OFFER_SELECT_SCREEN]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "offer_select_screen"
    },

    // pre-approved loan

    [AU_DESKTOP_EVENTS.PRE_APPROVED_PROVIDER]: {
        event: "custom_event",
        eventCategory: "Pre_approved",
        eventAction: "loan_provider_name"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVED_SUBMIT]: {
        event: "custom_event",
        eventCategory: "Pre_approved",
        eventAction: "Next"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVED_THANK_YOU]: {
        event: "custom_event",
        eventCategory: "Pre_approved",
        eventAction: "Thank_you_pop_up_interactions"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVED_PROVIDER_SLIDER]: {
        event: "custom_event",
        eventCategory: "Pre_approved",
        eventAction: "loan_provider_slider"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVED_BACK]: {
        event: "custom_event",
        eventCategory: "Pre_approved",
        eventAction: "Back"
    },
    // my bookings

    [AU_DESKTOP_EVENTS.MY_BOOKINGS_BUTTON_CLICK]: {
        event: "custom_event",
        eventCategory: "My_booking",
        eventAction: "Button_click"
    },
    [AU_DESKTOP_EVENTS.MY_SELL_ORDERS_SCREEN]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "sell_orders_screen"
    },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_BUTTON_CLICKED]: {
        event: "custom_event",
        eventCategory: "Track Booking"
    },

    [AU_DESKTOP_EVENTS.TEST_DRIVE_CANCEL_POP_UP]: {
        event: "custom_event",
        eventCategory: "My_booking",
        eventAction: "test_drive_cancel_pop_up"
    },

    [AU_DESKTOP_EVENTS.TEST_DRIVE_CANCELLED_POP_UP]: {
        event: "custom_event",
        eventCategory: "My_booking",
        eventAction: "test_drive_cancelled_pop_up"
    },
    [AU_DESKTOP_EVENTS.SELL_ORDER_BOOKING]: {
        event: "custom_event",
        eventCategory: "My_booking",
        eventAction: "sell_order_booking"
    },
    [AU_DESKTOP_EVENTS.MY_ACCOUNT_SELL_OR_TRADE_IN_SCREEN]: {
        event: "custom_event",
        eventCategory: "C2B_Flow"
    },
    [AU_DESKTOP_EVENTS.MY_ACCOUNT_CTA_CLICKED]: {
        event: "custom_event",
        eventCategory: "C2B_Flow",
        eventAction: "my_account_screen"
    },
    [AU_DESKTOP_EVENTS.MY_ACCOUNT_SELL_OR_TRADE_IN]: {
        event: "custom_event",
        eventCategory: "C2B_Flow",
        eventAction: "my_account_screen",
        eventLabel: "sell_or_trade_in"
    },

    // learn more events

    [AU_DESKTOP_EVENTS.LEARN_MORE_OPEN]: {
        event: "custom_event",
        eventCategory: "Cars24_home_page",
        eventAction: "learn_more_open"
    },
    [AU_DESKTOP_EVENTS.LEARN_MORE_CLOSE]: {
        event: "custom_event",
        eventCategory: "Cars24_home_page",
        eventAction: "learn_more_close"
    },
    [AU_DESKTOP_EVENTS.LEARN_MORE_CALL_US]: {
        event: "custom_event",
        eventCategory: "Cars24_home_page",
        eventAction: "learn_more_call_us"
    },

    // static pages events

    [AU_DESKTOP_EVENTS.TERMS_OF_USE_EMAIL_CLICK]: {
        event: "custom_event",
        eventCategory: "Cars24_terms_of_use",
        eventAction: "email_link"
    },
    [AU_DESKTOP_EVENTS.PRIVACY_POLICY_LINK_CLICK]: {
        event: "custom_event",
        eventCategory: "Cars24_privacy_policy",
        eventAction: "link_selected"
    },
    [AU_DESKTOP_EVENTS.PRIVACY_POLICY_DRIVA_CLICK]: {
        event: "custom_event",
        eventCategory: "Cars24_privacy_policy",
        eventAction: "Driva_link_selected"
    },
    [AU_DESKTOP_EVENTS.CONTACT_US_INPUT]: {
        event: "custom_event",
        eventCategory: "Contact_us",
        eventAction: "Form_details_submitted"
    },
    [AU_DESKTOP_EVENTS.CONTACT_US_BUTTON]: {
        event: "custom_event",
        eventCategory: "Contact_us",
        eventAction: "Button_selected"
    },
    [AU_DESKTOP_EVENTS.CAREER_LINKEDIN]: {
        event: "custom_event",
        eventCategory: "Career",
        eventAction: "Linkedin_redirection_button_selected"
    },
    [DESKTOP_EVENT_ACTION.ATF_INTERACTION]: {
        event: "custom_event",
        eventCategory: DESKTOP_EVENT_CATEGORY.CARS24_HOMEPAGE,
        eventAction: DESKTOP_EVENT_ACTION.ATF_INTERACTION
    },
    [DESKTOP_EVENT_ACTION.MAKE_SELECTED]: {
        event: "custom_event",
        eventCategory: DESKTOP_EVENT_CATEGORY.CARS24_HOMEPAGE,
        eventAction: DESKTOP_EVENT_ACTION.MAKE_SELECTED
    },
    [DESKTOP_EVENT_ACTION.MODEL_SELECTED]: {
        event: "custom_event",
        eventCategory: DESKTOP_EVENT_CATEGORY.CARS24_HOMEPAGE,
        eventAction: DESKTOP_EVENT_ACTION.MODEL_SELECTED
    },
    [DESKTOP_EVENT_ACTION.BROWSE_LATEST_CAR]: {
        event: "custom_event",
        eventCategory: DESKTOP_EVENT_CATEGORY.CARS24_HOMEPAGE,
        eventAction: DESKTOP_EVENT_ACTION.BROWSE_LATEST_CAR
    },
    [DESKTOP_EVENT_ACTION.SOCIAL_MEDIA]: {
        event: "custom_event",
        eventCategory: DESKTOP_EVENT_CATEGORY.ALL_PAGES,
        eventAction: DESKTOP_EVENT_ACTION.SOCIAL_MEDIA
    },
    [DESKTOP_EVENT_ACTION.SELL_CAR_LINKS]: {
        event: "custom_event",
        eventCategory: DESKTOP_EVENT_CATEGORY.ALL_PAGES,
        eventAction: DESKTOP_EVENT_ACTION.SELL_CAR_LINKS
    },
    [DESKTOP_EVENT_ACTION.USEFUL_LINKS]: {
        event: "custom_event",
        eventCategory: DESKTOP_EVENT_CATEGORY.ALL_PAGES,
        eventAction: DESKTOP_EVENT_ACTION.USEFUL_LINKS
    },
    [DESKTOP_EVENT_ACTION.ORDER_SUMMARY]: {
        event: "custom_event",
        eventCategory: DESKTOP_EVENT_CATEGORY.CHECKOUT_PAGE,
        eventAction: DESKTOP_EVENT_ACTION.ORDER_SUMMARY
    },
    [DESKTOP_EVENT_ACTION.USER_AGREEMENT]: {
        event: "custom_event",
        eventCategory: DESKTOP_EVENT_CATEGORY.CHECKOUT_PAGE,
        eventAction: DESKTOP_EVENT_ACTION.USER_AGREEMENT
    },
    [DESKTOP_EVENT_ACTION.COOKIES]: {
        event: "custom_event",
        eventCategory: DESKTOP_EVENT_CATEGORY.CARS24_HOMEPAGE,
        eventAction: DESKTOP_EVENT_ACTION.COOKIES
    },
    [AU_DESKTOP_EVENTS.CAR_CLICK]: {
        event: "carClick"
    },
    [AU_DESKTOP_EVENTS.CAR_IMPRESSION]: {
        event: "carsImpression"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS]: {
        event: "carDetail"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_NAVIGATION_ITEMS_CLICKED]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "navigation_tab_selected"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_IMAGE_GALLERY_AB]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "image_gallery_desktop_ab"
    },
    [AU_DESKTOP_EVENTS.CAR_DETAILS_FEATURE_SEARCH]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "car_details_feature_search"
    },
    [AU_DESKTOP_EVENTS.START_PURCHASE]: {
        event: "startpurchase"
    },
    [AU_DESKTOP_EVENTS.CHECKOUT]: {
        event: "checkout"
    },
    [AU_DESKTOP_EVENTS.PURCHASE_SUCCESS]: {
        event: "purchase success"
    },
    [AU_DESKTOP_EVENTS.BYOF_ORDER_SUMMARY]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "Byof_order_summary"
    },
    [AU_DESKTOP_EVENTS.CHECKOUT_ORDER_SUMMARY]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "order_summary"
    },
    [AU_DESKTOP_EVENTS.COMING_SOON_LISTING_INFO]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "coming_soon_info_icon_click",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.COMING_SOON_DETAIL_INFO]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "coming_soon_info_icon_click",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.COMING_SOON_NOTIFY_ME]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "coming_soon_notify_me",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.COMING_SOON_THANK_YOU]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "coming_soon_thank_you_popup"
    },
    [AU_DESKTOP_EVENTS.RESERVATION_NOTIFY_ME_CTA]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "notify_me_cta"
    },
    [AU_DESKTOP_EVENTS.RESERVATION_NOTIFY_ME_TOOLTIP]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "notify_me_cta_tooltip"
    },
    [AU_DESKTOP_EVENTS.RESERVATION_NOTIFY_ME_TOOLTIP_POPUP]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "notify_me_cta_tooltip_popup"
    },
    [AU_DESKTOP_EVENTS.RESERVATION_NOTIFY_ME]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "notify_me"
    },
    [AU_DESKTOP_EVENTS.RESERVATION_REMOVE_FROM_WISHLIST]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "remove_from_wishlist"
    },
    [AU_DESKTOP_EVENTS.RESERVATION_REMOVE_FROM_WISHLIST_POPUP]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "remove_from_wishlist_popup"
    },
    [AU_DESKTOP_EVENTS.STATE_WISE_PRICING_INFO_ICON]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "car_card_egc_info_icon"
    },
    [AU_DESKTOP_EVENTS.STATE_WISE_PRICING_INFO_ICON_DETAILS]: {
        event: "custom_event",
        eventCategory: "Cars24_details_page",
        eventAction: "car_card_egc_info_icon"
    },
    [AU_DESKTOP_EVENTS.STATE_WISE_PRICING_STATE_SELECT]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "price_break_down_state_selected"
    },
    [AU_DESKTOP_EVENTS.STATE_WISE_PRICE_BREAKDOWN_GET_STARTED]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "price_break_down_get_started"
    },

    //TRADE-IN

    [AU_DESKTOP_EVENTS.TRADE_IN_OPT_IN_SCREEN]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "opt_in_screen"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_REVIEW_CAR_DETAIL_METHOD]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "review_car_detail_method"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_REGO_NUMBER_ENTERED]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "rego_number_entered"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_STATE_SELECTED]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "state_selected"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_VIN_ENTERED]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "vin_entered"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_YEAR_SELECTED]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "year_selected"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_KBB_CAR_OUTPUT_CONFIRMATION]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "kbb_car_ouptut_confirmation"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_CAR_OWNER]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "car_owner"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_MAJOR_ACCIDENT]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "major_accident"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_PROCEED_WITHOUT_TRADE_IN]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "proceed_without_trade_in"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_ODOMETER_READING]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "odometer_reading"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_DASHBOARD_WARNING_LIGHTS]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "dashboard_warning_lights"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_AIR_CONDITIONING]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "air_conditioning"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_EXTERIOR_DAMAGE]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "exterior_damage"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_INTERIOR_DAMAGE]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "interior_damage"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_EXTERIOR_DAMAGE_OTHERS_INPUT]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "exterior_damage_others_input"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_SERVICE_LOG]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "service_log"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_SERVICE_LOG_STATUS]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "service_log_status"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_NUMBER_OF_KEYS]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "number_of_keys"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_OWE_FINANCE]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "owe_finance"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_COLLECT_FINANCE_PAYMENT]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "collect_finance_payment"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_CONDITIONS_OF_EVALUATION_SELECTED]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "conditions_of_evaluation_selected"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_OFFER_ACCEPTED]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "offer_accepted"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_NEXT]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "next"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_BACK]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "back"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_OFFER_SCREEN]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "offer_screen"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_EXPIRY_SCREEN]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "expiry_screen"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_VARIANT_SELECTED]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "variant_selected"
    },
    [AU_DESKTOP_EVENTS.PROCEED_AFTER_PARTS_SELECTED]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "proceed_after_parts_selection"
    },

    // Trade-in top funnel
    [AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_NEXT]: {
        event: "custom_event",
        eventCategory: AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_CATEGORY,
        eventAction: "next"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_BACK]: {
        event: "custom_event",
        eventCategory: AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_CATEGORY,
        eventAction: "back"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_VARIANT_NOT_RETURN]: {
        event: "custom_event",
        eventCategory: AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_CATEGORY,
        eventAction: "variant_not_returned"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_GET_OFFER]: {
        event: "custom_event",
        eventCategory: AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_CATEGORY,
        eventAction: "Get_offer"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_GET_STARTED]: {
        event: "custom_event",
        eventCategory: AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_CATEGORY,
        eventAction: "get_started"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_REVIEW_CAR_DETAIL_METHOD]: {
        event: "custom_event",
        eventCategory: AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_CATEGORY,
        eventAction: "review_car_detail_method"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_REGO_NUMBER_ENTERED]: {
        event: "custom_event",
        eventCategory: AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_CATEGORY,
        eventAction: "rego_number_entered"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_STATE_SELECTED]: {
        event: "custom_event",
        eventCategory: AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_CATEGORY,
        eventAction: "state_selected"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_VIN_ENTERED]: {
        event: "custom_event",
        eventCategory: AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_CATEGORY,
        eventAction: "vin_entered"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_YEAR_SELECTED]: {
        event: "custom_event",
        eventCategory: AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_CATEGORY,
        eventAction: "year_selected"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_VARIANT_SELECTED]: {
        event: "custom_event",
        eventCategory: AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_CATEGORY,
        eventAction: "variant_selected"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_DONT_AGREE_ASSESSMENT_MODEL]: {
        event: "custom_event",
        eventCategory: AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_CATEGORY,
        eventAction: "don't_agree_with_our_assessment_model"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_VIEW_CARS]: {
        event: "custom_event",
        eventCategory: AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_CATEGORY,
        eventAction: "view_cars"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_TRADE_IN_PRICE_TOGGLE_CLP]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "trade_in_price_toggle"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_TRADE_IN_PRICE_TOGGLE_CDP]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "trade_in_price_toggle"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_MY_ACCOUNT]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "my_account",
        eventLabel: "trade_in"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_TRADE_IN]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "trade_in"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_TOP_FUNNEL_HEADER_TRADE_IN_YOUR_CAR]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "sell_car",
        eventLabel: "trade_in_your_car"
    },

    // Trade-in make-model
    [AU_DESKTOP_EVENTS.VARIANT_NOT_RETURN]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "variant_not_returned"
    },
    [AU_DESKTOP_EVENTS.NO_TRADEIN_MAKE_SELECTED]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "make_dropdown_selected"
    },
    [AU_DESKTOP_EVENTS.NO_TRADEIN_MODEL_SELECTED]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "model_dropdown_selected"
    },
    [AU_DESKTOP_EVENTS.NO_TRADEIN_YEAR_SELECTED]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "year_dropdown_selected"
    },
    [AU_DESKTOP_EVENTS.NO_TRADEIN_FUEL_SELECTED]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "fuel_type_dropdown_selected"
    },
    [AU_DESKTOP_EVENTS.NO_TRADEIN_BODY_SELECTED]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "body_type_dropdown_selected"
    },
    [AU_DESKTOP_EVENTS.NO_TRADEIN_TRANSMISSION_SELECTED]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "transmission_type_dropdown_selected"
    },
    [AU_DESKTOP_EVENTS.NO_TRADEIN_DRIVETRAIN_SELECTED]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "DriveTrain_type_dropdown_selected"
    },
    [AU_DESKTOP_EVENTS.NO_TRADEIN_VARIANT_SELECTED]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "Variant_type_dropdown_selected"
    },

    [AU_DESKTOP_EVENTS.LOGIN_SUCCESSFULL_FACEBOOK]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "Successful_fb_login"
    },
    [AU_DESKTOP_EVENTS.LOGIN_SUCCESSFULL_APPLE]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "Successful_apple_login"
    },
    [AU_DESKTOP_EVENTS.HOMEPAGE_CATEGORY]: {
        event: "custom_event",
        eventCategory: "Cars24_home_page",
        eventAction: "lifestyle_category"
    },
    [AU_DESKTOP_EVENTS.KBB_PRICE_PROMISE]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "KBB_price_promise",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.KBB_SHOW_MORE]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "KBB_show_more",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.KBB_INFO_ICON]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "KBB_info_icon",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.HEADER_PAGE_SELECTED]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "header_page_selected"
    },
    [AU_DESKTOP_EVENTS.PAY_ONLINE_METHOD]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "pay_online_method"
    },

    [AU_DESKTOP_EVENTS.PAY_PARTIAL_INFO_MODEL]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "pay_partial_info_model",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.PAY_ONLINE_CTA_SELECTED]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "pay_online_cta_selected"
    },

    [AU_DESKTOP_EVENTS.PAY_SPLIT_SLIDER]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "pay_split_slider",
        eventLabel: "NA"
    },

    [AU_DESKTOP_EVENTS.MY_BOOKING_TASKS]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "My_booking_tasks"
    },
    [AU_DESKTOP_EVENTS.SPLIT_ORDER_SUMMARY]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "split_order_summary"
    },
    [AU_DESKTOP_EVENTS.SPLIT_ORDER_SUMMARY_POST_PAYMENT]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "split_order_summary_post_payment"
    },
    [AU_DESKTOP_EVENTS.THREE_HUNDRED_CATEGORY]: {
        event: "custom_event",
        eventCategory: "300_pt_inspection_shown",
        eventAction: "category_selected"
    },
    [AU_DESKTOP_EVENTS.THREE_HUNDRED_SEARCH]: {
        event: "custom_event",
        eventCategory: "300_pt_inspection_shown",
        eventAction: "search"
    },
    [AU_DESKTOP_EVENTS.THREE_HUNDRED_CTA]: {
        event: "custom_event",
        eventCategory: "300_pt_inspection_shown",
        eventAction: "find_your_car_cta_selected",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.DELIVERY_SCREEN]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "delivery_screen"
    },
    [AU_DESKTOP_EVENTS.PICK_UP]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "pick_up"
    },
    [AU_DESKTOP_EVENTS.PICK_UP_HOME_DELIVERY]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "Pick_up_home_delivery",
        eventLabel: "Confirm"
    },
    [AU_DESKTOP_EVENTS.DOCUMENT_VERIFIED]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "documents_verified",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.CONTRACT_OF_SALE]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "contract_of_sale",
        eventLabel: "read_and_sign"
    },
    [AU_DESKTOP_EVENTS.NUDGE_CARD]: {
        event: "custom_event",
        eventCategory: "Cars24_home_page",
        eventAction: "nudge_card"
    },
    [AU_DESKTOP_EVENTS.SHOW_EASTER_EGG]: {
        event: "custom_event",
        eventCategory: "Cars_24_home_page",
        eventAction: "sale_banner",
        eventLabel: "show_sale_cars_click"
    },
    [AU_DESKTOP_EVENTS.SHOW_EASTER_EGG_TERMS]: {
        event: "custom_event",
        eventCategory: "Cars_24_home_page",
        eventAction: "sale_banner",
        eventLabel: "show_sale_terms_&_condition_click"
    },
    [AU_DESKTOP_EVENTS.BLACK_FRIDAY_BANNER_CLICKED]: {
        event: "custom_event",
        eventCategory: "Cars_24_home_page",
        eventAction: "black_friday_banner_clicked",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.SHOW_KBB_FAN]: {
        eventCategory: "Cars24_detail_page",
        event: "custom_event",
        eventAction: "pricing_fan_enabled"
    },
    [AU_DESKTOP_EVENTS.HIDE_KBB_FAN]: {
        eventCategory: "Cars24_detail_page",
        event: "custom_event",
        eventAction: "pricing_fan_disabled"
    },
    // preapproved driva
    [AU_DESKTOP_EVENTS.PREAPPROVAL_DRIVA_VALUE_PROP]: {
        event: "custom_event",
        eventCategory: "Preapproval_Driva_value_prop",
        eventAction: "cta_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_DRIVA_DEPOSIT_INPUT]: {
        event: "custom_event",
        eventCategory: "Preapproval_Driva_deposit",
        eventAction: "deposit_input"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_DRIVA_DEPOSIT_SLIDER]: {
        event: "custom_event",
        eventCategory: "Preapproval_Driva_deposit",
        eventAction: "deposit_slider"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_DRIVA_LOAN_TENURE_OPTION]: {
        event: "custom_event",
        eventCategory: "Preapproval_Driva_loan_tenure",
        eventAction: "option_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_DRIVA_BALLOON_CLICK]: {
        event: "custom_event",
        eventCategory: "Preapproval_balloon",
        eventAction: "cta_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_DRIVA_BALLOON_OPTION]: {
        event: "custom_event",
        eventCategory: "Preapproval_Driva_balloon",
        eventAction: "balloon_amount_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_DRIVA_LOAN_TYPE]: {
        event: "custom_event",
        eventCategory: "Preapproval_Driva_business_purpose",
        eventAction: "option_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_DRIVA_LOAN_TYPE_ADDITIONAL]: {
        event: "custom_event",
        eventCategory: "Preapproval_Driva_business_purpose",
        eventAction: "business_yes_additional_questions"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_DRIVA_NAME_DETAILS]: {
        event: "custom_event",
        eventCategory: "Preapproval_Driva_personal_detail",
        eventAction: "Name_details_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_DRIVA_DOB]: {
        event: "custom_event",
        eventCategory: "Preapproval_Driva_DOB",
        eventAction: "DOB_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_DRIVA_RESIDENCY]: {
        event: "custom_event",
        eventCategory: "Preapproval_Driva_residency_status",
        eventAction: "option_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_DRIVA_RESIDENCY_VISA]: {
        event: "custom_event",
        eventCategory: "Preapproval_Driva_residency_status",
        eventAction: "visa_class_number"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_DRIVA_ADDRESS]: {
        event: "custom_event",
        eventCategory: "Preapproval_Driva_address",
        eventAction: "Address_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_DRIVA_ADDRESS_TENURE]: {
        event: "custom_event",
        eventCategory: "Preapproval_Driva_address_length",
        eventAction: "Tenure_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_DRIVA_LIVING_SITUATION]: {
        event: "custom_event",
        eventCategory: "Preapproval_Driva_living_situation",
        eventAction: "option_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_DRIVA_EMPLOYMENT_STATUS]: {
        event: "custom_event",
        eventCategory: "Preapproval_Driva_employment_status",
        eventAction: "option_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_DRIVA_SELECT_OFFER]: {
        event: "custom_event",
        eventCategory: "Preapproval_Driva_pre_approved",
        eventAction: "cta_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_DRIVA_CUSTOMIZE]: {
        event: "custom_event",
        eventCategory: "Preapproval_Driva_customize",
        eventAction: "variable_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_DRIVA_REJECTION]: {
        event: "custom_event",
        eventCategory: "Preapproval_Driva_rejection",
        eventAction: "cta_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_APPLY_NOW]: {
        event: "custom_event",
        eventCategory: "Cars24_home_page",
        eventAction: "pre_approval_apply_now"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_GET_STARTED]: {
        event: "custom_event",
        eventCategory: "pre_approval_flow",
        eventAction: "get_started"
    },
    [AU_DESKTOP_EVENTS.PRICE_DETAILS_SECTION_FULL_PAY]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "price_details_section_full_pay"
    },
    [AU_DESKTOP_EVENTS.LOCATION_STATE_LISTING]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "location_state"
    },
    [AU_DESKTOP_EVENTS.LOCATION_STATE_DETAIL]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "location_state"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_VIEW_CARS]: {
        event: "custom_event",
        eventCategory: "pre_approval_success_page",
        eventAction: "view_cars",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVAl_SUCCESS]: {
        event: "custom_event",
        eventCategory: "pre_approval_flow",
        eventAction: "pre_approval_success",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVAl_REJECTED]: {
        event: "custom_event",
        eventCategory: "pre_approval_flow",
        eventAction: "pre_approval_reject",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVAl_EDIT_MY_ACCOUNT]: {
        event: "custom_event",
        eventCategory: "my_account_page_section",
        eventAction: "finance_edit_details",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVAl_EDIT_TAB_SELECTED]: {
        event: "custom_event",
        eventCategory: "edit_form",
        eventAction: "tab_selected"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVAl_EDIT_COMPLETED]: {
        event: "custom_event",
        eventCategory: "edit_form",
        eventAction: "edit_completed",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVAl_EDIT_REJECTION]: {
        event: "custom_event",
        eventCategory: "pre_approval_rejection_page",
        eventAction: "edit_finance_form",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVAl_EDIT_EXPIRED]: {
        event: "custom_event",
        eventCategory: "pre_approval_expiry",
        eventAction: "apply_again",
        eventLabel: "NA"
    },
    // preApproval inhouse
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_VALUE_PROP]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_value_prop",
        eventAction: "cta_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_DEPOSIT_INPUT]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_deposit",
        eventAction: "deposit_input"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_DEPOSIT_SLIDER]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_deposit",
        eventAction: "deposit_slider"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_LOAN_TENURE_OPTION]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_loan_tenure",
        eventAction: "option_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_BALLOON_CLICK]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_balloon",
        eventAction: "cta_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_BALLOON_OPTION]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_balloon",
        eventAction: "balloon_amount_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_LOAN_TYPE]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_business_purpose",
        eventAction: "option_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_LOAN_TYPE_ADDITIONAL]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_business_purpose",
        eventAction: "business_yes_additional_questions"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_NAME_DETAILS]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_personal_detail",
        eventAction: "Name_details_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_DOB]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_DOB",
        eventAction: "DOB_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_RESIDENCY]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_residency_status",
        eventAction: "option_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_RESIDENCY_VISA]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_residency_status",
        eventAction: "visa_class_number"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_ADDRESS]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_address",
        eventAction: "Address_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_EMAIL_ID_ENTERED]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_personal_detail",
        eventAction: "email_id_entered"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_INCOME_DETAILS]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_income_detail",
        eventAction: "income_details_entered"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_OFFER_SCREEN]: {
        event: "custom_event",
        eventCategory: "pre_approval_offer_generation"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_MOBILE_NUMBER_ENTERED]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_personal_detail",
        eventAction: "mobile_number_entered"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_ADDRESS_TENURE]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_address_length",
        eventAction: "Tenure_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_LIVING_SITUATION]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_living_situation",
        eventAction: "option_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_EMPLOYMENT_STATUS]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_employment_status",
        eventAction: "option_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_SELECT_OFFER]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_pre_approved",
        eventAction: "cta_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_REJECTION]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_rejection",
        eventAction: "cta_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_RELATIONSHIP_STATUS]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_relationship_status",
        eventAction: "option_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_NO_OF_DEPENDANTS]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_no_of_dependants",
        eventAction: "no_of_dependants_entered"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_EMPLOYMENT_STATUS_TENURE]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_employment_status_tenure",
        eventAction: "Tenure_selected"
    },
    [AU_DESKTOP_EVENTS.PREAPPROVAL_IN_HOUSE_FINANCE_EMPLOYMENT_INDUSTRY_CONTUATION]: {
        event: "custom_event",
        eventCategory: "Preapproval_in_house_finance_employment_industry_contuation",
        eventAction: "option_selected"
    },
    // inhouse - checkout flow
    [AU_DESKTOP_EVENTS.IN_HOUSE_FINANCE_RELATIONSHIP_STATUS]: {
        event: "custom_event",
        eventCategory: "In_house_finance_relationship_status",
        eventAction: "option_selected"
    },
    [AU_DESKTOP_EVENTS.IN_HOUSE_FINANCE_NO_OF_DEPENDANTS]: {
        event: "custom_event",
        eventCategory: "In_house_finance_no_of_dependants",
        eventAction: "no_of_dependant_selected"
    },
    [AU_DESKTOP_EVENTS.IN_HOUSE_FINANCE_INCOME_DETAILS]: {
        event: "custom_event",
        eventCategory: "In_house_finance_income_details",
        eventAction: "income_details_selected"
    },
    [AU_DESKTOP_EVENTS.FINANCE_FILTER_SHOW_CARS]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_show_cars",
        eventLabel: "options(Make_model/ Price/finance/ Km/ Year/ More)"
    },
    [AU_DESKTOP_EVENTS.FINANCE_FILTER_TAB_SELECED]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_price_finance"
    },
    [AU_DESKTOP_EVENTS.FINANCE_FILTER_SELECTIONS]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_finance"
    },
    [AU_DESKTOP_EVENTS.PRICE_CALCULATOR_TAB_SELECTED]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "price_details_section"
    },
    [AU_DESKTOP_EVENTS.PRICE_CALCULATOR_SELECTIONS]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "price_details_section_finance"
    },
    [AU_DESKTOP_EVENTS.PRICE_CALCULATOR_SECTION_START_PURCHASE]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "price_details_section_full_pay",
        eventLabel: "start_purchase"
    },
    [AU_DESKTOP_EVENTS.SWIPE_IN_GALLERY]: {
        event: "b2c_Swipe_in_gallery",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Swipe_in_gallery"
    },
    [AU_DESKTOP_EVENTS.CAR_GALLERY_VIEW]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "Image_gallery_view"
    },
    [AU_DESKTOP_EVENTS.LOGIN_SUCCESS_FACEBOOK]: {
        "event": "custom_event",
        "eventCategory": "Login_Success",
        "eventAction": "Facebook_login",
        "eventLabel": "Facebook"
    },
    [AU_DESKTOP_EVENTS.LOGIN_SUCCESS_GOOGLE]: {
        "event": "custom_event",
        "eventCategory": "Login_Success",
        "eventAction": "google_login",
        "eventLabel": "Google"
    },
    [AU_DESKTOP_EVENTS.LOGIN_SUCCESS_APPLE]: {
        "event": "custom_event",
        "eventCategory": "Login_Success",
        "eventAction": "Apple_login",
        "eventLabel": "Apple"
    },
    [AU_DESKTOP_EVENTS.LOGIN_SUCCESS_EMAIL]: {
        "event": "custom_event",
        "eventCategory": "Login_Success",
        "eventAction": "email_login",
        "eventLabel": "Email"
    },
    [AU_DESKTOP_EVENTS.DOWNLOAD_APP_FROM_TOP_BANNER]: {
        "event": "custom_event",
        "eventCategory": "Cars24_au_all_pages",
        "eventAction": "download_from_top_banner"
    },
    [AU_DESKTOP_EVENTS.DOWNLOAD_APP_FOOTER]: {
        "event": "custom_event",
        "eventCategory": "Cars24_au_all_pages",
        "eventAction": "download_app"
    },
    [AU_DESKTOP_EVENTS.DOWNLOAD_APP_FROM_TOP_BANNER_CLOSE]: {
        "event": "custom_event",
        "eventCategory": "Cars24_au_all_pages",
        "eventAction": "download_from_top_banner_closed"
    },
    [AU_DESKTOP_EVENTS.DOWNLOAD_APP_FROM_TOP_BANNER_CLOSE]: {
        "event": "custom_event",
        "eventCategory": "Cars24_au_all_pages",
        "eventAction": "download_from_top_banner_closed"
    },
    [AU_DESKTOP_EVENTS.SEO_EXIT_INTENT]: {
        "event": "custom_event",
        "eventCategory": "Cars24_detail_page",
        "eventAction": "seo_exit_intent"
    },
    [AU_DESKTOP_EVENTS.FILTERS_MORE_SEATS_ON]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_more_seats_on"
    },
    [AU_DESKTOP_EVENTS.FILTERS_MORE_SEATS_OFF]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_more_seats_off"
    },
    [AU_DESKTOP_EVENTS.LISTING_CONTENT_READ_MORE]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "read_more"
    },
    [AU_DESKTOP_EVENTS.LISTING_CONTENT_READ_LESS]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "read_less"
    },
    [AU_DESKTOP_EVENTS.DOWNLOAD_APP_BANNER]: {
        "event": "custom_event",
        "eventCategory": "Cars24_home_page",
        "eventAction": "download_app_banner"
    },
    [AU_DESKTOP_EVENTS.BANNER_NUMBER_ENTERED]: {
        "event": "custom_event",
        "eventCategory": "Cars24_home_page",
        "eventAction": "banner_number_entered"
    },
    [AU_DESKTOP_EVENTS.BANNER_SMS_BUTTON_CLICKED]: {
        "event": "custom_event",
        "eventCategory": "Cars24_home_page",
        "eventAction": "banner_sms_button_clicked"
    },
    [AU_DESKTOP_EVENTS.BANNER_NUMBER_INVALID]: {
        "event": "custom_event",
        "eventCategory": "Cars24_home_page",
        "eventAction": "banner_number_entered_invalid"
    },
    [AU_DESKTOP_EVENTS.PERFECT_CAR_MAKE]: {
        "event": "custom_event",
        "eventCategory": "Cars24_home_page",
        "eventAction": "top_make"
    },
    [AU_DESKTOP_EVENTS.PERFECT_CAR_MODEL]: {
        "event": "custom_event",
        "eventCategory": "Cars24_home_page",
        "eventAction": "top_model"
    },
    [AU_DESKTOP_EVENTS.PERFECT_CAR_BODYTYPE]: {
        "event": "custom_event",
        "eventCategory": "Cars24_home_page",
        "eventAction": "top_bodytype"
    },
    [AU_DESKTOP_EVENTS.PERFECT_FUELTYPE]: {
        "event": "custom_event",
        "eventCategory": "Cars24_home_page",
        "eventAction": "top_fueltype"
    },
    [AU_DESKTOP_EVENTS.PERFECT_SEE_ALL_CARS]: {
        "event": "custom_event",
        "eventCategory": "Cars24_home_page",
        "eventAction": "top_see_all_cars"
    },
    [AU_DESKTOP_EVENTS.BANNER_VARIANT_A]: {
        "event": "custom_event",
        "eventCategory": "Cars24_home_page",
        "eventAction": "Original_banner",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.BANNER_VARIANT_B]: {
        "event": "custom_event",
        "eventCategory": "Cars24_home_page",
        "eventAction": "New_app_banner",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.PERFECT_TAB_CLICKED]: {
        "event": "custom_event",
        "eventCategory": "Cars24_home_page",
        "eventAction": "top_tab_clicked"
    },
    [AU_DESKTOP_EVENTS.COUPON_CODE_ENTERED]: {
        "event": "custom_event",
        "eventCategory": "Cars24_checkout_page",
        "eventAction": "coupon_code_entered"
    },
    [AU_DESKTOP_EVENTS.COUPON_APPLY_BUTTON]: {
        "event": "custom_event",
        "eventCategory": "Cars24_checkout_page",
        "eventAction": "coupon_apply_button_clicked"
    },
    [AU_DESKTOP_EVENTS.COUPON_REMOVE]: {
        "event": "custom_event",
        "eventCategory": "Cars24_checkout_page",
        "eventAction": "coupon_remove"
    },
    [AU_DESKTOP_EVENTS.DRIVA_REDIRECTION_LINK]: {
        "event": "custom_event",
        "eventCategory": "Pre_approved",
        "eventAction": "driva_redirection_link"
    },
    [AU_DESKTOP_EVENTS.IN_HOUSE_REDIRECTION_LINK]: {
        "event": "custom_event",
        "eventCategory": "Pre_approved",
        "eventAction": "in_house_redirection_link"
    },
    [AU_DESKTOP_EVENTS.HOW_TO_BUY_FAQ_LINK]: {
        "event": "custom_event",
        "eventCategory": "Cars24_home_page",
        "eventAction": "how_to_buy_faq_link"
    },
    [AU_DESKTOP_EVENTS.PRICE_FILTER_SELECTED_ON_SCROLL]: {
        "event": "custom_event",
        "eventCategory": "cars24_listing_page",
        "eventAction": "price_filter_selected_on_scroll"
    },
    [AU_DESKTOP_EVENTS.CARS24_COVER_PAGE_MODAL]: {
        "event": "custom_event",
        "eventCategory": "Cars24_checkout_page",
        "eventAction": "Cars_24_cover_page_model"
    },
    [AU_DESKTOP_EVENTS.CARS24_COVER]: {
        "event": "custom_event",
        "eventCategory": "Cars24_checkout_page",
        "eventAction": "Cars24_cover"
    },
    [AU_DESKTOP_EVENTS.CARS24_COVER_DETAIL_PAGE]: {
        "event": "custom_event",
        "eventCategory": "Cars24_detail_page",
        "eventAction": "Cars24_cover",
        "eventLabel": "learn_more"
    },
    [AU_DESKTOP_EVENTS.CARS24_COVER_EW_COMPARISON]: {
        "event": "custom_event",
        "eventCategory": "Cars24cover",
        "eventAction": "EW_Plans_Compare",
        "eventLabel": "Open_EW_comparison"
    },
    [AU_DESKTOP_EVENTS.CARS24_COVER_EW_COVERAGE_DETAILS]: {
        "event": "custom_event",
        "eventCategory": "Cars24cover",
        "eventAction": "EW_Coverage_Details",
        "eventLabel": "Open_EW_coverage_details"
    },
    [AU_DESKTOP_EVENTS.CARS24_COVER_PAGE_VIEW_VARIANT_A]: {
        "event": "custom_event",
        "eventCategory": "Cars24_checkout_page",
        "eventAction": "Cars24_cover",
        "eventLabel": "Page view_Variant A"
    },
    [AU_DESKTOP_EVENTS.CARS24_COVER_PAGE_VIEW_VARIANT_B]: {
        "event": "custom_event",
        "eventCategory": "Cars24_checkout_page",
        "eventAction": "Cars24_cover",
        "eventLabel": "Page view_Variant B"
    },
    [AU_DESKTOP_EVENTS.CARS24_COVER_ADD_TO_LOAN]: {
        "event": "custom_event",
        "eventCategory": "Cars24_checkout_page",
        "eventAction": "Cars24_cover",
        "eventLabel": "ADD_AND_PROCEED_add_to_loan"
    },
    [AU_DESKTOP_EVENTS.CARS24_COVER_PAY_OUTRIGHT]: {
        "event": "custom_event",
        "eventCategory": "Cars24_checkout_page",
        "eventAction": "Cars24_cover",
        "eventLabel": "ADD_AND_PROCEED_pay_outright"
    },
    // [AU_DESKTOP_EVENTS.CARS24_COVER_EW_ADD]: {
    //     "event": "custom_event",
    //     "eventCategory": "Cars24cover",
    //     "eventAction": "EW_Add",
    //     "eventLabel": "{{Add_EW_proceed}}"
    // },
    // [AU_DESKTOP_EVENTS.CARS24_COVER_EW_SKIP]: {
    //     "event": "custom_event",
    //     "eventCategory": "Cars24cover",
    //     "eventAction": "EW_Skip",
    //     "eventLabel": "{{Skip_EW_proceed}}"
    // },
    // [AU_DESKTOP_EVENTS.CARS24_COVER_EW_PLAN]: {
    //     "event": "custom_event",
    //     "eventCategory": "Cars24cover",
    //     "eventAction": "EW_plan"
    // },
    [AU_DESKTOP_EVENTS.GET_VALUATION]: {
        "event": "custom_event",
        "eventCategory": "Cars24_detail_page",
        "eventAction": "trade_in_banner",
        "eventLabel": "clicked"
    },
    [AU_DESKTOP_EVENTS.GET_VALUATION_VIEWED]: {
        "event": "custom_event",
        "eventCategory": "Cars24_detail_page",
        "eventAction": "trade_in_banner",
        "eventLabel": "viewed"
    },
    [AU_DESKTOP_EVENTS.SELL_OR_TRADE_IN_CAR]: {
        "event": "custom_event",
        "eventCategory": "Cars24_Landing_page",
        "eventAction": "sell_or_trade_in_your_car_selected",
        "eventLabel": "NA"
    },
    [AU_DESKTOP_EVENTS.CAR_VALUE_CALCULATOR]: {
        "event": "custom_event",
        "eventCategory": "Cars24_Landing_page",
        "eventAction": "find_your_car_value_selected",
        "eventLabel": "NA"
    },
    [AU_DESKTOP_EVENTS.SELL_CITY_WISE_PAGE]: {
        "event": "custom_event",
        "eventCategory": "Cars24_Landing_page",
        "eventAction": "browse_by_loaction_menu"
    },
    [AU_DESKTOP_EVENTS.C2BCARDLISTING]: {
        "event": "custom_event",
        "eventCategory": "Cars24_listing_page",
        "eventAction": "get_valuation_cta_clicked"
    },
    [AU_DESKTOP_EVENTS.LISTING_PAGE]: {
        "event": "custom_event",
        "eventCategory": "Cars24_listing_page"
    },
    [AU_DESKTOP_EVENTS.LISTING_PAGE_SEARCH_AUTO_SUGGESTION_APPLIED]: {
        "event": "custom_event",
        "eventCategory": "Cars24_listing_page",
        "eventAction": "search_auto_suggestion_applied"

    },
    [AU_DESKTOP_EVENTS.LISTING_PAGE_POPULAR_SEARCH]: {
        "event": "custom_event",
        "eventCategory": "Cars24_listing_page",
        "eventAction": "popular_search"
    },
    [AU_DESKTOP_EVENTS.LISTING_PAGE_RECENTLY_SEARCHED]: {
        "event": "custom_event",
        "eventCategory": "Cars24_listing_page",
        "eventAction": "recently_searched"
    },
    [AU_DESKTOP_EVENTS.CARS24_AU_ALL_PAGES]: {
        "event": "custom_event",
        "eventCategory": "Cars24_au_listing_page"
    },
    [DESKTOP_EVENT_KEY.CHECKOUT_PAGE_NEXT]: {
        "event": "custom_event",
        "eventCategory": DESKTOP_EVENT_CATEGORY.CHECKOUT_PAGE,
        "eventAction": DESKTOP_EVENT_ACTION.NEXT
    },
    [DESKTOP_EVENT_KEY.CHECKOUT_PAGE_BACK]: {
        "event": "custom_event",
        "eventCategory": DESKTOP_EVENT_CATEGORY.CHECKOUT_PAGE,
        "eventAction": DESKTOP_EVENT_ACTION.BACK
    },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_DATE_MODEL]: {
        "event": "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "test_drive_date_model"
    },
    [AU_DESKTOP_EVENTS.NOTIFY_ME_POP_UP]: {
        "event": "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "notify_me_pop_up"
    },
    [AU_DESKTOP_EVENTS.PAYABLE_LATER]: {
        "event": "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "payable_later"
    },
    [AU_DESKTOP_EVENTS.ORDER_SUMMARY_POST_PAYMENT]: {
        "event": "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "order_summary_post_payment"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVAL_CTA]: {
        event: "custom_event",
        eventCategory: "Pre approval CTA"
    },
    [AU_DESKTOP_EVENTS.CTA_SELECTED]: {
        "event": "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "cta_selected"
    },
    [AU_DESKTOP_EVENTS.SELECT_CAR]: {
        "event": "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "select_car"
    },
    [AU_DESKTOP_EVENTS.SEE_FULL_DETAILS]: {
        "event": "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "see_full_details"
    },
    [AU_DESKTOP_EVENTS.CONFIRMATION_POP_UP]: {
        "event": "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "see_full_details"
    },
    [AU_DESKTOP_EVENTS.CHECKOUT_PAGE]: {
        "event": "custom_event",
        eventCategory: "Cars24_checkout_page"
    },
    [AU_DESKTOP_EVENTS.FINANCE_BANNER]: {
        event: "custom_event",
        eventCategory: "Cars24_landing_page_exp",
        eventAction: "finance_banner",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.FINANCE_BANNER_GET_PRE_APPROVAL_CTA]: {
        event: "custom_event",
        eventCategory: "Cars24_landing_page_exp",
        eventAction: "get_pre_approval_cta",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.FINANCE_BANNER_SELECT_MAKE]: {
        event: "custom_event",
        eventCategory: "Cars24_landing_page_exp",
        eventAction: "select_make",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.FINANCE_BANNER_SELECT_MODEL]: {
        event: "custom_event",
        eventCategory: "Cars24_landing_page_exp",
        eventAction: "select_model",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.FINANCE_BANNER_SHOW_ALL_CARS]: {
        event: "custom_event",
        eventCategory: "Cars24_landing_page_exp",
        eventAction: "show_all_cars",
        eventLabel: "NA"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVAL_CTA]: {
        event: "custom_event",
        eventCategory: "Pre approval CTA"
    },
    [AU_DESKTOP_EVENTS.GREEN_ID_BUTTON_CLICK]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "Green_ID_cta_selected"
    },
    [AU_DESKTOP_EVENTS.IN_HOUSE_COMMERCIAL_LOAN]: {
        event: "custom_event",
        eventCategory: "Pre approval_Comm loan question"
    },
    [AU_DESKTOP_EVENTS.WHATSAPP_CHAT_WIDGET_CLICKED]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "WhatsApp_Chat_Widget_Clicked"
    },
    [AU_DESKTOP_EVENTS.CARS24_AU_ALL]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages"
    },
    [AU_DESKTOP_EVENTS.MENU_YOUR_ORDERS]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "HEADER_menu_your_sell_orders"
    },
    [AU_DESKTOP_EVENTS.MY_SELL_ORDERS]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "my_account"
    },
    [AU_DESKTOP_EVENTS.BOOK_VIRTUAL_INSPECTION]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "my_sell_orders"
    },
    [AU_DESKTOP_EVENTS.FINISH_VALUATION_FORM]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "my_account_sell_your_car"
    },
    [AU_DESKTOP_EVENTS.CAR_COMPARE_CDP]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "car_compare_cdp"
    },
    [AU_DESKTOP_EVENTS.CLICK_ADD_TO_LOAN]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "Cars24_cover",
        eventLabel: "Click_Add_to_Loan"
    },
    [AU_DESKTOP_EVENTS.VIEW_FINANCE]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "Cars24_cover",
        eventLabel: "View_Finance"
    },

    // CLP Filter Revamp
    [AU_DESKTOP_EVENTS.FILTER_REVAMP_FILTER_COLOR]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_colour"
    },
    [AU_DESKTOP_EVENTS.LOAN_OFFERS_TOP_PLENTI]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "Loan_offers_Top_Plenti"
    },
    [AU_DESKTOP_EVENTS.LOAN_OFFERS_OTHER_OPEN]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "Loan_offers_Other_Open"
    },
    [AU_DESKTOP_EVENTS.LENDER_DETAILS_PLENTI]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "Lender_details_Plenti"
    },
    [AU_DESKTOP_EVENTS.HOME_PAGE_NUDGES]: {
        event: "custom_event",
        eventCategory: "Home_page_nudges"
    },
    [AU_DESKTOP_EVENTS.MY_TASKS_SUMMARY]: {
        event: "custom_event",
        eventCategory: "My_tasks_summary"
    },
    [AU_DESKTOP_EVENTS.STAGE_2_FORMS]: {
        event: "custom_event",
        eventCategory: "Stage 2 forms"
    },
    [AU_DESKTOP_EVENTS.VIEW_CARS_BY_MAKE]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "view_cars_by_make"
    },
    [AU_DESKTOP_EVENTS.VIEW_CARS_BY_MODEL]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "view_cars_by_model"
    },
    [AU_DESKTOP_EVENTS.CARS24_COVER_WARRANTY]: {
        "event": "custom_event",
        "eventCategory": "Cars24_cover",
        "eventAction": "Cars24_cover"
    },
    [AU_DESKTOP_EVENTS.CONTINUEPURCHASE]: {
        event: "continuepurchase"
    },
    [AU_DESKTOP_EVENTS.EW_Screen_Pop_up]: {
        event: "custom_event",
        eventCategory: "Cars24 cover",
        eventAction: "EW_Screen Pop up"
    },
    [AU_DESKTOP_EVENTS.EW_Coverage_Details_pop_up]: {
        event: "custom_event",
        eventCategory: "Cars24 cover",
        eventAction: "EW_Coverage_Details_pop up",
        eventLabel: "Open_EW_coverage_details_pop up"
    },
    [AU_DESKTOP_EVENTS.EW_Compare_pop_up]: {
        event: "custom_event",
        eventCategory: "Cars24 cover",
        eventAction: "EW_Compare_pop up",
        eventLabel: "Open_EW_Compare_pop up"
    },
    [AU_DESKTOP_EVENTS.EW_CARS24_COVER]: {
        event: "custom_event",
        eventCategory: "Cars24 cover"
    },
    [AU_DESKTOP_EVENTS.ACCOUNT_DELETION_IN_PROGRESS]: {
        "event": "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "account_deletion_in_progress"
    },
    // GA4 eccomerce impression
    [AU_DESKTOP_EVENTS.VIEW_ITEM_LIST]: {
        event: "view_item_list"
    },
    [AU_DESKTOP_EVENTS.SELECT_ITEM]: {
        event: "select_content"
    },
    [AU_DESKTOP_EVENTS.VIEW_ITEM]: {
        event: "view_item"
    },
    [AU_DESKTOP_EVENTS.BEGIN_CHECKOUT]: {
        event: "begin_checkout"
    },
    [AU_DESKTOP_EVENTS.PURCHASE]: {
        event: "purchase"
    },
    [AU_DESKTOP_EVENTS.ADD_TO_CART]: {
        event: "add_to_cart"
    },
    [AU_DESKTOP_EVENTS.LISTING_DISCOUNT_VISIBLE]: {
        "event": "custom_event",
        eventCategory: "Car_Listing_Page",
        eventAction: "discount_visible"
    },
    [AU_DESKTOP_EVENTS.DETAIL_DISCOUNT_VISIBLE]: {
        "event": "custom_event",
        eventCategory: "Car_Detail_page",
        eventAction: "discount_visible"
    },
    [AU_DESKTOP_EVENTS.LISTING_BELOW_MARKET_CLICKED]: {
        "event": "custom_event",
        eventCategory: "Car_Listing_Page",
        eventAction: "below_market_clicked"
    },
    [AU_DESKTOP_EVENTS.DETAIL_BELOW_MARKET_CLICKED]: {
        "event": "custom_event",
        eventCategory: "Car_Detail_page",
        eventAction: "below_market_clicked"
    },
    [AU_DESKTOP_EVENTS.CAR_LOCATION]: {
        "event": "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "car_location"
    },
    [AU_DESKTOP_EVENTS.PAYMENT_MODE_SCREEN_NON_PREAPPROVED]: {
        "event": "custom_event",
        eventCategory: "Payment mode screen",
        eventAction: "Non_Pre approved_cusotmer"
    },
    [AU_DESKTOP_EVENTS.TRADE_IN_REJECTION_CONTINUE]: {
        event: "custom_event",
        eventCategory: "Trade_in",
        eventAction: "trade_in_rejection_continue"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVAL_FLOW]: {
        "event": "custom_event",
        eventCategory: "pre_approval_flow"
    },
    [AU_DESKTOP_EVENTS.EDIT_FLOW]: {
        "event": "custom_event",
        eventCategory: "Edit_Flow"
    },
    [AU_DESKTOP_EVENTS.SHOW_MINIMUM_SPLIT_VARIANT]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "show_minimum_split_variant"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVED_LOAN_LIMIT]: {
        "event": "custom_event",
        eventCategory: "CLP",
        eventAction: "filters",
        eventLabel: "pre_approved_loan_limit"
    },
    [AU_DESKTOP_EVENTS.FINANCE_OK_GOT_IT]: {
        "event": "custom_event",
        eventCategory: "CLP",
        eventAction: "Finance",
        eventLabel: "Okay_got_it"
    },
    [AU_DESKTOP_EVENTS.FINANCE_TAB]: {
        "event": "custom_event",
        eventCategory: "CLP",
        eventAction: "Filter",
        eventLabel: "Finance_Tab"
    },
    [AU_DESKTOP_EVENTS.FINANCE_SHOW_CARS]: {
        "event": "custom_event",
        eventCategory: "CLP",
        eventAction: "Filter",
        eventLabel: "Finance_Show_cars"
    },
    [AU_DESKTOP_EVENTS.REPYAMENT_HIGH_TO_LOW]: {
        "event": "custom_event",
        eventCategory: "CLP",
        eventAction: "SORT",
        eventLabel: "Repyament_High_to_low"
    },
    [AU_DESKTOP_EVENTS.REPYAMENT_LOW_TO_HIGH]: {
        "event": "custom_event",
        eventCategory: "CLP",
        eventAction: "SORT",
        eventLabel: "Repyament_Low_to_high"
    },
    [AU_DESKTOP_EVENTS.FINANCE_SHOW_CARS]: {
        "event": "custom_event",
        eventCategory: "CLP",
        eventAction: "Filter",
        eventLabel: "Finance_Show_cars"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVED_LOAN_VIEW_CARS]: {
        event: "custom_event",
        eventCategory: "CLP",
        eventAction: "tile",
        eventLabel: "pre_approved_loan_view_cars"
    },
    [AU_DESKTOP_EVENTS.FILTER_REVAMP_FEATURES]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_features"
    },
    [AU_DESKTOP_EVENTS.FILTER_REVAMP_FILTER_MODEL_SELECTED_ON]: {
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_model_on"
    },
    [AU_DESKTOP_EVENTS.FILTER_REVAMP_FILTER_PRICE_SELECTED]: {
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_price"
    },
    [AU_DESKTOP_EVENTS.FILTER_REVAMP_FILTER_TRANSMISSION_SELECTED]: {
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_transmission"
    },
    [AU_DESKTOP_EVENTS.FILTER_REVAMP_FILTER_YEAR_SELECTED]: {
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_year"
    },
    [AU_DESKTOP_EVENTS.FILTER_REVAMP_FILTER_FUEL_TYPE_SELECTED]: {
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_fuel_type"
    },
    [AU_DESKTOP_EVENTS.FILTER_REVAMP_FILTER_BODY_TYPE_SELECTED]: {
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_body_type"
    },
    [AU_DESKTOP_EVENTS.FILTER_REVAMP_FILTER_KM_SELECTED]: {
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_km"
    },
    [AU_DESKTOP_EVENTS.FILTER_REVAMP_FILTER_DRIVETRAIN_SELECTED]: {
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_drivetrain"
    },
    [AU_DESKTOP_EVENTS.FILTER_REVAMP_FILTER_FEATURES_SELECTED]: {
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_features"
    },
    [AU_DESKTOP_EVENTS.FILTER_REVAMP_FILTER_SEAT_SELECTED]: {
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_seat"
    },
    [AU_DESKTOP_EVENTS.FILTER_REVAMP_SORT_ATTRIBUTE_SELECTED_SELECTED]: {
        eventCategory: "Cars24_listing_page",
        eventAction: "sort_attribute_selected"
    },
    [AU_DESKTOP_EVENTS.FILTER_REVAMP_CLEAR_ALL_FILTERS_SELECTED]: {
        eventCategory: "Cars24_listing_page",
        eventAction: "clear_all_filters"
    },
    [AU_DESKTOP_EVENTS.FILTER_REVAMP_FILTER_DESELECTED_CONTAINER]: {
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_deselected_container"
    },
    [AU_DESKTOP_EVENTS.FILTER_REVAMP_FILTER_DESELECTED_PILL]: {
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_deselected_pill"
    },
    [AU_DESKTOP_EVENTS.FILTER_REVAMP_FILTER_TESTDRIVE]: {
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_test_drive"
    },

    [AU_DESKTOP_EVENTS.DRIVE_AWAY_PRICE_CTA]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "drive_away_price_CTA"
    },
    [AU_DESKTOP_EVENTS.SUPPORT_WIDGET_HELP_ICON_AB]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "help_icon_desktop_a/b"
    },
    [AU_DESKTOP_EVENTS.SUPPORT_WIDGET_HELP_ICON_CLICKED]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "help_icon_clicked"
    },
    [AU_DESKTOP_EVENTS.SUPPORT_WIDGET_CTA_SELECT]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "Help_Interface_CTA_Selected"
    },
    [AU_DESKTOP_EVENTS.SUPPORT_WIDGET_FORM_SUBMIT_EMAIL_SELECT]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "Email_selected",
        eventLabel: "form_submitted"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVED_LOAN_OFFERS]: {
        event: "custom_event",
        eventCategory: "Loan_offers"
    },
    [DESKTOP_EVENT_CATEGORY.CARS24_HOMEPAGE]: {
        event: "custom_event",
        eventCategory: "CARS24_HOMEPAGE"
    },
    [AU_DESKTOP_EVENTS.CDP_DELIVERY_TIMELINE]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "cdp_delivery_timeline"
    },
    [AU_DESKTOP_EVENTS.DIRECT_BANK_TRANSFER]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "Westpac"
    },
    [AU_DESKTOP_EVENTS.UPLOAD_PHOTO]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "upload_photo"
    },
    [AU_DESKTOP_EVENTS.SUBMIT_PHOTO]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "submit_photo",
        eventLabel: "NA"
    },

    // STAGE TWO
    [AU_DESKTOP_EVENTS.HELP_BUTTON]: {
        event: "custom_event",
        eventCategory: "Stage 2",
        eventAction: "Help button"
    },

    // user login events GA4

    [AU_DESKTOP_EVENTS.SIGNIN]: {
        event: "login"
    },
    [AU_DESKTOP_EVENTS.SIGNOUT]: {
        event: "logout"
    },
    [AU_DESKTOP_EVENTS.CAR_SEARCH]: {
        event: "search"
    },
    [AU_DESKTOP_EVENTS.INSPECTION_REPORT_INSPECTION_SECTION_VIEWED]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "inspection_section_viewed"
    },
    [AU_DESKTOP_EVENTS.INSPECTION_REPORT_WEAR_AND_TEAR_GUIDE]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "detailed_condition_report",
        eventLabel: "wear_and_tear_guide"
    },
    [AU_DESKTOP_EVENTS.INSPECTION_REPORT_CARS24_WARRANTY]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "detailed_condition_report",
        eventLabel: "cars24_warranty"
    },
    [AU_DESKTOP_EVENTS.INSPECTION_SECTION_IMAGES]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "detailed_condition_report"
    },
    [AU_DESKTOP_EVENTS.ZERO_DP_HOMEPAGE]: {
        event: "custom_event",
        eventCategory: "Home Page",
        eventAction: "Customer_Zero deposit"
    },
    [AU_DESKTOP_EVENTS.ZERO_DP_PRE_APPROVAL]: {
        event: "custom_event",
        eventCategory: "Pre approval"
    },
    [AU_DESKTOP_EVENTS.ZERO_DP_CLP]: {
        event: "custom_event",
        eventCategory: "CLP",
        eventAction: "Customer_Zero_Deposit"
    },
    [AU_DESKTOP_EVENTS.ZERO_DP_CDP]: {
        event: "custom_event",
        eventCategory: "CDP",
        eventAction: "Get started"
    },
    [AU_DESKTOP_EVENTS.ZERO_DP_CHECKOUT]: {
        event: "custom_event",
        eventCategory: "Checkout",
        eventAction: "Basic_details"
    },
    [AU_DESKTOP_EVENTS.ZERO_DP_LOAN_OFFERS_SCREEN]: {
        event: "custom_event",
        eventCategory: "Loan offers screen"
    },
    [AU_DESKTOP_EVENTS.ZERO_DP_STAGE2]: {
        event: "custom_event",
        eventCategory: "Stage 2"
    },
    [AU_DESKTOP_EVENTS.EMAIL_ALREADY_EXIST_SCREEN]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "email_already_exist_screen"
    },
    [AU_DESKTOP_EVENTS.LOGIN_OPTION_SELECTED]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "login_option_selected"
    },
    [AU_DESKTOP_EVENTS.VERIFICATION_MODAL]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "verification_modal"
    },
    [AU_DESKTOP_EVENTS.BUY_ONLINE_BASIC_DETAILS_SCREEN_FORM_FILLED]: {
        event: "Filled_Buy_Online_Personal_Details",
        eventCategory: "Cars24_checkout_page",
        eventAction: "filled_personal_details_buy_online"
    },
    [AU_DESKTOP_EVENTS.BUY_ONLINE_BASIC_DETAILS_SCREEN_COMPLETED]: {
        event: "basic_details_completed_buy_online",
        eventCategory: "Cars24_checkout_page",
        eventAction: "basic_details_completed_buy_online"
    },
    [AU_DESKTOP_EVENTS.BASIC_DETAILS_SCREEN]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "basic_details_screen",
        eventLabel: "email_added"
    },
    [AU_DESKTOP_EVENTS.EXIT_INTENT]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "exit_intent"
    },
    [AU_DESKTOP_EVENTS.POST_BC_SCREEN]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "post_bc_screen"
    },
    [AU_DESKTOP_EVENTS.GUEST_CAR_RESERVED]: {
        event: "custom_event",
        eventCategory: "Reservation",
        eventAction: "Guest_car_reserved"
    },
    [AU_DESKTOP_EVENTS.GUEST_CAR_RESERVE_AT_ZERO]: {
        event: "custom_event",
        eventCategory: "Reservation",
        eventAction: "Guest_car reserve at zero",
        eventLabel: "Click_Zero_deposit_reservation"
    },
    [AU_DESKTOP_EVENTS.GUEST_CAR_RESERVE_AT_NON_ZERO]: {
        event: "custom_event",
        eventCategory: "Reservation",
        eventAction: "Guest_car reserve at non_zero",
        eventLabel: "Click_finance_non_zero_reservation"
    },
    [AU_DESKTOP_EVENTS.GUEST_EMAIL_VERIFICATION]: {
        event: "custom_event",
        eventCategory: "Verify",
        eventAction: "Guest_Email_verification"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVED_NO]: {
        event: "custom_event",
        eventCategory: "Tints",
        eventAction: "Pre approved_No"
    },
    [AU_DESKTOP_EVENTS.PRE_APPROVED_YES]: {
        event: "custom_event",
        eventCategory: "Tints",
        eventAction: "Pre approved_Yes"
    },
    [AU_DESKTOP_EVENTS.ORDER_SUMMARY_CASH]: {
        event: "custom_event",
        eventCategory: "Tints",
        eventAction: "Order summary_Cash"
    },
    [AU_DESKTOP_EVENTS.ORDER_SUMMARY_FINANCE]: {
        event: "custom_event",
        eventCategory: "Tints",
        eventAction: "Order summary_Finance"
    },
    [AU_DESKTOP_EVENTS.HELP_SUPPORT_ICON_CLICKED]: {
        event: "custom_event",
        eventCategory: "Cars24_au_all_pages",
        eventAction: "help_icon_clicked_EMAIL_variant"
    },
    [AU_DESKTOP_EVENTS.SELL_ORDER_VALUATION_NUDGE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "C2B_finish_valuation_nudge"
    },
    [AU_DESKTOP_EVENTS.SELL_ORDER_BOOK_INSPECTION_NUDGE]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "C2B_book_inspection_nudge"
    },
    [AU_DESKTOP_EVENTS.S20_VIEWER_AND_GALLERY]: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "s20_viewer_and_gallery"
    },
    [AU_DESKTOP_EVENTS.SEARCH_NO_RESULT_SCREEN]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "search_no_result"
    },
    [AU_DESKTOP_EVENTS.DESKTOP_CLP_REVAMP]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "desktop_clp_revamp"
    },
    [AU_DESKTOP_EVENTS.CALLBACK_REQUEST_MODAL_OPENED]: {
        eventCategory: "Cars24_checkout_page",
        eventAction: "Call back request generator",
        eventLabel: "call back request pop up"
    },
    [AU_DESKTOP_EVENTS.CALLBACK_REQUEST_LINK_CLICKED]: {
        eventCategory: "Cars24_checkout_page",
        eventAction: "Call back request generator",
        eventLabel: "call back request link"
    },
    [AU_DESKTOP_EVENTS.ORDER_SUMMARY_PAGE]: {
        event: "custom_event",
        eventCategory: "Order summary page"
    },
    [AU_DESKTOP_EVENTS.CDP_NO_SERVICE_OLD_TIMER]: {
        event: "custom_event",
        eventCategory: "CDP",
        eventAction: `No_service_old_timer`,
        eventLabel: `Landed`
    },
    [AU_DESKTOP_EVENTS.EW_CHECKOUT_NO_SERVICE_OLD_TIMER]: {
        event: "custom_event",
        eventCategory: "EW_checkout",
        eventAction: `No_service_old_timer`,
        eventLabel: `Landed_EW_page_checkout`
    },
    [AU_DESKTOP_EVENTS.CDP_NO_SERVICE_OLD_TIMER_VIEWED]: {
        event: "custom_event",
        eventCategory: "CDP"
    },
    [AU_DESKTOP_EVENTS.FINANCE_LANDING_PAGE_USER_LANDED]: {
        event: "custom_event",
        eventCategory: "Finance landing page_user_landed"
    },
    [AU_DESKTOP_EVENTS.FINANCE_LANDING_PAGE_CHECK_PRE_APPROVAL]: {
        event: "custom_event",
        eventCategory: "Finance landing page_check_pre_approval"
    },
    [AU_DESKTOP_EVENTS.FINANCE_LANDING_PAGE_BROWSE_CARS]: {
        event: "custom_event",
        eventCategory: "Finance landing page_browse_cars"
    },
    [AU_DESKTOP_EVENTS.FINANCE_LANDING_PAGE_TOP_CAPSULES]: {
        event: "custom_event",
        eventCategory: "Finance landing page_top_capsules"
    },
    [AU_DESKTOP_EVENTS.FINANCE_LANDING_PAGE_CALCULATOR]: {
        event: "custom_event",
        eventCategory: "Finance landing page_calculator"
    },
    [AU_DESKTOP_EVENTS.NUDGES_FINANCE_KNOW_MORE_FINANCE]: {
        event: "custom_event",
        eventCategory: "Nudges_finance_know_more_finance",
        eventAction: "Know_more"
    },
    [AU_DESKTOP_EVENTS.MENU_FINANCING_CLICKED]: {
        event: "custom_event",
        eventCategory: "Menu_financing_clicked"
    },
    [AU_DESKTOP_EVENTS.BYOF_LANDING_PAGE]: {
        event: "custom_event",
        eventCategory: "click",
        eventAction: "BYOF_Landing page"
    },
    [AU_DESKTOP_EVENTS.FINANCE_LANDING_PAGE_WHAT_HAPPENS_AFTER_LINK]: {
        event: "custom_event",
        eventCategory: "Finance landing page_what_happens_after_link"
    },
    [AU_DESKTOP_EVENTS.ESTIMATED_VALUATION_RANGE_VARIANT]: {
        event: "custom_event",
        eventCategory: "C2B_flow",
        eventAction: "range_price_viewed"
    },
    [AU_DESKTOP_EVENTS.FF_VOLUME_EXPERIMENT]: {
        event: "custom_event",
        eventCategory: "FF_volume_experiment"
    },
    [AU_DESKTOP_EVENTS.PRE_BC_CAR_CARE_PACKS_LANDING_PAGE]: {
        event: "custom_event",
        eventCategory: AU_DESKTOP_EVENTS.PRE_BC_CAR_CARE_PACKS_LANDING_PAGE
    },

    [AU_DESKTOP_EVENTS.PRE_BC_CAR_CARE_PACKS_VAS_STORE]: {
        event: "custom_event",
        eventCategory: AU_DESKTOP_EVENTS.PRE_BC_CAR_CARE_PACKS_VAS_STORE
    },

    [AU_DESKTOP_EVENTS.PRE_BC_CAR_CARE_PACKS_CART]: {
        event: "custom_event",
        eventCategory: AU_DESKTOP_EVENTS.PRE_BC_CAR_CARE_PACKS_CART
    },

    [AU_DESKTOP_EVENTS.PRE_BC_CAR_CARE_PACKS_LANDING_PAGE]: {
        event: "custom_event",
        eventCategory: "Pre BC_car_care_packs_landing page"
    },

    [AU_DESKTOP_EVENTS.PRE_BC_CAR_CARE_PACKS_PRODUCT_DETAIL_PAGE]: {
        event: "custom_event",
        eventCategory: AU_DESKTOP_EVENTS.PRE_BC_CAR_CARE_PACKS_PRODUCT_DETAIL_PAGE
    },
    [AU_DESKTOP_EVENTS.DRIVA_DEPOSIT_OPEN]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "deposit_open"
    },
    [AU_DESKTOP_EVENTS.DRIVA_TENURE_OPEN]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "tenure_open"
    },
    [AU_DESKTOP_EVENTS.DRIVA_BALLOON_OPEN]: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "balloon_open"
    },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_TEST_DRIVE_BANNER_SEEN]: { event: "custom_event", eventCategory: "Checkout" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_CTA_SELECTED]: { event: "cdp_cta_selected", eventCategory: "Cars24_detail_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_ATF_INTERACTIONS]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_DETAIL_PERSONAL]: { event: "Filled_Test_Drive_Personal_Details", eventCategory: "Cars24_checkout_page", eventAction: "filled_personal_details_test_drive" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_BASIC_DETAILS_FILLED]: {event: "basic_details_completed_test_drive", eventCategory: "Cars24_checkout_page", eventAction: "basic_details_completed_test_drive"},
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_BACK]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_CHECKOUT]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_CHECKOUT_WHATSAPP_CONSENT]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_NEXT]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_FINANCE_BACK]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_FINANCE_NEXT]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_BOOK_SLOT_BACK]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_BOOK_SLOT_NEXT]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_DELIVERY_HUB_ADDRESS]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_BOOKING_DAY_SELECTED]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_BOOKING_TIME_SELECTED]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_EDIT_PICKUP_LOCATION_CLICKED]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_EDIT_PICKUP_POPUP_INTERACTION]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_BOOKING_CONFIRM_POPUP_SEEN]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_BOOKING_RESCHEDULE_POPUP_SEEN]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_BOOKING_CANCEL_POPUP_SEEN]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_BOOKING_RESCHEDULE_POPUP_CLOSED]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_BOOKING_CANCEL_POPUP_CLOSED]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_BOOKING_CONFIRMATION_BACK]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_BOOKING_CONFIRMATION_BANNER_SEEN]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_BANNER_CLICKED]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_BOOKING_CONFIRMATION_BUY_ONLINE]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_BOOKING_CONFIRMATION_RESCHEDULE]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_BOOKING_CONFIRMATION_CANCEL]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_BOOKING_CANCELLATION]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_TD_POPUP_INTERACTION]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_TD_USER_TYPE]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_LOCATION_EXPOSURE]: { event: "custom_event" },
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_TD_STRIP_INTERACTION]: { event: "custom_event", eventCategory: "TD Strip Interaction"},
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_TD_STRIP_POPUP_CLOSED]: { event: "custom_event", eventCategory: "TD Strip Popup Closed"},
    [AU_DESKTOP_EVENTS.TEST_DRIVE_V2_PRELIMINARY_SCREEN]: { event: "custom_event", eventCategory: "Cars24_checkout_page" },

    [AU_DESKTOP_EVENTS.RECENTLY_VIEWED_CAR_CLICK]: { event: "custom_event", eventCategory: "Cars24_home_page", eventAction: AU_DESKTOP_EVENTS.RECENTLY_VIEWED_CAR_CLICK},
    [AU_DESKTOP_EVENTS.RECOMMENDED_CAR_CLICK]: { event: "custom_event", eventCategory: "Cars24_home_page", eventAction: AU_DESKTOP_EVENTS.RECOMMENDED_CAR_CLICK},
    [AU_DESKTOP_EVENTS.RECENTLY_ADDED_CAR_CLICK]: { event: "custom_event", eventCategory: "Cars24_home_page", eventAction: AU_DESKTOP_EVENTS.RECENTLY_ADDED_CAR_CLICK},
    [AU_DESKTOP_EVENTS.PICKS_FOR_YOU_CAR_CLICK]: { event: "custom_event", eventCategory: "Cars24_home_page", eventAction: AU_DESKTOP_EVENTS.PICKS_FOR_YOU_CAR_CLICK},
    [AU_DESKTOP_EVENTS.CARS_BY_PRICE_CLICK]: { event: "custom_event", eventCategory: "Cars24_home_page", eventAction: AU_DESKTOP_EVENTS.CARS_BY_PRICE_CLICK},
    [AU_DESKTOP_EVENTS.CARS_BY_BODYTYPE_CLICK]: { event: "custom_event", eventCategory: "Cars24_home_page", eventAction: AU_DESKTOP_EVENTS.CARS_BY_BODYTYPE_CLICK},
    [AU_DESKTOP_EVENTS.GALLERY_OPENED]: { event: "custom_event", eventCategory: "Cars24_detail_page", eventAction: AU_DESKTOP_EVENTS.GALLERY_OPENED},

    [AU_DESKTOP_EVENTS.INFO_ICON_REPAYMENT]: {event: "custom_event", eventCategory: "infoicon_repayment"},
    [AU_DESKTOP_EVENTS.CDP_TOOLTIP_ST_HOVER]: {
        event: "custom_event",
        eventCategory: "ST_specific_events",
        eventAction: "CDP_hover"
    },
    [AU_DESKTOP_EVENTS.MMVY_DETIAL_ENTERED]: {
        event: "custom_event",
        eventCategory: "Trade_in"
    },
    [AU_DESKTOP_EVENTS.WEB_CUSTOM_STATE_EXPERIMENT]: { event: "custom_event"},
    [AU_DESKTOP_EVENTS.SELL_TRADE_BANNER]: { event: "custom_event", eventCategory: "Cars24_home_page", eventAction: "clicked", eventLabel: "sell_trade_banner"},
    [AU_DESKTOP_EVENTS.DESKTOP_CDP_REVAMP]: { event: "custom_event", eventCategory: "Cars24_detail_page", eventAction: AU_DESKTOP_EVENTS.DESKTOP_CDP_REVAMP}

};

