import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";

const getActiveState = (index, collapseAccordion, defaultActiveIndex) => {
    if (Array.isArray(defaultActiveIndex)) {
        return !(defaultActiveIndex.indexOf(index) > -1);
    }
    if (defaultActiveIndex === index) {
        return false;
    }
    return collapseAccordion;
};

const Accordion = ({ children, totalItems = 1, collapseAccordion = false, toggleAll = false, defaultActiveIndex = -1 }) => {
    const itemsArray = useMemo(() =>
        Array.from({ length: totalItems }).map((_, index) =>
            getActiveState(index, collapseAccordion, defaultActiveIndex)
        ), [totalItems, collapseAccordion, defaultActiveIndex]
    );

    const [currentVisibleStates, setCurrentVisibleStates] = useState(itemsArray);

    const onAccordionClickHandler = useCallback(({ index }) => {
        setCurrentVisibleStates(prevStates => {
            const newStates = toggleAll ? [...prevStates] : [...itemsArray];
            newStates[index] = !prevStates[index];
            return newStates;
        });
    }, [toggleAll, itemsArray]);

    const accordionProps = useMemo(() => ({
        onClick: onAccordionClickHandler,
        currentVisibleStates
    }), [onAccordionClickHandler, currentVisibleStates]);

    return (
        <ul>
            {children(accordionProps)}
        </ul>
    );
};

const Heading = ({ children, headingRef }) => {
    return <React.Fragment>{React.cloneElement(children, { ref: headingRef })}</React.Fragment>;
};

const Body = ({ children }) => {
    return <React.Fragment>{children}</React.Fragment>;
};

const Content = ({ children }) => {
    return <li>{children}</li>;
};

// PropTypes
Accordion.propTypes = {
    children: PropTypes.func,
    totalItems: PropTypes.number,
    toggleAll: PropTypes.bool,
    collapseAccordion: PropTypes.bool,
    defaultActiveIndex: PropTypes.number
};

Heading.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    headingRef: PropTypes.object
};

Body.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

Content.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

// Attach sub-components
Accordion.Content = Content;
Accordion.Heading = Heading;
Accordion.Body = Body;

export default Accordion;
