export const AMPLITUDE_EVENTS_CATEGORY = {
    CAR_IMPRESSION: "car impression",
    CAR_CARDS_IMPRESSION_IN_BATCH: "six car impressions",
    CAR_LISTING_PAGE_OPENED: "car listing page opened",
    CAR_CARD_CLICKED: "car card clicked",
    CAR_DETAIL_PAGE_OPENED: "car detail page opened",
    PURCHASE_STARTED: "purchase started",
    BASIC_DETAILS_ENTERED: "basic details entered",
    DELIVERY_DETAILS_ENTERED: "Delivery next button",
    PAYMENT_DETAILS_ENTERED: "payment details entered",
    CHECKOUT: "Checkout",
    PURCHASE_SUCCESS: "purchase success",
    FILTER_APPLIED: "filter applied",
    FILTER_SELECTED: "filter selected",
    FILTER_DESELECTED: "filter deselected",
    SORT_BY_SELECTED: "sort by selected",

    CONTINUE_BUYING: "continue buying",

    // FINANCE FIRST
    FF_PRE_APPROVAL: "FF pre approval",

    FF_PRE_APPROVAL_RESULT: "FF pre approval result",
    FF_PRE_APPROVAL_CARS_VIEWED: "FF pre approval cars viewed",
    KNOW_MORE_PREAPPROVAL_CLICK: "Know_more_result_pre approval",
    GET_PRE_APPROVAL: "Get pre approval",

    // CLP
    NEW_PRE_APPROVAL_TILE_SEEN: "New pre approval tile seen",

    // CAR FIRST
    CAR_FIRST_PRE_APPROVAL_SUBMITTED: "Car first pre approval submitted",

    // LOAN CALCULATOR
    LOAN_CALCULATOR_POP_UP_VIEWED: "Loan calculator pop up viewed",
    LOAN_CALCULATOR_CDP_USED: "Loan calculator_CDP used",

    // EXTENDED WARRANTY
    EXTENDED_WARRANTY_SELECTED: "Extended warranty selected",
    EXTENDED_WARRANTY_MODE: "Extended warranty mode",
    EXTENDED_WARRANTY_SKIPPED: "Extended warranty skipped",

    // PAYMENT MODE
    PAYMENT_MODE_SELECTED: "Payment mode selected",

    // LOAN QUOTES
    TERMINATION_CHARGES: "Termination charges",
    LOAN_PROCESSING_FEES_HYPERLINK: "Loan processing fees_hyperlink",
    DAP_HYPERLINK: "DAP_hyperlink",
    REFUNDABILITY_DEPOSIT: "refundability_deposit",
    LENDER_TILE_CLICKED: "Lender tile_clicked",
    INTERMEDIARY_VIEW_LOAN_QUOTE_CTA: "Intermediary_view_loan_quote_CTA",
    LOAN_QUOTE_SELECTION: "Loan quote selection",
    LETS_START: "Let's start clicked",
    BUSINESS_PURPOSE_QUES_ANSWERED: "Business purpose ques answered",
    LOAN_DETAILS_COMPLETED: "Loan details completed",
    CURRENT_EMPLOYMENT_STATUS_ANSWERED: "current employment status answered",
    CURRENT_EMPLOYMENT_DURATION: "current employment duration",
    GROSS_INCOME: "gross income",
    HOUSE_MORTGAGE: "House mortgage/rent answered",
    NAME_AND_DOB_ANSWERED: "Name and dob answered",
    CURRENT_LIVING_QUESTION_AND_DEPENDENTS_ANSWERED: "Current living question and dependents answered",
    RELATIONSHIP_STATUS_AND_PARTNERS_GROSS_INCOME_ANSWERED: "relationship status and partner's gross income answered",
    FORM_SUBMISSION: "Form submission",
    HELP_ACCESSED: "Help accessed",
    // HOME PAGE
    HOMEPAGE_OPENED: "homepage opened",
    SEARCH_BAR_CLICKED: "search bar clicked",
    SEARCH_AUTO_SUGGESTION: "search auto suggestion applied",
    FREE_TEXT_SERACH: "free text search done",
    NO_RESULTS_FOUND: "no results found",
    SEARCH_RESULT_CLICKED: "search result clicked",
    FILTER_SUGGESTION: "filter suggestions",
    CARS_BY_PRICE_SEEN: "desktop cars by price seen",
    CARS_BY_BODYTYPE_SEEN: "desktop cars by bodytype seen",
    DESKTOP_PERSONALISATION_SECTION_SEEN: "desktop personalisation cars seen",
    DESKTOP_PERSONALISATION_SECTION_SELECTED: "desktop personalisation cars selected",
    DESKTOP_CARS_BY_PRICE_SELECTED: "desktop cars by price selected",
    MOBILE_CARS_BY_PRICE_SELECTED: "mobile cars by price selected",
    DESKTOP_CARS_BY_BODYTYPE_SELECTED: "desktop cars by bodytype selected",
    MOBILE_CARS_BY_BODYTYPE_SELECTED: "mobile cars by bodytype selected",
    PREAPPROVAL_CLICK_DONE: "pre approval click done",
    C2B_CLICK_DONE: "c2b click done",

    WISHLIST_CLICKED: "wishlist clicked",
    WISHLIST_SECTION_CTA_CLICKED: "wishlist section cta clicked",
    GALLERY_OPENED: "gallery opened",
    TRADE_IN_PAGE_EVENT: "trade-in page event",
    EXTEDNED_WARRANTY_OPTED: "Extedned warranty opted",
    CASH_AMOUNT_SELECTED: "Cash amount selected",
    PAYMENT_METHOD_SELECTED: "payment method selected",
    PAYMENT_INITIATION_SCREEN_SHOWN: "Payment initiation screen shown",
    LOGIN_SUCCESS: "login success",
    LOGOUT_SUCCESS: "logout success",
    HELP_ICON_CLICKED: "H&S icon clicked",
    INQUIRY_SUBMITTED: "Inquiry submitted",
    SEARCH_AUTO_SUGGESTION_APPLIED: "search auto suggestion applied",
    FREE_TEXT_SEARCH_DONE: "free text search done",
    RECENTLY_SEARCHED_CLICKED: "recently searched clicked",
    POPULAR_SEARCHES_CLICKED: "popular searches clicked",
    POPULAR_BRANDS_CLICKED: "popular brands clicked",
    SEARCH_RELATED_CAR_CARD_CLICKED: "search related car card clicked",
    I360_VIEWER_INTERACTION: "360 viewer interaction",
    GALLERY_360VIEWER_CLOSED: "gallery/360 viewer closed",
    CDP_TABS_SELECTED: "CDP tabs selected",
    CAR_DETAILS_VIEWED: "car details viewed",
    FAQ_SSECTIONS_USED: "FAQs sections used",
    VIEW_FULL_REPORT: "view full report",
    SERVICE_HISTORY_VIEWED: "service history viewed",
    CAR_GALLERY_CLICKED: "car gallery clicked",
    BELOW_MARKET_PRICE_CLICKED: "below market price clicked",

    TOP_BRANDS_CLICKED: "top brands clicked",
    POPULAR_CATEGORIES_CLICKED: "popular categories clicked",
    CARS_BY_CATEGORY_CLICKED: "cars by category clicked",
    MODEL_SELECTED: "model selected",
    DESKTOP_CATEGORY_SELECTED: "desktop category selected",
    DESKTOP_CARS_BY_LIFESTYLE_SELECTED: "desktop cars by lifestyle selected",
    VALUE_PROPS_SEEN: "value props seen",
    VALUE_PROPS_SCROLLED: "value props scrolled",
    REVIEW_SECTION_SEEN: "review section seen",
    REVIEW_SECTION_SCROLLED: "review section scrolled",
    DESKTOP_LATEST_CARD_CLICKED: "desktop latest card clicked",
    SUPPORT_METHOD_CHOSEN: "Support method chosen",
    HS_S_ICON_CLICKED: "H&S icon clicked",
    TRADE_IN_HOMEPAGE_BANNER_CLICKED: "trade in homepage banner clicked",
    SUBSRIBE_NOW_CLICKED: "subsribe now clicked",
    SHOW_CARS_CLICKED: "show cars clicked",
    NOT_LOOKING_TO_BUY_BANNER_CLICKED: "not looking to buy homepage banner clicked",
    RECENTLY_ADDED_CARD_CLICKED: "recently added card clicked",
    RECENTLY_ADDED_CARD_SEEN: "recently added card seen",
    RECENTLY_VIEWED_CARD_SEEN: "recently viewed card seen",
    RECENTLY_VIEWED_CARD_CLICKED: "recently viewed card clicked",
    PICKS_FOR_YOU_CARD_SEEN: "picks for you card seen",
    PICKS_FOR_YOU_CARD_CLICKED: "picks for you card clicked",
    MOBILE_CARS_BY_PRICE_SEEN: "mobile cars by price seen",
    MOBILE_CARS_BY_BODY_TYPE_SEEN: "mobile cars by bodytype seen",
    "MAKE_SELECTED": "make selected",

    "HOW_IT_WORKS_SECTION_SEEN": "how it works section seen",
    "HOW_IT_WORKS_VIDEO_PALYED": "how it works video palyed",
    "C2B_LINK_CLICKED": "not looking to buy homepage banner clicked",
    "TRADE_HOMEPAGE_BANNER_CLICKED": "trade in homepage banner clicked",
    "CATEGORY_SELECTED": "desktop category selected",
    "LIFESTYLE_SELECTED": "desktop cars by lifestyle selected",

    CAR_DETAILS_PAGE_OPENED: "car details page opened",
    WISHILIST_CLICKED: "wishilist clicked",
    SHARE_BUTTON_CLICKED: "share button clicked",
    HELP_AND_SUPPORT_BUTTON_CLICKED: "help and support button clicked",
    EGC_SECTION_CLICKED: "EGC section clicked",
    LOAN_CALCULATOR_CLICKED: "loan calculator clicked",
    HERO_IMAGE_CLICKED: "hero image clicked",
    THREE_SIXTY_STICKER_CLICKED: "360 sticker clicked",
    GALLERY_NAVIGATION_TABS_SELECTED_ON_CDP: "gallery navigation tabs selected on CDP",
    GALLERY_VIEWED: "gallery viewed",
    GALLERY_IMAGE_PINCHED_TO_ZOOM: "gallery image pinched to zoom",
    GALLERY_HOTSPOTS_VIEWED: "gallery hotspots viewed",
    GALLERY_HOTSPOTS_CLOSED: "gallery hotspots closed",
    THREE_SIXTY_VIEWER_VIEWED: "360 viewer viewed",
    THREE_SIXTY_VIEWER_INTERACTIONS: "360 viewer interactions",
    HOTSPOT_CLICKED: "hotspot clicked",
    THREE_SIXTY_VIEWER_ZOOM: "360 viewer zoom",
    OVERVIEW_CLICKED: "overview clicked",
    VIEW_MORE_OVERVIEW_CLICKED: "view more overview clicked",
    FEATURES_AND_SPECIFICATIONS_SECTION_VIEWED: "features and specifications section viewed",
    PREMIUM_FEATURES_VIEWED: "premium features viewed",
    FEATURES_SEARCHED: "features searched",
    VIEW_ALL_FEATURES_CLICKED: "view all features clicked",
    SPECIFICATIONS_SELECTED: "specifications selected",
    CAR_HISTORY_VIEWED: "car history viewed",
    PPSR_REPORT_VIEWED: "PPSR report viewed",
    INSPECTION_REPORT_VIEWED: "Inspection Report Viewed",
    CLEAR_ALL: "clear all",

    //VAS
    LANDING_PAGE: "Landing page",
    VAS_STORE: "VAS store",
    CART_PAGE: "Cart page",
    PRODUCT_DETAIL_PAGE: "Product detail page",
    SIMILAR_CARS_SEEN: "similar section seen",

    WHATSAPP_CONSENT_USED: "checkout whatsapp consent used",
    "MM_SEARCH_BAR_CLICKED": "make model search bar clicked",
    "MM_SEARCH_DONE": "make model search done",
    "SEE_MORE_FILTERS_CLICKED": "see more filters clicked",
    "MM_ALPHABET_SELECTED": "make model alphabet selected",
    "SEE_ALL_MAKES": "see all makes clicked",

    PAYMENT_MODE_LANDED: "Payment mode landed",

    CARD_CALL: "Card call",
    CARD_EMAIL: "Card e-mail",
    CARD_TEXT: "Card text",
    CARD_CONTACT_US: "Card contact us",

    PAYMENT_MODE_LEARN_MORE: "payment amount learn more",

    PAYMENT_MODE_BACK_CLICK: "Payment amount back click",

    // basic details
    BASIC_DETAIL_FIRST_NAME: "basic details firstname",
    BASIC_DETAIL_LAST_NAME: "basic details lastname",
    BASIC_DETAILS_PHONE_NO: "basic details phnumber",
    BASIC_DETAILS_EMAIL: "basic details email",
    BASIC_DETAILS_NEXT_CLICKED: "basic details (next)",

    // Guest Login
    GUEST_CHECKOUT: "guest checkout",
    // Delivery
    DELIVERY_HUB_ADDRESS: "Delivery Hub address",
    DELIVERY_FAQ: "FAQ",
    DELIVERY_BACK_BUTTON: "Delivery back button",

    //banking transfer
    BT_NAME_COPIED: "Bank transfer Name copied",
    BT_ACCNO_COPIED: "Bank transfer ACCNO copied",
    BT_BSB_COPIED: "Bank transfer BSB copied",
    BT_REFERENCE_COPIED: "Bank transfer reference copied",
    BT_AMOUNT_COPIED: "Bank transfer amount copied",
    BT_TRANSFER_FUNDS_CLICKED: "BT I've TransferredFunds clicked",
    BT_CALL: "Bank transfer call",
    BT_EMAIL: "Bank transfer email",
    BT_TEXT: "Bank transfer text",
    BT_UPLOAD_SCREENSHOT: "Bank transfer upload screenshot",
    BT_CHECK_AGAIN: "Bank transfer check again",
    BT_CONTACT_US: "Bank transfer contact us",

    "PROMO_CODE": "Promo code",
    PPSR_VIEWED: "PPSR Viewed",
    CALLBACK_REQUEST_MODAL_OPENED: "call back request pop up",
    CALLBACK_REQUEST_LINK_CLICKED: "call back request link",
    GALLERY_CTA_CLICKED: "gallery/360 CTA clicked",
    "CDP_BACK_BUTTON_CLICKED": "cdp back button clicked",

    CASH_UserLanded: "CASH_UserLanded",
    CASH_know_more: "CASH_know_more",
    CASH_Testimonials: "CASH_Testimonials",
    "CASH_I agree to terms button": "CASH_I agree to terms button",
    "CASH_FAQ": "CASH_FAQ",
    "CASH_Got a promo code": "CASH_Got a promo code",
    "CASH_Drop down": "CASH_Drop down",

    ORDER_SUMMARY_PAGE: "Order summary page",
    RESERVE_CAR_FINANCE: "Reserve car finance",
    RESERVE_CAR_T: "Reserve car_T&C",
    DROP_DOWN_OPEN: "Drop down_Open",
    DROP_DOWN_CLOSE: "Drop down_close",
    REFUNDABILITY_POP_UP: "Refundability pop up",
    TESTIMONIALS: "Testimonials",
    PRICE_BREAKUP: "Price breakup",
    RESERVE_CAR_CLICK_WITHOUT_SELECTING_PAYMENT_MODE: "Reserve car click_without_selecting_payment_mode",
    RESERVE_CAR_CLICK_WITHOUT_SELECTING_PAYMENT_MODE_BYOF: "Reserve car click_without_selecting_payment_mode byo",
    ORDER_SUMMARY_VARIANT_FINANCE: "Order summary variant finance",
    RESERVE_CAR_CASH: "Reserve car CASH",
    "SIMILAR_CARS_ON_HERO_IMAGE_CLICKED": "similar cars on hero image clicked",
    VIEW_MORE_SIMILAR_CARS_CLICKED: "view more similar cars clicked",
    VIEW_SIMILAR_ON_RESERVED_CDP_CLICKED: "view similar on reserved cdp clicked",
    "MORE_MODELS_FOR_YOU_SEEN": "more models for you seen",
    "MORE_CARS_IN_YOUR_BUDGET_SEEN": "more cars in your budget seen",
    "NOTIFY_ME_CLICKED": "notify me clicked",

    //FINANCE LANDING PAGE
    USER_LANDED: "User_Landed",
    CHECK_PRE_APPROVAL: "Check_pre_approval",
    BROWSE_CARS: "Browse_cars",
    WHAT_HAPPENS_AFTER_LINK: "What_happens_after_link",
    TOP_CAPSULES: "Top_capsules",
    Calculator: "Calculator",
    KNOW_MORE_FINANCE: "Know_more_finance",
    FINANCING_CLICKED: "Financing_clicked",

    FIND_CAR_DELIVERY_CHECKOUT: "Find cars in your location here",

    "WT Name copied": "WT Name copied",
    "WT AccNo copied": "WT AccNo copied",
    "WT BSB copied": "WT BSB copied",
    "WT reference copied": "WT reference copied",
    "WT Amount copied": "WT Amount copied",
    "WT contact us": "WT contact us",
    "WT Upload screenshot": "WT Upload screenshot",
    "WT details Uploaded": "WT details Uploaded",
    "WT Upload screenshot info": "WT Upload screenshot info",
    "WT Submit": "WT Submit",
    "WT Back": "WT Back",

    HAPPY_PURCHASES_SEEN: "Happy Purchases Seen",
    HAPPY_PURCHASES_SCROLLED: "Happy Purchases section scrolled",
    PLATFORM_RATINGS_SEEN: "Platform Ratings Seen",
    PLATFORM_REVIEWS_SEEN: "Platform Reviews Seen",
    PLATFORM_REVIEWS_SCROLLED: "Platform Reviews scrolled",
    PLATFORM_REVIEWS_OPENED: "Platform Reviews opened",
    SALE_BANNER_CLICKED: "sale banner clicked",
    CDP_NO_SERVICE_OLD_TIMERS: "CDP_no_service_old_timers",
    CDP_NO_SERVICE_OLD_TIMERS_EW_CDP: "CDP_no_service_old_timers_EW_CDP",
    CDP_NO_SERVICE_OLD_TIMERS_EW_CHECKOUT: "CDP_no_service_old_timers_EW_checkout",
    "Warranty value prop clicked": "Warranty value prop clicked",

    BYOF_REVAMP_USERLANDED: "Order summary variant byo",
    REFUNDABILITY_POP_UP_BYOF: "Refundability pop up byo",
    DROP_DOWN_OPEN_BYOF: "Drop down_Open byo",
    DROP_DOWN_CLOSE_BYOF: "Drop down_close byo",
    TESTIMONIALS_BYOF: "Testimonials byo",
    RESERVE_CAR_TC_BYOF: "Reserve car_T&C byo",
    RESERVE_CAR_FINANCE_BYOF: "Reserve car finance byo",
    PRICE_BREAKUP_BYOF: "Price breakup byo",
    BYOF_LANDING_PAGE: "BYOF_Landing_page",

    INTEREST_RATE_MATCH: "Rate_match",

    SOMETHING_WENT_WRONG_PREAPPROVAL_RESULT_SCREEN: "Something_went_wrong",

    INFO_ICON_REPAYMENT: "Infoicon_repayment"
};

export const VISUAL_CDP_EVENTS = {
    CAR_DETAILS_PAGE_OPENED: "car details page opened",
    WISHILIST_CLICKED: "wishilist clicked",
    SHARE_BUTTON_CLICKED: "share button clicked",
    HELP_AND_SUPPORT_BUTTON_CLICKED: "help and support button clicked",
    EGC_SECTION_CLICKED: "EGC section clicked",
    LOAN_CALCULATOR_CLICKED: "loan calculator clicked",
    BELOW_MARKET_PRICE_CLICKED: "below market price clicked",
    HERO_IMAGE_CLICKED: "hero image clicked",
    THREE_SIXTY_STICKER_CLICKED: "360 sticker clicked",
    GALLERY_NAVIGATION_TABS_SELECTED_ON_CDP: "gallery navigation tabs selected on CDP",
    GALLERY_VIEWED: "gallery viewed",
    GALLERY_IMAGE_PINCHED_TO_ZOOM: "gallery image pinched to zoom",
    GALLERY_HOTSPOTS_VIEWED: "gallery hotspots viewed",
    GALLERY_HOTSPOTS_CLOSED: "gallery hotspots closed",
    THREE_SIXTY_VIEWER_VIEWED: "360 viewer viewed",
    THREE_SIXTY_VIEWER_INTERACTIONS: "360 viewer interactions",
    HOTSPOT_CLICKED: "hotspot clicked",
    THREE_SIXTY_VIEWER_ZOOM: "360 viewer zoom",
    OVERVIEW_CLICKED: "overview clicked",
    VIEW_MORE_OVERVIEW_CLICKED: "View More Overview Clicked",
    FEATURES_AND_SPECIFICATIONS_SECTION_VIEWED: "features and specifications section viewed",
    PREMIUM_FEATURES_VIEWED: "premium features viewed",
    PREMIUM_FEATURES_IMAGE_OPENED: "premium features image opened",
    FEATURES_IMAGE_PINCHED_TO_ZOOM: "features image pinched to zoom",
    FEATURES_SEARCHED: "features searched",
    F_AND_S_SEARCH_BOX_USED: "f&s search box used",
    VIEW_ALL_FEATURES_CLICKED: "view all features clicked",
    VIEW_ALL_BUTTON_CLICKED: "view all button clicked",
    F_AND_S_SECTION_VIEWED: "features and specifications section viewed",
    F_AND_S_DROPDOWN_USED: "f&s dropdown used",
    SPECIFICATIONS_SELECTED: "specifications selected",
    CAR_HISTORY_VIEWED: "car history viewed",
    SERVICE_HISTORY_VIEWED: "service history viewed",
    PPSR_REPORT_VIEWED: "PPSR report viewed",
    INSPECTION_REPORT_VIEWED: "Inspection Report Viewed",
    CONTACT_OUR_EXPERTS_CLICKED: "Contact Our Experts clicked",
    PRICING_DETAILS_INTERACTION: "pricing details interaction",
    FINANCE_SECTION_USED: "finance section used",
    PAY_OUTRIGHT_SECTION_USED: "pay outright section used",
    BUY_WITH_CONFIDENCE_SECTION_USED: "Buy With Confidence section used",
    HOW_TO_BUY_SECTION_SEEN: "how it works/how to buy section seen",
    HOW_IT_WORKS_VIDEO_PLAYED: "how it works video played",
    FAQS_SECTIONS_USED: "FAQs sections used",
    REVIEWS_SEEN: "Reviews seen",
    SIMILAR_CARS_VIEWED: "Similar cars viewed",
    MORE_CARS_FOR_YOU_SECTION_VIEWED: "More cars for you section viewed",
    CHAT_ICON_CLICKED: "chat icon clicked",
    TABS_SELECTED: "tabs selected",
    BUY_WITH_CONFIDENCE_SECTION_SCROLLED: "buy with confidence section scrolled",
    TRADE_IN_BANNER_VIEWED: "cdp trade in banner viewed",
    CLEAR_ALL: "clear all",
    CDP_NO_SERVICE_OLD_TIMERS_EW_CDP: "CDP_no_service_old_timers_EW_CDP",
    VALUE_PROPS_SEEN: "Value Props Seen",
    VALUE_PROPS_SCROLLED: "Value Props Scrolled",
    BENEFITS_INCLUDED_CTA_CLICKED: "price benefits CTA clicked"
};

export const FINANCE_FIRST_EXPERIMENT_AMPLITUDE_EVENTS = {
    USER_VARIANT: "User_variant_FF_AB",
    BOTTOM_NAV_FINANCE: "Bottom_nav_finance",
    CARD_VIEWED: "Card_viewed",
    FINANCE_CARD_CLICKED: "Finance_card_clicked",
    LETS_START_CLICKED: "Let's_start_clicked",
    LOAN_CALCULATOR_CDP_USER: "Loan_calculator_CDP_user type",
    LOAN_CALCULATOR_CDP_ACTION: "Loan_calculator_CDP_action",
    CALCULATOR_CDP_ACTION: "Calculator_CDP_action"
};

export const TEST_DRIVE_V2_AMPLITUDE_EVENTS = {
    PURCHASE_STARTED: "purchase started",
    CHECKOUT_INITIATED: "checkout initiated",
    BOOK_TEST_DRIVE: "book test drive",
    CONTINUE_BUYING: "continue buying",
    TRACK_BOOKING: "track booking",

    BASIC_DETAILS_FIRST_NAME: "basic details firstname",
    BASIC_DETAILS_LAST_NAME: "basic details lastname",
    BASIC_DETAILS_PH_NUMBER: "basic details phnumber",
    BASIC_DETAILS_EMAIL: "basic details email",

    CHECKOUT_WHATSAPP_CONSENT_USED: "checkout whatsapp consent used",
    BASIC_DETAILS_BACK: "Basic Details (Back)",
    BASIC_DETAILS_NEXT: "Basic Details (Next)",
    FINANCE_BACK: "finance (back)",
    FINANCE_NEXT: "finance (next)",
    BOOK_SLOT_BACK: "book slot (back)",
    BOOK_SLOT_NEXT: "book slot (next)",
    DELIVERY_HUB_ADDRESS: "Delivery Hub address",
    BOOKING_DAY_SELECTED: "Booking day selected",
    BOOKING_TIME_SELECTED: "Booking time selected",
    EDIT_PICKUP_LOCATION_CLICKED: "edit pickup location clicked",
    EDIT_PICKUP_POPUP_INTERACTION: "edit pickup popup interaction",
    BOOKING_CONFIRMATION_BACK: "booking confirmation (back)",
    BOOKING_CONFIRM_POPUP_SEEN: "booking Confirm popup seen",
    BOOKING_RESCHEDULED_POPUP_SEEN: "booking Rescheduled popup seen",
    BOOKING_CANCEL_POPUP_SEEN: "booking Cancel popup seen",
    BOOKING_RESCHEDULE_POPUP_CLOSED: "booking Reschedule popup closed",
    BOOKING_CANCEL_POPUP_CLOSED: "booking Cancel popup closed",
    SECOND_FOLD_SCREENSEEN: "second fold screen seen",
    BANNER_CLICKED: "banner clicked",
    BOOKING_CONFIRMATION_BUY_ONLINE: "booking confirmation (buy online)",
    BOOKING_CONFIRMATION_RESCHEDULE: "booking confirmation (reschedule)",
    BOOKING_CONFIRMATION_CANCEL: "booking confirmation (cancel)",
    BOOKING_CANCELLATION_POPUP: "booking cancellation popup",
    TD_POP_UP: "TD pop up",
    TD_WEBVIEW_MSITE: "TD webview/TD mSite",
    TD_USER_TYPE: "Non TG/TG Test/TG Control",
    VICTORIA_TEXT_CLICKED: "Victoria text clicked",
    TEST_DRIVE_LOCATION: "test-drive-location",
    GOOGLE_MAPS_IFRAME_SEEN: "Gmaps iframe seen",
    TD_STRIP_INTERACTION: "TD Strip Interaction",
    TD_STRIP_POPUP_CLOSED: "TD Strip Popup Closed",
    PRELIMINARY_SCREEN: "Self booking screen opened",

    TRACK_TD_BOOKING: "Track Booking",
    MY_BOOKING_TEST_DRIVE_BOOK: "book test drive",
    MY_BOOKINGS_VIEW_SIMILAR_CAR: "View similar clicked",
    MY_BOOKINGS_NOTIFY_ME: "Notify me clicked",
    MY_BOOKINGS_LANDING: "My Bookings opened",
    BUY_NOW: "Buy Now"
};

export const ST_AMPLITUDE_EVENTS = {
    PAYMENT_MODE_PAGE_LANDED: "Payment_mode_page_landed",
    PAYMENT_MODE_PAGE_CLICKED: "Payment_mode_page_clicked",
    NEXT_STEPS_POPUP_TYPE: "Next_steps_pop_up_type",
    LOAN_QUOTES_PAGE: "Loan quotes page",
    ORDER_SUMMARY_PAGE: "Order summary page"
};

export const CDP_REVAMP_AMPLITUDE_EVENTS = {
    CDP_NAVIGATION_SELECTED: "CDP navigation tab selected",
    GALLERY_CAROUSEL_INTERACTION: "gallery carousel interaction",
    FNS_SEARCH_BOX_USED: "f&s search box used",
    SERVICE_HISTORY_SECTION_SEEN: "service history section seen",
    SERVICE_HISTORY_VIEWED: "service history viewed",
    PPSR_REPORT_VIEWED: "PPSR report viewed",
    FNS_SECTION_SEEN: "f&s section seen",
    VALUE_PROPS_SEEN: "Value Props Seen",
    HAPPY_PURCHASES_SEEN: "Happy Purchases Seen",
    HAPPY_PURCHASES_SCROLLED: "Happy Purchases Scrolled",
    MORE_CARS_FOR_YOU_SECTION_CLICKED: "more cars for you section clicked",
    PREMIUM_FEATURES_VIEWED: "premium features viewed",
    PREMIUM_FEATURES_IMAGE_OPENED: "premium features image opened",
    PREMIUM_FEATURES_SECTION_SEEN: "premium features section seen",
    SECONDARY_HEADER_IN_CDP_USED: "secondary header in cdp used",
    CAR_CARD_CLICKED: "car card clicked",
    SIMILAR_CARS_ON_CDP_SEEN: "similar cars on cdp seen",
    MORE_MODELS_FOR_YOU_SEEN: "more models for you seen",
    SIMILAR_CARS_ON_CDP_SCROLLED: "similar cars on cdp scrolled",
    MORE_MODELS_FOR_YOU_SCROLLED: "more models for you scrolled",
    FNS_TAB_SELECTED: "f&s tab selected",
    GET_STARTED_CTA_VIEWED: "get started CTA viewed",
    FINANCING_INFO_ICON_CLICKED: "financing info button clicked"
};

