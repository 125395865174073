/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import BlueArrow from "./images/blue-arrow.svg";
import PriceBreakdownContentDropDown from "../price-breakdown-content-dropdown/component";

const PriceBreakdownContents = ({ paymentSummaryData }) => {
    const { summary = {} } = paymentSummaryData || {};

    return (
        <div>
            {summary?.lineItems?.length > 0 &&
                    summary?.lineItems?.map((item) => (
                        <Dropdown item={item} />
                    ))}

            <div styleName={"styles.totalAmountContainerLoanBreakdown"}>
                <p styleName={"styles.totalLoan-ffBreakdown"}>{summary?.aggregatedItem?.label}</p>
                <p styleName={"styles.totalLoan-ffBreakdown"}>
                    {makePriceLabelRound(summary?.aggregatedItem?.amount)}
                </p>
            </div>
        </div>
    );
};

const Dropdown = ({ item }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalSummary, setModalSummary] = useState({});
    const [expandedModal, setShowExpandedModal] = useState("");
    const [expandedDetails, setExpandedDetails] = useState([]);

    const handleModal = () => {
        setShowModal(!showModal);
    };

    const showCharges = (clickedItem) => {
        setModalSummary(clickedItem?.summary);
        handleModal();
    };

    const rootLevelItemClicked = (clickedItem) => {
        if (clickedItem?.summary) {
            if (expandedModal === clickedItem.key) {
                setShowExpandedModal("");
                setExpandedDetails([]);
            } else if (clickedItem?.summary?.viewType === "MODAL") {
                setModalSummary(clickedItem?.summary);
                handleModal();
            } else {
                setShowExpandedModal(clickedItem?.key);
                setExpandedDetails(clickedItem);
            }
        }
    };

    const renderAmountDisplay = (details) => {
        if (details?.type === "ADDITION") {
            return (
                <p styleName={"styles.detailLabelFFBreakdonw"}>
                    {makePriceLabelRound(details?.amount)}
                </p>
            );
        }

        if (details?.type === "FREE") {
            return (
                <React.Fragment>
                    <p
                        styleName={
                            details?.key === "GOVT_CHARGES"
                                ? "styles.typeCunderlinebreakdown"
                                : "styles.typeFreeLabelbreakDown"
                        }
                        onClick={() => showCharges(details)}
                    >
                        {details?.label}
                    </p>
                    <p styleName={"styles.typeFreeLabelbreakDown"}>Free</p>
                </React.Fragment>
            );
        }

        return (
            <p styleName={"styles.typeFreeLabelbreakDown"}>
                -{`${makePriceLabelRound(details?.amount)}`}
            </p>
        );
    };

    return (<React.Fragment>
        <div>
            <div styleName={"styles.lineItemSummary-ffBreakdown"}>
                <div styleName={"styles.flexWrap-ffBreakdown"}>
                    <p
                        styleName={
                            item?.summary && Object.keys(item?.summary || {}).length > 0
                                ? "styles.drivePrice"
                                : "styles.typeAWithoutUnderline"
                        }
                        onClick={() => rootLevelItemClicked(item)}
                    >
                        {item?.label}
                        {item?.summary && Object.keys(item?.summary || {}).length > 0 && (
                            <img src={BlueArrow} />
                        )}
                    </p>
                    {item?.type === "SUBTRACTION" ? (
                        <div styleName={"styles.typeFreeLabelbreakDown"}>
                            -{makePriceLabelRound(item?.amount)}
                        </div>
                    ) : (
                        <div styleName={"styles.driveAmount-ffBreakdown"}>
                            {makePriceLabelRound(item?.amount)}
                        </div>
                    )}
                </div>
                {item?.key === "DEPOSIT_PARTIAL_PAYMENT" && <p styleName={"styles.breakdownContentsDisclaimerTextlabel"}>100% refundable if you change your mind</p>}
                {item?.disclaimer && (
                    <div styleName={"styles.breakdownContentsDisclaimerTextlabel"}>
                        {item?.disclaimer}
                    </div>
                )}
            </div>

            {showModal && <PriceBreakdownContentDropDown isDropdown summary={modalSummary || {}} />}

            {expandedModal && expandedModal === item.key && (
                <div styleName={`styles.expandedWrapContainer-ffBreakdown`}>
                    {expandedDetails?.summary?.lineItems.length > 0 &&
                        expandedDetails?.summary?.lineItems.map((details) => {
                            return (
                                <div styleName={"styles.sectionWrapBreakDown"} key={details?.key}>
                                    {details?.type !== "FREE" && (
                                        <p
                                            styleName={
                                                details?.key === "GOVT_CHARGES"
                                                    ? "styles.typeCunderlinebreakdown"
                                                    : "styles.detailLabelFFBreakdonw"
                                            }
                                            onClick={() => showCharges(details)}
                                        >
                                            {details?.label}
                                        </p>
                                    )}
                                    {renderAmountDisplay(details)}
                                </div>
                            );
                        })}
                </div>
            )}
        </div>
    </React.Fragment>);
};

Dropdown.propTypes = {
    item: PropTypes.object
};

PriceBreakdownContents.propTypes = {
    paymentSummaryData: PropTypes.object,
    isInHouse: PropTypes.bool,
    isByoOpted: PropTypes.bool,
    splitPaymentOpted: PropTypes.bool
};

export default PriceBreakdownContents;
