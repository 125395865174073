import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FinanceFirstPriceBreakup from "./component";
import { getVehiclePickUpCities } from "../delivery-info/actions";

const mapStateToProps = ({
    checkout: {
        financeData,
        order: {
            chargeDetails,
            splitPaymentOpted,
            tradeinOpted,
            financeOpted,
            financeProvider,
            priceBreakDown,
            deliveryMode
        },
        egcData,
        allStatesPrice,
        tradeInData,
        splitAmountDetails

    },
    deliveryInfo,
    carDetails: {
        content,
        config
    }

}) => ({
    deliveryMode,
    deliveryInfo,
    content,
    config,
    chargeDetails,
    egcData,
    tradeInData,
    tradeinOpted,
    splitPaymentOpted,
    splitAmountDetails,
    financeData,
    financeOpted,
    financeProvider,
    allStatesPrice,
    priceBreakDown
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getVehiclePickUpCitiesConnect: getVehiclePickUpCities
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FinanceFirstPriceBreakup);
