export const ADDRESS_LEVEL_TYPE = {
    SUBPREMISE: "subpremise",
    STREET_NUMBER: "street_number",
    ROUTE: "route",
    LOCALITY: "locality",
    ADMINISTRATIVE_AREA_LEVEL_2: "administrative_area_level_2",
    ADMINISTRATIVE_AREA_LEVEL_1: "administrative_area_level_1",
    COUNTRY: "country",
    POSTAL_CODE: "postal_code"
};

const checkAddressComponentType = (data = {}, address = {}) => {
    const addressLevelType = data.types[0];
    switch (addressLevelType) {
    case ADDRESS_LEVEL_TYPE.SUBPREMISE: {
        return { ...address, unit: data.long_name};
    }
    case ADDRESS_LEVEL_TYPE.STREET_NUMBER: {
        return { ...address, streetNumber: data.long_name};
    }
    case ADDRESS_LEVEL_TYPE.ROUTE: {
        return { ...address, street: data.long_name};
    }
    case ADDRESS_LEVEL_TYPE.LOCALITY: {
        return { ...address, suburb: data.long_name};
    }
    case ADDRESS_LEVEL_TYPE.ADMINISTRATIVE_AREA_LEVEL_2: {
        return { ...address, city: data.long_name};
    }
    case ADDRESS_LEVEL_TYPE.ADMINISTRATIVE_AREA_LEVEL_1: {
        return { ...address, state: data.short_name || data.long_name, stateFullName: data.long_name || data.short_name};
    }
    case ADDRESS_LEVEL_TYPE.COUNTRY: {
        return { ...address, country: data.long_name};
    }
    case ADDRESS_LEVEL_TYPE.POSTAL_CODE: {
        return { ...address, postCode: data.long_name};
    }
    default: {
        return { ...address };
    }
    }
};

const formatAddressObject = (addressObj) => {
    const addressComponents = addressObj.address_components;
    let formatedAddress = {
        full: addressObj.formatted_address,
        unit: "",
        streetNumber: "",
        street: "",
        suburb: "",
        city: "",
        state: "",
        stateFullName: "",
        country: "",
        postCode: ""
    };
    if (addressComponents) {
        addressComponents.map((value) => {
            formatedAddress = checkAddressComponentType(value, formatedAddress);
        });
    }
    return formatedAddress;
};

const formatAddressForPayload = (address = {}, coordinates) => {
    const houseNumber = address.unit ? `${address.unit}/${address.streetNumber}` : address.streetNumber;
    return {
        addressLine1: `${houseNumber} ${ address.street}`,
        addressLine2: `${address.suburb} ${address.city} ${address.postCode} ${address.state} ${address.country || ""} `,
        pincode: address.postCode,
        city: address.city,
        state: address.state,
        latitude: (coordinates || {}).lat,
        longitude: (coordinates || {}).lng,
        formattedAddress: address.full
    };
};

const formatDeliveryAddressToDrivaAddress = (address) => {
    let formatedAddress = {};
    if (address) {
        formatedAddress = {
            full: `${address.addressLine1  }${  address.addressLine2}`,
            unit: 0,
            streetNumber: "",
            street: address.addressLine1,
            suburb: (address.addressLine2 || "").split(address.city)[0],
            city: address.city,
            state: address.state,
            postCode: address.pincode
        };
    }

    return formatedAddress;
};

const formatDeliveryAddressToString = (address = {}, seperator = " ") => {
    const blackList = ["latitude", "longitude", "state", "pincode", "city"];
    const addressString =  Object.keys(address || {})
        .map(item => blackList.indexOf(item) > -1 ? "" : address[item] || "")
        .filter(item => item);
    return {addressString: addressString.join(seperator), addressArray: addressString || []};
};

const getAddressLabel = (selectedAddress = {}) => {
    const {formattedAddress, addressLine1, addressLine2, state } = selectedAddress || {};
    if (state) {
        return formattedAddress || `${addressLine1} ${addressLine2}`;
    }
    return "";
};

export {
    formatAddressObject,
    formatAddressForPayload,
    formatDeliveryAddressToDrivaAddress,
    formatDeliveryAddressToString,
    getAddressLabel
};
