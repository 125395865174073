import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RegistrationChargesFlow from "./component";

const mapStateToProps = ({
    checkout: {
        cmsConfig: {
            pageSections: cmsSections = []
        } = {},
        order: {
            orderId
        } = {}
    } = {}
}) => ({
    cmsSections,
    orderId
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationChargesFlow);
