import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import styles from "./styles.css";

import OverflowContainer from "../../../shared/container-overflow";

import CircleArrow from "./images/circle-arrow.svg";
import { trackDesktopCustomEventsAU } from "../../../../tracking";

import { SCREEN_TYPE } from "../reducers";
import { makePriceLabelRound } from "../../../../utils/helpers/make-price-label";
import { getVASItems, setAddonScreenType } from "../actions";
import { AU_DESKTOP_EVENTS } from "../../../../tracking/au.desktop-events";

const PickAndChoose = ({
    egc,
    getVASItemsConnect = () => {},
    leastProductRepaymentPerWeek,
    products = [],
    setAddonScreenTypeConnect = () => {},
    year
}) => {

    const onExploreNowClick = async () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.PRE_BC_CAR_CARE_PACKS_LANDING_PAGE, {
            eventLabel: "Yes",
            eventAction: "Explore now"
        });
        await getVASItemsConnect("VAS_POPULAR_BUNDLES", year, egc);
        setAddonScreenTypeConnect({screenType: SCREEN_TYPE.VAS_STORE, activeStore: "VAS_POPULAR_BUNDLES"});
    };

    return (
        <div styleName={"styles.pickChooseCardOuter"}>
            <p styleName={"styles.pickChooseHeading"}>Pick and choose what you want</p>
            <p styleName={"styles.pickChooseSubHeading"}>Add to loan starting {makePriceLabelRound(leastProductRepaymentPerWeek)}/week</p>
            <div styleName={"styles.overFlowWrapper"}>
                <OverflowContainer>
                    {products.map((product) => {
                        const { iconUrl, key, name, discountedPrice  } = product || {};

                        const onProductClick = async () => {
                            await getVASItemsConnect(key, year, egc);
                            setAddonScreenTypeConnect({ screenType: SCREEN_TYPE.VAS_STORE, activeStore: key });
                            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.PRE_BC_CAR_CARE_PACKS_LANDING_PAGE, {
                                eventLabel: name,
                                eventAction: "Product_click"
                            });
                        };

                        return (
                            <div onClick={onProductClick} key={key} styleName={"styles.pickChooseSliderBox"}>
                                <img src={iconUrl} />
                                <p styleName={"styles.tabName"}>{name}</p>
                                <p styleName={"styles.tabPrice"}>{makePriceLabelRound(discountedPrice)}</p>
                            </div>
                        );
                    })}
                </OverflowContainer>
            </div>
            <div onClick={onExploreNowClick} styleName={"styles.buttonWrapper"}>
                <p styleName={"styles.exploreNow"}>Explore now</p>
                <img src={CircleArrow} />
            </div>
        </div>
    );
};

const mapStateToProps = ({
    carDetails: {
        content: {
            egc,
            year
        }
    },
    addOns: {
        vasBundles: {
            data: {
                leastProductRepaymentPerWeek,
                products
            }
        }
    }
}) => ({
    egc,
    leastProductRepaymentPerWeek,
    products,
    year
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getVASItemsConnect: getVASItems,
    setAddonScreenTypeConnect: setAddonScreenType
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PickAndChoose);

PickAndChoose.propTypes = {
    activeStore: PropTypes.string,
    content: PropTypes.object,
    egc: PropTypes.number,
    getVASItemsConnect: PropTypes.func,
    leastProductRepaymentPerWeek: PropTypes.number,
    products: PropTypes.array,
    setAddonScreenTypeConnect: PropTypes.func,
    year: PropTypes.number
};

