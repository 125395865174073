import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AddOnsPlans from "./component";
import { withRouter } from "react-router-dom";
import { setCounterAnimation } from "../../add-ons/actions";

const mapStateToProps = ({
    addOns: {
        vasCart: { data: vasCartData },
        servicingPriceConfig,
        postBcLandingPlans: { data: planDetails } = {},
        postBcStepStatus,
        showCounterAnimation
    },
    checkout: { order: { paymentOption, orderId, financeProvider, drivingLicenceStatus } = {}, signContractData },
    myBookings: {
        paymentSummaryData: { chargeDetails }
    },
    user: { firstName: userFirstName },
    carDetails: { content }
}) => ({
    vasCartData,
    servicingPriceConfig,
    planDetails,
    paymentOption,
    chargeDetails,
    userFirstName,
    content,
    orderId,
    postBcStepStatus,
    financeProvider,
    drivingLicenceStatus,
    signContractData,
    showCounterAnimation
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setCounterAnimationConnect: setCounterAnimation
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddOnsPlans));
