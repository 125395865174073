export const AMPLITUDE_EVENT_NAMES = {
    PURCHASE_STARTED: "purchase started",
    PURCHASE_STARTED_USP: "purchase started usp"
};

export const GA_EVENTS = {
    BOOKING_INITATED_USP: "booking_initiated_usp",
    BOOKING_INTIATED_DETAIL: {
        event: "custom_event",
        eventCategory: "Cars24_detail_page",
        eventAction: "booking_initiated_usp"
    },
    CHECKOUT_POP_UP_DISPLAYED: {
        eventCategory: "Cars24_checkout_page",
        event: "Checkout_Car_Reserved",
        eventAction: "Checkout_Car_Reserved_Pop_up_displayed"
    },
    CHECKOUT_POP_UP_CTA_CLICKED: {
        eventCategory: "Cars24_checkout_page",
        event: "Checkout_Car_Reserved",
        eventAction: "Checkout_Car_Reserved_CTA_Clicked"
    },
    DELIVERY_DETAILS_SCREEN: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "delivery_details_screen"
    },
    DELIVERY_OPTION_SELECTED: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "delivery_option_selected"
    },
    DELIVERY_FAQ_SELECTED: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "delivery_faq_selected"
    },
    INTERSTATE_CHARGES_TAB_ENTERED: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "interstate_charges_tabs_entered"
    },
    CHECKOUT_PAGE_PERSONAL_DETAILS: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "Detail Personal"
    },
    CHECKOUT_PAGE_EVENT_ALREADY_EXIST: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "email_already_exist_screen"
    },
    CHECKOUT_PAGE_LOGIN_OPTION_SELECTED: {
        event: "custom_event",
        eventCategory: "Cars24_checkout_page",
        eventAction: "login_option_selected"
    }
};
