import { X_VEHICLE_TYPE } from "../constants/api-constants";
import formQueryParams from "../utils/helpers/form-query-params";
import { getDeviceType } from "../utils/helpers/get-device-type";
import { API_VERSION_V1 } from "./api-version";
import parseCookie from "../utils/helpers/parse-cookie";
import { REFERRER_SOURCE } from "../constants/app-constants";

// eslint-disable-next-line max-statements
export default (api) => {
    const deviceType = getDeviceType();
    const getCheckoutSteps = (token, orderId) => {
        return api.get(`/v1/checkout-journey/step/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.car,
                SOURCE: deviceType
            }
        });
    };

    const createCheckoutSteps = (params, token) => {
        return api.post(`/v1/checkout-journey/step`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const updateCheckoutSteps = (params, token) => {
        return api.put(`/v1/checkout-journey/step/${params.orderId}`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const getTestDriveSteps = (token, orderId) => {
        return api.get(`/v1/test-drive-journey/step/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.car,
                SOURCE: deviceType
            }
        });
    };

    const createTestDriveSteps = (params, token) => {
        return api.post(`/v1/test-drive-journey/step`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const updateTestDriveSteps = (params, token) => {
        return api.put(`/v1/test-drive-journey/step/${params.orderId}`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                SOURCE: deviceType
            }
        });
    };

    // eslint-disable-next-line max-params
    const getDeliveryAvailability = (secureToken, appointmentId, pincode, origincity, pickUp, originState, emiEnabled) => {
        return api.get(`/v1/availability?pincode=${pincode}&origincity=${origincity}&appointmentId=${appointmentId}&pickUp=${pickUp}&originState=${originState}&emiEnabled=${emiEnabled || false}`, {
            headers: {
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const getBookingSummary = (token, orderId) => {
        return api.get(`/v1/bookings/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const cancelOrder = (orderId, token, params) => {
        return api.put(`/v1/order/${orderId}/cancel${formQueryParams(params)}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "CAR",
                SOURCE: deviceType
            }
        });
    };
    const fetchNudgeList = (token) => {
        return api.get(`/v1/alerts`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const closeNudgeList = (token, payload) => {
        return api.put(`/v1/alerts/suppress`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const getInhousePreApprovalLoanDetails = (token) => {
        return api.get(`/v1/preapproval/quote`, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const getInhouseEligibility = (token) => {
        return api.get(`/v1/preapproval/me`, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const updateInhousePreApprovalLoan = (token, uuid, payload) => {
        return api.post(`v1/preapproval/quote/${uuid}`, payload, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const submitInhousePreApprovalLoan = (token, uuid, payload) => {
        return api.post(`v1/preapproval/submit/${uuid}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const inHouseFinanceFirstFinancePollApi = (uuid, token) => {
        return api.get(`/v1/preapproval/poll/${uuid}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const createTestDriveAvailability = (token, payload) => {
        return api.post(`/v1/test-drive/order`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                SOURCE: deviceType,
                referrerSource: parseCookie(REFERRER_SOURCE) || ""

            }
        });
    };

    const fetchInspectionReportDataApi = (token, vehicleId) => {
        return api.get(`/v1/inspection/${vehicleId}/report`, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.car,
                SOURCE: deviceType
            }
        });
    };

    const fetchFilters = (token) => {
        return api.get("/v1/filter?showRevampedFilters=true", {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {})
            }});
    };

    const updateC2bTrade = (token, params) => {
        return api.put(`${API_VERSION_V1}c2b/trade/offer`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const submitC2bTrade = (token, params, orderId) => {
        return api.put(`${API_VERSION_V1}c2b/trade/order/${orderId}/submit`, {...params}, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR
            }
        });
    };

    const getDepositRangeValueInHouse = () => {
        return api.get(`/v1/deposit/range`, {
            headers: {
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const getSellbookings = (token) => {
        return api.get(`/v1/sell/bookings`, {
            headers: {
                Authorization: `Bearer ${token}`,
                SOURCE: deviceType
            }
        });
    };

    const getUnbookedSellbooking = (token) => {
        return api.get(`/v1/sell/unbooked`, {
            headers: {
                Authorization: `Bearer ${token}`,
                SOURCE: deviceType
            }
        });
    };

    const fetchListing = (params, queryString, token) => {
        return api.get(`/v1/vehicle${queryString ? `?${queryString}` : ``}`, {
            params,
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {})
            }},
        );
    };

    const fetchCarPricing = (token, appointmentId, queryString) => {
        return api.get(`/v1/pricing/${appointmentId}${queryString ? `?${queryString}` : ``}`, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {})
            }});
    };

    const getFinancePostPaymentApplication = (token, orderId) => {
        return api.get(`v1/finance/${orderId}/loan-application`, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                SOURCE: deviceType
            }});
    };

    const patchFinancePostPaymentApplication = (params, payload) => {
        const {token, orderId, formId} = params;
        return api.patch(`v1/finance/${orderId}/loan-application/${formId}`, {...payload}, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                SOURCE: deviceType
            }});
    };

    const postFinancePostPaymentApplication = (params, payload) => {
        const {token, orderId, formId} = params;
        return api.post(`v1/finance/${orderId}/loan-application/${formId}`, {...payload}, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                SOURCE: deviceType
            }});
    };

    const patchFinanceUploadDocument = (params, payload) => {
        const {token, orderId, formId} = params;
        return api.patch(`v1/finance/${orderId}/loan-document/${formId}`, {...payload}, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                SOURCE: deviceType
            }});
    };

    const postFinanceUploadDocument = (params, payload) => {
        const {token, orderId, formId} = params;
        return api.post(`v1/finance/${orderId}/loan-document/${formId}`, {...payload}, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                SOURCE: deviceType
            }});
    };

    const financeUploadDocument = (params, payload) => {
        const {token, orderId} = params;
        return api.post(`finance/api/v1/${orderId}/upload-document`, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                SOURCE: deviceType
            }});
    };

    const fetchBookedTasksList = (token) => {
        return api.get(`/v1/tasks`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const getPreSignedFinanceUploadedImage = (token, params) => {
        return api.get(`finance/api/v1/loan-document/preSigned`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            },
            params
        });
    };

    const updatePostBcStep = ({ token, orderId, step }) => {
        return api.put(
            `/v1/post-bc-journey/step`,
            {
                orderId,
                step,
                state: "complete"
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                    SOURCE: deviceType
                }
            }
        );
    };

    const getPostBcStep = ({ orderId, token }) => {
        return api.get(`/v1/post-bc-journey/step/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const fetchOrderDetailsApi = (orderId) => {
        return api.get(`/price/communication/${orderId}`
        );
    };

    const fetchPickupDetailsApi = (token, orderId, pincode) => {
        return api.get(`/price/communication/hub/pickup/details?orderId=${orderId}${pincode ? `&pincode=${pincode}` : ``}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    };

    const acceptOrderApi = (token, orderId, payload) => {
        return api.put(`/price/communication/accept/sell?orderId=${orderId}`, payload,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    };

    const fetchSlotDetailsApi = (token) => {
        return api.get(`/price/communication/slot`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
    };

    const postRoadworthyCertificateInspectionDetails = (params, payload) => {
        const {token} = params;
        return api.post(`v1/oms/service-order`, {...payload}, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                SOURCE: deviceType
            }});
    };

    const getCmsContent = ({ slug, city = "" }) => {
        const url = `/v1/cms/${slug}${city ? `?city=${city}` : ""}`;
        return api.get(url, {
            headers: {
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType
            }
        });
    };

    const createServiceOrder = (data, token) => {
        return api.post(`v1/oms/service-order`, data, {
            headers: {
                X_VEHICLE_TYPE: X_VEHICLE_TYPE.CAR,
                SOURCE: deviceType,
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    };
    return {
        getCheckoutSteps,
        createCheckoutSteps,
        updateCheckoutSteps,
        getTestDriveSteps,
        createTestDriveSteps,
        updateTestDriveSteps,
        getBookingSummary,
        cancelOrder,
        getDeliveryAvailability,
        fetchNudgeList,
        closeNudgeList,
        fetchBookedTasksList,
        getInhousePreApprovalLoanDetails,
        getInhouseEligibility,
        updateInhousePreApprovalLoan,
        submitInhousePreApprovalLoan,
        inHouseFinanceFirstFinancePollApi,
        createTestDriveAvailability,
        fetchInspectionReportDataApi,
        fetchFilters,
        updateC2bTrade,
        getDepositRangeValueInHouse,
        getSellbookings,
        getUnbookedSellbooking,
        submitC2bTrade,
        fetchListing,
        fetchCarPricing,
        getFinancePostPaymentApplication,
        patchFinancePostPaymentApplication,
        postFinancePostPaymentApplication,
        patchFinanceUploadDocument,
        postFinanceUploadDocument,
        financeUploadDocument,
        getPreSignedFinanceUploadedImage,
        updatePostBcStep,
        getPostBcStep,
        fetchOrderDetailsApi,
        fetchPickupDetailsApi,
        acceptOrderApi,
        fetchSlotDetailsApi,
        getCmsContent,
        createServiceOrder,
        postRoadworthyCertificateInspectionDetails
    };
};
