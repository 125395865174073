/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

import { PURPOSE_DETAILS_TYPE, PURPOSE_TYPE, SIGN_CONTRACT_STATUS } from "../../../../constants/checkout-constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { trackDesktopCustomEventsAU } from "../../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../../tracking/au.desktop-events";
import { AU_STATES_CODE } from "../../../../constants/app-constants";
import ListTab from "../../../shared/list-tab";
import InputText from "../../../shared/input-text";
import Checkbox from "../../../shared/checkbox";
import styles from "./styles.css";
import TransparentButton from "../../../shared/button/transparent-button";
import Button from "../../../shared/button";
import Loader from "../../../shared/loader";
import { getMyBookingCarDetailsURL } from "../../../../utils/helpers/get-detail-page-url";
import { tasksRoutes } from "../../../../constants/au.desktop/tasks-routes";
import { DESKTOP_CONTRACT_STEP } from "../constant";

const SignContractForm = ({
    secureToken,
    personalDetails,
    signContractData = [],
    order,
    updateDeliveryContractConnect,
    setUserPersonalDetailsConnect,
    handleContractStep
}) => {
    const { userPurpose } = personalDetails;
    const { deliveryAddress, orderId, appointmentId} = order;
    const history = useHistory();
    const { UNSIGNED } = SIGN_CONTRACT_STATUS;
    const [isRegAddAvailable, setRegAddAvailable] = useState(false);
    const [selectedKey, setSelectedKey] = useState(userPurpose && userPurpose.purpose);
    const [signContractLoading, setSignContractLoading] = useState(false);
    // const [businessData, setBusinessData] = useState(true);
    // const [isPersonal, setPersonal] = useState(selectedKey === "Personal");
    const [selectedACN, setSelectedACN] = useState("ABN");
    const [isAcn, setIsAcn] = useState(true);
    const { documentStatus } = signContractData[0] || {};
    const getOrderPayload = (orderResponse) => {
        const orderPayload = {
            ...orderResponse,
            userPurpose: {
                ...userPurpose
            }
        };
        return orderPayload;
    };

    const handleSignNowClick = async () => {
        if (documentStatus === UNSIGNED) {
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.MY_TASKS_SUMMARY, {
                eventAction: "Contract_started",
                eventLabel: "{{Nudge_click}}"
            });
        }
        // if (businessData) {
        //     const { relativeURL } = getMyBookingCarDetailsURL(appointmentId, postPaymentRoutes.signContract.route, null, EXPERIMENT_TYPE.VARIANT_B);
        //     history.push(relativeURL);
        // }

        try {
            setSignContractLoading(true);
            const orderPayload = getOrderPayload(order);
            await updateDeliveryContractConnect(orderPayload, orderId, secureToken);
            handleContractStep(DESKTOP_CONTRACT_STEP.DOCUMENT);
            setSignContractLoading(false);
        } catch (err) {
            setSignContractLoading(false);
        }

        // const { relativeURL } = getMyBookingCarDetailsURL(content.appointmentId, tasksRoutes.signContract.route);
        // history.push(relativeURL);

    };

    useEffect(() => {
        setUserPersonalDetailsConnect({ ...personalDetails, ["userPurpose"]: { ...order.userPurpose } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order && order.userPurpose]);

    useEffect(() => {
        if (orderId && userPurpose) {
            setSelectedKey(userPurpose.purpose);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userPurpose && userPurpose.purpose, orderId]);

    const abnValidator = (num) => {
        const inputNumber = num || "";
        const MOBILE_REGEX_AU = new RegExp(/^\d{11}$/);
        return MOBILE_REGEX_AU.test(parseInt(inputNumber));
    };

    const getABNNumber = deliveryAddress && (deliveryAddress.state !== AU_STATES_CODE.VIC ? true : false);

    const isNextButtonDisabled = () => {
        if (!userPurpose) {
            return true;
        }
        if (getABNNumber && selectedKey === "Business") {
            return (
                !(userPurpose.acnNumber || (userPurpose.abnNumber && abnValidator(userPurpose.abnNumber)))
                || !userPurpose.crnNumber ||
                !userPurpose.companyName ||
                //!(userPurpose.acnNumber && userPurpose.abnNumber) ||
                !(userPurpose.registeredAddress || isRegAddAvailable)
            );
        } else if (selectedKey === "Personal") {
            return !(userPurpose.registeredAddress || isRegAddAvailable);
        }
        return (
            !userPurpose.acnNumber
        || !userPurpose.crnNumber ||
        !userPurpose.companyName ||
        //!(userPurpose.acnNumber && userPurpose.abnNumber) ||
        !(userPurpose.registeredAddress || isRegAddAvailable));

    };

    const handleBack = () => {
        const { relativeURL } = getMyBookingCarDetailsURL(appointmentId, tasksRoutes.viewTask.route);
        history.push(relativeURL);
    };

    // if (!signContractPollCompleted) {
    //     return <TaskCardShimmer />;
    // }
    const handleTabClick = (e) => {
        // setPersonal(!isPersonal);
        setSelectedKey(e);
        setUserPersonalDetailsConnect({ ...personalDetails, ["userPurpose"]: { ...userPurpose, purpose: e } });
    };
    const handleTabNumberClick = (e) => {
        setIsAcn(!isAcn);
        setSelectedACN(e);
        // setUserPersonalDetailsConnect({ ...personalDetails, ["userPurpose"]: { ...userPurpose, selectedACN: e } })
    };
    const handleChange = (e) => {
        setUserPersonalDetailsConnect({ ...personalDetails, ["userPurpose"]: { ...userPurpose, [e.target.id]: e.target.value }});
    };

    const handleRegisteredAddress = () => {
        setRegAddAvailable(!isRegAddAvailable);
        setUserPersonalDetailsConnect({ ...personalDetails, ["userPurpose"]: { ...userPurpose, registeredAddress: deliveryAddress ? deliveryAddress.formattedAddress : "" }});

    };

    if (!orderId) {
        return <Loader/>;
    }

    return (
        <div styleName={"styles.taskCard styles.taskCardVariantB "}>

            <p styleName={"styles.contractText styles.subHeading"}>We need some details for your contract </p>
            <div styleName={"styles.tabInput"}>
                <p styleName={"styles.purpose"}>Purpose the car will be used for?</p>
                <div styleName={"styles.tabContainer"}>
                    {PURPOSE_TYPE.map((option) => (
                        <ListTab
                            key={option.value}
                            data={option}
                            dataKey={"value"}
                            labelKey={"label"}
                            selectedKey={selectedKey ? selectedKey : PURPOSE_TYPE[0].value }
                            onClick={() => handleTabClick(option.value)}
                        />
                    ))}
                </div>
            </div>
            {selectedKey === "Personal" ? null : <><div styleName={"styles.inputFields"}>
                <p styleName={"styles.purpose"}>What’s your company’s name?</p>
                <InputText
                    id="companyName"
                    text="Full company name"
                    value={ userPurpose && (userPurpose.companyName ? userPurpose.companyName : "")}
                    onChange={handleChange}
                />
            </div><div styleName={"styles.inputFields"}>
                <p styleName={"styles.purpose"}>Enter CRN number</p>
                <InputText
                    id="crnNumber"
                    text="Enter CRN number"
                    value={ userPurpose && (userPurpose.crnNumber ? userPurpose.crnNumber : "")}
                    onChange={handleChange}
                    //errorMessage="Please enter a valid CRN number"
                    // onBlurCallback={() => trackInput("last_name")}
                    // onFocusCallback={scrollParent}
                />
            </div><div styleName={"styles.tabInput2"}>
                <p styleName={"styles.purpose"}> {getABNNumber ?  "ACN/ABN number" : "ACN Number"}</p>
                {getABNNumber ? <div styleName={"styles.tabContainer"}>
                    {PURPOSE_DETAILS_TYPE.map((option) => (
                        <ListTab
                            key={option.value}
                            data={option}
                            dataKey={"value"}
                            labelKey={"label"}
                            selectedKey={selectedACN ? selectedACN : PURPOSE_DETAILS_TYPE[0].value }
                            onClick={() => handleTabNumberClick(option.value)}
                        />
                    ))}
                </div> : null}
            </div><div styleName={"styles.tabInputcheckABN"}>
                {selectedACN === "ABN" && getABNNumber ? <InputText
                    id={"abnNumber"}
                    text={"ABN Number"}
                    value={ userPurpose && (userPurpose.abnNumber ? userPurpose.abnNumber : "")}
                    onChange={handleChange}
                    validator={num => abnValidator(num)}
                    errorMessage="Please enter a valid ABN number"
                /> : <InputText
                    id={"acnNumber"}
                    text={"ACN Number"}
                    value={ userPurpose && (userPurpose.acnNumber ? userPurpose.acnNumber : "")}
                    onChange={handleChange}
                /> }
            </div><div styleName={"styles.tabInput"}><a href="https://abr.business.gov.au/" target="_blank" styleName={"styles.checkAbnStatus"}>
                            Check ABN Details
            </a>
            </div></>
            }
                 <><div styleName={"styles.inputFields"}>
                     <p styleName={"styles.purpose"}>Registered Address</p>
                     <p styleName={"styles.contractText styles.subHeading"}>As mentioned in your license</p>
                     <InputText
                         id="registeredAddress"
                         value={isRegAddAvailable && deliveryAddress ? deliveryAddress.formattedAddress : (userPurpose && (userPurpose.registeredAddress ? userPurpose.registeredAddress : "")) }
                         text = "Enter registered address"
                         onChange={handleChange}
                     />
                 </div><div styleName={"styles.checkBoxContainer"}>
                     <Checkbox id="registeredAddressCheckbox" checked={isRegAddAvailable} onClickCallback={handleRegisteredAddress}/>
                     <p styleName={"styles.sameAddress"}>
                            Same as delivery address</p>
                 </div></>
                 <div className="buttonGlobalContainer" styleName={`styles.buttonWrapper`}>
                     <div styleName={"styles.transparentButton"}>
                         <TransparentButton  text={"Back"} onClick={handleBack}/>
                     </div>
                     <Button
                         text={"sign contract"}
                         disabled={isNextButtonDisabled()}
                         onClick={handleSignNowClick}
                         isLoading={signContractLoading}
                     />

                 </div>
                 {/* {renderSignContractStatus()} */}
        </div>
    );
};

SignContractForm.propTypes = {
    content: PropTypes.object,
    secureToken: PropTypes.string,
    personalDetails: PropTypes.object,
    signContractData: PropTypes.array,
    signContractPollCompleted: PropTypes.bool,
    showVariant: PropTypes.string,
    dowloadContractConnect: PropTypes.func,
    order: PropTypes.object,
    setUserPersonalDetailsConnect: PropTypes.func,
    updateDeliveryContractConnect: PropTypes.func,
    handleContractStep: PropTypes.func
};

export default SignContractForm;
