import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import Logo from "./images/footer-logo.svg";
import { Link } from "react-router-dom";
import { AU_DESKTOP_EVENTS, DESKTOP_EVENT_ACTION } from "../../../tracking/au.desktop-events";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import LazyImage from "../../shared/lazy-image";
import { appUrl, cmsURl } from "../../../constants/url-constants";
import { COUNTRY, DOWNLOAD_APP_URL_FOOTER, SELL_CAR_SEO_MENU, FOOTER_GEOS, SOCIAL_MEDIA_DATA } from "../../../constants/app-constants";

const Footer = ({
    isLoggedIn,
    seoMenu
}) => {
    const trackEvent = (e, type = "social", label) => {
        const events = { action: "", params: ""};
        switch (type) {
        case "social":
            events.action = DESKTOP_EVENT_ACTION.SOCIAL_MEDIA;
            events.params = `options ${e ? e.target.innerText : label}`;
            break;
        case "links":
            events.action = DESKTOP_EVENT_ACTION.USEFUL_LINKS;
            events.params = `Link selected ${e ? e.target.innerText : label}`;
            break;
        default: events.action = "";
        }
        trackDesktopCustomEventsAU(events.action, {eventLabel: events.params});
    };

    const trackEventC2b = (e) => {
        const events = { action: "", params: ""};

        events.action = DESKTOP_EVENT_ACTION.SELL_CAR_LINKS;
        events.params = e.target.innerText;

        trackDesktopCustomEventsAU(events.action, {eventLabel: events.params});
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    const handleIos = () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.DOWNLOAD_APP_FOOTER, {eventLabel: "ios_download"});
    };

    const handlePlay = () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.DOWNLOAD_APP_FOOTER, {eventLabel: "android_download"});

    };

    const {cityTypeUrl = []} = seoMenu;

    return (
        <footer styleName={"styles.footer"}>
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-md-2 col-lg-2"}>
                        <p styleName={"styles.heading"}>DOWNLOAD THE APP</p>
                        <div styleName={"styles.appLink"}>
                            <a href={DOWNLOAD_APP_URL_FOOTER} onClick={handleIos}>
                                <LazyImage
                                    className={"img-fluid"}
                                    src="https://fastly-production.24c.in/cars24/link_category_items/app-store.png"
                                    alt="iphone_app"
                                />
                            </a>
                            <a href={DOWNLOAD_APP_URL_FOOTER} onClick={handlePlay}>
                                <LazyImage
                                    className={"img-fluid"}
                                    src="https://fastly-production.24c.in/cars24/link_category_items/play-store.png"
                                    alt="android_app"
                                />
                            </a>
                        </div>
                    </div>
                    <div className={"col-md-8 col-lg-8"}>
                        <div className={"row"}>
                            <div className={"col-md-3 col-lg-3"}>
                                <div className="row">
                                    <div className="col" styleName={"styles.sellBuyLocationWrapper"}>
                                        <div>
                                            <h3 styleName={"styles.heading"}>BUY CARS BY LOCATION</h3>
                                            <ul styleName={"styles.footerLink styles.carByLocationWrap"} onClick={trackEvent}>
                                                {(cityTypeUrl || []).map(item =>
                                                    (<li key={item.label}>
                                                        <a href={`${appUrl()}/${item.spath}`}>{item.label}</a>
                                                    </li>)
                                                )}

                                            </ul>
                                        </div>
                                        <div>
                                            <h3 styleName={"styles.heading"}>SELL YOUR CAR</h3>
                                            <ul styleName={"styles.footerLink"} onClick={trackEventC2b}>
                                                {(SELL_CAR_SEO_MENU || []).map(item =>
                                                    (<li key={item.label}>
                                                        <a href={`${appUrl()}/${item.spath}`}>{item.label}</a>
                                                    </li>)
                                                )}

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-3 col-lg-3"}>
                                <h3 styleName={"styles.heading"}>USEFUL LINKS</h3>
                                <ul styleName={"styles.footerLink"}>
                                    <li>
                                        {/* <p onClick={onClickListingPage}>Find your car</p> */}
                                        <Link to={`/buy-used-cars-${COUNTRY.slug}/`} onClick={() => trackEvent(null, "links", "career")}>
                                        Find a Car
                                        </Link>
                                    </li>
                                    <li>
                                        {/* <p onClick={onClickListingPage}>Find your car</p> */}
                                        <Link to={isLoggedIn ? "/my-account/financing/" :  "/financing/get-pre-approval/"} onClick={() => trackEvent(null, "links", "financing")}>
                                        Apply for pre-approval
                                        </Link>
                                    </li>
                                    <li>
                                        {/* <p onClick={onClickListingPage}>Find your car</p> */}
                                        <Link to="/cars24-finance/" onClick={() => trackEvent(null, "links", "cars24_finance")}>
                                        CARS24 Finance
                                        </Link>
                                    </li>
                                    <li>
                                        {/* <p onClick={onClickListingPage}>Find your car</p> */}
                                        <a href="https://apply.workable.com/cars24/?lng=en" onClick={() => trackEvent(null, "links", "career")}>
                                            Careers
                                        </a>
                                    </li>
                                    <li>
                                        <Link to="/thecars24way/" onClick={() => trackEvent(null, "links", "CARS24_way")}>
                                            The CARS24 Way
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/about-us/" onClick={() => trackEvent(null, "links", "about_us")}>
                                            About Us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/contact-us/" onClick={() => trackEvent(null, "links", "contact_us")}>
                                            Contact Us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/sitemap/" onClick={() => trackEvent(null, "links", "sitemap")}>
                                            Sitemap
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/cars24cover/" onClick={() => trackEvent(null, "links", "warranty")}>
                                            Warranty
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/reviews/" onClick={() => trackEvent(null, "links", "reviews")}>
                                            User Reviews
                                        </Link>
                                    </li>
                                    <li>
                                        <a href={`${cmsURl()}/blog/`} onClick={() => trackEvent(null, "links", "blog")} target="_blank">
                                            Blog
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className={"col-md-3 col-lg-3"}>
                                <h3 styleName={"styles.heading"}>INFORMATION</h3>
                                <ul styleName={"styles.footerLink"} onClick={(e) => trackEvent(e, "links")} >
                                    <li>
                                        <Link to="/privacy-policy/">Privacy Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="/terms-of-use/">Terms &amp; Conditions</Link>
                                    </li>
                                    <li>
                                        <Link to="/terms-of-purchase/">Terms of Purchase</Link>
                                    </li>
                                    <li>
                                        <Link to="/terms-of-sale/">Terms of Sale</Link>
                                    </li>
                                    <li>
                                        <a href={`${cmsURl()}/blog/faq/`}> FAQ </a>
                                    </li>
                                </ul>
                            </div>
                            <div className={"col-md-3 col-lg-3"}>
                                <div className="row">
                                    <div className="col" styleName={"styles.sellBuyLocationWrapper"}>
                                        <div>
                                            <h3 styleName={"styles.heading"}>KEEP IN TOUCH</h3>
                                            <ul styleName={"styles.footerLink"} onClick={trackEvent}>
                                                {SOCIAL_MEDIA_DATA.map((item) => (
                                                    <li key={item.title}>
                                                        <a href={item.link} target="_blank">
                                                            {item.title}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div styleName={"styles.marginTop"}>
                                            <h3 styleName={"styles.heading"}>OTHER GEOGRAPHIES</h3>
                                            <ul styleName={"styles.footerLink"}>
                                                {FOOTER_GEOS.map((item) => (
                                                    <li key={item.title}>
                                                        <a href={item.link} target="_blank">
                                                            {item.title}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-2 col-lg-2"}>
                        <Link styleName={"styles.logo"} to="/" onClick={scrollToTop}><LazyImage src={Logo} alt="CARS 24" /></Link>
                        <span styleName={"styles.copyRight"}>&copy;CARS24 2023</span>
                    </div>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    selectedCityCode: PropTypes.number,
    isLoggedIn: PropTypes.bool,
    selectedCityName: PropTypes.string,
    reloadCarListConnect: PropTypes.func,
    resetCarListConnect: PropTypes.func,
    seoMenu: PropTypes.object,
    isSellYourCarPage: PropTypes.bool
};

export default Footer;
