import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";
import CloseButton from "../../shared/icon-cross";
import Verified from "./images/verified.svg";
import ErrorIcon from "./images/error.svg";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import { getBookingConfirmedDate } from "../../../utils/helpers/get-day-from-date";
import Modal from "../../shared/modal";
import { PAYMENT_STATUS } from "../../../constants/checkout-constants";
import { getPaymentMethodType } from "../../../utils/helpers/checkout-util";
import ArrowIcon from "../../shared/arrow";

const ICON = {
    SUCCESS: Verified,
    FAILED: ErrorIcon
};
const PaymentHistoryCards = ({onClose, bookingSummary = [], onBack}) => {
    const { orderPaymentDetails = [] } = bookingSummary || {};
    const payHistory = (orderPaymentDetails || []).filter(item => item.status === PAYMENT_STATUS.SUCCESS);

    return (
        <Modal isOpen={true}>
            <div
                styleName={"styles.modalWrapper"}>
                <div styleName={"styles.header"}>
                    {onBack && <span styleName="styles.back" onClick={onBack}><ArrowIcon grey rotateBy={-90}/></span>}
                    <h4 styleName={"styles.heading"}>PAYMENT SUMMARY</h4>
                    <div styleName={"styles.headerActions"}>
                        <span>
                            <CloseButton type="grey" onClickHandler={onClose} />
                        </span>
                    </div>
                </div>

                <div
                    styleName={"styles.modalBody"}>
                    {payHistory.map((item, index) =>
                        (
                            <React.Fragment key={index}>
                                {(item.status !== PAYMENT_STATUS.CREATED && item.status !== PAYMENT_STATUS.INITIATED) && <div styleName={"styles.outer"} key={item.paymentIdentifier + index}>
                                    <div styleName={"styles.wrapper"}>
                                        <p styleName={`styles.payment ${index ? "" : "styles.deposite"} `}><img src={ICON[item.status]} />{index ? `Payment ${index}` : "Deposit"}</p>
                                        <p styleName={"styles.txnId"}>Txn. ID: #{item.paymentIdentifier}</p>
                                    </div>
                                    <div styleName={"styles.wrapper"}>
                                        <p styleName={"styles.amount"}>{makePriceLabelRound(item.amount)}</p>
                                        <p styleName={"styles.paymentWay"}>
                                    Paid with {getPaymentMethodType(item.paymentGateway)}
                                            <span>{getBookingConfirmedDate(new Date(item.updateAt))}</span>
                                        </p>
                                    </div>
                                    {item.status === PAYMENT_STATUS.FAILED && <div styleName={"styles.wrapper"}>
                                        <p styleName={"styles.failed"}>Payment failed</p>
                                        <p styleName={"styles.paymentWay"}>Any amount deducted will be refunded within 3-5 business days</p>
                                    </div>}
                                </div>}
                            </React.Fragment>
                        )
                    )}
                </div>
            </div>
        </Modal>
    );
};

PaymentHistoryCards.propTypes = {
    onClose: PropTypes.func,
    bookingSummary: PropTypes.array,
    onBack: PropTypes.func
};

export default PaymentHistoryCards;
