import React from "react";
import styles from "./styles.css";

import { useHistory } from "react-router-dom";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import TrackImpression from "../../shared/track-impression";
import Financing from "./images/terms.png";
import Impact from "./images/impact.png";
import Refund from "./images/refund.png";
import Button from "../../shared/button";
import CarImage from "./images/car-img.png";

const SellTradeInBannerRevamp = () => {
    const history = useHistory();

    const handleOnClick = () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.SELL_TRADE_BANNER);
        history.push("/sell-your-car/");
    };
    return (
        <TrackImpression event={{gaName: AU_DESKTOP_EVENTS.FF_VOLUME_EXPERIMENT, data: {eventLabel: "Trade_in_Home_page", eventAction: "Card_viewed"}, properties: {"Card_viewed": "Trade_in_Home_page"}}}>
            <div styleName={"styles.outer"}>
                <img src={CarImage} />
                <p styleName={"styles.heading"}>Looking to <span>sell</span> or <span>trade-in</span>?<br />We’re buying!</p>
                <div styleName={"styles.stepsWrapper"}>
                    <p styleName={"styles.steps"}><img width="28" height="28"  src={Financing} alt="financing icon image" />Get an instant quote 100% online</p>
                    <p styleName={"styles.steps"}><img width="28" height="28" src={Impact} alt="impact icon image"  />Convenient virtual inspection</p>
                    <p styleName={"styles.steps"}><img width="28" height="28" src={Refund} alt="refund icon image"  />Car pickup at your doorstep</p>
                </div>
                <Button text="GET valuation" onClick={handleOnClick}/>
            </div>
        </TrackImpression>
    );
};

export default SellTradeInBannerRevamp;
