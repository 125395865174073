/* eslint-disable complexity */
/* eslint-disable no-magic-numbers */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { Link } from "react-router-dom";
import Logo from "../../shared/logo/component";
import HeartIcon from "./images/wishlist-icon.svg";
import withLogin from "../with-login";
import {  trackDesktopCustomEventsAU, trackMobileCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import LazyImage from "../../shared/lazy-image";
import ArrowIcon from "../../shared/arrow";
import AvatarText from "../../shared/avatar-text/avatar-text";
import { appUrl } from "../../../constants/url-constants";
import { LOGIN_SOURCE, NUMBER, SEARCH_ALL_CARS_ROUTE } from "../../../au-c2b/constants/app-constants";
import { USER_LOGIN_STATUS } from "../../../constants/app-constants";
import { getItemFromSession, setItemInSession } from "../../../utils/helpers/session-storage";
import { SELECT_OFFER_SESSION_KEY } from "../../../constants/finance-constants";
import currentPathObject from "../../../utils/helpers/get-current-page";
import { GA_EVENTS } from "./tracking";
// eslint-disable-next-line max-statements
const Header = ({
    history,
    isLoggedIn,
    profileName,
    logoutConnect,
    openLoginPopupConnect,
    resetWishlistConnect,
    seoMenu = {},
    fixed = false,
    preApproved,
    userTradeInData,
    email,
    setTradeInToggleSelectedConnect
}) => {
    const firstName = profileName ? profileName.split(" ")[0] : "";
    const [showDropdown, setShowDropdown] = useState(false);
    const [showToolTip, setShowToolTip] = useState(false);

    const headerDropRef = useRef(null);
    const profileHeader = useRef(null);

    const handleClickOutside = event => {
        if (profileHeader.current && !profileHeader.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);

    useEffect(() => {
        if ((preApproved || userTradeInData.tradeIn) && getItemFromSession("showFinanceTradeNudge") === null) {
            setShowToolTip(true);
            setItemInSession("showFinanceTradeNudge", true);
            window.setTimeout(() => {
                setShowToolTip(false);
                setItemInSession("showFinanceTradeNudge", false);
            }, NUMBER.THREE_THOUSAND);
        }
    }, [preApproved, userTradeInData.tradeIn]);

    const onClickLoginSignup = (e) => {
        e.preventDefault();
        trackMobileCustomEventsAU(AU_DESKTOP_EVENTS.LOGIN_INITIATED, {
            eventLabel: LOGIN_SOURCE.HAMBURGER
        });
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.HAMBURGER_MENU_OPTIONS, {
            eventLabel: `option selected login`
        });
        openLoginPopupConnect(true);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [history]);

    const onClickExpandMenu = () => {
        setShowDropdown(!showDropdown);
    };

    const onClickLogout = (e) => {
        e.preventDefault();
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.HAMBURGER_MENU_OPTIONS, {
            eventLabel: `option selected logout`
        });
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.SIGNOUT, {
            user_id: email,
            user_status: USER_LOGIN_STATUS.LOGGED_OUT
        });
        const selectOfferBackButtonSession = window.sessionStorage.getItem(SELECT_OFFER_SESSION_KEY);
        if (selectOfferBackButtonSession) window.sessionStorage.removeItem(SELECT_OFFER_SESSION_KEY);
        setShowDropdown(false);
        logoutConnect(() => setTradeInToggleSelectedConnect(null));
        resetWishlistConnect();
    };

    const navigateToWishlist = (e) => {
        e.preventDefault();
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.HEADER_WISHLIST_OPEN);
        history.push("/wishlist/");
    };

    const navigateToMyBookings = (e) => {
        e.preventDefault();
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.HEADER_WISHLIST_OPEN);

        const {isDetailsPage, isHomePage, isListingPage} = currentPathObject() || {};
        if (isDetailsPage || isHomePage || isListingPage) {
            let location;
            if (isDetailsPage) {
                location = "CDP";
            } else if (isHomePage) {
                location = "Homepage";
            } else location = "CLP";
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.MY_BOOKINGS_LANDING, {
                event: "custom_event",
                eventCategory: "My Bookings opened",
                eventAction: "location",
                eventLabel: location
            });
        }
        history.push("/my-bookings/");
    };

    const navigateToMyAccount = (e) => {
        e.preventDefault();
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.HEADER_WISHLIST_OPEN);
        history.push("/my-account/");
    };

    const navigateToSellOrders = (e) => {
        e.preventDefault();
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.MENU_YOUR_ORDERS);
        history.push("/my-sell-orders/");
    };

    const trackEvent = (e, label) => {
        const eventLabel = label || (e && e.target.getAttribute("data-event"));
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.HEADER_PAGE_SELECTED, { eventLabel });
    };

    const handleRoutes = (e, route) => {
        e.preventDefault();
        history.push(route);
    };

    const trackSpathEvent = (eventLabel) => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.HEADER_BUY_A_CAR, {eventLabel});
    };

    const closeToolTip = () => {
        setShowToolTip(false);
    };

    const handleCars24FinanceClicked = (e) => {
        e.preventDefault();
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.MENU_FINANCING_CLICKED, {
            eventAction: "Clicked",
            eventLabel: "Yes"
        });
        handleRoutes(e, "/cars24-finance");
    };

    const handleRoadWorthyClick = (e) => {
        trackDesktopCustomEventsAU(GA_EVENTS.RWC_SECTION_ENTERED, GA_EVENTS.RWC_SECTION_ENTERED);
        handleRoutes(e, "/roadworthy-certificate-melbourne/");
    };
    const {makeUrl = [], makeModelUrl = [], fuelUrl = [], bodyUrl = [], cityTypeUrl = []} = seoMenu || {};

    return (
        <div styleName={`styles.header ${fixed ? "styles.headerFixed" : ""}`} className="pre_exit_header">
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-md-2"} styleName="styles.logoWrapper">
                        {
                            <Link to="/" className={"d-inline-block"} styleName={"styles.logo"}>
                                <Logo />
                            </Link>
                        }
                    </div>
                    <div className={"col-md-8"}>
                        <ul styleName="styles.navList" onClick={trackEvent}>
                            <li styleName={"styles.carFindWrapper"} >
                                <a data-event="find_car" >Buy a car <ArrowIcon /> </a>
                                <ul styleName={"styles.loginDropdownfirst styles.carFind "}>
                                    <div styleName={"styles.listOuter"}>
                                        <h6>Popular makes</h6>
                                        {(makeUrl || []).map((item) => (
                                            <li key={item.label} >
                                                <a href={`${appUrl()}/${item.spath}`} onClick={() => trackSpathEvent(AU_DESKTOP_EVENTS.POPULAR_MAKE + item.spath)}>{item.label}</a>
                                            </li>))}
                                        <a href={`${appUrl()}${SEARCH_ALL_CARS_ROUTE}/`} styleName={"styles.viewAll"}>View all makes</a>
                                    </div>
                                    <div styleName={"styles.listOuter"}>
                                        <h6>Popular models</h6>
                                        {(makeModelUrl || []).map((item) => (
                                            <li key={item.label} >
                                                <a href={`${appUrl()}/${item.spath}`} onClick={() => trackSpathEvent(AU_DESKTOP_EVENTS.POPULAR_MODEL + item.spath)}>{item.label}</a>
                                            </li>))}
                                        <a  href={`${appUrl()}${SEARCH_ALL_CARS_ROUTE}/`} styleName={"styles.viewAll"}>View all models</a>
                                    </div>
                                    <div styleName={"styles.listOuter"}>
                                        <h6>Body Type</h6>
                                        {(bodyUrl || []).map((item) => (
                                            <li key={item.label} >
                                                <a href={`${appUrl()}/${item.spath}`} onClick={() => trackSpathEvent(AU_DESKTOP_EVENTS.FUEL_TYPE_SELECTED + item.spath)}>{item.label}</a>
                                            </li>))}
                                    </div>
                                    <div styleName={"styles.listOuterSecondLast"}>
                                        <h6>Fuel Type</h6>
                                        {(fuelUrl || []).map((item) => (
                                            <li key={item.label} >
                                                <a href={`${appUrl()}/${item.spath}`} onClick={() => trackSpathEvent(AU_DESKTOP_EVENTS.BODY_TYPE_SELECTED + item.spath)}>{item.label}</a>
                                            </li>))}
                                    </div>
                                    <div styleName={"styles.listOuterLast"}>
                                        <h6>Location</h6>
                                        {(cityTypeUrl || []).map((item) => (
                                            <li key={item.label} >
                                                <a href={`${appUrl()}/${item.spath}`} onClick={() => {
                                                    trackSpathEvent(AU_DESKTOP_EVENTS.BODY_TYPE_SELECTED + item.spath);
                                                }}>{item.label}</a>
                                            </li>))}
                                    </div>
                                </ul>
                            </li>
                            <li styleName={"styles.carFinanceWrapper"}>
                                <a
                                    href={`${appUrl()}/sell-your-car/`}
                                    data-event="sellCarClicked"
                                    onClick={() => {
                                        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.SELL_OR_TRADE_IN_CAR);
                                    }}
                                >
                                    Sell / Trade-in
                                </a>
                            </li>
                            <li styleName={"styles.carFinanceWrapper"}  >
                                <a data-event="finance" ref={headerDropRef}>Car Finance <ArrowIcon /></a>
                                <ul styleName={`styles.loginDropdown styles.carFinance`} >
                                    <div styleName={"styles.listOuter"}>
                                        <li><a data-event="financingClicked"
                                            href={isLoggedIn ? `${appUrl()}/my-account/financing/` : `${appUrl()}/financing/get-pre-approval/`}
                                            onClick={(e) => handleRoutes(e, isLoggedIn ? `/my-account/financing` : `/financing/get-pre-approval`)}
                                            route={isLoggedIn ? "/my-account/financing/" : "/financing/get-pre-approval/"}>Apply for pre-approval
                                        </a></li>
                                    </div>
                                    <li><a data-event="cars24Finance" href={`${appUrl()}/cars24-finance/`} onClick={handleCars24FinanceClicked}>CARS24 Finance</a></li>
                                </ul>
                            </li>
                            <li styleName={"styles.carFinanceWrapper"}  >
                                <a data-event="finance" ref={headerDropRef}>Services <ArrowIcon /></a>
                                <ul styleName={`styles.loginDropdown styles.carFinance`} >
                                    <div styleName={"styles.listOuter"}>
                                        <li><a data-event="financingClicked"
                                            href={`${appUrl()}/roadworthy-certificate-melbourne/`}
                                            onClick={handleRoadWorthyClick}
                                            route={"/roadworthy-certificate-melbourne/"}>Roadworthy certificate
                                        </a></li>
                                    </div>
                                </ul>
                            </li>
                            <li styleName={"styles.carFinanceWrapper"} >
                                <a data-event="finance" ref={headerDropRef}>How it works <ArrowIcon  /></a>
                                <ul styleName={`styles.loginDropdown styles.carFinance`} >
                                    <li>
                                        <a data-event="CARS24_Quality_Standards" href={`${appUrl()}/blog/car-quality-standards-cars24/`} target="_blank">CARS24 Quality Standards
                                        </a>
                                    </li>
                                    <div styleName={"styles.listOuter"}>
                                        <li><a data-event="CARS24_way" href={`${appUrl()}/thecars24way/`} onClick={(e) => handleRoutes(e, "/thecars24way/")}>The CARS24 Way
                                        </a></li>
                                    </div>
                                    <li><a data-event="cars24Cover" href={`${appUrl()}/cars24cover/`} onClick={(e) => handleRoutes(e, "/cars24cover/")}>CARS24 Platinum Cover™</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className={"col-md-2"} styleName={"styles.loginOuter"}>
                        <ul styleName={"styles.navListRight"}>
                            {isLoggedIn ?
                                <React.Fragment>
                                    <li>
                                        <a onClick={navigateToWishlist} >
                                            <LazyImage src={HeartIcon} alt="Wishlist" />
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={onClickExpandMenu} styleName={"styles.login"} ref={profileHeader}>
                                            <AvatarText fullName={profileName} />
                                            <span styleName={"styles.profileName"}>Hi,{firstName}</span> <ArrowIcon rotateBy={showDropdown ? 270 : 90} />
                                        </a>
                                    </li>
                                </React.Fragment> :
                                <React.Fragment>
                                    <li>
                                        <a onClick={onClickLoginSignup} styleName={"styles.login"}>
                                            Login / Sign Up
                                        </a>
                                    </li>
                                </React.Fragment>
                            }
                            {showToolTip && <span styleName={"styles.toolTipWrapper"}>
                                <p styleName={"styles.tooltipTextOne"}>My account</p>
                                <p styleName={"styles.tooltipTextTwo"}>View and modify all details related to  <span>finance</span> and <span>trade-in</span> </p>
                                <a styleName={"styles.tooltipGetIt"} onClick={closeToolTip}>Got it</a>
                            </span>
                            }
                        </ul>
                        {showDropdown && <ul styleName={"styles.loginDropdown styles.loginDropdownMenu"} >
                            <li><a onClick={navigateToMyBookings} href="#" route="/my-bookings/" gaEvent="myBookingClicked">My bookings</a></li>
                            <li><a onClick={navigateToSellOrders} href="#" route="/my-sell-orders/" gaEvent="sellOrdersClicked">My sell orders</a></li>
                            <li><a onClick={navigateToMyAccount} href="#" route="/my-account/" gaEvent="myAccountClicked">My Account</a></li>
                            <li><a href="#" onClick={onClickLogout}>Logout</a></li>
                        </ul>}
                    </div>
                </div>
            </div>
        </div>
    );
};

Header.propTypes = {
    history: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool,
    fixed: PropTypes.bool,
    profileName: PropTypes.string,
    logoutConnect: PropTypes.func,
    resetWishlistConnect: PropTypes.func,
    resetCarListConnect: PropTypes.func,
    reloadCarListConnect: PropTypes.func,
    openLoginPopupConnect: PropTypes.func,
    selectedCityCode: PropTypes.string,
    secureToken: PropTypes.string,
    selectedCityName: PropTypes.string,
    clearAllFiltersConnect: PropTypes.func,
    getSeoListConnect: PropTypes.func,
    seoMenu: PropTypes.object,
    logoURL: PropTypes.string,
    preApproved: PropTypes.bool,
    userTradeInData: PropTypes.object,
    email: PropTypes.string,
    setTradeInToggleSelectedConnect: PropTypes.func
};

export default withLogin(Header);
