/* eslint-disable complexity */
/* eslint-disable max-statements */
import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import ArrowIcon from "../../../shared/arrow";
import Modal from "../../../shared/modal";
import PlatinumCoverMonthlyCard from "../../platinum-cover-monthly-card/component";
import proceedArrow from "./images/proceed.svg";
import ComprehensiveCoverage from "../../comprehensive-coverage/component";

const PostBcAddonsExplore = ({
    isOpen,
    onClose,
    selectedProductIndex,
    setSelectedProductIndex,
    onProceedClick,
    productList,
    handleKnowMoreModal,
    categoryKey,
    bannerText,
    bannerImage,
    isFinanceUser,
    isStepAdded,
    addedPlansData,
    selectedPlanData,
    setSelectedPlanData
}) => {
    const isDisabled = selectedProductIndex === null || selectedProductIndex === -1;
    const handleAddPlan = () => {
        if (!isDisabled) {
            onClose();
            onProceedClick(selectedProductIndex);
        }
    };

    const showKnowMore = ["CAR_SERVICING", "EXTENDED_WARRANTY_PLAN"].includes(categoryKey);

    const handleModal = () => {
        handleKnowMoreModal(categoryKey);
    };

    const AddedPlansView = () => {
        return (
            <React.Fragment>
                {addedPlansData?.map((item, index) => {
                    return (
                        <PlatinumCoverMonthlyCard
                            type={isFinanceUser ? "loan" : "payout"}
                            coverDetails={item}
                            isActive={selectedPlanData?.key === item?.key}
                            setSelectedProductIndex={() => setSelectedPlanData(item)}
                            index={index}
                            showPriceWithEmi={false}
                            hideClearButton={true}
                            bottomSticker={item.suggestedItemDescription}
                            repaymentsPerWeek={item?.repaymentsPerWeek}
                            isPostBcCard={true}
                        />
                    );
                })}
            </React.Fragment>
        );
    };

    return (
        <Modal isOpen={isOpen}>
            <div styleName={"styles.outer"}>
                <div styleName={"styles.modalHeader"}>
                    <p styleName={"styles.heading"} onClick={onClose}>
                        <ArrowIcon black={"BlackArrow"} rotateBy={90} />
                        Explore all plans
                    </p>
                </div>
                <div styleName={"styles.wrapper"}>
                    <div styleName={"styles.contentWrap"}>
                        <div styleName={"styles.leftColumn"}>
                            <p styleName={"styles.suggestedPlans"}>{isStepAdded ? "Selected plan" : "Suggested plan"}</p>
                            {isStepAdded ? <AddedPlansView /> :
                             productList?.map((item, index) => {
                                 if (item?.suggested) {
                                     return (
                                         <PlatinumCoverMonthlyCard
                                             type={isFinanceUser ? "loan" : "payout"}
                                             coverDetails={item}
                                             isActive={selectedProductIndex === index}
                                             setSelectedProductIndex={setSelectedProductIndex}
                                             index={index}
                                             showPriceWithEmi={false}
                                             hideClearButton={true}
                                             bottomSticker={item.suggestedItemDescription}
                                             repaymentsPerWeek={item?.repaymentsPerWeek}
                                             isPostBcCard={true}
                                         />
                                     );
                                 } else {
                                     return null;
                                 }
                             })}
                            <p styleName={"styles.otherPlans"}>Other plans</p>
                            {productList?.map((item, index) => {
                                const isPlanAdded = addedPlansData?.find((plan) => plan?.key === item?.key);
                                const isCategoryPlanAdded = addedPlansData?.find((plan) => plan?.categoryKey === categoryKey);
                                const isOtherPlanAdded = !isPlanAdded && isCategoryPlanAdded && item?.suggested;
                                if (!item?.suggested || isOtherPlanAdded) {
                                    return (
                                        <div styleName={"styles.otherPlansWrapper"}>
                                            <PlatinumCoverMonthlyCard
                                                type={isFinanceUser ? "loan" : "payout"}
                                                coverDetails={item}
                                                isActive={selectedProductIndex === index}
                                                setSelectedProductIndex={setSelectedProductIndex}
                                                index={index}
                                                showPriceWithEmi={false}
                                                hideClearButton={true}
                                                repaymentsPerWeek={item?.repaymentsPerWeek}
                                                isPostBcCard={true}
                                            />
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                        <div styleName={"styles.rightColumn"}>
                            <ComprehensiveCoverage
                                showKnowMore={showKnowMore}
                                bannerText={bannerText}
                                bannerImage={bannerImage}
                                onKnowMoreClick={handleModal}
                            />
                        </div>
                    </div>
                </div>

                <div styleName={"styles.buttonWrapper"}>
                    <button
                        styleName={isDisabled ? "styles.disabledButton" : ""}
                        disabled={isDisabled}
                        onClick={handleAddPlan}
                    >
                        {isFinanceUser ? "Add to loan" : "Add"}
                        <img src={proceedArrow} />
                    </button>
                </div>
            </div>
        </Modal>
    );
};

PostBcAddonsExplore.propTypes = {
    carCoverData: PropTypes.object,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    selectedProductIndex: PropTypes.number,
    setSelectedProductIndex: PropTypes.func,
    onProceedClick: PropTypes.func,
    onRemoveClick: PropTypes.func,
    productList: PropTypes.array,
    paymentOption: PropTypes.string,
    handleKnowMoreModal: PropTypes.func,
    categoryKey: PropTypes.string,
    bannerText: PropTypes.string,
    bannerImage: PropTypes.string,
    isFinanceUser: PropTypes.bool,
    isStepAdded: PropTypes.bool,
    addedPlansData: PropTypes.array,
    selectedPlanData: PropTypes.object,
    setSelectedPlanData: PropTypes.func
};

export default PostBcAddonsExplore;
