import React from "react";
import PropTypes from "prop-types";
import Overlay from "../../shared/overlay";
import IconSpinner from "../../shared/icon-spinner";
import styles from "./styles.css";

const PageLoader = ({
    opacity = 1
}) => {
    return (
        <Overlay
            background="white"
            opacity={opacity}>
            <div styleName={"styles.loadingIcon"}>
                <IconSpinner />
            </div>
        </Overlay>
    );
};

PageLoader.propTypes = {
    opacity: PropTypes.number
};

export default PageLoader;
