import { useState, useEffect } from "react";
const useOnloadIdle = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        const onLoad = () => {
            const rIC = window.requestIdleCallback || window.setTimeout;
            rIC(() => setIsLoaded(true));
        };
        window.addEventListener("load", onLoad);

        return () => {
            window.removeEventListener("load", onLoad);
        };
    }, []);
    return isLoaded;
};
export default useOnloadIdle;
