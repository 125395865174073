
import { AU_RECENTLY_VIEWD_TIMESTAMP, NUMBER } from "../../constants/app-constants";
import parseCookie from "./parse-cookie";
import saveCookie from "./save-cookie";
import { uniqueStack } from "./unique-stack";

export const getRecentlyViewed = (key, getLatest = false) => {
    const recentlyViewed = parseCookie(key);
    try {
        return recentlyViewed ? JSON.parse(recentlyViewed).slice(getLatest ? 0 : 1) : [];
    } catch (e) {
        return [];
    }
};
export const getRecentViewedWithTimestamp = (key) => {
    const recentAppointments = parseCookie(key);
    try {
        const parsed = recentAppointments ? JSON.parse(recentAppointments) : { appointmentIds: [] };
        return parsed?.appointmentIds || [];
    } catch (e) {
        return [];
    }
};

export const addToRecentAppointments = (
    key,
    appointmentId,
    { max = NUMBER.FIVE, expiry = NUMBER.THREE_HUNDRED_SIXTY_FIVE } = {}
) => {
    const recentAppointments = getRecentViewedWithTimestamp(key, true);

    // Create new appointment entry
    const newAppointment = {
        addedAt: new Date().toISOString(),
        appointmentId
    };

    // Remove existing entry if present
    const filteredAppointments = recentAppointments.filter(
        appointment => appointment.appointmentId !== appointmentId
    );

    // Add new appointment to start and limit to max
    const updatedAppointments = {
        appointmentIds: [newAppointment, ...filteredAppointments].slice(0, max)
    };

    saveCookie(key, JSON.stringify(updatedAppointments), expiry);
    return updatedAppointments;
};
// eslint-disable-next-line no-magic-numbers
export const addToRecentlyViewed = (key, item, { max = NUMBER.FIVE, expiry = 365 } = {}) => {
    const recentlyViewed = getRecentlyViewed(key, true);
    addToRecentAppointments(AU_RECENTLY_VIEWD_TIMESTAMP, item);
    const updatedRecentlyViewed = uniqueStack(recentlyViewed, max + 1)(item);
    saveCookie(key, JSON.stringify(updatedRecentlyViewed), expiry);
};

