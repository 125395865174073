import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Modal from "../../shared/modal";
import styles from "./styles.css";
import Button from "../../shared/button";
import snarkdown from "snarkdown";

const VasPackageAddModal = ({ info, open, onClose }) => {
    const { title, message, ctaText } = info || {};

    useEffect(() => {
        return () => {
            if (typeof window !== "undefined") {
                document.body.style.overflow = "unset";
            }
        };
    }, []);

    return (
        <Modal isOpen={open} close={onClose}>
            <div styleName={"styles.modalOuter"}>
                <p styleName={"styles.questionHeading"} dangerouslySetInnerHTML={{
                    __html: snarkdown(title || "")
                }} />
                <p styleName={"styles.subHeadingAnswer"}  dangerouslySetInnerHTML={{
                    __html: snarkdown(message || "")
                }} />
                <Button onClick={onClose} text={ctaText} />
            </div>
        </Modal>
    );
};

VasPackageAddModal.propTypes = {
    info: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func
};

export default VasPackageAddModal;
