import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { fastlyUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import { makeOdometerLabelAU } from "../../../utils/helpers/make-odometer-label";
import { getDeliveryDate } from "../../../utils/helpers/get-day-from-date";
import { getPaymentTypeLabel, getPaymentTypePrefix } from "../../../utils/helpers/checkout-util";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import { getAmountWithSurcharge } from "../../../utils/helpers/split-payment";
import { DELIVERY_TYPES } from "../../../constants/checkout-constants";
import usePricing from "../../../hooks/use-pricing";

// eslint-disable-next-line complexity
const BookCarDetail = ({
    year,
    make,
    model,
    odometerReading,
    fuelType,
    transmissionType,
    variant,
    viewOrderSummary,
    setShowOrderSummary,
    mainImage,
    config,
    pickupTime,
    deliveryDateRange = {},
    showPaidInfo = false,
    bookingAmount,
    paymentMethodType,
    order
}) => {
    const { to = "", from = "" } = deliveryDateRange || {};
    const carFullName = `${year || ""} ${make || ""} ${model || ""}`;
    const { deliveryMode  } = order || {};
    const hasMainImage = mainImage && mainImage.path && config && config.imageHost ? true : false;
    const carImageUrl = hasMainImage
        ? fastlyUrl(config.imageHost, mainImage.path, imageTypes.helloARCarCardAu)
        : null;

    const openOrderSummary = () => {
        setShowOrderSummary(true);
    };

    const { financeOpted } = order || {};
    const { tradeInPrice } = usePricing();

    const orderSummary = () => {
        return (
            !viewOrderSummary ? (
                <ul styleName={"styles.variantList"}>
                    <li>{makeOdometerLabelAU(odometerReading)}</li>
                    <li>{fuelType}</li>
                </ul>
            ) : (
                <p onClick={openOrderSummary} styleName={"styles.orderSummaryLink"}>
                    View Order Summary
                </p>
            )
        );
    };

    const getTotalAmountPaid = () => {
        const totalAmount = getAmountWithSurcharge(tradeInPrice.value, paymentMethodType);
        if (financeOpted) {
            return makePriceLabelRound(bookingAmount);
        } else {
            return makePriceLabelRound(totalAmount.amountWithSurcharge);
        }
    };

    return (
        <div styleName={"styles.flex1 styles.detailWrapper"}>
            <div styleName="styles.wrapper">
                <div className="col-lg-4" styleName={"styles.leftCol"}>
                    <div styleName={"styles.carImage"}>
                        <img src={carImageUrl} alt="car image" />
                    </div>
                </div>
                <div className="col-lg-8" styleName={"styles.rightCol"}>
                    <p className={"carNameOrderSummary"}>{carFullName}</p>
                    <p className={"carModelVariantOrderSummary"} >{variant} <span>{transmissionType}</span></p>
                    {orderSummary()}
                </div>
            </div>
            {showPaidInfo && <React.Fragment>
                <div  styleName="styles.flex1">
                    <div styleName={"styles.payVias"}>
                        <label>{getPaymentTypePrefix(paymentMethodType)} <span>{getPaymentTypeLabel(paymentMethodType)}</span></label>
                        <p styleName={"styles.amount"}>{getTotalAmountPaid()}</p>
                    </div>
                </div>
                <div  styleName="styles.flex1">
                    {pickupTime && <div styleName={"styles.payVias styles.payViasDate"}>
                        <label>{deliveryMode === DELIVERY_TYPES.PickUp ? "Pick Up Date" : "Delivery Date"}</label>
                        <p styleName={"styles.date"}>{ to && from ?
                            `${from} - ${to}` :
                            (from || getDeliveryDate(new Date(pickupTime)))}</p>
                    </div>}
                </div>
            </React.Fragment>
            }
        </div>
    );
};

BookCarDetail.propTypes = {
    config: PropTypes.object,
    year: PropTypes.string,
    make: PropTypes.string,
    model: PropTypes.string,
    variant: PropTypes.string,
    odometerReading: PropTypes.number,
    fuelType: PropTypes.string,
    transmissionType: PropTypes.string,
    viewOrderSummary: PropTypes.bool,
    setShowOrderSummary: PropTypes.func,
    mainImage: PropTypes.object,
    paymentMethodType: PropTypes.string,
    bookingAmount: PropTypes.string,
    pickupTime: PropTypes.string,
    showPaidInfo: PropTypes.bool,
    deliveryDate: PropTypes.string,
    order: PropTypes.object,
    deliveryDateRange: PropTypes.object,
    tradeInData: PropTypes.object,
    egcData: PropTypes.object,
    shippingPrice: PropTypes.number,
    pickupCharges: PropTypes.number
};

export default BookCarDetail;
