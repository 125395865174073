import React from "react";
import PropTypes from "prop-types";
import style from "./style.css";

const Overlay = ({
    background,
    opacity,
    onClickHandler,
    children
}) => {
    const styleOb = {
        opacity,
        background
    };

    let component = (
        <div styleName="style.overlay" style={styleOb} onClick={onClickHandler}/>
    );

    if (children) {
        component = (
            <div styleName="style.overlay" style={styleOb} onClick={onClickHandler}>
                {children}
            </div>
        );
    }
    return component;
};

Overlay.propTypes = {
    background: PropTypes.string,
    opacity: PropTypes.string,
    onClickHandler: PropTypes.func,
    children: PropTypes.object
};

export default  Overlay;
