import React from "react";
import { connect } from "react-redux";

import styles from "./styles.css";
import PropTypes from "prop-types";
import ErrorHandler from "../../shared/with-error-handler";
import NeedHelpWidget from "../need-help-widget";
import { getCheckoutPageURL } from "../../../utils/helpers/get-detail-page-url";
import { useHistory } from "react-router-dom";
import { checkoutRoutes } from "../../../constants/checkout-routes";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";

const ProductDetailHeader = ({content, infoBar, name, amount, discountedPrice, repaymentsPerWeek}) => {
    const history = useHistory();

    const { text: infoBarText, iconUrl: infoBarIcon } = infoBar || {};

    const onProductDetailClose = () => {
        trackMobileCustomEventsAU(AU_DESKTOP_EVENTS.PRE_BC_CAR_CARE_PACKS_PRODUCT_DETAIL_PAGE, {
            eventAction: "Landed_detail_page_Back",
            eventLabel: "Yes"
        });
        const { relativeURL } = getCheckoutPageURL(content, checkoutRoutes.addOns.route, "/");
        history.push(relativeURL);
    };

    return (
        <React.Fragment>
            <div styleName={"styles.headerWrapper"}>
                <div styleName={"styles.headerLeft"}>
                    <p styleName={"styles.headings"}>{name}</p>
                    {infoBar &&
                        <p styleName={"styles.maximumSavingsWrap"}>
                            <img src={infoBarIcon} />{infoBarText}</p>
                    }
                </div>
                <p styleName={"styles.priceWrapper styles.priceWrapperPreApproved"}>
                    <span styleName={"styles.boldAmount styles.amountPreApproved"}>{makePriceLabelRound(repaymentsPerWeek)}/week</span>&nbsp;or&nbsp;
                    <span styleName={"styles.boldAmount styles.discountPricePreApproved"}>{makePriceLabelRound(discountedPrice)}</span>&nbsp;
                    {discountedPrice < amount && <span styleName={"styles.discountPrice"}>{makePriceLabelRound(amount)}</span>}
                </p>
                <div styleName={"styles.leftWrapper"}>
                    <ErrorHandler>
                        <NeedHelpWidget vasProductDetail={true}  />
                    </ErrorHandler>
                    <div styleName={"styles.needHelpWrapper"} onClick={onProductDetailClose}>
                        <p styleName={"styles.needHelp"}>Exit</p>
                    </div>
                </div>
            </div>
        </React.Fragment>

    );
};

const mapStateToProps = ({
    carDetails: {
        content
    }
}) => ({
    content
});

ProductDetailHeader.propTypes = {
    amount: PropTypes.number,
    content: PropTypes.object,
    discountedPrice: PropTypes.number,
    infoBar: PropTypes.object,
    name: PropTypes.string,
    preApproved: PropTypes.bool,
    repaymentsPerWeek: PropTypes.number,
    tag: PropTypes.string,
    type: PropTypes.string
};

export default connect(mapStateToProps)(ProductDetailHeader);
