import React, {useState, useMemo} from "react";

import { CHECKOUT_FLOW_CMS_SECTIONS } from "../../../constants/checkout-constants";
import { PropTypes } from "prop-types";
import { REGO_SERVICE_POPUP } from "./constant";
import { getPageSectionByName } from "../../../utils/helpers/get-cms-section";
import loadable from "@loadable/component";

const RegistrationChargesAndMoreModal = loadable(() => import("../registration-charges-and-more-modal"));
const RegistrationChargesLearnMore = loadable(() => import("../registration-charges-learn-more/component"));
const RegistrationChargesRemoval = loadable(() => import("../registration-charges-removal"));

const STEP_COMPONENTS = [
    {
        step: REGO_SERVICE_POPUP.REGO_CHARGES,
        component: RegistrationChargesAndMoreModal,
        cmsConfigKey: "charges_popup"
    },
    {
        step: REGO_SERVICE_POPUP.REMOVAL_FLOW,
        component: RegistrationChargesRemoval,
        cmsConfigKey: "remove_popup"
    },
    {
        step: REGO_SERVICE_POPUP.REGO_CHARGES_LEARN_MORE,
        component: RegistrationChargesLearnMore,
        cmsConfigKey: "learn_more"
    }
];

const RegistrationChargesFlow = ({
    isOpen = false,
    onClose,
    cmsSections,
    orderId,
    egcStateData,
    isFinanceFirstCheckout,
    onDeliveryStateChange
}) => {
    const [currentStep, setCurrentStep] = useState(STEP_COMPONENTS[0]);

    const deliveryDetailCmsConfig = useMemo(() => {
        const res =  getPageSectionByName(cmsSections, CHECKOUT_FLOW_CMS_SECTIONS.DELIVERY_DETAILS);
        const { meta = {}} = res || {};
        return meta.rego_service || {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cmsSections]);

    const handleNextClick = (val) => {
        let nextStep = null;
        if (val.length) {nextStep = STEP_COMPONENTS.find((item) => item.step === val);} else {
            const currentIndex = STEP_COMPONENTS.findIndex((item) => item.step === currentStep.step);
            if (currentIndex !== -1 && currentIndex < STEP_COMPONENTS.length - 1) {
                nextStep = STEP_COMPONENTS[currentIndex + 1];
            }
        }
        setCurrentStep(nextStep);
    };

    const Component = currentStep.component;

    if (deliveryDetailCmsConfig) {
        return (
            <React.Fragment>
                {currentStep.component && (
                    <Component
                        cmsConfig={deliveryDetailCmsConfig?.[currentStep.cmsConfigKey] || {}}
                        onClose={onClose}
                        isOpen={isOpen}
                        handleNextStep={handleNextClick}
                        orderId={orderId}
                        egcStateData={egcStateData}
                        isFinanceFirstCheckout={isFinanceFirstCheckout}
                        onDeliveryStateChange={onDeliveryStateChange}
                    />
                )}
            </React.Fragment>
        );
    }
    return null;
};

RegistrationChargesFlow.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    cmsSections: PropTypes.array,
    orderId: PropTypes.string,
    egcStateData: PropTypes.array,
    isFinanceFirstCheckout: PropTypes.bool,
    onDeliveryStateChange: PropTypes.func
};

export default RegistrationChargesFlow;
