import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    valuationDetails: {
        valuationData: {},
        loading: false,
        error: false,
        isSSR: false
    },
    pickupDetails: {
        pickupData: {},
        loading: false,
        error: false
    },
    acceptOrder: {
        success: false,
        error: false,
        loading: false
    },
    slotDetails: {
        slotData: [],
        loading: false,
        error: false,
        isSSR: false
    }
};

export const fetchOrderDetailsRequest = (state) => {
    return {
        ...state,
        valuationDetails: {
            valuationData: {},
            loading: true,
            error: false,
            isSSR: false
        }
    };
};

export const fetchOrderDetailsSuccess = (state, action) => {
    return {
        ...state,
        valuationDetails: {
            valuationData: action.payload,
            loading: false,
            isSSR: false
        }
    };
};

export const fetchOrderDetailsFailure = (state) => {
    return {
        ...state,
        valuationDetails: {
            valuationData: {},
            loading: false,
            error: true,
            isSSR: false
        }
    };
};

/* PICKUP DETAILS */

export const fetchPickupDetailsRequest = (state) => {
    return {
        ...state,
        pickupDetails: {
            pickupData: {},
            loading: true,
            error: false
        }
    };
};

export const fetchPickupDetailsSuccess = (state, action) => {
    return {
        ...state,
        pickupDetails: {
            pickupData: action.payload,
            loading: false,
            error: false
        }
    };
};

export const fetchPickupDetailsFailure = (state) => {
    return {
        ...state,
        pickupDetails: {
            pickupData: {},
            loading: false,
            error: true
        }
    };
};

/* ACCEPT SELL ORDER */

export const acceptSellOrderRequest = (state) => {
    return {
        ...state,
        acceptOrder: {
            success: false,
            error: false,
            loading: true
        }
    };
};

export const acceptSellOrderSuccess = (state) => {
    return {
        ...state,
        acceptOrder: {
            success: true,
            error: false,
            loading: false
        }
    };
};

export const acceptSellOrderFailure = (state) => {
    return {
        ...state,
        acceptOrder: {
            success: false,
            error: true,
            loading: false
        }
    };
};

/*Slot Details*/

export const fetchSlotDetailsRequest = (state) => {
    return {
        ...state,
        slotDetails: {
            slotData: [],
            loading: false,
            error: false,
            isSSR: false
        }
    };
};

export const fetchSlotDetailsSuccess = (state, action) => {
    return {
        ...state,
        slotDetails: {
            slotData: action.payload,
            loading: false,
            error: false,
            isSSR: false
        }
    };
};

export const fetchSlotDetailsFailure = (state) => {
    return {
        ...state,
        slotDetails: {
            slotData: {},
            loading: false,
            error: false,
            isSSR: false
        }
    };
};

export const HANDLERS = {
    [Types.FETCH_VALUATION_DETAILS_REQUEST]: fetchOrderDetailsRequest,
    [Types.FETCH_VALUATION_DETAILS_SUCCESS]: fetchOrderDetailsSuccess,
    [Types.FETCH_VALUATION_DETAILS_FAILURE]: fetchOrderDetailsFailure,
    [Types.FETCH_PICKUP_DETAILS_REQUEST]: fetchPickupDetailsRequest,
    [Types.FETCH_PICKUP_DETAILS_SUCCESS]: fetchPickupDetailsSuccess,
    [Types.FETCH_PICKUP_DETAILS_FAILURE]: fetchPickupDetailsFailure,
    [Types.ACCEPT_SELL_ORDER_REQUEST]: acceptSellOrderRequest,
    [Types.ACCEPT_SELL_ORDER_SUCCESS]: acceptSellOrderSuccess,
    [Types.ACCEPT_SELL_ORDER_FAILURE]: acceptSellOrderFailure,
    [Types.FETCH_SLOT_DETAILS_REQUEST]: fetchSlotDetailsRequest,
    [Types.FETCH_SLOT_DETAILS_SUCCESS]: fetchSlotDetailsSuccess,
    [Types.FETCH_SLOT_DETAILS_FAILURE]: fetchSlotDetailsFailure

};

export default createReducer(INITIAL_STATE, HANDLERS);
