import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getOrderDetail } from "../checkout-info/actions";
import PriceBreakdownContents from "./component";

const mapStateToProps = ({
    myBookings: {
        paymentSummaryData
    }
}) => ({
    paymentSummaryData

});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getOrderDetailConnect: getOrderDetail
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PriceBreakdownContents);
