import { createTypes } from "reduxsauce";
export default createTypes(
    `
    FETCH_CMS_CONTENT_INIT
    FETCH_CMS_CONTENT_SUCCESS
    FETCH_CMS_CONTENT_FAILURE
    UPDATE_SHOW_INSPECTION_FORM
    UPDATE_INSPECTION_FORM_LOADER
`,
    {
        prefix: "roadworthyCertificate/"
    }
);
