import React from "react";
import styles from "./styles.css";
import customerCare from "./images/customer-care.svg";
import PropTypes from "prop-types";
import withCallbackRequest from "../with-callback-request";
import { CALLBACK_SOURCE_SCREEN } from "../../../constants/app-constants";

const ContactOurTeamCard = ({handleCallbackRequest}) => {

    return (
        <div className="media" styleName={"styles.mediaWrapper"}>
            <img src={customerCare} alt="Contact our team" />
            <div className="media-body">
                <p styleName={"styles.heading"}>Have questions?</p>
                <p styleName={"styles.subHeading"} onClick={handleCallbackRequest}>Request a callback</p>
            </div>
        </div>
    );
};

ContactOurTeamCard.propTypes = {
    handleCallbackRequest: PropTypes.func
};

export default withCallbackRequest(ContactOurTeamCard, CALLBACK_SOURCE_SCREEN.DAP_MODAL, {
    isQuoteAvailable: false
});
