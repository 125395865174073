import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import { getCheckoutPageURL, getTestDriveV2CheckoutURL } from "../../../utils/helpers/get-detail-page-url";
import CheckoutHeader from "../checkout-header";
import styles from "./styles.css";
import Button from "../../shared/button";
import TransparentButton from "../../shared/button/transparent-button";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import DrivaFinanceNav from "../driva-finance-nav";
import CreditScoreDiv from "../../shared/credit-score-impact";
import PaymentAgreeTerms from "../payment-agree-terms";
import { CAR_LIST_EVENT_NAMES } from "../../../constants/app-constants";

// eslint-disable-next-line complexity
const CheckoutLayout = ({
    children,
    setAgreeTerms,
    routeData = {},
    showHeader = false,
    showNavigation = false,
    showFooter = true,
    hideNextButton,
    customNextText,
    customBackText,
    nextButtonDisabled,
    nextButtonLoading,
    backButtonLoading = false,
    customCallClick,
    onCustomPillClick = () => {},
    customBack,
    customNext,
    content,
    history,
    isAffiliate,
    drivaInputForm = false,
    isDrivaErrorScreen = false,
    isPaymentPage = false,
    completedPercentage,
    showCreditScore = false,
    errorScreenCTA = "",
    errorScreenCTACallback = () => { },
    headerTitle = "Apply for finance",
    removePaddingBottom = false,
    preApporvedOrigin,
    showSkipButton,
    showTDSubtext
}) => {
    const isTDflow = preApporvedOrigin === CAR_LIST_EVENT_NAMES.TEST_DRIVE_FINANCE;
    const defaultBack = () => {
        const generateNextUrl = isTDflow ? getTestDriveV2CheckoutURL : getCheckoutPageURL;
        const { relativeURL: backURL } = generateNextUrl(content, routeData.defaultBack);
        history.push(backURL);
    };

    const defaultNext = () => {
        const { relativeURL: nextURL } = getCheckoutPageURL(content, routeData.defaultNext);
        history.push(nextURL);
    };

    const defaultCallClick = () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CHECKOUT_HEADER_CALL, {
            eventLabel: `options ${routeData.gtmPageName}`
        });
    };

    let wrapperStyles = "styles.wrapper";
    if (routeData.isCheckoutPreApproval) wrapperStyles = `${wrapperStyles} styles.noBoxShadow`;

    return (
        <div
            styleName={`${removePaddingBottom ? "styles.paddingBottomZero" : "styles.layoutWrapper"}`}
        >
            {(drivaInputForm || isDrivaErrorScreen) &&
            <DrivaFinanceNav
                isDrivaErrorScreen={isDrivaErrorScreen}
                errorScreenCTA={errorScreenCTA}
                errorScreenCTACallback={errorScreenCTACallback}
                completedPercentage={completedPercentage}
                title={headerTitle}
                showTDSubtext={showTDSubtext}
            />
            }
            {showHeader ? (
                <CheckoutHeader
                    showExtraInfo={showNavigation}
                    onCallClick={customCallClick || defaultCallClick}
                    showPreApprovalPill={routeData.showPreApprovalPill}
                    onCustomPillClick={onCustomPillClick}
                />
            ) : (
                ""
            )}

            {children}

            {showFooter ? (
                <div styleName={ routeData.key === "tradeInValuation" ? "styles.tradeInButtonWrapper"  : wrapperStyles}>
                    {isPaymentPage && <PaymentAgreeTerms  onClickCheckBox={setAgreeTerms} />}
                    {!!showCreditScore && <div styleName={`styles.creditScoreWrapper`}><CreditScoreDiv /></div>}
                    <div className="buttonGlobalContainer" styleName={`styles.buttonWrapper ${routeData.showPreApprovalPill ? "styles.preApproveButtonWrapper" : ""}`}>
                        {
                            showSkipButton ?
                                <div styleName={"styles.skiptestdrivebutton"}>
                                    <p isLoading={backButtonLoading} onClick={customBack || defaultBack}>Skip, book test drive</p>
                                </div> :
                                <div styleName={isTDflow ? "styles.skiptestdrivebutton" : "styles.transparentButton"}>
                                    <TransparentButton isLoading={backButtonLoading} disabled={isAffiliate && routeData.key === "basicDetails"} text={customBackText || "Back"} onClick={customBack || defaultBack}/>
                                </div>
                        }
                        {hideNextButton ? "" :
                            <Button
                                text={customNextText || "Next"}
                                onClick={customNext || defaultNext}
                                disabled={nextButtonDisabled}
                                isLoading={nextButtonLoading && !nextButtonDisabled}
                            />
                        }
                    </div>
                </div>

            ) : (
                ""
            )}
        </div>
    );
};

CheckoutLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    routeData: PropTypes.object.isRequired,
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    isDrivaErrorScreen: PropTypes.bool,
    hideNextButton: PropTypes.bool,
    showCreditScore: PropTypes.bool,
    customBack: PropTypes.func,
    customNext: PropTypes.func,
    customCallClick: PropTypes.func,
    onCustomPillClick: PropTypes.func,
    isPaymentPage: PropTypes.bool,
    nextButtonDisabled: PropTypes.bool,
    nextButtonLoading: PropTypes.bool,
    isAffiliate: PropTypes.bool,
    showNavigation: PropTypes.bool,
    completedPercentage: PropTypes.bool,
    content: PropTypes.object,
    history: PropTypes.object,
    customNextText: PropTypes.string,
    drivaInputForm: PropTypes.bool,
    backButtonLoading: PropTypes.bool,
    customBackText: PropTypes.string,
    errorScreenCTA: PropTypes.string,
    errorScreenCTACallback: PropTypes.func,
    headerTitle: PropTypes.string,
    setAgreeTerms: PropTypes.func,
    removePaddingBottom: PropTypes.bool,
    preApporvedOrigin: PropTypes.string,
    showSkipButton: PropTypes.bool,
    showTDSubtext: PropTypes.bool
};

export default withRouter(CheckoutLayout);
