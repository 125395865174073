import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {fetchSearchResult} from "./actions";
import {fetchTotalAvailableCar} from "./actions";
import {setHomeSSR} from "./actions";
import Home from "./component";

const mapStateToProps = ({
    user: {
        secureToken,
        isLoggedIn,
        isUserZeroDpVariant,
        userGeoData,
        gaId
    },
    abExperiment: {
        showTestDriveV2
    },
    homeCarList: {
        metaContent,
        recentlyViewedCookieData,
        isSSR,
        searchPageResult
    },
    filters: {
        allFilters
    },
    preApprovalLoan: {
        financeData: {
            loanType
        },
        preApproved,
        stEligible
    },
    checkout: {
        stEligibleData
    },
    supportWidget: {
        showEmailSupportModal
    }
}) => ({
    isLoggedIn,
    isUserZeroDpVariant,
    searchPageResult,
    metaContent,
    allFilters,
    recentlyViewedCookieData,
    isSSR,
    secureToken,
    showTestDriveV2,
    loanType,
    preApproved,
    stEligibleData,
    stEligible,
    userGeoData,
    gaId,
    showEmailSupportModal
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSearchResultConnect: fetchSearchResult,
    setHomeSSRConnect: setHomeSSR,
    fetchTotalAvailableCarConnect: fetchTotalAvailableCar
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);
