import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ContactOurTeamCard from "./component";
import { checkoutCallbackRequest } from "../checkout-info/actions";

const mapStateToProps = ({
    carDetails: { config },
    user: { mobile},
    checkout: {
        callbackDetails: {
            isExpired,
            requested
        } }

}) => ({
    config,
    mobile,
    checkoutCallbackRequestExpired: isExpired,
    callbackRequest: requested
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    checkoutCallbackRequestConnect: checkoutCallbackRequest
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContactOurTeamCard);
