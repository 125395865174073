/* eslint-disable max-statements */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styles from "./styles.css";
import { showToast } from "../../../shared/toast-message/actions";
import { NUMBER, VAS_PAGE_SCREEN_TYPE } from "../../../../constants/app-constants";
import VasPackageAddModal from "../../vas-package-add-modal/component";
import { useHistory } from "react-router-dom";
import { getCheckoutPageURL } from "../../../../utils/helpers/get-detail-page-url";
import { checkoutRoutes } from "../../../../constants/checkout-routes";
import CarPackagesCardRevamp from "../car-packages-card-revamp/component";
import { addVASItem, getVASCart, removeVASItem, setCounterAnimation } from "../../add-ons/actions";
import { addParamsToURL } from "../../../../constants/url-constants";

const settings = {
    slidesToShow: 2.5,
    slidesToScroll: 1,
    infinite: false,
    arrow: false,
    dots: false,
    focusOnSelect: true,
    useTransform: false
};

const CarPackagesRevamp = ({
    addKey,
    addLoading,
    addVASItemConnect,
    appointmentId,
    bundles,
    content,
    removeLoading,
    removeKey,
    removeVASItemConnect,
    setCounterAnimationConnect,
    showToastConnect,
    orderId,
    vasCartData
}) => {
    const history = useHistory();

    const [packageAddedModal, setPackageAddedModal] = useState(false);
    const [packageAddedModalInfo, setPackageAddedModalInfo] = useState({});

    const vasCartItems = (vasCartData || {}).items || [];

    const onAddClick = async ({ key, type }) => {
        try {
            setCounterAnimationConnect(false);
            const { errorDetails } = await addVASItemConnect({ appointmentId, key, type, orderId, vasPageName: VAS_PAGE_SCREEN_TYPE.ADD_ONS });
            if (errorDetails) {
                const { variant, message } = errorDetails || {};
                if (variant === "TOAST") {
                    showToastConnect({ variant: "black", text: message, position: "bottom", toastHideTime: NUMBER.TWO_THOUSAND, toastStyle: "addOnToastMsg" });
                } else if (variant === "POPUP") {
                    setPackageAddedModal(true);
                    setPackageAddedModalInfo(errorDetails);
                }
            }
            setCounterAnimationConnect(true);
            // trackMobileCustomEventsAU(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_LANDING_PAGE, {
            //     eventLabel: name,
            //     eventAction: "Pack_add"
            // });
            // trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.LANDING_PAGE, {
            //     "Pack_add": name
            // });
        } catch (error) {
            //console.log("error", error);
        }
    };

    const onRemoveClick = async ({ key, type }) => {
        setCounterAnimationConnect(false);
        await removeVASItemConnect({ appointmentId, key, type, orderId, vasPageName: VAS_PAGE_SCREEN_TYPE.ADD_ONS});
        setCounterAnimationConnect(true);
    };

    return (
        <div styleName={"styles.carPackageOuter"}>
            <div styleName={"styles.headerWrapper"}>
                <div styleName={"styles.leftWrap"}>
                    <p styleName={"styles.carPackageHeading"}>Car care packages</p>
                    <p styleName={"styles.carPackageSubHeading"}>
                        Add to loan and pay per week
                    </p>
                </div>
                <div styleName={"styles.stickerWrapper"}>
                    <p styleName={"styles.stickerText"}>80% users' choice</p>
                </div>
            </div>
            <div styleName={"styles.sliderWrapper"}>
                <div styleName={"styles.rightGradient"}>&nbsp;</div>
                <Slider {...settings}>
                    {(bundles || []).map((bundle) => {
                        const { key, name, type, category = [] } = bundle || {};

                        const isItemAlreadyPresent = vasCartItems.findIndex((item) => item.key === key);

                        const onBundleClick = () => {
                            const { year, egc } = content || {};
                            const { relativeURL } = getCheckoutPageURL(content, checkoutRoutes.addOns.route);
                            const url = addParamsToURL(relativeURL, `key=${key}&type=${type}&carYear=${year}&carPrice=${egc}`);
                            history.push(url);
                        };

                        const onCtaClick = (e) => {
                            e.stopPropagation();
                            if (isItemAlreadyPresent === -1) onAddClick({ key, type, category, name });
                            else onRemoveClick({ key, type });
                        };

                        const loading = (addLoading || removeLoading) && key === (addKey || removeKey);

                        let ctaText = "Add";
                        if (isItemAlreadyPresent !== -1) ctaText = "Remove";

                        return (
                            <CarPackagesCardRevamp
                                bundle={bundle}
                                ctaText={ctaText}
                                key={key}
                                loading={loading}
                                onCtaClick={onCtaClick}
                                onBundleClick={onBundleClick}
                                showKnowMore={true}
                            />
                        );
                    })}
                </Slider>
            </div>
            <VasPackageAddModal info={packageAddedModalInfo} open={packageAddedModal} onClose={() => setPackageAddedModal(false)} />
        </div>
    );
};

const mapStateToProps = ({
    carDetails: {
        content,
        content: {
            appointmentId,
            model
        }
    },
    checkout: {
        order: {
            orderId
        },
        quotesAvailable: preApproved
    },
    addOns: {
        vasBundles: {
            data: {
                leastBundleRepaymentPerWeek,
                bundles
            }
        },
        addVASItem: {
            loading: addLoading,
            key: addKey
        },
        removeVASItem: {
            loading: removeLoading,
            key: removeKey
        },
        vasCart: {
            data: vasCartData
        }
    }
}) => ({
    addKey,
    addLoading,
    appointmentId,
    bundles,
    content,
    leastBundleRepaymentPerWeek,
    model,
    orderId,
    preApproved,
    removeKey,
    removeLoading,
    vasCartData
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    addVASItemConnect: addVASItem,
    removeVASItemConnect: removeVASItem,
    getVASCartConnect: getVASCart,
    showToastConnect: showToast,
    setCounterAnimationConnect: setCounterAnimation
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarPackagesRevamp);

CarPackagesRevamp.propTypes = {
    addKey: PropTypes.string,
    addLoading: PropTypes.bool,
    addVASItemConnect: PropTypes.func,
    appointmentId: PropTypes.string,
    bundles: PropTypes.array,
    content: PropTypes.object,
    getVASCartConnect: PropTypes.func,
    leastBundleRepaymentPerWeek: PropTypes.number,
    model: PropTypes.string,
    orderId: PropTypes.string,
    preApproved: PropTypes.bool,
    removeKey: PropTypes.string,
    removeLoading: PropTypes.bool,
    removeVASItemConnect: PropTypes.func,
    showToastConnect: PropTypes.func,
    setCounterAnimationConnect: PropTypes.func,
    vasCartData: PropTypes.object
};
