import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_VALUATION_DETAILS_REQUEST
    FETCH_VALUATION_DETAILS_SUCCESS
    FETCH_VALUATION_DETAILS_FAILURE
    FETCH_PICKUP_DETAILS_REQUEST
    FETCH_PICKUP_DETAILS_SUCCESS
    FETCH_PICKUP_DETAILS_FAILURE
    ACCEPT_SELL_ORDER_REQUEST
    ACCEPT_SELL_ORDER_SUCCESS
    ACCEPT_SELL_ORDER_FAILURE
    FETCH_SLOT_DETAILS_REQUEST
    FETCH_SLOT_DETAILS_SUCCESS
    FETCH_SLOT_DETAILS_FAILURE
    `,
    {
        prefix: "priceCommunication/"
    }
);
