/* eslint-disable complexity */
import React, { useEffect } from "react";
import styles from "./styles.css";
import dropDown from "./images/arrow-grey.svg";
import dropDownBlue from "./images/arrow-blue.svg";
import PropTypes from "prop-types";
import PlatinumCoverExpandedSection from "../add-ons-expanded-section";
import ComprehensiveCoverage from "../../comprehensive-coverage/component";
import Selected from "./images/selected.svg";
import Check from "./images/check.svg";
import { trackMobileCustomEventsAU } from "../../../../tracking";
import PLATINUM_COVER_GA_EVENTS from "../add-ons/tracking";
import { makePriceLabelRound } from "../../../../utils/helpers/make-price-label";

const AddOnsPlan = ({
    isExpanded,
    setExpandedSection,
    planDetails,
    expandedSection,
    itemNumber,
    isStepSkipped,
    isStepAdded,
    handleKnowMoreModal,
    isDisabled,
    isFinanceUser,
    setShowAddonsNudge
}) => {
    const {
        title,
        categoryKey,
        landingDescription,
        skipText,
        vasPackage,
        bannerText,
        bannerImage,
        explorePlanDescription,
        addedPackage,
        leastRepaymentsPerWeek
    } = planDetails?.product || {};

    const addedPlanText = addedPackage?.length && `${addedPackage[0]?.title || ""} ${isFinanceUser ? `(${makePriceLabelRound(addedPackage[0]?.repaymentsPerWeek)}/week)` : ""}`;

    useEffect(() => {
        if (isExpanded) {
            trackMobileCustomEventsAU("", {
                ...PLATINUM_COVER_GA_EVENTS.ADD_ONS_HOME_PAGE.PRODUCT_VIEWED,
                eventLabel: PLATINUM_COVER_GA_EVENTS.ADD_ONS_HOME_PAGE.PRODUCT_VIEWED.eventLabel(categoryKey)
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpanded]);

    const handleExpandedSection = () => {
        if (isExpanded) {
            setExpandedSection(null);
        } else {
            setExpandedSection(categoryKey);
        }
    };

    const handleModal = () => {
        trackMobileCustomEventsAU("", {
            ...PLATINUM_COVER_GA_EVENTS.KNOW_MORE_DETAILS,
            eventLabel: PLATINUM_COVER_GA_EVENTS.KNOW_MORE_DETAILS.eventLabel(categoryKey)
        });
        handleKnowMoreModal(categoryKey);
    };

    const isVisited = !isDisabled;
    const showKnowMore = ["CAR_SERVICING", "EXTENDED_WARRANTY_PLAN", "CAR_COVER_BUNDLES"].includes(categoryKey);

    return (
        <div
            styleName={"styles.outer"}
            style={{
                maxHeight: isExpanded ? `1000px` : "67px",
                transition: "max-height 1.25s ease"
            }}
        >
            <div styleName={"styles.topSection"}>
                <div
                    className="media"
                    styleName={`styles.mediaContainer ${isVisited ? "styles.activeSection" : "styles.disabledSection"}`}
                >
                    {isStepAdded ? (
                        <img src={Selected} height={24} width={24} alt="Checkbox" />
                    ) : (
                        <p styleName={"styles.stepsCount"}>{itemNumber}</p>
                    )}
                    <div className="media-body">
                        <p styleName={"styles.heading"}>
                            {title}
                            <img
                                src={isVisited ? dropDownBlue : dropDown}
                                alt="Accordion image"
                                style={{ transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)" }}
                                onClick={handleExpandedSection}
                            />
                        </p>
                        {
                            isStepAdded ? <p styleName={"styles.bulletPoint"}>
                                <img src={Check} /> {addedPlanText}
                            </p> : null
                        }
                    </div>
                </div>
                {isExpanded && !isStepAdded && <p styleName={"styles.warrantyText"}>{landingDescription}</p>}
                {isExpanded && (
                    <div styleName={"styles.comprehensiveCard"}>
                        <ComprehensiveCoverage
                            showKnowMore={showKnowMore}
                            onKnowMoreClick={handleModal}
                            bannerText={bannerText}
                            bannerImage={bannerImage}
                        />
                    </div>
                )}
            </div>
            <div>
                <PlatinumCoverExpandedSection
                    isExpanded={isExpanded}
                    productList={vasPackage}
                    planKey={categoryKey}
                    skipText={skipText}
                    setExpandedSection={setExpandedSection}
                    expandedSection={expandedSection}
                    isStepSkipped={isStepSkipped}
                    isStepAdded={isStepAdded}
                    additionalInfoText={explorePlanDescription}
                    handleKnowMoreModal={handleKnowMoreModal}
                    categoryKey={categoryKey}
                    bannerText={bannerText}
                    bannerImage={bannerImage}
                    isFinanceUser={isFinanceUser}
                    setShowAddonsNudge={setShowAddonsNudge}
                    leastRepaymentsPerWeek={leastRepaymentsPerWeek}
                />
            </div>

        </div>
    );
};

AddOnsPlan.propTypes = {
    handleKnowMoreModal: PropTypes.func,
    planDetails: PropTypes.object,
    isExpanded: PropTypes.bool,
    setExpandedSection: PropTypes.func,
    navigateToNextScreen: PropTypes.func,
    planKey: PropTypes.string,
    title: PropTypes.string,
    expandedSection: PropTypes.string,
    itemNumber: PropTypes.number,
    isStepSkipped: PropTypes.bool,
    isStepAdded: PropTypes.bool,
    isDisabled: PropTypes.bool,
    bannerText: PropTypes.string,
    bannerImage: PropTypes.string,
    isFinanceUser: PropTypes.bool,
    setShowAddonsNudge: PropTypes.func
};

export default AddOnsPlan;
