import React, { useState } from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";
import Modal from "../../shared/modal";
import CloseButton from "../../shared/icon-cross";
import H2Regular from "../../shared/h2-regular/component";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import BlueArrow from "./images/blue-arrow.svg";
const DeliveryDriveAwayPriceModal = ({ summary, onClose }) => {
    const [isAccordianOpen, setIsAccordianOpen] = useState(null);
    const modalTitle = summary?.title || summary?.aggregatedItem?.label;
    const isTypeAccordian = (item) => {
        return item?.summary && Object.keys(item?.summary || {}).length > 0 && item?.summary?.viewType === "ACCORDION";
    };

    const handleAccordianClick = (item) => {
        if (isAccordianOpen === item?.key) {
            setIsAccordianOpen(null);
        } else {
            setIsAccordianOpen(item?.key);
        }
    };

    const renderSubItems = (summaryItem) => {
        return (
            <div styleName={"styles.secondSectionWrap"}>
                {summaryItem?.lineItems?.map((subItem) => (
                    <div styleName={"styles.subItemElement"}>
                        <div styleName={"styles.heading"}>
                            <p styleName={"styles.leftText"}>{subItem?.label}</p>
                            <p styleName={"styles.leftText"}>
                                {subItem?.type === "SUBTRACTION"
                                    ? `-${makePriceLabelRound(subItem?.amount)}`
                                    : makePriceLabelRound(subItem?.amount)}
                            </p>
                        </div>
                        {subItem?.disclaimers?.length > 0 && subItem.disclaimers.map((text, index) => <div key={index} styleName={"styles.subItemsDisclaimer"}>{text}</div>)}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <Modal isOpen={true} close={onClose}>
            <div styleName={"styles.car_cover_main"}>
                <div styleName={"styles.heading"}>
                    <div>
                        <H2Regular text={modalTitle} />
                        <p styleName="styles.paidDirectly">{summary?.subTitle || ""}</p>
                    </div>

                    <div styleName={"styles.closeWrapper"}>
                        <CloseButton type="grey" onClickHandler={onClose} />
                    </div>
                </div>
                <div styleName={"styles.contentWrap"}>
                    <div styleName={"styles.priceBreakupWrapper"}>
                        {(summary?.lineItems || []).map(
                            (item) =>
                                item.amount >= 0 && (
                                    <React.Fragment key={item?.key}>
                                        <div styleName={"styles.firstSectionWrapper"}>
                                            <p
                                                styleName={
                                                    isTypeAccordian(item)
                                                        ? "styles.accordianLabelSection"
                                                        : "styles.leftText "
                                                }
                                                onClick={() => isTypeAccordian(item) && handleAccordianClick(item)}
                                            >
                                                {item?.label}
                                                {isTypeAccordian(item) && <img src={BlueArrow} alt="blue-arrow" />}
                                            </p>
                                            <p styleName={`styles.leftText ${item?.type === "FREE" ? "styles.typeFreeLabelbreakDown" : ""}`}>{makePriceLabelRound(item?.amount)}</p>
                                        </div>
                                        {isAccordianOpen === item?.key && renderSubItems(item?.summary)}
                                    </React.Fragment>
                                )
                        )}
                        <div styleName={"styles.firstSectionWrapper styles.border"}>
                            <p styleName={"styles.totalText"}>{"Total"}</p>
                            <p styleName={"styles.totalPrice"}>
                                {makePriceLabelRound(Math.abs(summary?.aggregatedItem?.amount))}
                            </p>
                        </div>
                    </div>
                    <div styleName={"styles.driveAwayModalbottomTncContainer"}>
                        {Object.keys(summary?.termsAndConditions || {}).length > 0 &&
                            (summary?.termsAndConditions || []).map((tnc, index) => (
                                <div styleName={"styles.bottomBox"} key={index}>
                                    <p styleName={"styles.bottomText"}>{tnc}</p>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

DeliveryDriveAwayPriceModal.propTypes = {
    summary: PropTypes.object,
    onClose: PropTypes.func
};

export default DeliveryDriveAwayPriceModal;
