/* eslint-disable max-len */
import { appUrl, currentEnv, ppApiUrl } from "./url-constants";

export const NUMBER = {
    ZERO: 0,
    DECIMAL_FIVE: 0.5,
    DECIMAL_ZERO_FIVE: 0.05,
    DECIMAL_EIGHT: 0.8,
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
    SEVEN: 7,
    EIGHT: 8,
    NINE: 9,
    TEN: 10,
    TWELVE: 12,
    THIRTEEN: 13,
    FOURTEEN: 14,
    FIFTEEN: 15,
    SIXTEEN: 16,
    EIGHTEEN: 18,
    NINETEEN: 19,
    TWENTY: 20,
    TWENTY_TWO: 22,
    TWENTY_FOUR: 24,
    TWENTY_FIVE: 25,
    TWENTY_EIGHT: 28,
    TWENTY_NINE: 29,
    THIRTY: 30,
    THIRTY_FOUR: 34,
    FOURTY: 40,
    FIFTY: 50,
    SIXTY: 60,
    HUNDRED: 100,
    ONE_HUNDRED_FIFTY: 150,
    TWO_HUNDRED: 200,
    TWO_HUNDRED_EIGHTY: 280,
    ONE_HUNDRED_EIGHTY: 180,
    THREE_HUNDRED: 300,
    FIVE_HUNDRED: 500,
    SEVEN_HUNDRED: 700,
    THOUSAND: 1000,
    ONE_THOUSAND_TWENTY: 1020,
    ONE_THOUSAND_TWENTY_ONE: 1021,
    ONE_THOUSAND_TWENTY_TWO: 1022,
    ONE_THOUSAND_THIRTY_ONE: 1031,
    ONE_THOUSAND_THIRTY_TWO: 1032,
    ONE_THOUSAND_FIFTY_ONE: 1051,
    ONE_THOUSAND_FIFTY_FOUR: 1054,
    ONE_THOUSAND_FIFTY_FIVE: 1055,
    TWO_THOUSAND: 2000,
    THREE_THOUSAND: 3000,
    FOUR_THOUSAND: 4000,
    FIVE_THOUSAND: 5000,
    TEN_THOUSAND: 10000,
    TWENTY_FIVE_THOUSAND: 25000,
    FIFTY_THOUSAND: 50000,
    ONE_LAKH: 100000,
    TEN_LAKH: 1000000,
    THIRTY_LAKH: 3000000,
    NINETY_NINE_LAKH: 9900000,
    TWENTY_ONE: 21,
    TWENTY_SIX: 26,
    THIRTY_ONE: 31,
    THIRTY_SIX: 36,
    FOURTY_ONE: 41,
    FOURTY_SIX: 46,
    NINETEEN_SEVENTY: 1970,
    MINUS_TWENTY: -20,
    TWENTY_THOUSAND: 20000,
    FIFTY_FIVE_HUNDRED: 5500,
    FIFTEEN_HUNDRED: 1500,
    ONE_THOUSAND_TWELVE: 1012,
    ONE_THOUSAND_FOURTEEN: 1014,
    ONE_THOUSAND_THIRTEEN: 1013,
    ONE_THOUSAND_TWENTY_FIVE: 1025,
    ONE_THOUSAND_TWENTY_SEVEN: 1027,
    MINUS_THREE: -3,
    THOUSAND_SEVEN: 1007,
    SIXTY_FIVE_THOUSAND: 65000,
    ONE_LAKH_FIFTY_THOUSAND: 150000,
    THREE_LAKH_FIFTY_THOUSAND: 350000,
    FOUR_HUNDRED_FOUR: 404,
    FOUR_HUNDRED_ONE: 401,
    THOUSAND_THREE: 1003,
    NINTY_NINE: 99,
    FOUR_HUNDRED: 400,
    TWO_THOUSAND_FOURTEEN: 2014,
    FOURTEEN_LAKH: 140000,
    ONE_LAKH_FORTY_THOUSAND: 140000
};

export const resetReducerKeys = {
    RESET_CAR_FINDER: "RESET_CAR_FINDER",
    RESET_BRANDS: "RESET_BRANDS",
    RESET_MODELS: "RESET_MODELS",
    RESET_YEARS: "RESET_YEARS",
    RESET_VARIANTS: "RESET_VARIANTS",
    RESET_REGISTRATION_STATES: "RESET_REGISTRATION_STATES",
    RESET_KILOMETERS_DRIVEN: "RESET_KILOMETERS_DRIVEN",
    RESET_BLOGS: "RESET_BLOGS",
    RESET_TESTIMONIALS: "RESET_TESTIMONIALS",
    RESET_EXPERTS: "RESET_EXPERTS",
    RESET_FAQS: "RESET_FAQS",
    RESET_RECENTLY_INSPECTED: "RESET_RECENTLY_INSPECTED",
    RESET_FOOTER: "RESET_FOOTER",
    META_TAGS: "RESET_META_TAGS",
    RESET_NEWS: "RESET_NEWS",
    RESET_LEAD_DATA: "RESET_LEAD_DATA",
    RESET_SEO_MODELS: "RESET_SEO_MODELS",
    RESET_BRANCH_LOCATION: "RESET_BRANCH_LOCATION",
    RESET_CITIES: "RESET_CITIES",
    RESET_APPOINTMENT_LOCATION: "RESET_APPOINTMENT_LOCATION",
    RESET_APPOINTMENT_DETAILS: "RESET_APPOINTMENT_DETAILS",
    RESET_USER: "RESET_USER",
    RESET_LOCATION: "RESET_LOCATION",
    RESET_RESCHEDULE: "RESET_RESCHEDULE",
    RESET_CONFIRMATION_DETAILS: "RESET_CONFIRMATION_DETAILS",
    RESET_SETTINGS: "RESET_SETTINGS",
    RESET_WIDGET: "RESET_WIDGET",
    RESET_REVIEW: "RESET_REVIEW",
    RESET_BRANCH_LOCATOR: "RESET_BRANCH_LOCATOR",
    RESET_ONE_STEP_APPOINTMENT: "RESET_ONE_STEP_APPOINTMENT",
    RESET_REVIEW_DETAILS: "RESET_REVIEW_DETAILS",
    RESET_REVIEW_SUBMISSION: "RESET_REVIEW_SUBMISSION",
    RESET_RC_TRANSFER: "RESET_RC_TRANSFER",
    RESET_TOP_SELLING_USED_CARS: "RESET_TOP_SELLING_USED_CARS",
    RESET_NEED_HELP: "RESET_NEED_HELP",
    RESET_SEO_TESTIMONIALS: "RESET_SEO_TESTIMONIALS",
    RESET_RATED_TESTIMONIALS: "RESET_RATED_TESTIMONIALS",
    RESET_BRANCH_FILTER: "RESET_BRANCH_FILTER",
    RESET_TRUECALLER: "RESET_TRUECALLER",
    RESET_RTO_INFO: "RESET_RTO_INFO",
    RESET_LEAD_BRANCHES: "RESET_LEAD_BRANCHES",
    RESET_LEAD_BRANCH_DATE: "RESET_LEAD_BRANCH_DATE",
    RESET_LEAD_PROGRESS_BAR: "RESET_LEAD_PROGRESS_BAR",
    RESET_SEO_DETAILS: "RESET_SEO_DETAILS",
    RESET_AB_EXPERIMENTS: "RESET_AB_EXPERIMENTS",
    RESET_CAR_LIST: "RESET_CAR_LIST",
    RESET_FILTERS: "RESET_FILTERS",
    RESET_CONFIG: "RESET_CONFIG",
    RESET_FUEL_PRICE: "RESET_FUEL_PRICE",
    RESET_LEAD_RTO: "RESET_LEAD_RTO",
    RESET_CAR_DETAILS: "RESET_CAR_DETAILS",
    RESET_EXPLORE_USED_CARS: "RESET_EXPLORE_USED_CARS",
    RESET_SIMILAR_CARS: "RESET_SIMILAR_CARS",
    RESET_RECENTLY_VIEWED_CARS: "RESET_RECENTLY_VIEWED_CARS",
    RESET_SELECT_OFFER_PRICE: "RESET_SELECT_OFFER_PRICE",
    RESET_PARKING_YARD: "RESET_PARKING_YARD",
    FEATURED_CARS: "FEATURED_CARS",
    RESET_CONTENT_HOOK: "RESET_CONTENT_HOOK",
    RESET_LEGACY_LEAD_FORM: "RESET_LEGACY_LEAD_FORM",
    RESET_SELL_BIKE: "RESET_SELL_BIKE",
    RESET_SCRIPT_LOADER: "RESET_SCRIPT_LOADER",
    RESET_REFER: "RESET_REFER",
    RESET_FRANCHISE_FORM: "RESET_FRANCHISE_FORM",
    RESET_PARTNER_FORM: "RESET_PARTNER_FORM",
    RESET_LOAN_FORM: "RESET_LOAN_FORM",
    RESET_BIKE_FORM: "RESET_BIKE_FORM",
    RESET_BIKE_BRANDS: "RESET_BIKE_BRANDS",
    RESET_BIKE_BRANCHES: "RESET_BIKE_BRANCHES",
    RESET_BIKE_BRANCH_DATE: "RESET_BIKE_BRANCH_DATE",
    RESET_CLASSIFIED: "RESET_CLASSIFIED",
    MAKE_AN_OFFER: "MAKE_AN_OFFER",
    USED_CAR_LEAD_INFO: "USED_CAR_LEAD_INFO",
    RESET_MARKETING_LEAD_FORM: "RESET_MARKETING_LEAD_FORM",
    RESET_LEAD_BRANCH_SELECTIONS: "RESET_LEAD_BRANCH_SELECTIONS",
    RESET_TOP_SELLING_CARS: "RESET_TOP_SELLING_CARS",
    RESET_FEATURED_CARS: "RESET_FEATURED_CARS",
    RESET_EXCHANGE_CAR_DETAILS: "RESET_EXCHANGE_CAR_DETAILS",
    RESET_TOP_DEALS_REDUCER: "RESET_TOP_DEALS_REDUCER",
    RESET_LISTING_INFO: "RESET_LISTING_INFO",
    RESET_LOAN_CALCULATION_DATA: "RESET_LOAN_CALCULATION_DATA",
    RESET_BUYER_SELLER_DETAIL: "RESET_BUYER_SELLER_DETAIL",
    RESET_BIKE_RESCHEDULE: "RESET_BIKE_RESCHEDULE",
    RESET_BIKE_CONFIRMATION_DETAILS: "RESET_BIKE_CONFIRMATION_DETAILS",
    RESET_WISHLISTED_CARS: "RESET_WISHLISTED_CARS",
    RESET_WISHLISTED_LOGIN: "RESET_WISHLISTED_LOGIN",
    RESET_TOAST_MESSAGE: "RESET_TOAST_MESSAGE",
    RESET_CAR_FINDER_LISTING: "RESET_CAR_FINDER_LISTING",
    RESET_CAR_FINDER_CAR_LIST: "RESET_CAR_FINDER_CAR_LIST",
    RESET_CAR_SERVICES_DATA: "RESET_CAR_SERVICES_DATA",
    RESET_CAR_SERVICE_LEAD_FORM: "RESET_CAR_SERVICE_LEAD_FORM",
    RESET_CAR_SERVICE_INFO: "RESET_CAR_SERVICE_INFO",
    RESET_CAR_SERVICE_BOOK: "RESET_CAR_SERVICE_BOOK",
    RESET_LEAD_FUEL_TYPE: "RESET_LEAD_FUEL_TYPE",
    RESET_DEAL_PAYMENT_INFO: "RESET_DEAL_PAYMENT_INFO",
    RESET_CARS24_SELECT: "RESET_CARS24_SELECT",
    RESET_GS_BUY_CAR_DETAILS: "RESET_GS_BUY_CAR_DETAILS",
    RESET_CONSUMER_FINANCE: "RESET_CONSUMER_FINANCE",
    RESET_MY_BOOKINGS: "RESET_MY_BOOKINGS",
    RESET_LOCATION_PICKER_MODAL: "RESET_LOCATION_PICKER_MODAL",
    RESET_CHECKOUT: "RESET_CHECKOUT",
    RESET_CHAT_ICON: "RESET_CHAT_ICON",
    RESET_CALLBACK_DETAILS: "RESET_CALLBACK_DETAILS",
    RESET_C24Q_PRICE: "RESET_C24Q_PRICE",
    RESET_CUSTOMER_CAR_INSPECTION_PARAMETER: "RESET_CUSTOMER_CAR_INSPECTION_PARAMETER",
    SAVED_FILTERS: "SAVED_FILTERS",
    RESET_RECOMMENDED_CARS: "RESET_RECOMMENDED_CARS",
    RESET_DELIVERY_INFO: "RESET_DELIVERY_INFO",
    RESET_HOME_LIST: "RESET_HOME_LIST",
    RESET_SITEMAP_LIST: "RESET_SITEMAP_LIST",
    RESET_NUDGE_LIST: "RESET_NUDGE_LIST"
};

export const stringConstants = {
    YES: "yes",
    NO: "no"
};

//added undefiend for local env - has to tbe removed
const baseUrl = {
    privateProduct: {
        QA: "https://consumer-private-web-qa.ninja24.in/account",
        STAGING: "https://stage-c2b-website.qac24svc.dev/account",
        LNOD: "https://stage-c2b-website.qac24svc.dev/account",
        PRODUCTION: "https://www.cars24.com/account"
    },
    c2c: {
        QA: "http://c2cweb-qa.ninja24.in/buy-used-cars",
        STAGING: "https://stage-c2b-website.qac24svc.dev/buy-used-cars",
        // STAGING: "http://localhost:3000//buy-used-cars",
        LNOD: "https://stage-c2b-website.qac24svc.dev/buy-used-cars",
        PRODUCTION: "https://www.cars24.com/buy-used-cars"
    },
    c2bPHP: {
        STAGING: "https://staging.cars24.com",
        PRODUCTION: "https://www.cars24.com"
    }
};

export const redirect = {
    AUTH_LOGIN: `${baseUrl.privateProduct[currentEnv]}/login`,
    AUTH_APPOINTMENTS: `${baseUrl.privateProduct[currentEnv]}/appointments`,
    AUTH_BIDS: `${baseUrl.privateProduct[currentEnv]}/bids`,
    AUTH_PROFILE: `${baseUrl.privateProduct[currentEnv]}/profile`,
    c2C: (path = "") => `${baseUrl.c2c[currentEnv]}${path}`,
    CARS24_HOME: appUrl,
    BYE_BYE_DRIVE: `${appUrl()}/byebyedrive/`,
    BLOG: `${appUrl()}/blog`,
    RC_TRANSFER: `${appUrl()}/rc-transfer`,
    HOW_IT_WORKS: `${appUrl()}/how-it-works`,
    WE_ARE_HIRING: "https://cars24.recruitee.com/",
    FAQ: `${appUrl()}/faq`,
    FAQ_AE: `${appUrl()}/ae/faq`,
    HELP: `${appUrl()}/need-help`,
    BECOME_OUR_PARTNER: `${appUrl()}/become-our-partner`,
    BRANCHES: `${appUrl()}/centres`,
    CAR_LOAN: `${appUrl()}/car-loan`,
    TESTIMONIALS: `${appUrl()}/testimonials/`,
    GOOGLE_PLAY_STORE_APP: "https://play.google.com/store/apps/details?id=com.cars24.seller&hl=en_IN",
    APPLE_STORE_APP: "https://itunes.apple.com/in/app/cars24-sell-car-at-best-price/id1364492085?mt=8",
    FINANCIAL_SERVICES_TERMS_CONDITIONS: "https://img.24c.in/cars24/docs/finance/terms_&_conditions.pdf",
    CAR_SERVICE: `${appUrl()}/car-service?itm_source=car_service&itm_medium=nav_bar`,
    MY_BOOKINGS: `${appUrl()}/my-bookings`
};

export const OTP_MAX_LENGTH = 4;
export const OTP_MAX_LENGTH_EMAIL = 6;

export const HTTP_CODES = {
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    SUCCESS: 200,
    FOUND: 302,
    MOVED_PERMANENTLY: 301
};

export const LOCAL_STORAGE_KEYS = {
    userProfile: "userProfile"
};

export const FEATURE_FLAG_KEYS = {
    KBB_FEATURE_FLAG: "kbbFeatureFlag"
};

export const COOKIE_SEARCH_KEYWORDS = "c2b-searched-keywords";

export const IGNORE_PRIVATE_PRODUCT_APIS = [`${ppApiUrl()}/pp/ums/users`];

export const LEAD_FORM = {
    DEFAULT: "default",
    ONE_STEP: "oneStep",
    BIKE: "bike",
    MARKETING: "marketing",
    CARFINDER: "carfinder",
    CAR_SERVICE: "carService",
    FINANCE_FORM: "financeForm"
};

export const LOCATION_REQUEST_TYPE = {
    COORDINATES: "coordinates",
    PLACE: "place"
};

export const MAP_TYPE = {
    GOOGLE: "googlemap",
    MMI: "mapmyindia"
};

export const DISPOSITION_STATUS = {
    SOLD: "SOLD",
    PUBLISHED: "PUBLISHED",
    BOOKED: "BOOKED",
    LOST: "LOST",
    RE_PUBLISH: "RE-PUBLISH",
    RE_PUBLISH_1: "RE_PUBLISH",
    AUCTIONED: "AUCTIONED",
    REJECTED: "REJECTED",
    DRAFT: "DRAFT"
};

export const DEFAULT_C2C_CITY = {
    city_id: "8597",
    city_name: "Delhi-NCR",
    city_slug: "delhi-ncr",
    pincode: "110001"
};

export const AD_TYPE = {
    INSTANTLY_AVAILABLE: "instantlyAvailable",
    MONEYBACK_GUARANTEE: "moneybackGuarantee",
    GET_CAR_PRICE: "getCarPrice",
    FINANCE_AVAILABLE: "financeAvailable",
    WARRANTY_AVAILABLE: "warrantyAvailable",
    PRICE_DROP_AVAILABLE: "priceDropAvailable",
    VIEW_360_AVAILABLE: "view360Available",
    CAR_FINDER: "carFinder",
    DEAL_RATING: "dealRating"
};

export const CAR_LIST_EVENT_NAMES = {
    LISTING: "Buy_used_cars_C2C_Car_Listing_Page",
    B2C_LISTING: "Buy_used_cars_B2C_Car_Listing_Page",
    MONEYBACK: "Buy_used_cars_C2C_Car_Listing_Page_Moneyback",
    INSTANTLY_AVAILABLE: "Buy_used_cars_C2C_Car_Listing_Page_Instantly_Available",
    FINANCE_AVAILABLE: "Buy_used_cars_C2C_Car_Listing_Page_Finance_Available",
    PRICE_DROP: "Buy_used_cars_C2C_Car_Listing_Page_Price_Drop",
    HOME_PAGE: "Buy_used_cars_C2C_Car_Home_Page",
    B2C_HOME_PAGE: "Buy_used_cars_B2C_Homepage",
    FILTER_PAGE: "Buy_used_cars_C2C_filter_page",
    DETAILS: "Buy_used_cars_C2C_Car_Details_Page",
    VIEW_360_AVAILABLE: "Buy_used_cars_C2C_Car_Listing_Page_View_360_Available",
    WHISLIST: "Buy_used_cars_C2C_Whislist_page",
    DETAIL_SIMILAR_CARS: "Buy_used_cars_C2C_Car_detail_page_similiar_cars"
};

export const AB_TEST_VARIATIONS = {
    CONTROL: "CONTROL",
    VARIANT: "VARIANT",
    VARIANT_A: "VARIANT_A",
    VARIANT_B: "VARIANT_B"
};

export const TEST_DRIVE_BOOKING_STATUS_MESSAGE = {
    BOOKED: "Test drive booked",
    COMPLETED: "Test drive conducted",
    CANCELLED: "Test drive has been cancelled",
    RESCHEDULED: "",
    CANCELLING: ""
};

export const TEST_DRIVE_BOOKING_STATUS_TYPE = {
    BOOKED: "BOOKED",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
    RESCHEDULED: "RESCHEDULED",
    CANCELLING: "CANCELLING"
};

export const APPOINTMENT_TYPE = {
    BRANCH: "branch",
    HOME: "inspection",
    CRM: "CRM"
};

export const APPOINTMENT_SCREEN = {
    DEFAULT: "default",
    ADDRESS: "address",
    DOCUMENT: "document"
};

export const FORM_TYPE = {
    CAR: "car",
    BIKE: "bike",
    CLASSIFIED_RESCHEDULE: "classified_reschedule",
    ONE_STEP: "one_step",
    BIKE_RESCHEDULE: "bike_reschedule",
    PAYWALL_BOOK_NOW: "paywall_book_now",
    PAYWALL_MAKE_OFFER: "paywall_make_offer",
    GS_BOOK_NOW: "gs_book_now",
    TOKEN_PAYMENT: "token_payment",
    VONE_STEP: "vone_step",
    CHECKOUT: "checkout"
};

export const INACTIVE_CAR_STATES = [
    DISPOSITION_STATUS.LOST,
    DISPOSITION_STATUS.SOLD,
    DISPOSITION_STATUS.DRAFT,
    DISPOSITION_STATUS.AUCTIONED,
    DISPOSITION_STATUS.REJECTED
];

export const COOKIE_KEYS = {
    CAR_SERVICE_FIRST_CLICK_URL: "car_service_first_click_url"
};

export const PAYMENT_OPTIONS = {
    FINANCE: "FINANCE",
    CASH: "CASH"
};

export const ANGLE = {
    ZERO: 0,
    NINETY: 90,
    HUNDRED_EIGHTY: 180,
    TWO_HUNDRED_SEVENTY: 270,
    THREE_HUNDRED_SIXTY: 360
};

export const DEFAULT_CITY = {
    AE: {
        city_id: "73662522",
        city_name: "Dubai",
        city_slug: "dubai",
        code: "CC_AE_1"
    },
    IN: {
        city_id: "8597",
        city_name: "Delhi-NCR",
        city_slug: "delhi-ncr"
    },
    AU: {
        city_id: "",
        city_name: "Brisbane",
        code: "BNE100",
        pincode: 4000,
        city_slug: "brisbane"
    }
};

export const COUNTRY = {
    name: "Australia",
    slug: "australia",
    id: "",
    code: "au"
};

export const CURRENCY = {
    IN: "₹",
    AE: "AED",
    AU: "$"
};

export const COUNTRY_CODE = {
    IN: "IN",
    AE: "AE",
    AU: "AU"
};

export const AU_HOMEPAGE_PAGINATION_SIZE = 6;
export const MY_BOOKINGS_PAGINATION_SIZE = 20;
export const AU_CITY = "au_city";
//todo: remove duplicated constant
export const DEFAULT_AU_CITY_CODE = DEFAULT_CITY.AU.code;
export const DEFAULT_AU_PINCODE = DEFAULT_CITY.AU.pincode;
export const DEFAULT_AU_CITY_NAME = DEFAULT_CITY.AU.city_name;

export const HELPLINE_NUMBER_AU = { label: "+61 482 091 942", value: "+61482091942 " };
export const SMS_NUMBER_AU = { label: "0488 825 496", value: "0488825496" };
export const SUPPORT_EMAIL = {
    label: "sellmycar.au@cars24.com",
    redirect: "mailto:sellmycar.au@cars24.com"
};

export const PHONE_COUNTRY_CODE = 61;

export const EXTERNAL_LINKS = {
    DRIVA_POLICY: "https://www.driva.com.au/legal",
    CAREERS_LINK: "https://www.linkedin.com/jobs/cars24-australia-jobs-worldwide?f_C=73063943&trk=top-card_top-card-primary-button-top-card-primary-cta&position=1&pageNum=0",
    WORKABLE_CAREER_LINK: "https://apply.workable.com/cars24/?lng=en"
};

export const API_SOURCE = {
    M_SITE: "mSite",
    WEB_APP: "WebApp"
};

export const PAYMENT_SURCHARGE = { value: 1.38, label: "1.38%" };

export const LOAN_PROVIDER = {
    DRIVA: "DRIVA",
    BYOF: "BYOF",
    NONE: "NONE"
};

export const PAYMENT_HISTORY_STATUS = {
    INITIATED: "INITIATED"
};

export const WARRANTY_TERMS_CONDITION = "https://drive.google.com/file/d/1mA5j2pdxXfEcnD5ysYXmL34GOcq43Tlb/view";

export const DEFAULT_CITY_PINCODE_MAP = {
    NSW: 2000,
    VIC: 3000,
    QLD: 4000,
    SA: 5000
};

export const SEARCH_ALL_CARS_ROUTE = `/buy-used-cars-${COUNTRY.slug}`;

export const ERROR_TYPES = {
    DEFAULT: "default",
    ODOMETER: "odometer",
    YEAR: "year",
    FINANCE: "finance",
    HIGH_VALUATION: "highValuation",
    CONDITION: "condition",
    DO_NOT_PROCURE: "doNotProcure",
    MAKE_MODEL_ERROR: "makeModelError",
    WRITTEN_OFF: "writtenOff",
    REGISTRATION: "registration"
};

export const ERROR_STATUS_MAP = {
    1013: ERROR_TYPES.DEFAULT,
    1020: ERROR_TYPES.ODOMETER,
    1021: ERROR_TYPES.YEAR,
    1022: ERROR_TYPES.FINANCE,
    1025: ERROR_TYPES.HIGH_VALUATION,
    1027: ERROR_TYPES.CONDITION,
    1028: ERROR_TYPES.DO_NOT_PROCURE,
    1030: ERROR_TYPES.MAKE_MODEL_ERROR
};

export const ERROR_GA_MAP = {
    [ERROR_TYPES.DEFAULT]: "default_rejection",
    [ERROR_TYPES.ODOMETER]: "odo_rejection",
    [ERROR_TYPES.YEAR]: "year_rejection",
    [ERROR_TYPES.FINANCE]: "finance_rejection",
    [ERROR_TYPES.HIGH_VALUATION]: "price_rejection",
    [ERROR_TYPES.CONDITION]: "condition_rejection",
    [ERROR_TYPES.DO_NOT_PROCURE]: "do_not_procure_rejection",
    [ERROR_TYPES.MAKE_MODEL_ERROR]: "make_model_rejection",
    [ERROR_TYPES.WRITTEN_OFF]: "written_off",
    [ERROR_TYPES.REGISTRATION]: "registration"
};

export const STEP_ROUTE_MAP = {
    SELECT_CAR_INFO: "car-details",
    VALUATION: "valuation",
    CAR_CONDITION: "exterior-condition",
    PRECONDITIONS: "pre-conditions",
    EXTERIOR_CONDITION: "exterior-condition",
    MECHANICAL_CONDITION: "mechanical-condition",
    INTERIOR_CONDITION: "interior-condition",
    CAR_CONDITION_AND_DETAILS: "exterior-condition",
    ESTIMATED_VALUATION: "estimated-valuation",
    ADDITIONAL_DETAILS: "extra-details"
};

export const INSPECTION_ROUTE_MAP = {
    SELECT_DAY: "select-day",
    PERSONAL_DETAILS: "personal-details"
};

export const TASK_SOURCES = {
    IN_HOUSE_FINANCE: "IN_HOUSE_FINANCE",
    DOC_SIGN: "DOC_SIGN",
    DMS: "DMS"
};

export const TASK_SOURCES_ALERT = {
    FINISH_LOAN_APPLICATION: "FINISH_LOAN_APPLICATION",
    UPLOAD_DOCUMENTS: "UPLOAD_DOCUMENTS",
    DL_VERIFY: "DL_VERIFY",
    SALE: "SALE",
    SPLIT_PAYMENT: "SPLIT_PAYMENT"
};

export const ENABLE_NEW_POST_BOOK_FLOW = {
    UPLOAD_DOCUMENTS: "UPLOAD_DOCUMENTS",
    DL_VERIFY: "DL_VERIFY",
    SPLIT_PAYMENT: "SPLIT_PAYMENT",
    SALE: "SALE"
};

export const CITY_WISE_CONTENT = {
    Brisbane: {
        top: {
            heading: "Sell used cars in Brisbane, 100% online",
            description: "Selling your car can be exhausting and stressful. No more wasting your time listing your car in every online marketplace and classifieds. No more waiting for calls and emails from 'potential buyers' who never respond and often never show up to inspect. No more questions from buyers who aren't serious about purchasing. Sell your car to CARS24 in Melbourne today, all online."
        },
        bottom: {
            heading: "How CARS24 decides the best price for your car",
            description: "When you sell your car with us, you can use our free car valuation calculator to find out how much your car is worth. To get an estimate, enter details such as your car model, make, mileage, physical condition, number of keys, damage, and imperfections. CARS24 provides a sophisticated tool for used car valuation to offer an estimate of your car's market value, which you can then use to compare rates at car dealers. It helps you determine the trade-in value for your car and answers your question of ‘What is the trade-in value for my car?’. Whether you want to sell your car in Australia or find cars for cash near you, CARS24 is a great choice to find car buyers online and for those looking to trade in their current car in lieu of buying the next one.",
            additionalText: ""
        },
        reviews: [{
            name: "Francesca K.",
            title: "Easy and quick sale…",
            description: "Easy and quick sale… would definitely recommend selling this way. I sold my car through Cars24 (I am based in Brisbane). The whole process was so easy and I am very happy with their offer for my car. Sagar (the CARS24 rep) kept me in the loop throughout, and everything was processed so quickly. A deal was agreed on, on the day of the inspection. A deposit was paid immediately, and the balance was deposited into my account the day before they collected the car. I am really impressed and would definitely recommend this method of selling a car to my friends. Do yourself a favour and don’t waste time haggling with people on selling sites. Thanks, Sagar for helping make the process so easy"
        },
        {
            name: "Michael Ward",
            title: "Sale of my 2016 Toyota Hilux Ute",
            description: "Sale of my 2016 Toyota Hilux Ute. Thank you Cars24 team for doing a fantastic job buying my car. I got the price I wanted and the process was seamless which I appreciate greatly. Kindest regards"
        },
        {
            name: "Ashlee",
            title: "Super easy hassle free!",
            description: "Super easy hassle free! Sold my car for a reasonable price, Cars24 handled all the logistics with zero hassle. Divya was very helpful and friendly. Would definitely use it again."
        }
        ],
        location: [
            {
                text: "40 Steel Pl, Morningside QLD 4170"
            }
        ]
    },
    Melbourne: {
        top: {
            heading: "Sell used cars in Melbourne, 100% online",
            description: "Selling your car can be exhausting and stressful. No more wasting your time listing your car in every online marketplace and classifieds. No more waiting for calls and emails from 'potential buyers' who never respond and often never show up to inspect. No more questions from buyers who aren't serious about purchasing. Sell your car to CARS24 in Melbourne today, all online."
        },
        bottom: {
            heading: "How CARS24 decides the best price for your car",
            description: "When you sell your car with us, you can use our free car valuation calculator to find out how much your car is worth. To get an estimate, enter details such as your car model, make, mileage, physical condition, number of keys, damage, and imperfections. CARS24 provides a sophisticated tool for used car valuation to offer an estimate of your car's market value, which you can then use to compare rates at car dealers. It helps you determine the trade-in value for your car and answers your question of ‘What is the trade-in value for my car?’. Whether you want to sell your car in Australia or find cars for cash near you, CARS24 is a great choice to find car buyers online and for those looking to trade in their current car in lieu of buying the next one.",
            additionalText: ""
        },
        reviews: [{
            name: "Alison G",
            title: "Selling my car to Cars24 was so easy!!",
            description: "Selling my car to Cars24 was so easy!! The whole experience of selling my car to Cars24 was very easy. I inquired about selling my car and that day Vish called me and organised a virtual inspection. This was done over video call. That afternoon he had already sent me the contract of sale and the next day the money was transferred into my bank account. The car was picked up the following day. So well organised and easy!"
        },
        {
            name: "Olivia",
            title: "So easy, I didn’t have to do a thing!",
            description: "So easy, I didn’t have to do a thing! Selling my car via Cars24 was so easy. I supplied a few details, answered some simple questions and they made me a competitive offer in no time. No haggling, no time wasters and no stress for me. The whole process was made easier as I didn’t need to do a thing - I didn’t even have to wash the car! Hassle free for me , I’d definitely recommend selling via Cars24."
        },
        {
            name: "Suzana Anna Djordj",
            title: "Thank You Cars24!!",
            description: " Selling my Toyota Corolla was simply amazing, Thank You Cars24!! Thank you Noreen!! First time user of Cars24 and all I can say is, it’s absolutely the best way to sell a car. From start to finish the process was so easy, no stress, no headaches, Ms Noreen was simply wonderful, very patient with me, very professional and explained the process thoroughly.  I can honestly say I recommend this service 110% to everyone who has a car to sell. Thank you again Ms Noreen, I’ve not had such outstanding customer service in a while!!"
        }
        ],
        location: [
            {
                text: "118 Cherry Ln, Laverton North VIC 3026"
            }
        ]

    },
    Sydney: {
        top: {
            heading: "Sell used cars in Sydney, 100% online",
            description: "Selling your car can be exhausting and stressful. No more wasting your time listing your car in every online marketplace and classifieds. No more waiting for calls and emails from 'potential buyers' who never respond and often never show up to inspect. No more questions from buyers who aren't serious about purchasing. Sell your car to CARS24 in Melbourne today, all online."
        },
        bottom: {
            heading: "How CARS24 decides the best price for your car",
            description: "When you sell your car with us, you can use our free car valuation calculator to find out how much your car is worth. To get an estimate, enter details such as your car model, make, mileage, physical condition, number of keys, damage, and imperfections. CARS24 provides a sophisticated tool for used car valuation to offer an estimate of your car's market value, which you can then use to compare rates at car dealers. It helps you determine the trade-in value for your car and answers your question of ‘What is the trade-in value for my car?’. Whether you want to sell your car in Australia or find cars for cash near you, CARS24 is a great choice to find car buyers online and for those looking to trade in their current car in lieu of buying the next one.",
            additionalText: ""
        },
        reviews: [{
            name: "Timothy",
            title: "Easy, hassle free process!",
            description: "Easy, hassle free process! The process of selling my car happened in a matter of days. Noreen, who accompanied the process, exceeded my expectations. Overall satisfied with the service!"
        },
        {
            name: "Fernando Jose Belchior dos Santos",
            title: "Sale of my 2016 Toyota Hilux Ute",
            description: " I really liked all the work done by Cars24. From the valuation to vehicle pick-up everything was smooth and hassle free."
        },
        {
            name: "Bennette",
            title: "Reliable, fast and simple",
            description: "I feel it very simple and fast, reliable, especially with the communication with Miss Noreen, she shows her profession, patience to answer my lots of questions as its my first time selling car through online. if my friends would like to buy and sell the car, I could recommend cars24 to them."
        }
        ],
        location: [
            {
                text: "155 Parramatta Rd, Granville NSW 2142"
            }
        ]
    },
    default: {
        reviews: [{
            name: "Glenn",
            title: "Incredibly easy to sell",
            description: "Incredibly easy to sell. Gave me the best price out of any quotes I had received, and the sales process could not have been quicker or simpler. Massive kudos to this company and for Connor who assisted. Dream transaction!"
        },
        {
            name: "Rachelle",
            title: "I sold my car in a matter of days!",
            description: " I sold my car in a matter of days! I can’t believe how easy the whole process was. Virtual inspection was organised for the next day following the initial contact. I was offered a good price then and there. Deposit transferred immediately and pick up organised, full balance received the day before pick up. I only had to sign and hand over the keys! The best part of all of this was I didn’t have to worry about strangers coming to look at the car, haggling prices and taking time out of my day to sell my car. The easiest and most stress free process, extremely happy with Cars24!"
        },
        {
            name: "Hannah T",
            title: "Great, simple process",
            description: "Really easy process. Great, simple process. My car was sold very quickly- the virtual inspection was easy and the lady talked me through exactly what I needed to do. An offer was made immediately and payment came through promptly. Seamless process. One which I’d do again for sure!"
        }
        ],
        location: [
            {
                text: "<span>Melbourne:</span> 118 Cherry Ln, Laverton North VIC 3026"
            },
            {
                text: "<span>Brisbane:</span> 40 Steel Pl, Morningside QLD 4170"
            },
            {
                text: "<span>Sydney:</span> 155 Parramatta Rd, Granville NSW 2142"
            }
        ]
    }
};

export const SHAZAM_FAQ = { //move duplicate
    key: "shazamFaq",
    heading: "How can I enter the SHAZAM! FURY OF THE GODS offer? ",
    data: ["Entry is only open to NSW, QLD and VIC residents who are 18 years and over and holds a valid drivers licence issued in an Australian State or Territory AND be the registered owner of the vehicle submitted for valuation."],
    listPoints: [
        "All completed valuations 1-16th March will automatically enter the prize draw to WIN 1 of 10 double cinema passes to see ‘Shazam! Fury of the Gods’.",
        "All completed valuations 1-31st March will automatically enter the prize draw to WIN a used Mini Cooper S 2014 with automatic transmission, including a minimum of 3-months registration, a minimum 3-months compulsory third party insurance, stamp duty and dealer delivery charges (which may vary from State by State)."
    ]
};

export const GUEST_POPUP_TYPES = {
    EMAIL: "email",
    VERIFY: "verify"
};

export const OPTIONS_LIST_TYPE = {
    LIST: "list",
    POPULAR: "popular"
};

export const UTM_PARAMS_KEY = "utm__params";

export const TRADE_STATE = [ //move
    {
        stateCode: "AU_NSW ",
        stateName: "New South Wales",
        label: "NSW",
        value: "New South Wales"
    },
    {
        stateCode: "AU_QLD",
        stateName: "Queensland",
        label: "QLD",
        value: "Queensland"
    },
    {
        stateCode: "AU_NTR",
        stateName: "Northern Territory",
        label: "NT",
        value: "Northern Territory"
    },
    {
        stateCode: "AU_CAPTR",
        stateName: "Australian Capital Territory",
        label: "ACT",
        value: "Australian Capital Territory"
    },
    {
        stateCode: "AU_SAU",
        stateName: "South Australia",
        label: "SA",
        value: "South Australia"
    },
    {
        stateCode: "AU_TAS",
        stateName: "Tasmania",
        label: "TAS",
        value: "Tasmania"
    },
    {
        stateCode: "AU_VIC",
        stateName: "Victoria",
        label: "VIC",
        value: "Victoria"
    },
    {
        stateCode: "AU_WEST",
        stateName: "Western Australia",
        label: "WA",
        value: "Western Australia"
    }
];

export const BREADCRUMB_DATA = [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "Sell my car",
        path: "/sell-your-car/"
    }
];

export const LOGIN_SOURCE = {
    WISHLIST: "wishlist",
    FINANCE: "finance",
    TRADE_IN: "tradein",
    CHECKOUT: "checkout",
    HAMBURGER: "hamburger"

};

export const CDP_REDIRECTION_KEY = "c2b_cdp_redirection";
