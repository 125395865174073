import React, { useState } from "react";
import { CALLBACK_SOURCE_SCREEN, NUMBER } from "../../../constants/app-constants";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { checkoutCallbackRequest } from "../checkout-info/actions";
import styles from "./styles.css";

const withCallbackRequest = (Component, sourceScreen = CALLBACK_SOURCE_SCREEN.LENDER_DETAILS, params = {}) => {
    const WithCallbackRequest = (props) => {
        const [showRequestAlreadyReceivedModal, setShowRequestAlreadyReceivedModal] = useState(false);
        const [showRequestAlreadyReceivedModalText, setShowRequestAlreadyReceivedModalText] = useState(false);
        const { checkoutCallbackRequestConnect, checkoutCallbackRequestExpired, mobile, callbackRequest } = props;

        const handleCallbackRequest = async () => {

            if (!checkoutCallbackRequestExpired) {
                setShowRequestAlreadyReceivedModalText(true);
                window.setTimeout(() => {
                    setShowRequestAlreadyReceivedModalText(false);
                }, NUMBER.THREE_THOUSAND);
            } else {
                try {
                    //get screen name
                    await checkoutCallbackRequestConnect(sourceScreen, params);
                    setShowRequestAlreadyReceivedModal(true);
                    window.setTimeout(() => {
                        setShowRequestAlreadyReceivedModal(false);
                    }, NUMBER.FOUR_THOUSAND_FIVE_HUNDRED);
                } catch (error) {
                    //console.log(error);
                }
            }
        };

        return (<React.Fragment><Component {...props} handleCallbackRequest={handleCallbackRequest} callbackRequest={callbackRequest} />
            {showRequestAlreadyReceivedModal &&
                <div styleName={"styles.alertmsgBox"}>
                    <p styleName={"styles.requestRaised"}>We will give you a call on {mobile} shortly</p>
                </div>
            }

            {showRequestAlreadyReceivedModalText &&
                <div styleName={"styles.alertmsgBox"}>
                    <p styleName={"styles.requestRaised"}>Request already received. Please wait for our call</p>
                </div>
            }
        </React.Fragment>);
    };
    const mapStateToProps = ({
        carDetails: { config },
        user: { mobile},
        checkout: {
            callbackDetails: {
                isExpired,
                requested
            } }

    }) => ({
        config,
        mobile,
        checkoutCallbackRequestExpired: isExpired,
        callbackRequest: requested
    });

    const mapDispatchToProps = (dispatch) => bindActionCreators({
        checkoutCallbackRequestConnect: checkoutCallbackRequest
    }, dispatch);

    WithCallbackRequest.propTypes = {
        checkoutCallbackRequestConnect: PropTypes.func,
        checkoutCallbackRequestExpired: PropTypes.bool,
        mobile: PropTypes.string,
        callbackRequest: PropTypes.bool
    };
    return connect(mapStateToProps, mapDispatchToProps)(WithCallbackRequest);
};

export default withCallbackRequest;
