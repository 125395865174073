import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import getRandomNumberInRange from "../../../utils/helpers/get-random-number-in-range";
import LoaderWithText from "../loader-with-text";
import { CAR_LISTING_LOADER_TEXT } from "./constant";
import styles from "./styles.css";

const RandomizedTextLoader = ({height = "40px", width = "40px", fullScreen = false}) => {

    const [loaderText, setLoaderText] = useState("");

    useEffect(() => {
        // eslint-disable-next-line no-magic-numbers
        const randomNumber = getRandomNumberInRange(0, 3);
        const randomText = CAR_LISTING_LOADER_TEXT[randomNumber];
        setLoaderText(randomText);
    }, []);

    return (
        loaderText && <div styleName={"styles.randomizedLoaderContainer"}>
            <LoaderWithText loaderText={loaderText} height={height} width={width} fullScreen={fullScreen}/>
        </div>
    );
};

export default RandomizedTextLoader;

RandomizedTextLoader.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
    fullScreen: PropTypes.bool
};
