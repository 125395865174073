import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import styles from "./styles.css";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import BlueArrow from "./images/blue-arrow.svg";

const PriceBreakdownContentDropDown = ({ summary }) => {
    const aggreadItem = summary?.aggregatedItem || {};
    const [isAccordianOpen, setIsAccordianOpen] = useState(null);

    const renderSubItems = (summaryItem) => {
        return (
            <div styleName={"styles.secondSectionWrap"}>
                {summaryItem?.lineItems?.map((subItem) => (
                    <div styleName={"styles.firstSectionWrapper"}>
                        <p styleName={"styles.leftText"}>{subItem?.label}</p>
                        <p styleName={"styles.leftText"}>
                            {subItem?.type === "SUBTRACTION"
                                ? `-${makePriceLabelRound(subItem?.amount)}`
                                : makePriceLabelRound(subItem?.amount)}
                        </p>
                    </div>
                ))}
            </div>
        );
    };

    const isTypeAccordian = (item) => {
        return item?.summary && Object.keys(item?.summary || {}).length > 0 && item?.summary?.viewType === "ACCORDION";
    };

    const handleAccordianClick = (item) => {
        if (isAccordianOpen === item?.key) {
            setIsAccordianOpen(null);
        } else {
            setIsAccordianOpen(item?.key);
        }
    };

    return (
        <div styleName={"styles.contentWrap"}>
            <div styleName={"styles.priceBreakupWrapper"}>
                {(summary?.lineItems || []).map(
                    (item) =>
                        item.amount >= 0 && (
                            <React.Fragment key={item?.key}>
                                <div styleName={"styles.firstSectionWrapper"}>
                                    <p
                                        styleName={
                                            isTypeAccordian(item) ? "styles.accordianLable" : "styles.leftText"
                                        }
                                        onClick={() => isTypeAccordian(item) && handleAccordianClick(item)}
                                    >
                                        {item?.label}
                                        {isTypeAccordian(item) && <img src={BlueArrow} alt="blue-arrow" />}
                                    </p>
                                    <p styleName={`styles.leftText ${item?.type === "FREE" ? "styles.typeFreeLabelbreakDown" : ""}`}>{makePriceLabelRound(item?.amount)}</p>
                                </div>
                                {isAccordianOpen === item?.key && renderSubItems(item?.summary)}
                            </React.Fragment>
                        )
                )}

                <div styleName={"styles.firstSectionWrapper styles.border"}>
                    <p styleName={"styles.totalText"}>{aggreadItem?.label}</p>
                    <p styleName={"styles.totalPrice"}>{makePriceLabelRound(aggreadItem?.amount)}</p>
                </div>
            </div>
            <div styleName={"styles.DriveAwayModalbottomTncContainer"}>
                {Object.keys(summary?.termsAndConditions || {}).length > 0 &&
                            (summary?.termsAndConditions || []).map((tnc, index) => (
                                <div styleName={"styles.bottomBox"} key={index}>
                                    <p styleName={"styles.bottomText"}>{tnc}</p>
                                </div>
                            ))}
            </div>
        </div>
    );
};

const mapStateToProps = () => ({});

PriceBreakdownContentDropDown.propTypes = {
    onClose: PropTypes.func,
    summary: PropTypes.object
};

export default connect(mapStateToProps, null)(PriceBreakdownContentDropDown);
