/* eslint-disable max-params */

import { getDeviceType } from "../utils/helpers/get-device-type";

/* eslint-disable max-statements */
export default (api) => {

    const deviceType = getDeviceType();
    const fetchListing = (params, queryString, token) => {
        return api.get(`/vehicle${queryString ? `?${queryString}` : ``}`, {
            params,
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {})
            }},
        );
    };

    const fetchListingV2 = (params, queryString, token) => {
        return api.get(`/v2/vehicle${queryString ? `?${queryString}` : ``}`, {
            params,
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {})
            }},
        );
    };

    const fetchComparsionDetails = (queryString, token, params) => {
        return api.get(`/vehicle/compare?ids=${queryString}`, {
            params,
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {})
            }},
        );
    };

    const fetchCarDetail = (appointmentId, token, params = {}) => {
        return api.get(`/vehicle/${appointmentId}`, {
            params,
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                SOURCE: deviceType
            }});
    };

    const fetchFilters = (token, userGeoData, queryParams = "") => {
        return api.get(`/v2/filter?showRevampedFilters=true${queryParams.length ? `&${queryParams}` : ""}`, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                "X-Location-Data": JSON.stringify(userGeoData)
            }});
    };

    const fetchCategories = () => {
        return api.get(`/vehicle/category`);
    };

    const fetchSimilarCars = (vehicleId, params, token) => {
        return api.get(`/vehicle/similar/${vehicleId}`, {params,
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {})
            }},
        );
    };

    const fetchWishlistCarsList = (vehicleId, params) => {
        return api.get(`/vehicle/similar/${vehicleId}`, {params});
    };

    const fetchSeoSimilarCars = (vehicleId, params, token) => {
        return api.get(`/vehicle/seo/similar/${vehicleId}`,  {params,
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {})
            }},
        );
    };

    const fetchAutoSuggestions = (params) => {
        return api.get(`/auto-suggest`, {params});
    };

    const fetchCarPricing = (appointmentId, queryString, params) => {
        return api.get(`/vehicle/pricing/${appointmentId}${queryString ? `?${queryString}` : ``}`, {params});
    };

    const fetchAlert = () => {
        return api.get("/alert/filter");
    };

    const createAlert = (params) => {
        return api.post("/alert/save", params);
    };

    const getDeliveryAvailability = (appointmentId, pincode, origincity, pickUp) => {
        return api.get(`vehicle/availability/availability?pincode=${pincode}&origincity=${origincity}&appointmentId=${appointmentId}&pickUp=${pickUp}`);
    };

    const getVehiclePickUpCities = (states) => {
        return api.get(`vehicle/availability/pickup-locations?state=${states}`);
    };

    const getSitemapList = () => {
        return api.get(`siteMap/html`);
    };

    const getC2bAvailability = (pincode, origincity, pickUp) => {
        return api.get(`vehicle/availability?pincode=${pincode}&origincity=${origincity}&pickUp=${pickUp}`);
    };

    const sendAppLink = (params) => {
        return api.post("/subscribe/app/link", params);
    };
    const getMenuSeoList = (params) => {
        return api.get("/siteMap/topSeo", params);
    };

    const getWarrantyPlans = (token, params = "") => {
        return api.get(`/extended-warranty/plans${params}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "CAR"
            }
        });
    };

    const getDeliveryTimeline = (token, originCity) => {
        return api.get(`/vehicle/availability/all-states${originCity ? `?origincity=${originCity}` : ""}`, {
            headers: {
                // Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "CAR"
            }
        });
    };

    const fetchSearchPage = async (payload, token, params = "") => {
        return await api.post(`/v1/search-page${params}`, payload, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: "CAR",
                X_COUNTRY: "AU",
                source: "search"
            }
        });
    };
    const setRecentlyViewed = async (payload, token, params = "") => {
        return await api.post(`/vehicle/recently-viewed${params}`, payload, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: "CAR",
                X_COUNTRY: "AU",
                source: "search"
            }
        });
    };

    const postEventImpressionSource = (payload, token) => {
        return api.post("/impression", payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_VEHICLE_TYPE: "CAR",
                X_COUNTRY: "AU"
            }
        });
    };

    const fetchAutoSuggestionsRobustRanking = (params) => {
        return api.get(`/v2/auto-suggest`, {params});
    };

    const fetchCarDetailV3 = (appointmentId, token, params = {}, platform = "mSite") => {
        return api.get(`/v3/vehicle/${appointmentId}`, {
            params,
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                "X_SOURCE": platform
            }});
    };

    const fetchTopFilters = (token, params, userGeoData) => {
        return api.get(`/top-filter${params}`, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                "X-Location-Data": JSON.stringify(userGeoData)
            }});
    };

    const fetchFiltersV2 = (token, userGeoData, queryParams = "") => {
        return api.get(`/v2/filter?showRevampedFilters=true${queryParams.length ? `&${queryParams}` : ""}`, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                "X-Location-Data": JSON.stringify(userGeoData)
            }});
    };
    const getTotalAvailableCars = (token) => {
        return api.get("total-cars", {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {})
            }});
    };
    const fetchClpData = (params, queryString, token) => {
        return api.get(`/clp-data${queryString ? `?${queryString}` : ``}`, {
            params,
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {})
            }},
        );
    };

    const setTestDriveVariant = async (payload, token) => {
        return await api.post(`/test-drive/variant`, payload, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {}),
                X_VEHICLE_TYPE: "CAR",
                X_COUNTRY: "AU",
                source: "search"
            }
        });
    };

    const getTestDriveVariant = (queryString, token) => {
        return api.get(`/test-drive/variant${queryString ? `?${queryString}` : ``}`, {
            headers: {
                ...(token ?  {Authorization: `Bearer ${token}`} : {})
            }},
        );
    };

    return {
        fetchListing,
        fetchCarDetail,
        fetchFilters,
        fetchSimilarCars,
        fetchWishlistCarsList,
        fetchAutoSuggestions,
        fetchCarPricing,
        fetchAlert,
        createAlert,
        fetchCategories,
        getDeliveryAvailability,
        getSitemapList,
        getVehiclePickUpCities,
        getC2bAvailability,
        fetchSeoSimilarCars,
        sendAppLink,
        getWarrantyPlans,
        getMenuSeoList,
        getDeliveryTimeline,
        fetchComparsionDetails,
        fetchSearchPage,
        postEventImpressionSource,
        fetchAutoSuggestionsRobustRanking,
        fetchCarDetailV3,
        fetchTopFilters,
        fetchFiltersV2,
        getTotalAvailableCars,
        fetchClpData,
        setTestDriveVariant,
        getTestDriveVariant,
        fetchListingV2,
        setRecentlyViewed
    };
};
