export const AU_FILTER_EVENTS_KEYS = {
    FILTER_REVAMP_FILTER_SELECTED: "filter_selected",
    FILTER_REVAMP_FILTER_SHOW_CARS: "filter_show_cars",
    FILTER_REVAMP_FILTER_PRICE: "filter_price",
    FILTER_REVAMP_FILTER_FINANCE: "filter_finance",
    FILTER_REVAMP_FILTER_KM: "filter_km",
    FILTER_REVAMP_FILTER_YEAR: "filter_year",
    FILTER_REVAMP_FILTER_FUEL_TYPE: "filter_fuel_type",
    FILTER_REVAMP_FILTER_TRANSMISSION: "filter_transmission",
    FILTER_REVAMP_FILTER_BODY_TYPE: "filter_body_type",
    FILTER_REVAMP_FILTER_MAKE_MODEL_SEARCH: "filter_make_model_search",
    FILTER_REVAMP_FILTER_POPULAR_MAKE_SELECTED: "filter_popular_make_selected",
    FILTER_REVAMP_FILTER_MAKE_SELECTED: "filter_make_selected",
    FILTER_REVAMP_FILTER_MAKE_ON: "filter_make_on",
    FILTER_REVAMP_FILTER_MAKE_OFF: "filter_make_off",
    FILTER_REVAMP_FILTER_DELIVERY_TIME: "filter_delivery_time",
    FILTER_REVAMP_FILTER_SEATS: "filter_seats",
    FILTER_REVAMP_FILTER_PROMOTION: "filter_promotion",
    FILTER_REVAMP_FILTER_COLOUR: "filter_colour",
    FILTER_REVAMP_FILTER_FILTER_SUGGESTION: "filter_suggestion",
    FILTER_REVAMP_FILTER_DRIVE_TRAIN: "filter_drive_train_type",
    FILTER_REVAMP_FILTER_SAFETY_QUALITY: "filter_safety_quality"
};

export const AU_FILTER_EVENT = {
    [AU_FILTER_EVENTS_KEYS.FILTER_REVAMP_FILTER_SELECTED]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_selected"
    },
    [AU_FILTER_EVENTS_KEYS.FILTER_REVAMP_FILTER_SHOW_CARS]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_show_cars"
    },
    [AU_FILTER_EVENTS_KEYS.FILTER_REVAMP_FILTER_PRICE]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_price"
    },
    [AU_FILTER_EVENTS_KEYS.FILTER_REVAMP_FILTER_FINANCE]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_finance"
    },
    [AU_FILTER_EVENTS_KEYS.FILTER_REVAMP_FILTER_KM]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_km"
    },
    [AU_FILTER_EVENTS_KEYS.FILTER_REVAMP_FILTER_YEAR]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_year"
    },
    [AU_FILTER_EVENTS_KEYS.FILTER_REVAMP_FILTER_FUEL_TYPE]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_fuel_type"
    },
    [AU_FILTER_EVENTS_KEYS.FILTER_REVAMP_FILTER_TRANSMISSION]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_transmission"
    },
    [AU_FILTER_EVENTS_KEYS.FILTER_REVAMP_FILTER_BODY_TYPE]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_body_type"
    },
    [AU_FILTER_EVENTS_KEYS.FILTER_REVAMP_FILTER_MAKE_MODEL_SEARCH]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_make_model_search"
    },
    [AU_FILTER_EVENTS_KEYS.FILTER_REVAMP_FILTER_POPULAR_MAKE_SELECTED]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_popular_make_selected"
    },
    [AU_FILTER_EVENTS_KEYS.FILTER_REVAMP_FILTER_MAKE_SELECTED]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_make_selected"
    },
    [AU_FILTER_EVENTS_KEYS.FILTER_REVAMP_FILTER_MAKE_ON]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_make_on"
    },
    [AU_FILTER_EVENTS_KEYS.FILTER_REVAMP_FILTER_MAKE_OFF]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_make_off"
    },
    [AU_FILTER_EVENTS_KEYS.FILTER_REVAMP_FILTER_DELIVERY_TIME]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_delivery_time"
    },
    [AU_FILTER_EVENTS_KEYS.FILTER_REVAMP_FILTER_SEATS]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_seats"
    },
    [AU_FILTER_EVENTS_KEYS.FILTER_REVAMP_FILTER_PROMOTION]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_promotion"
    },
    [AU_FILTER_EVENTS_KEYS.FILTER_REVAMP_FILTER_COLOUR]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_color"
    },
    [AU_FILTER_EVENTS_KEYS.FILTER_REVAMP_FILTER_FILTER_SUGGESTION]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_suggestion"
    },
    [AU_FILTER_EVENTS_KEYS.FILTER_REVAMP_FILTER_DRIVE_TRAIN]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_drivetrain"
    },
    [AU_FILTER_EVENTS_KEYS.FILTER_REVAMP_FILTER_SAFETY_QUALITY]: {
        event: "custom_event",
        eventCategory: "Cars24_listing_page",
        eventAction: "filter_safety_quality"
    }
};
