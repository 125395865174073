import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setStatsigInitialization } from "../shared/ab-experiment/actions";
import { getRefreshTokenInfo, getSeoList, logoutSuccess, setCurrentScreen, setGAId, setSecureToken, updateIsFirstUserSession } from "./actions";
import AUConfiguration from "./component";
import { setModalContentSeen, updateNudgeCountSyncStatus, updateSupportWidgetConfig } from "../au.mobile/support-widget/actions";

const mapStateToProps = ({
    user: {seoMenu, firstName, middleName, lastName, email, mobile, isLoggedIn, loginType, userId, gaId},
    abExperiment,
    abExperiment: { msiteBi2ph }
}) => ({
    seoMenu,
    firstName,
    middleName,
    lastName,
    email,
    mobile,
    isLoggedIn,
    loginType,
    userId,
    abExperiment,
    gaId,
    msiteBi2ph
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getRefreshTokenInfoConnect: getRefreshTokenInfo,
    logoutSuccessConnect: logoutSuccess,
    setSecureTokenConnect: setSecureToken,
    setGAIdConnect: setGAId,
    getSeoListConnect: getSeoList,
    setCurrentScreenConnect: setCurrentScreen,
    updateIsFirstUserSessionConnect: updateIsFirstUserSession,
    updateSupportWidgetConfigConnect: updateSupportWidgetConfig,
    setModalContentSeenConnect: setModalContentSeen,
    updateNudgeCountSyncStatusConnect: updateNudgeCountSyncStatus,
    setStatsigInitializationConnect: setStatsigInitialization
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AUConfiguration);
