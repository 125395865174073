import * as React from "react";
import styles from "./styles.css";
import HelpIcon from "./images/help.png";

import loadable from "@loadable/component";
import PropTypes from "prop-types";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";

const NeedHelpModal = loadable(() => import("../need-help-modal/component"));
const EmailUsModal = loadable(() => import("../email-us-modal"));

const NeedHelpWidget = ({ vasProductDetail = false,  vasStore = false, financeUniqueId, getInhousePreApprovalLoanDetailsConnect}) => {
    const [helpSupportModal, setHelpSupportModal] = React.useState(false);
    const [emailSupportModal, setEmailSupportModal] = React.useState(false);

    const handleHelpModal = async (val) => {
        if (val && !financeUniqueId) {
            await getInhousePreApprovalLoanDetailsConnect();
        }
        setHelpSupportModal(val);
    };

    const handleWidgetClick = (val) => {
        if (emailSupportModal) return;
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.SUPPORT_WIDGET_HELP_ICON_CLICKED, {
            eventLabel: window.location.href
        });
        handleHelpModal(val);

        if (vasStore && val) {
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.PRE_BC_CAR_CARE_PACKS_VAS_STORE, {
                eventAction: "VAS Store_Help",
                eventLabel: "Yes"
            });
        }

        if (vasProductDetail && val) {
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.PRE_BC_CAR_CARE_PACKS_PRODUCT_DETAIL_PAGE, {
                eventAction: "Landed_detail_page_Help",
                eventLabel: "Yes"
            });
        }
    };

    const handleEmailUsModal = (val) => {
        setEmailSupportModal(val);
    };

    return (
        <React.Fragment>
            <div styleName={"styles.needHelpWrapper"} onClick={() => handleWidgetClick(!helpSupportModal)} >
                <img src={HelpIcon} />
                <p styleName={"styles.needHelp"}>Need help?</p>
            </div>
            {helpSupportModal &&
                <div>
                    <NeedHelpModal vasStore={vasStore} handleEmailUsModal={handleEmailUsModal} handleHelpModal={handleHelpModal} setHelpSupportModal={setHelpSupportModal} />
                </div>
            }
            {emailSupportModal && <EmailUsModal handleEmailUsModal={handleEmailUsModal} handleHelpModal={handleHelpModal} />}
        </React.Fragment>
    );
};
export default NeedHelpWidget;

NeedHelpWidget.propTypes = {
    showWidget: PropTypes.bool,
    vasStore: PropTypes.bool,
    getInhousePreApprovalLoanDetailsConnect: PropTypes.func,
    financeUniqueId: PropTypes.string,
    vasProductDetail: PropTypes.bool
};
