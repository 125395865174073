/* eslint-disable max-params */
import {loadAsyncScript} from "../../utils/helpers/load-async-script";
const DISABLE_ZEN_PATH = ["/checkout/"];

// eslint-disable-next-line no-var

export const zendeskVisibility = () => {
    if (window && window.zE && window.zE.hide) {
        if (DISABLE_ZEN_PATH.filter(item => window.location.pathname.indexOf(item) > -1).length) {
            window.zE.hide();
        } else {
            window.zE.show();

        }
    }
};

export const loadZendesk = () => {
    window.zESettings = {
        webWidget: {
            chat: {
                connectOnPageLoad: false
            }
        }
    };
    loadAsyncScript("ze-snippet", `https://static.zdassets.com/ekr/snippet.js?key=${process.env.ZENDESK_ID_AU}`, () => {
        if (window && window.zE) window.zE(() => zendeskVisibility());
    });
};

export const loadClevertap = () => {
    window.clevertap = {event: [], profile: [], account: [], onUserLogin: [], notifications: [], privacy: []};
    // replace with the CLEVERTAP_ACCOUNT_ID with the actual ACCOUNT ID value from your Dashboard -> Settings page
    window.clevertap.account.push({"id": process.env.CLEVERTAP_ID_AU});
    window.clevertap.privacy.push({optOut: false}); //set the flag to true, if the user of the device opts out of sharing their data
    window.clevertap.privacy.push({useIP: false}); //set the flag to true, if the user agrees to share their IP data
    (function () {
        const wzrk = document.createElement("script");
        wzrk.type = "text/javascript";
        wzrk.async = true;
        wzrk.src = `${document.location.protocol === "https:" ? "https://d2r1yp2w7bby2u.cloudfront.net" : "http://static.clevertap.com"  }/js/clevertap.min.js`;
        const s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(wzrk, s);
    })();
};

