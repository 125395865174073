import React, {useState, useEffect} from "react";
import Modal from "../../../../shared/modal";
import styles from "./styles.css";
import BackArrow from "./images/back-arrow.svg";
import banner from "./images/banner.png";
import check from "./images/check.svg";
import PropTypes from "prop-types";
// import { trackMobileCustomEventsAU } from "../../../../tracking";
// import { PLATINUM_COVER_GA_EVENTS } from "../../cars24-patinum-cover/tracking";
import Cars24PlatinumCoverTheBestModal from "../cars24-platinum-cover-the-best-modal/component";
import VerifiedUser from "./images/verified.svg";
import toggleArrow from "./images/accordion.svg";
import locationIcon from "./images/location.svg";

const servicingList = [
    "Servicing at 6 month / 10,000km",
    "Completed by skilled CARS24 technicians at our local service centre",
    "Lock in attractive prices"
];

const mechanicalList = [
    "Engine",
    "Cooling",
    "Drivetrain",
    "Ignition",
    "Streering",
    "Fuel system",
    "Differential",
    "Timing belt",
    "Driveshaft",
    "Clutch",
    "Transmission",
    "Gearbox"
];

const electricalList = [
    "Computers",
    "Starter motor",
    "ECUs",
    "Alternator",
    "Power windows",
    "ABS brake system",
    "Air conditioning"
];

// eslint-disable-next-line complexity
const WarrantyModal = ({onClose, isOpen, planKey, modalHeading}) => {
    const [activeTab, setActiveTab] = useState(0);
    const [isWhatIsCoveredModalOpen, setIsWhatIsCoveredModalOpen] = useState(true);

    const isServicing = planKey === "CAR_SERVICING" || planKey === "CAR_COVER_BUNDLES";
    const isWarranty = planKey === "EXTENDED_WARRANTY_PLAN" || planKey === "CAR_COVER_BUNDLES";
    const isBundle = planKey === "CAR_COVER_BUNDLES";

    useEffect(() => {
        if (isOpen) {
            // trackMobileCustomEventsAU("", PLATINUM_COVER_GA_EVENTS.WHAT_IS_COVERED_MODAL_OPENED);
        }
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen}>
            <div styleName={"styles.outer"}>

                <div styleName={"styles.headerWrap"}>
                    <img src={BackArrow} alt="See what’s covered back arrow" onClick={onClose}/>
                    <p styleName={"styles.heading"}>{modalHeading}</p>
                </div>
                <div styleName={"styles.banner"}>
                    <img src={banner} alt="See what’s covered banner" />
                </div>
                <div styleName={"styles.containerWrapper"}>
                    <div styleName={"styles.outerWrapper"}>
                        <div styleName={"styles.whatsCovered"} onClick={() => setIsWhatIsCoveredModalOpen(!isWhatIsCoveredModalOpen)}>
                            <p styleName={"styles.whatsCoveredHeading"}><img src={VerifiedUser} />What’s covered?</p>
                            <img styleName={isWhatIsCoveredModalOpen ? "styles.toggleArrowRotate" : ""} src={toggleArrow} />
                        </div>
                        {isWhatIsCoveredModalOpen && (
                            <React.Fragment>
                                {isBundle && (
                                    <div styleName={"styles.contentWrapper"}>
                                        <p styleName={"styles.contentHeadingOne"}>
                                            <span>1</span>Free Roadside Assistance
                                        </p>
                                    </div>
                                )}
                                {isWarranty && (
                                    <div styleName={"styles.contentWrapper"}>
                                        <p styleName={"styles.contentHeading"}>Extended warranty cover</p>
                                        <div styleName={"styles.tabWrapper"}>
                                            <div
                                                styleName={`styles.tab ${activeTab === 0 ? "styles.tabActive" : ""}`}
                                                onClick={() => setActiveTab(0)}
                                            >
                                                <p styleName={"styles.tabName"}>Mechanical</p>
                                            </div>
                                            <div
                                                styleName={`styles.tab ${activeTab === 1 ? "styles.tabActive" : ""}`}
                                                onClick={() => setActiveTab(1)}
                                            >
                                                <p styleName={"styles.tabName"}>Electrical</p>
                                            </div>
                                        </div>
                                        {activeTab === 0 ? (
                                            <div styleName={"styles.listWrapper"}>
                                                {mechanicalList.map((item) => (
                                                    <p styleName={"styles.list"}>
                                                        <img src={check} alt="Lists check status icon" />
                                                        {item}
                                                    </p>
                                                ))}
                                            </div>
                                        ) : (
                                            <div styleName={"styles.listWrapper"}>
                                                {electricalList.map((item) => (
                                                    <p styleName={"styles.list"}>
                                                        <img src={check} alt="Lists check status icon" />
                                                        {item}
                                                    </p>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                )}
                                {isServicing && (
                                    <div styleName={"styles.contentWrapper"}>
                                        <p styleName={"styles.contentHeading"}>
                                            <span>{isBundle ? "3" : "1"}</span>Car maintainance - Servicing
                                        </p>
                                        {servicingList.map((item) => (
                                            <p styleName={"styles.list"}>
                                                <img src={check} alt="Lists check status icon" />
                                                {item}
                                            </p>
                                        ))}
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                    <Cars24PlatinumCoverTheBestModal isServicing={isServicing} isWarranty={isWarranty} isBundle={isBundle}/>
                </div>
                {isServicing && <div styleName={"styles.bottomCard"}>
                    <p styleName={"styles.serviceCenter"}>CARS24 service center</p>
                    <p styleName={"styles.dropOff"}>Drop off your car at the service centre and we’ll do the rest.</p>
                    <p styleName={"styles.location"}><img src={locationIcon} alt="Location icon" />118 Cherry Ln, Laverton North VIC 3026, Australia</p>
                </div>}
            </div>
        </Modal>
    );
};

WarrantyModal.propTypes = {
    onClose: PropTypes.func,
    servicingLocation: PropTypes.string,
    isOpen: PropTypes.bool,
    planKey: PropTypes.string,
    modalHeading: PropTypes.string
};

export default WarrantyModal;
