/* eslint-disable complexity */
import React from "react";
import styles from "./styles.css";
import Modal from "../../shared/modal";
import PropTypes from "prop-types";
import { LOAN_PROVIDER } from "../../../constants/app-constants";
import BackArrow from "./images/backArrow.svg";
import PriceBreakdownContents from "../price-breakdown-contents";

const FinanceFirstCheckoutPriceBreakupModal = ({
    onClose,
    isOpen,
    financeOpted,
    // financeData,
    // deliveryInfo,
    // chargeDetails,
    // listingPrice,
    // tradeInData,
    // tradeinOpted,
    // isZeroDpScreen,
    // amountWithSurcharge,
    splitPaymentOpted,
    // splitAmountDetails,
    financeProvider
    // allStatesPrice,
    // priceBreakDown
    // deliveryMode
}) => {
    const isInHouse = financeProvider === LOAN_PROVIDER.CARS24 && financeOpted;
    const isByoOpted = financeProvider === LOAN_PROVIDER.BYOF && financeOpted;

    // const { loan: { deposit } = {} } = financeData || {};
    // const { minDepositRequired } = selectedQuote || {};

    // const depositPrice = deposit;
    // const egcData = allStatesPrice?.find((state) => state.stateCode === priceBreakDown?.stateCode);

    if (isOpen) {
        return (
            <Modal isOpen={isOpen}>
                <div styleName={"styles.outerFF-checkoutModal"}>
                    <div styleName={"styles.headerWrap"}>
                        <img src={BackArrow} alt="Price breakdown close icon" onClick={onClose} />
                        <p styleName={"styles.heading"}>Price breakdown</p>
                    </div>
                    <div styleName={"styles.modalContentWrapBreakup"}>
                        <PriceBreakdownContents
                            isByoOpted={isByoOpted}
                            isInHouse={isInHouse}
                            splitPaymentOpted={splitPaymentOpted}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
    return null;
};
FinanceFirstCheckoutPriceBreakupModal.propTypes = {
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
    financeData: PropTypes.object,
    financeOpted: PropTypes.bool,
    financeProvider: PropTypes.string,
    splitAmountDetails: PropTypes.object,
    chargeDetails: PropTypes.array,
    egcData: PropTypes.object,
    tradeInData: PropTypes.object,
    tradeinOpted: PropTypes.bool,
    deliveryInfo: PropTypes.object,
    isZeroDpScreen: PropTypes.bool,
    amountWithSurcharge: PropTypes.number,
    isInHouse: PropTypes.bool,
    splitPaymentOpted: PropTypes.bool,
    listingPrice: PropTypes.number,
    allStatesPrice: PropTypes.array,
    priceBreakDown: PropTypes.object,
    deliveryMode: PropTypes.string
};

export default FinanceFirstCheckoutPriceBreakupModal;
