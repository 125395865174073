import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MyBookingPostBcCarCard from "./component";

const mapStateToProps = ({
    checkout: {
        order: {
            financeProvider
        } = {}
    } = {},
    carDetails: {
        content,
        config
    } = {},
    myBookings: {
        paymentSummaryData
    } = {}
}) => ({
    content,
    config,
    financeProvider,
    paymentSummaryData
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MyBookingPostBcCarCard);
