/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-statements */
import React, { Fragment, useEffect, useState, useCallback } from "react";
import loadable from "@loadable/component";
import PropTypes from "prop-types";
import EventHandler from "../../utils/event-handler";
import { Helmet } from "react-helmet";
import { subscribe } from "valtio/vanilla";
import { proxyAuthState } from "../../service/userAuthApi";
import saveCookie from "../../utils/helpers/save-cookie";
import parseCookie from "../../utils/helpers/parse-cookie";
import { LANDING_ROUTE_CONSTANTS, NUMBER, C2B_ROUTES, LOCAL_STORAGE_KEYS, SESSION_KEYS } from "../../constants/app-constants";
import { ISLOGGEDIN_COOKIE, SUPPORT_WIDGET_HIDDEN_ROUTES } from "./constants.js";
import { emitEventNames } from "../../constants/event-constants";
import { loadClevertap } from "./dynamic-scripts";
import {  useLocation } from "react-router";
import { getItem, setItem } from "../../utils/helpers/storage-crud";
import ErrorHandler from "../shared/with-error-handler";
import isWebView from "../../utils/helpers/is-web-view";
import Smartlook from "smartlook-client";
import { clevertapUserProperties, trackCommonCustomEventsAU } from "../../tracking/index.js";
import { getItemFromSession, setItemInSession } from "../../utils/helpers/session-storage.js";
import { PRE_BI_ASSISTANCE_CATEGORIES } from "../../config/au.mobile/pre-bi-assistance-config/index.js";

import { statsigInitialization } from "../../utils/statsig-ab-utils/initialize-user-csr";
import { EXPERIMENT_NAME } from "../../utils/statsig-ab-utils/constant.js";
import useExperiments from "../../hooks/use-statsig-experiment.js";
import { DEVICE_TYPE, getDeviceType } from "../../tracking/c2c-tracking-params.js";
import ANALYTICS_EVENTS from "../au.mobile/bi-to-phone-basic-details/tracking.js";
import { getAbExpirementVariant } from "../../utils/helpers/get-ab-expirement-variant.js";
import { EXPERIMENT_TYPE } from "../../constants/optimize-constants.js";
import PageLoader from "../shared/page-loader/index.js";
const SupportWidgetDesktop = loadable(() => import("../au.desktop/support-widget"), { fallback: <PageLoader/> });
const SupportWidgetMobile = loadable(() => import("../au.mobile/support-widget"), { fallback: <PageLoader/> });
import useOnloadIdle from "../../hooks/use-onload-idle";

export const LoginContext = React.createContext({
    setOnLoginSuccessCallback: () => {},
    setOnLoginCloseCallback: () => {}
});

const AUConfiguration = ({
    children,
    getRefreshTokenInfoConnect,
    logoutSuccessConnect,
    setSecureTokenConnect,
    setGAIdConnect,
    type,
    seoMenu = {},
    getSeoListConnect,
    setCurrentScreenConnect,
    firstName,
    middleName,
    lastName,
    email,
    mobile,
    isLoggedIn,
    loginType,
    userId,
    abExperiment,
    gaId,
    msiteBi2ph,
    setStatsigInitializationConnect,
    updateIsFirstUserSessionConnect = () => {},
    setModalContentSeenConnect = () => {},
    updateSupportWidgetConfigConnect = () => {},
    updateNudgeCountSyncStatusConnect = () => {}

}) => {
    const [onLoginSuccessCallback, setOnLoginSuccessCallback] = useState(() => () => {});
    const isIdleLoaded = useOnloadIdle();
    const [onLoginCloseCallback, setOnLoginCloseCallback] = useState(() => () => {});
    const setOnLoginSuccessCallbackHandler = useCallback((func) => {
        setOnLoginSuccessCallback(() => func);
    }, []);
    const { statsigId } = abExperiment || {};

    const setOnLoginCloseCallbackHandler = useCallback((func) => {
        setOnLoginCloseCallback(() => func);
    }, []);
    const {pathname} = useLocation();
    const showSupportWidget = isWebView() ? false : !SUPPORT_WIDGET_HIDDEN_ROUTES.find(route => pathname.indexOf(route) > -1);
    const isSellCarPage =  C2B_ROUTES.find(route =>  pathname.indexOf(route) > -1);

    /*
        initializes event handler class
    */
    useEffect(() => {
        const eventInstance = EventHandler.getInstance();
        window.EventHandler = eventInstance;
        const initateStatsig = async () => {
            await statsigInitialization({ statsigId });
            setStatsigInitializationConnect({ statsigInitialized: true });
        };
        initateStatsig();

        // Set isFirstUserSession to true if the user has not visited the website before
        const hasVisited = getItem(LOCAL_STORAGE_KEYS.hasVisited);
        if (!hasVisited) {
            setItem(true, LOCAL_STORAGE_KEYS.hasVisited);
            updateIsFirstUserSessionConnect(true);
        }
        const modalContentSeen = getItem(LOCAL_STORAGE_KEYS.nudgeModalContentSeen);
        if (modalContentSeen) {
            setModalContentSeenConnect(true);
        }
        // pre bi assistance nudges fire once per category per session
        const categoryNudgeCount = getItemFromSession(SESSION_KEYS.CATEGORY_NUDGE_COUNT);
        if (!categoryNudgeCount) {
            setItemInSession(SESSION_KEYS.CATEGORY_NUDGE_COUNT, {
                [PRE_BI_ASSISTANCE_CATEGORIES.BUYER]: 0,
                [PRE_BI_ASSISTANCE_CATEGORIES.FINANCE]: 0,
                [PRE_BI_ASSISTANCE_CATEGORIES.GENERIC]: 0,
                [PRE_BI_ASSISTANCE_CATEGORIES.SELL]: 0
            });
            updateNudgeCountSyncStatusConnect(true);
        } else {
            updateSupportWidgetConfigConnect({categoryNudgeCounts: categoryNudgeCount});
            updateNudgeCountSyncStatusConnect(true);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        saveCookie(ISLOGGEDIN_COOKIE, false);
        loadClevertap();
    }, []);

    useEffect(() => {
        window.clevertap =  window.clevertap || {};
        window.dataLayer =  window.dataLayer || [];
    }, []);

    useEffect(() => {
        window.setTimeout(() => {
            if (window.ga && process.env.GA_ID) {
                window.ga("create", process.env.GA_ID, "auto");
            }
        }, NUMBER.FIVE_HUNDRED);
    }, []);

    useEffect(() => {
        let screenName = getItem("home_screen");
        window.setTimeout(() => {
            if (pathname  === LANDING_ROUTE_CONSTANTS.FINANCEYOURCAR) {
                screenName = LANDING_ROUTE_CONSTANTS.FINANCEYOURCAR;
            } else if (pathname ===  LANDING_ROUTE_CONSTANTS.SELLYOURCAR) {
                screenName = LANDING_ROUTE_CONSTANTS.SELLYOURCAR;
            } else {
                screenName = pathname === LANDING_ROUTE_CONSTANTS.DEFAULT ? LANDING_ROUTE_CONSTANTS.DEFAULT : (screenName || LANDING_ROUTE_CONSTANTS.DEFAULT);
            }
            setItem(screenName, "home_screen");
            setCurrentScreenConnect(screenName);
        }, NUMBER.FIVE_HUNDRED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCurrentScreenConnect]);

    useEffect(() => {
        if (!seoMenu.isSSR) {
            getSeoListConnect();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getSeoListConnect]);

    // clevertap Properties
    useEffect(() => {
        let cleverTapData = {};
        cleverTapData = {...cleverTapData, "clientId": gaId || "test_value"};
        clevertapUserProperties(cleverTapData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        window.setTimeout(() => {
            if (window.ga) {
                window.ga((tracker) => {
                    const clientId = tracker.get("clientId");
                    setGAIdConnect(clientId);
                    const TRACKING_CONSENT = emitEventNames.desktop.TRACKING_CONSENT;
                    const trackingConsentValue = JSON.parse(parseCookie(TRACKING_CONSENT));
                    if (!trackingConsentValue) {
                        const clientIdObj = {clientId, allowCookies: null};
                        saveCookie(TRACKING_CONSENT, JSON.stringify(clientIdObj), NUMBER.THIRTY);
                    }
                });
            }
        }, NUMBER.THOUSAND);
    }, [setGAIdConnect]);

    useEffect(() => {
        getRefreshTokenInfoConnect().catch(() => {});
    }, [getRefreshTokenInfoConnect]);

    useEffect(() => {
        const unsubscribe = subscribe(proxyAuthState, () => {
            if (proxyAuthState.isLoggedIn) {
                setSecureTokenConnect(proxyAuthState.accessToken);
            } else {
                logoutSuccessConnect();
            }
        });

        // Unsubscribe by calling the result
        return () => {
            unsubscribe();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proxyAuthState]);

    useEffect(() => {
        if (isLoggedIn && process.env.HOST_ENV === "PRODUCTION") {
            Smartlook.init(process.env.SMARTLOOK_PROJECT_ID, { region: "eu" });
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (isLoggedIn && process.env.HOST_ENV === "PRODUCTION" && Smartlook?.initialized()) {
            Smartlook.properties({ "environment": (process.env.HOST_ENV || "").toLowerCase() });
            Smartlook.identify(email || mobile || userId, {
                name: [firstName, middleName, lastName].filter(Boolean).join(" "),
                email,
                mobile,
                loginType,
                userId
            });
        }
        if (isLoggedIn) {
            window.dataLayer.push({au_email: email});
        }

    }, [email, firstName, isLoggedIn, lastName, loginType, middleName, mobile, userId]);

    useEffect(() => {
        const deviceType = getDeviceType();
        const isBi2phVariant = getAbExpirementVariant(msiteBi2ph, EXPERIMENT_TYPE.VARIANT_B);
        if (deviceType === DEVICE_TYPE.MOBILE) {
            trackCommonCustomEventsAU(ANALYTICS_EVENTS.BI_TO_PHONE_EXPOSURE_EVENT, {
                ...ANALYTICS_EVENTS.BI_TO_PHONE_EXPOSURE_EVENT,
                // eventLabel: "VARIANT_A"
                eventLabel: isBi2phVariant ? EXPERIMENT_TYPE.VARIANT_B : EXPERIMENT_TYPE.VARIANT_A
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useExperiments({ experimentKeys: [EXPERIMENT_NAME.financeFirstCheckoutRevamp] });

    const SupportWidget = type === "desktop" ? SupportWidgetDesktop : SupportWidgetMobile;
    return (
        <Fragment>
            <Helmet
                script={[
                    {
                        type: "text/javascript",
                        innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
                  '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${process.env.GTM_ID_AU}');`
                    }
                ]}
                meta={[
                    { name: "google-site-verification", content: "VUY_EcsQTevkNtbtQbpgkZrJoLQE4L1OincJrfjbJcw" },
                    { name: "google-site-verification", content: "gydA6QDX5Z1TF1TjzhdI7BWR2mbtO8cuw2t-vPkUrHI" }

                ]}
            />
            <LoginContext.Provider
                value={{
                    setOnLoginSuccessCallback: setOnLoginSuccessCallbackHandler,
                    setOnLoginCloseCallback: setOnLoginCloseCallbackHandler
                }}
            >
                { React.Children.map(children, child => {
                    return React.cloneElement(child, {
                        onLoginSuccessCallback,
                        setOnLoginSuccessCallback: setOnLoginSuccessCallbackHandler,
                        onLoginCloseCallback,
                        setOnLoginCloseCallback: setOnLoginCloseCallbackHandler,
                        type
                    });
                })}
                {showSupportWidget && isIdleLoaded &&
                    <ErrorHandler>
                        { isIdleLoaded && <SupportWidget isSellCarPage={isSellCarPage}/> }
                    </ErrorHandler>
                }
            </LoginContext.Provider>
        </Fragment>
    );
};

AUConfiguration.propTypes = {
    children: PropTypes.any.isRequired,
    getRefreshTokenInfoConnect: PropTypes.any.isRequired,
    logoutSuccessConnect: PropTypes.func,
    setSecureTokenConnect: PropTypes.func,
    setGAIdConnect: PropTypes.func,
    type: PropTypes.string,
    isLoginPopupVisible: PropTypes.bool,
    getSeoListConnect: PropTypes.func,
    seoMenu: PropTypes.object,
    setCurrentScreenConnect: PropTypes.func,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    mobile: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    loginType: PropTypes.string,
    userId: PropTypes.string,
    abExperiment: PropTypes.object,
    financeOpted: PropTypes.bool,
    financeProvider: PropTypes.string,
    msiteBi2ph: PropTypes.object,
    gaId: PropTypes.func,
    updateIsFirstUserSessionConnect: PropTypes.func,
    updateSupportWidgetConfigConnect: PropTypes.func,
    setModalContentSeenConnect: PropTypes.func,
    updateNudgeCountSyncStatusConnect: PropTypes.func,
    setStatsigInitializationConnect: PropTypes.func
};

export default AUConfiguration;
