import React, { useRef, useEffect, useState } from "react";
import AsyncSelect from "react-select/lib/Async";
import  { components } from "react-select";
import styles from "./style.css";
import PropTypes from "prop-types";

// pass isSearchable = false to disable the search
const Search = ({
    data = [],
    defaultMenuIsOpen = false,
    onChange = () => { },
    onBlurChange = () => {},
    placeholder = "Select...",
    customDefaultValue = null,
    autoFocus = false,
    showLogoOptions,
    showError = false,
    tabAccessibility = false,
    // styleName,
    validator,
    onMenuOpenCallback = () => { },
    onMenuCloseCallback = () => { },
    defaultInputValue = "",
    disabled = false,
    ...restProps
}) => {
    /* Added these for overriding onChange & value for formik fields */
    const {  form = {}, field = {} } = restProps || {};
    const searchRef = useRef();
    const [inputValue, setInputValue] = useState(customDefaultValue || defaultInputValue || "");
    useEffect(() => {
        if (autoFocus) {
            searchRef.current.focus();
        }
    }, [autoFocus]);

    const filterOptions = (input) => {
        return new Promise((resolve) => {
            const result = data.filter(({ value }) =>
                value.toString().toLowerCase().includes(input.toLowerCase()));
            resolve(result);
        });
    };

    const onInputChange = (v, action) => {
        if (validator && !validator(v)) {
            return null;
        }
        if (action.action === "input-blur") {
            onBlurChange(v, restProps);
        }
        if (action.action !== "input-blur" && action.action !== "menu-close") {
            setInputValue(v);
        }
        return null;
    };
    const Option = (props) => {
        const {logo, label, disabled: disabledProp} = props.data || {};
        return (
            <div styleName={`styles.logoWrapper ${disabledProp ? "styles.disableLogoWrapperEle" : ""}`}>
                <components.Option {...props}>
                    {logo && <img src={logo} alt={label}/>}
                    <span>{label}</span>
                </components.Option>
            </div>
        );
    };

    if (showLogoOptions) {
        restProps = {...restProps, components: { Option }};
    }

    if (restProps.isFormikForm) {
        const selectedValue = data.filter((obj) => obj.value === field.value);
        restProps = {...restProps, value: (selectedValue[0] || "")};
    }

    return (
        <div styleName={showError ? "styles.errorBox" : ""}>
            <AsyncSelect
                inputValue={inputValue}
                onInputChange={onInputChange}
                ref={searchRef}
                onChange={(value) => {
                    onChange(value, restProps);
                    if (restProps.isFormikForm) form.setFieldValue(restProps.id, value.value);
                }}
                className={`search-select-container`}
                placeholder={placeholder}
                defaultOptions={data}
                loadOptions={filterOptions}
                // options={options}
                classNamePrefix="search-select"
                defaultMenuIsOpen={defaultMenuIsOpen}
                onMenuOpen={onMenuOpenCallback}
                onMenuClose={onMenuCloseCallback}
                isDisabled={disabled}
                {...restProps}
                {...(tabAccessibility ? {
                    openMenuOnFocus: true,
                    tabSelectsValue: true
                } :
                    {
                        tabIndex: -1
                    }
                )}
            />
        </div>
    );
};

Search.propTypes = {
    data: PropTypes.array.isRequired,
    defaultMenuIsOpen: PropTypes.bool,
    showError: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onBlurChange: PropTypes.func.isRequired,
    customDefaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    autoFocus: PropTypes.bool,
    onMenuOpenCallback: PropTypes.func,
    onMenuCloseCallback: PropTypes.func,
    showLogoOptions: PropTypes.bool,
    tabAccessibility: PropTypes.bool,
    styleName: PropTypes.string,
    validator: PropTypes.func,
    disableTextInput: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    defaultInputValue: PropTypes.string,
    disabled: PropTypes.bool
};

export default Search;
