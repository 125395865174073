import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateCheckoutSteps } from "../checkout-info/actions";
import Cars24PlatinumCover from "./component";
import { setAddonScreenType, getCarCoverBundles, clearVASCart, removeVASItem, setCounterAnimation, getVASCart } from "../add-ons/actions";

const mapStateToProps = ({
    carDetails: { content },
    checkout: {
        order
    },
    addOns: {
        vasCart: {
            data: vasCartData
        },
        servicingPriceConfig,
        carCoverBundles: {
            data: carCoverData
        }
    },
    deliveryInfo: {
        deliveryData: { selfPickUp }
    }
}) => ({
    content,
    order,
    vasCartData,
    servicingPriceConfig,
    carCoverData,
    selfPickUp
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateCheckoutStepsConnect: updateCheckoutSteps,
    setAddonScreenTypeConnect: setAddonScreenType,
    getCarCoverBundleConnect: getCarCoverBundles,
    clearVASCartConnect: clearVASCart,
    removeVASItemConnect: removeVASItem,
    setCounterAnimationConnect: setCounterAnimation,
    getVASCartConnect: getVASCart
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cars24PlatinumCover);
