/* eslint-disable complexity */
import React, { Fragment, useRef, useEffect } from "react";
import styles from "./styles/index.css";
import PropTypes from "prop-types";
import scrollToView from "../../../utils/helpers/scroll-to-view";
import ArrowBack from "./images/arrow-back.svg";
const Button = (props) => {
    const {
        onClick,
        id = "",
        rupee = null,
        dataTitle = "",
        text = "",
        transparent = false,
        disabled = false,
        rightImage = null,
        leftImage = null,
        customStyle = [],
        customClass = "",
        isLoading = false,
        hideLoader = false,
        scrollTop,
        type = "button",
        btnView = "old",
        handleClick,
        customDisabled = false,
        showArrow = false,
        ...restProps
    } = props;

    const ref = useRef();

    useEffect(() => {
        if (scrollTop) {
            window.setTimeout(() => {
                scrollToView(ref.current);
            }, 0);
        }
    }, [scrollTop]);
    let buttonStyles = [];
    customStyle.forEach(item => {
        buttonStyles.push(`styles.${item}`);
    });
    buttonStyles = buttonStyles.join(" ");
    const transparentStyle = transparent ? "styles.transparent-btn" : "";
    const disabledStyle = (disabled || customDisabled) ? "styles.disable-btn" : "";
    //eslint-disable-next-line
    const styleName = `styles.common-btn styles.btn-styles ${disabledStyle} ${transparentStyle} ${buttonStyles} ${btnView === "new" ? "styles.new-btn-design" : ""}`;

    return (
        <Fragment>
            <button ref={ref} id={id} data-title={dataTitle}
                disabled={disabled || isLoading} onClick={onClick || handleClick}
                type={type} className={`btn ${customClass}`}
                styleName={styleName}
                {...restProps}
            >
                {leftImage && <img styleName={"styles.img-left"} src={leftImage} />}
                {text} {rightImage && <img styleName={"styles.img-right"} src={rightImage} />}
                {rupee && <span>{rupee}</span>}
                {
                    isLoading && !hideLoader &&
                    <i className="spinner" />
                }
                {(showArrow && !isLoading) && <img styleName={"styles.arrow-img"} src={ArrowBack} />}
            </button>
        </Fragment>
    );
};

Button.propTypes = {
    onClick: PropTypes.func,
    id: PropTypes.string,
    rupee: PropTypes.any,
    dataTitle: PropTypes.string,
    text: PropTypes.string,
    transparent: PropTypes.bool,
    disabled: PropTypes.bool,
    rightImage: PropTypes.any,
    leftImage: PropTypes.any,
    customStyle: PropTypes.array,
    customClass: PropTypes.string,
    isLoading: PropTypes.bool,
    scrollTop: PropTypes.bool,
    hideLoader: PropTypes.bool,
    type: PropTypes.string,
    btnView: PropTypes.string,
    handleClick: PropTypes.func,
    customDisabled: PropTypes.func,
    showArrow: PropTypes.bool
};

export default Button;
