import { createReducer } from "reduxsauce";
import Types from "./types";
import { NUMBER } from "../../../constants/app-constants";

export const INITIAL_STATE = {
    content: [],
    metaContent: {
        metaTitle: null,
        metaDescription: null,
        metaKeyWord: null,
        heading: null
    },
    totalCars: 0,
    totalPages: null,
    isSSR: false,
    page: 0,
    isLoading: false,
    error: null,
    config: {},
    shouldReloadList: false,
    personalised: false,
    dynamicContent: {},
    ipDetectedState: "",
    searchPageResult: {},
    loadingSearch: false,
    similarCarsContent: [],
    totalSimilarCars: 0,
    totalSimilarPages: null,
    isSimilarCarLoading: null,
    similarCarPage: -1,
    loadingFaq: false,
    buyWithConfidence: []
};

export const fetchCarList = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: true
    };
};

export const fetchCarListSuccess = (state = INITIAL_STATE, { data, params }) => {
    return {
        ...state,
        content: params.page === 0 ? data.results : [...state.content, ...data.results],
        metaContent: { ...data.metaContent },
        dynamicContent: params.page === 0 ? { ...data.dynamicContent } : { ...state.dynamicContent },
        totalCars: data.total,
        totalPages: data.totalPages,
        personalised: data.personalised,
        isLoading: false,
        error: null,
        ...((params && params.page >= 0) && { page: params.page }),
        config: data.config,
        ipDetectedState: data.ipDetectedState,
        similarCarsContent: [],
        totalSimilarCars: 0,
        similarCarPage: -1,
        totalSimilarPages: null,
        buyWithConfidence: data.buyWithConfidence || []
    };
};

export const fetchCarListFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        error,
        isLoading: false
    };
};

export const updateSSRStatus = (state = INITIAL_STATE, {isSSR}) => {
    return {
        ...state,
        isSSR
    };
};

export const resetCarList = (state = INITIAL_STATE) => {
    return {
        ...state,
        content: [],
        totalPages: null
    };
};

export const reloadCarList = (state = INITIAL_STATE, {shouldReloadList}) => {
    return {
        ...state,
        shouldReloadList
    };
};

export const fetchSimilarCarList = (state = INITIAL_STATE) => {
    return {
        ...state,
        isSimilarCarLoading: true
    };
};
export const fetchSimilarCarFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        error,
        isSimilarCarLoading: false
    };
};
export const fetchSimilarCarSuccess = (state = INITIAL_STATE, { data, params }) => {
    return {
        ...state,
        totalSimilarCars: data.total,
        totalSimilarPages: Math.ceil(data.total / NUMBER.TWENTY),
        similarCarsContent: [...state.similarCarsContent, ...data.results || []],
        personalised: data.personalised,
        buyWithConfidence: data.buyWithConfidence || [],
        ...(params && params.page >= 0 && { similarCarPage: params.page }),
        isSimilarCarLoading: false,
        error: null,
        config: data.config,
        ipDetectedState: data.ipDetectedState
    };
};

export const HANDLERS = {
    [Types.FETCH_CAR_LIST]: fetchCarList,
    [Types.FETCH_CAR_LIST_SUCCESS]: fetchCarListSuccess,
    [Types.FETCH_SIMILAR_CAR_LIST_SUCCESS]: fetchSimilarCarSuccess,
    [Types.FETCH_CAR_LIST_FAILURE]: fetchCarListFailure,
    [Types.UPDATE_SSR_STATUS]: updateSSRStatus,
    [Types.RESET_CAR_LIST]: resetCarList,
    [Types.RELOAD_CAR_LIST]: reloadCarList
};

export default createReducer(INITIAL_STATE, HANDLERS);
