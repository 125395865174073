import React, {useState} from "react";
import { EXPERIMENT_TYPE } from "../../../../constants/optimize-constants";
import styles from "./styles.css";

import BookingsFooter from "../../bookings-footer/component";
import ContractSaleNote from "../../contract-sale-note";
import SignContractForm from "../sign-contract-form";

const VerifyUserLicencey = () => {
    const [currentStep, setCurrentStep] = useState("SALE_CONTRACT");

    const handleClick = () => {
        setCurrentStep("SALE_CONTRACT_NOTE");
    };

    const handleBackClick = () => {
        setCurrentStep("SALE_CONTRACT");
    };

    return (
        <div className="row">
            {currentStep === "SALE_CONTRACT" &&  <div className="col-lg-7" styleName="styles.wrapper">
                <p styleName={"styles.header"}>Sign contract of sale</p>
                <SignContractForm handleContractStep={handleClick}/>
            </div>
            }
            {currentStep === "SALE_CONTRACT_NOTE" &&
            <div  className="col-lg-7" styleName="styles.wrapper">
                <ContractSaleNote showVariant={EXPERIMENT_TYPE.VARIANT_B} customSignClick={handleBackClick} />
            </div>
            }

            <div className="col-lg-5">
                <div styleName={"styles.getInTouchWrapper"}>
                    <BookingsFooter />
                </div>
            </div>
        </div>
    );
};

export default VerifyUserLicencey;
