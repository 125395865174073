import { b2cBffListingAU, ConfigService, ListingServiceAU } from "../../../service";
import { AU_HOMEPAGE_PAGINATION_SIZE, AU_RECENTLY_VIEWD_KEY, COOKIE_SEARCH_KEYWORDS, CURRENT_SALE, RECENTLY_APPLIED_FILTERS } from "../../../constants/app-constants";

import Types from "./types";
import {getSaleConfigType} from "../../../utils/helpers/sale-config";
import { FINANCE_TYPE } from "../../../constants/checkout-constants";
import { ZERO_DP_VARIANTS } from "../../../constants/optimize-constants";
import { fetchConfigInit, fetchConfigSuccess } from "../config-ssr/actions";
import parseCookie from "../../../utils/helpers/parse-cookie";
import Filters from "../../../utils/filters-v2";

const fetchCarListInit = () => ({
    type: Types.FETCH_CAR_LIST
});

const fetchCarListSuccess = (data, params, contentKey) => ({
    type: Types.FETCH_CAR_LIST_SUCCESS,
    data,
    params,
    contentKey
});

const setHomeSSRData = (data) => ({
    type: Types.SET_HOME_SSR,
    data
});

const fetchCarListFailure = (error) => ({ type: Types.FETCH_CAR_LIST_FAILURE, error });

const fetchCarList = (params, queryString, contentKey) => (dispatch, getState) => {

    dispatch(fetchCarListInit());
    const {
        user: { secureToken, isUserZeroDpVariant }
    } = getState();
    if (queryString && queryString.indexOf("userFinanceType") < 0) {
        queryString = `${queryString}&userFinanceType=${FINANCE_TYPE.CARS24}`;
    }

    const cityCode = "AU_VIC_MEL";
    const defaultParams = {
        ...(cityCode && { sf: `city:${cityCode}` }),
        size: AU_HOMEPAGE_PAGINATION_SIZE,
        page: 0,
        sort: "releavance",
        zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP
    };
    params = {...(params || defaultParams), zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP};
    return new Promise((resolve, reject) => {
        ListingServiceAU.fetchListing(params || defaultParams, queryString, secureToken)
            .then((response) => {
                dispatch(fetchCarListSuccess(response.data, params, contentKey));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(fetchCarListFailure(error));
                reject(error);
            });
    });
};

// const fetchConfigInit = () => ({
//     type: Types.FETCH_CONFIG
// });

// const fetchConfigSuccess = (data, params) => ({
//     type: Types.FETCH_CONFIG_SUCCESS,
//     data,
//     params
// });

const fetchConfigFailure = (error) => ({ type: Types.FETCH_CONFIG_FAILURE, error });

const fetchConfig = (params) => (dispatch) => {
    dispatch(fetchConfigInit());
    const {type = CURRENT_SALE} = params || {};
    return new Promise((resolve, reject) => {
        ConfigService.fetchConfigDetails({payload: [{configType: type}]})
            .then((response = {}) => {
                let obj = {};
                const {responseCode = null, message = null, data = []} = response.data || {};
                const currentLiveSale = data.find(item => item.config.saleLive) || {};
                const currentLiveSaleData = currentLiveSale.config || {};
                obj = {responseCode, message, ...currentLiveSaleData};
                const configObj = getSaleConfigType(obj);
                dispatch(fetchConfigSuccess({...obj, ...configObj}));
                resolve(obj);
            })
            .catch((error) => {
                dispatch(fetchConfigFailure(error));
                reject(error);
            });
    });
};

const sendAppLinkInit = () => ({
    type: Types.SEND_APP_LINK
});

const sendAppLinkSuccess = (data, params) => ({
    type: Types.SEND_APP_LINK_SUCCESS,
    data,
    params
});

const sendAppLinkFailure = (error) => ({ type: Types.SEND_APP_LINK_FAILURE, error });

const sendAppLink = (params) => (dispatch) => {
    dispatch(sendAppLinkInit());
    return new Promise((resolve, reject) => {
        ListingServiceAU.sendAppLink({mobile: params})
            .then((response = {}) => {
                dispatch(sendAppLinkSuccess(response));
                resolve(response);
            })
            .catch((error) => {
                dispatch(sendAppLinkFailure(error));
                reject(error);
            });
    });
};

const getSeoListInit = () => ({
    type: Types.SEO_HEADER_MENU
});

const getSeoListSuccess = (data, params) => ({
    type: Types.SEO_HEADER_MENU_SUCCESS,
    data,
    params
});

const getSeoListFailure = (data, params) => ({
    type: Types.SEO_HEADER_MENU_SUCCESS,
    data,
    params
});

const getSeoList = (params) => (dispatch) => {
    dispatch(getSeoListInit());
    return new Promise((resolve, reject) => {
        ListingServiceAU.getMenuSeoList({mobile: params})
            .then((response = {}) => {
                dispatch(getSeoListSuccess(response.data));
                resolve(response);
            })
            .catch((error) => {
                dispatch(getSeoListFailure(error));
                reject(error);
            });
    });
};

const setHomeSSR = (data) => (dispatch) => {
    dispatch(setHomeSSRData(data));
};

// const setCurrentScreen = (data) => {
//     let screenName = data;
//     if (typeof window !== "undefined") {
//         screenName = getItem("home_screen");
//         if (!screenName) {
//             setItem(screenName, "home_screen");
//         }
//     }
//     return ({
//         type: Types.SET_LOGO_URL,
//         data: screenName
//     });
// };

const fetchTotalAvailableCarSuccess = (data) => ({
    type: Types.FETCH_TOTAL_CAR_SUCCESS,
    data
});
const fetchTotalAvailableCarFailure = () => ({
    type: Types.FETCH_TOTAL_CAR_FAILURE
});

const fetchTotalAvailableCar = (params) => async (dispatch) => {
    try {
        const response = await ListingServiceAU.getTotalAvailableCars(params);
        dispatch(fetchTotalAvailableCarSuccess(response.data));
    } catch (error) {
        fetchTotalAvailableCarFailure(error);
    }
};

const fetchSearchPagesSuccess = (data) => ({
    type: Types.FETCH_SEARCH_PAGE_SUCCESS,
    data
});

const fetchSearchPagesFailure = () => ({
    type: Types.FETCH_SEARCH_PAGE_FAILURE

});
const fetchSearchPageInit = () => ({
    type: Types.FETCH_SEARCH_PAGE_INIT,
    loadingSearch: true
});

// eslint-disable-next-line max-statements
const fetchSearchResult = (payload = {}, params) => async (dispatch, getState) => {
    const {
        filters: {
            allFilters
        },
        cities: {
            selectedCity: {
                code: selectedCityCode
            } = {}
        } } = getState();

    dispatch(fetchSearchPageInit());
    const searchedKeyword = JSON.parse(parseCookie(COOKIE_SEARCH_KEYWORDS));
    const selectedFilters = JSON.parse(parseCookie(RECENTLY_APPLIED_FILTERS)) || [];
    const currentFilters = new Filters({ cityCode: selectedCityCode });
    const { payload: topFilterSearchUrl } = currentFilters.getListingPayload({
        selectedFilters,
        filters: allFilters
    });

    const {user: { secureToken }} = getState();
    try {
        const requestBody = {
            ...payload,
            recurringUser: !!secureToken,
            appointmentIdList: JSON.parse(parseCookie(AU_RECENTLY_VIEWD_KEY)),
            recentlySearched: searchedKeyword ? searchedKeyword.map(item => item.value) : [],
            ...topFilterSearchUrl && {topFilterSearchUrl}
        };
        const response = await b2cBffListingAU.fetchSearchPage(requestBody, secureToken, params);
        const {result = [], config} = response.data || {};
        let obj = {};
        result.forEach(item => (obj[item.type] = Array.isArray(item.templates) ? item.templates[0] : item.templates));
        /**
         * UNCOMMENT THE BELOW CODE TO GET RECENTLY APPLIED FILTER FROM API INSTEAD OF COOKIE
         * Change if (selectedFilters.length > 0) to if (personalizedFilters)
         */
        //const { personalizedFilters = {} } = obj;
        //if (personalizedFilters) {
        if (selectedFilters.length > 0) {
            //const { result: personalizedFiltersResult = {} } = personalizedFilters;
            //const parsedFilters = getFiltersData(personalizedFiltersResult, true);
            //const { appliedFilters = [] } = initAppliedFilters(parsedFilters.allFilters) || {};
            obj = {
                ...obj,
                personalizedFilters: selectedFilters
            };
        }

        dispatch(fetchSearchPagesSuccess({config, ...obj }));
    } catch (error) {
        dispatch(fetchSearchPagesFailure(error));
    }
};

export { fetchCarList,
    fetchConfig,
    sendAppLink,
    getSeoList,
    setHomeSSR,
    fetchTotalAvailableCar,
    fetchSearchResult
};
