/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import styles from "./styles.css";

import Modal from "../../../shared/modal";
import VasStoreSlider from "../vas-store-slider/component";
import Button from "../../../shared/button";
import MaximumSavingCard from "../maximum-saving-card/component";
import { trackDesktopCustomEventsAU } from "../../../../tracking";
import { SCREEN_TYPE } from "../reducers";
import { addVASItem, clearVASCart, removeVASItem, setAddonScreenType, setCounterAnimation } from "../actions";
import { showToast } from "../../../shared/toast-message/actions";
import CarPackage from "../car-package/component";
import VasPackageAddModal from "../../vas-package-add-modal/component";
import { NUMBER, SERVICING_VARIANT_MAPPING, VAS_PAGE_SCREEN_TYPE } from "../../../../constants/app-constants";
import VasStoreHeader from "../../vas-store-header/component";
import Disclaimer from "../disclaimer";

import InfoIcon from "./images/info.svg";
import { filterExcludedItems } from "../../../../utils/helpers/checkout-util";
import { getPaymentSummary } from "../../my-bookings/actions";
import { AU_DESKTOP_EVENTS } from "../../../../tracking/au.desktop-events";

const VASStore = ({
    addKey,
    addLoading,
    appointmentId,
    addVASItemConnect = () => { },
    clearVASCartConnect = () => { },
    content,
    model,
    onClose = () => { },
    open = true,
    orderId,
    preApproved = false,
    removeKey,
    removeLoading,
    removeVASItemConnect = () => { },
    setAddonScreenTypeConnect = () => { },
    setCounterAnimationConnect = () => { },
    showToastConnect = () => { },
    vasCartData = {},
    vasItems = {},
    servicingPriceConfig,
    getPaymentSummaryConnect = () => { }
}) => {

    const { data } = vasItems || {};
    const { metadata = {} } = data || {};
    const { disclaimer, items = [], highlights = [], desktopBanner } = metadata || {};
    const { iconUrl: desktopBannerIcon } = desktopBanner || {};

    const [packageAddedModal, setPackageAddedModal] = useState(false);
    const [packageAddedModalInfo, setPackageAddedModalInfo] = useState({});

    const vasCartItems = (vasCartData || {}).items || [];
    const isNewServicingVariant = servicingPriceConfig?.data?.experiment === SERVICING_VARIANT_MAPPING.VARIANT;
    const filteredVASItems = isNewServicingVariant ? filterExcludedItems(vasCartItems) : vasCartItems;
    const onCloseClick = () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.PRE_BC_CAR_CARE_PACKS_VAS_STORE, {
            eventLabel: "Yes",
            eventAction: "Back"
        });
        onClose();
    };

    const onClearCartClick = async () => {
        await clearVASCartConnect(orderId);
        await getPaymentSummaryConnect();
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.PRE_BC_CAR_CARE_PACKS_VAS_STORE, {
            eventAction: "VAS Store_Bottom nav_click_clear cart",
            eventLabel: "Yes"
        });
    };

    const onViewCartClick = async () => {
        await getPaymentSummaryConnect();
        setCounterAnimationConnect(false);
        setAddonScreenTypeConnect({ screenType: SCREEN_TYPE.CART_VIEW });
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.PRE_BC_CAR_CARE_PACKS_VAS_STORE, {
            eventAction: "VAS Store_Bottom nav_click_view cart",
            eventLabel: "Yes"
        });
    };

    useEffect(() => {
        if (open) {
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.PRE_BC_CAR_CARE_PACKS_VAS_STORE, {
                eventAction: "Landed",
                eventLabel: "Yes"
            });
        }

    }, [open]);

    useEffect(() => {
        if (typeof window !== "undefined") {
            document.body.style.overflow = "hidden";
        }

        return () => {
            if (typeof window !== "undefined") {
                document.body.style.overflow = "unset";
            }
        };
    }, []);

    return (
        <Modal isOpen={open} close={onCloseClick}>
            <div styleName={`styles.vasModalOuter ${vasCartItems.length > 0 ? "styles.vasModalOuterPadding" : ""}`}>
                <VasStoreHeader model={model} onCloseClick={onCloseClick} />
                <div styleName={"styles.modalContainer"}>
                    <VasStoreSlider />
                    <div styleName={"styles.contentContainer"}>
                        <div styleName={"styles.modalLeftSection"}>
                            {(items || []).length > 0 && items.map((item, i) => {
                                const { key, type, name } = item || {};
                                const isLastEl = items.length - 1 === i;

                                const isItemAlreadyPresent = filteredVASItems.findIndex((itm) => itm.key === key);

                                let ctaText = "Add";
                                if (isItemAlreadyPresent !== -1) ctaText = "Remove";

                                const loading = (addLoading || removeLoading) && key === (addKey || removeKey);

                                const onRemoveClick = async () => {
                                    setCounterAnimationConnect(false);
                                    await removeVASItemConnect({ appointmentId, key, type, orderId });
                                    setCounterAnimationConnect(true);
                                };

                                const onAddClick = async (e) => {
                                    e.stopPropagation();
                                    if (isItemAlreadyPresent !== -1) {
                                        onRemoveClick();
                                        return;
                                    }
                                    try {
                                        const { errorDetails } = await addVASItemConnect({ appointmentId, key, type, orderId, vasPageName: VAS_PAGE_SCREEN_TYPE.ADD_ONS });
                                        if (errorDetails) {
                                            const { variant, message } = errorDetails || {};
                                            if (variant === "TOAST") {
                                                showToastConnect({ variant: "black", text: message, position: "bottom", toastHideTime: NUMBER.TWO_THOUSAND, toastStyle: "addOnToastMsg" });
                                            } else if (variant === "POPUP") {
                                                setPackageAddedModal(true);
                                                setPackageAddedModalInfo(errorDetails);
                                            }
                                        }
                                    } catch (err) {
                                        /*console.log('error', err)*/
                                    }

                                    trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.PRE_BC_CAR_CARE_PACKS_VAS_STORE, {
                                        eventAction: "Product_add_VAS store",
                                        eventLabel: name
                                    });
                                };
                                return (
                                    <div key={key} styleName={`styles.carPackage ${isLastEl ? "styles.lastChild" : ""}`}>
                                        <CarPackage content={content} loading={loading} onClick={onAddClick} item={item} ctaText={ctaText} />
                                    </div>
                                );
                            })}

                            {preApproved && <Disclaimer />}

                        </div>
                        <div styleName={"styles.modalRightSection"}>
                            {desktopBannerIcon && <div styleName={"styles.bannerWrapper"}><img src={desktopBannerIcon} /></div>}
                            {(highlights || []).length > 0 && (
                                <div className={styles.highlights}>
                                    {highlights.map((highlight) => {
                                        const { name, iconUrl = "", description } = highlight || {};
                                        return (
                                            <MaximumSavingCard key={name} savingCardImage={iconUrl} savingCardHeading={name} savingCardDetail={description} />
                                        );
                                    })}
                                </div>
                            )}
                            {disclaimer && (
                                <div styleName={"styles.tintDisclaimer"}>
                                    <img src={InfoIcon} />
                                    <p>{disclaimer}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {filteredVASItems.length > 0 &&
                    <div styleName={"styles.footerWrrapper"}>
                        <div styleName={"styles.leftWrapper"}>
                            <p styleName={"styles.addedItem"}>{filteredVASItems.length} items added</p>
                            <p styleName={"styles.clearCart"} onClick={onClearCartClick}>Clear Cart</p>
                        </div>

                        <Button onClick={onViewCartClick} text="VIEW CART" />
                    </div>
                }
            </div>

            {packageAddedModal && <VasPackageAddModal info={packageAddedModalInfo} open={packageAddedModal} onClose={() => setPackageAddedModal(false)} />}
        </Modal>
    );
};

const mapStateToProps = ({
    carDetails: {
        content,
        content: {
            appointmentId,
            model
        }
    },
    checkout: {
        order: {
            orderId
        },
        quotesAvailable: preApproved
    },
    addOns: {
        vasItems,
        activeStore,
        vasCart: {
            data: vasCartData
        },
        addVASItem: {
            loading: addLoading,
            key: addKey
        },
        removeVASItem: {
            loading: removeLoading,
            key: removeKey
        },
        servicingPriceConfig
    }
}) => ({
    activeStore,
    addKey,
    addLoading,
    appointmentId,
    content,
    model,
    removeKey,
    removeLoading,
    orderId,
    preApproved,
    vasItems,
    vasCartData,
    servicingPriceConfig
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    addVASItemConnect: addVASItem,
    clearVASCartConnect: clearVASCart,
    removeVASItemConnect: removeVASItem,
    setAddonScreenTypeConnect: setAddonScreenType,
    setCounterAnimationConnect: setCounterAnimation,
    showToastConnect: showToast,
    getPaymentSummaryConnect: getPaymentSummary
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VASStore);

VASStore.propTypes = {
    activeStore: PropTypes.string,
    addKey: PropTypes.string,
    addLoading: PropTypes.bool,
    appointmentId: PropTypes.string,
    addVASItemConnect: PropTypes.func,
    clearVASCartConnect: PropTypes.func,
    content: PropTypes.object,
    model: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    orderId: PropTypes.string,
    preApproved: PropTypes.bool,
    removeVASItemConnect: PropTypes.func,
    removeKey: PropTypes.string,
    removeLoading: PropTypes.bool,
    setAddonScreenTypeConnect: PropTypes.func,
    setCounterAnimationConnect: PropTypes.func,
    showToastConnect: PropTypes.func,
    vasCartData: PropTypes.object,
    vasItems: PropTypes.object,
    servicingPriceConfig: PropTypes.object,
    getPaymentSummaryConnect: PropTypes.func
};

