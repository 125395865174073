import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";

import Arrow from "./images/arrow.svg";
import { makePriceLabelRound } from "../../../../utils/helpers/make-price-label";
import IconSpinner from "../../../shared/icon-spinner";
import snarkdown from "snarkdown";
import { useHistory } from "react-router-dom";
import { getCheckoutPageURL } from "../../../../utils/helpers/get-detail-page-url";
import { checkoutRoutes } from "../../../../constants/checkout-routes";
import { trackDesktopCustomEventsAU } from "../../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../../tracking/au.desktop-events";

const CarPackage = ({ content, item = {}, loading, ctaText = "Add", onClick = () => { } }) => {
    const history = useHistory();

    const {
        amount,
        detailsPageAvailable,
        discount,
        discountedPrice,
        description,
        iconUrl,
        name,
        key,
        category = [],
        tag,
        repaymentsPerWeek,
        type
    } = item || {};

    const onProductClick = () => {
        if (!detailsPageAvailable) return;

        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.PRE_BC_CAR_CARE_PACKS_LANDING_PAGE, {
            eventAction: "Product_detail",
            eventLabel: name
        });

        const { year, egc } = content || {};
        const { relativeURL } = getCheckoutPageURL(content, checkoutRoutes.addOns.route);
        const selector = relativeURL.includes("?") ? "&" : "?";
        history.push(`${relativeURL}${selector}key=${key}&type=${type}&carYear=${year}&carPrice=${egc}`);
    };

    return (
        <div styleName={`styles.cardOuter ${detailsPageAvailable ? "styles.cardClick" : ""}`} onClick={onProductClick}>
            <div styleName={"styles.cardTopSection"}>
                {tag &&
                    <div styleName={"styles.stickerWrapper"}>
                        <p styleName={"styles.saveAmount"}>{tag}</p>
                    </div>
                }
                <div styleName={"styles.cardHeaderWrap"}>
                    <p styleName={"styles.cardHeading"}>{name} {detailsPageAvailable && <img src={Arrow} />}</p>
                    <p styleName={"styles.cardSubHeading"}>{description}</p>
                </div>
                <div styleName={"styles.contentWrap"}>
                    {iconUrl && <div styleName={"styles.iconWrap"}><img src={iconUrl} /></div>}
                    <div styleName={"styles.flexContainer"}>
                        {category.map((product) => {
                        // eslint-disable-next-line no-shadow
                            const { key, name, iconUrl } = product || {};
                            return (
                                <div key={key} styleName={"styles.checklistWrap"}>
                                    <img src={iconUrl} />
                                    <p styleName={"styles.list"} dangerouslySetInnerHTML={{
                                        __html: snarkdown(name || "")
                                    }}/>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div styleName={"styles.cardBottomSection"}>
                <div>
                    <p styleName={"styles.actualPrice"}>
                        <span styleName={"styles.perWeek"}>{makePriceLabelRound(repaymentsPerWeek)}/week</span>
                        <span styleName={"styles.discountedPrice"}>{makePriceLabelRound(discountedPrice)}</span>
                        {discountedPrice < amount && <span styleName={"styles.discountPrice"}>{makePriceLabelRound(amount)}</span>}
                        {discount > 0 && <span styleName={"styles.savePrice"}>Save {makePriceLabelRound(discount)}</span>}
                    </p>
                </div>
                <button onClick={onClick} styleName={"styles.outerBtn"}>
                    {loading ? <IconSpinner /> : ctaText}
                </button>
            </div>
        </div>
    );
};

export default CarPackage;

CarPackage.propTypes = {
    content: PropTypes.object,
    ctaText: PropTypes.string,
    loading: PropTypes.bool,
    preApproved: PropTypes.bool,
    item: PropTypes.object,
    onClick: PropTypes.func
};

