/* eslint-disable max-statements */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PlatinumCoverMonthlyCard from "../../platinum-cover-monthly-card/component";
import ProceedBtn from "./images/proceed.svg";
import styles from "./styles.css";
import { trackMobileCustomEventsAU } from "../../../../tracking";
import ExploreOtherPlansModal from "../add-ons-explore";
import ArrowImage from "./images/arrow.svg";
import VasPackageAddModal from "../../vas-package-add-modal/component";
import PLATINUM_COVER_GA_EVENTS from "../add-ons/tracking";
import { makePriceLabelRound } from "../../../../utils/helpers/make-price-label";

const PlatinumCoverExpandedSection = ({
    isExpanded,
    productList,
    addVASItemConnect,
    removeVASItemConnect,
    setCounterAnimationConnect,
    orderId,
    year,
    egc,
    appointmentId,
    setExpandedSection,
    skipText,
    postBcLandingPlans,
    expandedSection,
    isStepSkipped,
    isStepAdded,
    additionalInfoText,
    postBcUpdateStepConnect,
    getPostBcLandingPlansConnect,
    updatePostBcStepConnect,
    handleKnowMoreModal,
    categoryKey,
    getPaymentSummaryConnect,
    bannerText,
    bannerImage,
    isFinanceUser,
    setShowAddonsNudge,
    leastRepaymentsPerWeek
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [packageAddedModalInfo, setPackageAddedModalInfo] = useState({});
    const [packageAddedModal, setPackageAddedModal] = useState(false);
    const [showExploreOtherPlansModal, setShowExploreOtherPlansModal] = useState(false);
    const [selectedPlanData, setSelectedPlanData] = useState(null);
    const selectedSectionIndex = postBcLandingPlans?.optionsList?.findIndex(
        (plan) => plan.categoryKey === expandedSection
    );
    const selectedSection = postBcLandingPlans?.optionsList?.[selectedSectionIndex];

    const addedPlansData = selectedSection?.addedPackage;
    const suggestedPlans = selectedSection?.vasPackage?.filter((item) => item.suggested);

    useEffect(() => {
        if (isStepSkipped) {
            setSelectedPlanData(null);
        } else if (addedPlansData?.length > 0) {
            setSelectedPlanData(addedPlansData?.[0]);
        } else if (suggestedPlans?.length > 1) {
            setSelectedPlanData(null);
        } else if (suggestedPlans?.[0]?.key) {
            setSelectedPlanData(suggestedPlans?.[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showExploreOtherPlansModal, postBcLandingPlans?.optionsList, expandedSection]);

    const onRemoveClick = async (e, index) => {
        const { key: productKey, type: productType } = addedPlansData[index] || {};

        try {
            if (e) e.stopPropagation();
            setSelectedPlanData(null);
            setIsLoading(true);
            setCounterAnimationConnect(false);

            await removeVASItemConnect({
                appointmentId,
                key: productKey,
                type: productType,
                orderId,
                year,
                price: egc,
                vasPageName: "CAR24_COVER",
                source: "POST_BC",
                updateQuote: isFinanceUser
            });
            await getPostBcLandingPlansConnect({ orderId, appointmentId });
            await getPaymentSummaryConnect();
            setIsLoading(false);
            setShowAddonsNudge(true);
            setCounterAnimationConnect(true);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const onAddClick = async (index) => {
        const { key: productKey, type: productType, name: productName, categoryKey: productCategoryKey } = productList[index] || {};

        try {
            setIsLoading(true);
            const addedVasData = await addVASItemConnect({
                appointmentId,
                key: productKey,
                type: productType,
                orderId,
                year,
                price: egc,
                vasPageName: "CAR24_COVER",
                source: "POST_BC",
                updateQuote: isFinanceUser
            });
            await postBcUpdateStepConnect({ orderId, key: productCategoryKey, appointmentId, action: "OPTED" });
            const promises = [
                getPostBcLandingPlansConnect({ orderId, appointmentId }),
                getPaymentSummaryConnect()
            ];
            await Promise.all(promises);
            const { errorDetails } = addedVasData;
            if (errorDetails) {
                const { variant } = errorDetails || {};
                if (variant === "TOAST") {
                    // showToastConnect({ variant: "black", text: message, position: "bottom", toastHideTime: NUMBER.TWO_THOUSAND, toastStyle: "addOnToastMsg" });
                } else if (variant === "POPUP") {
                    setPackageAddedModal(true);
                    setPackageAddedModalInfo(errorDetails);
                    if (selectedSectionIndex === postBcLandingPlans?.optionsList?.length - 1) {
                        setExpandedSection("last");
                        await updatePostBcStepConnect({ orderId, step: "addOns" });
                    }
                }
            } else {
                if (selectedSectionIndex < postBcLandingPlans?.optionsList?.length - 1) {
                    setExpandedSection(postBcLandingPlans?.optionsList?.[selectedSectionIndex + 1]?.categoryKey);
                } else {
                    setExpandedSection("last");
                    await updatePostBcStepConnect({ orderId, step: "addOns" });
                }
                setCounterAnimationConnect(true);
            }
            setShowAddonsNudge(true);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }

        trackMobileCustomEventsAU("", {
            ...PLATINUM_COVER_GA_EVENTS.ADD_TO_LOAN_OR_CART,
            eventLabel: PLATINUM_COVER_GA_EVENTS.ADD_TO_LOAN_OR_CART.eventLabel(productName, expandedSection)
        });
    };

    const onProceedClick = async (index) => {
        onAddClick(index);
        setShowExploreOtherPlansModal(false);
    };

    const onSkipClick = async () => {
        await postBcUpdateStepConnect({ orderId, key: expandedSection, appointmentId, action: "SKIPPED" });
        await getPostBcLandingPlansConnect({ orderId, appointmentId });
        if (selectedSectionIndex === postBcLandingPlans?.optionsList?.length - 1) {
            await updatePostBcStepConnect({ orderId, step: "addOns" });
            setExpandedSection("last");
        } else {
            setExpandedSection(postBcLandingPlans?.optionsList?.[selectedSectionIndex + 1]?.categoryKey);
        }

        trackMobileCustomEventsAU("", {
            ...PLATINUM_COVER_GA_EVENTS.I_DONT_WANT_CLICKED,
            eventLabel: PLATINUM_COVER_GA_EVENTS.I_DONT_WANT_CLICKED.eventLabel(expandedSection)
        });
    };

    const onCloseVasPackageModal = () => {
        setPackageAddedModal(false);
        // expandedSection("bundle");
    };

    const AddedPlansView = () => {
        return (
            <React.Fragment>
                <p styleName={"styles.suggestedPlans"}>Selected plan</p>
                {addedPlansData?.map((item, index) => {
                    return (
                        <PlatinumCoverMonthlyCard
                            type={isFinanceUser ? "loan" : "payout"}
                            coverDetails={item}
                            isActive={selectedPlanData?.key === item?.key}
                            setSelectedProductIndex={() => setSelectedPlanData(item)}
                            index={index}
                            clearSelection={onRemoveClick}
                            showPriceWithEmi={false}
                            hideClearButton={false}
                            bottomSticker={item.suggestedItemDescription}
                            repaymentsPerWeek={item?.repaymentsPerWeek}
                            isPostBcCard={true}
                        />
                    );
                })}
            </React.Fragment>
        );
    };

    const SuggestedPlansView = () => {
        return (
            <React.Fragment>
                <p styleName={"styles.suggestedPlans"}>Suggested plan</p>
                {suggestedPlans?.map((item, index) => {
                    return (
                        <PlatinumCoverMonthlyCard
                            type={isFinanceUser ? "loan" : "payout"}
                            coverDetails={item}
                            isActive={selectedPlanData?.key === item?.key}
                            setSelectedProductIndex={() => setSelectedPlanData(item)}
                            index={index}
                            clearSelection={onRemoveClick}
                            showPriceWithEmi={false}
                            hideClearButton={true}
                            bottomSticker={item.suggestedItemDescription}
                            repaymentsPerWeek={item?.repaymentsPerWeek}
                            isPostBcCard={true}
                        />
                    );
                })}
            </React.Fragment>
        );
    };

    const handleExploreOtherPlans = () => {
        setShowExploreOtherPlansModal(true);
        trackMobileCustomEventsAU("", {
            ...PLATINUM_COVER_GA_EVENTS.ADD_ONS_HOME_PAGE.OTHER_PLANS_EXPLORED,
            eventLabel: PLATINUM_COVER_GA_EVENTS.ADD_ONS_HOME_PAGE.OTHER_PLANS_EXPLORED.eventLabel(expandedSection)
        });
    };

    return (
        <div
            styleName={`${!isExpanded ? "styles.collapsed" : "styles.expanded"} ${isLoading ? "styles.loadingCart" : ""
                }`}
        >
            <VasPackageAddModal
                info={packageAddedModalInfo}
                open={packageAddedModal}
                onClose={onCloseVasPackageModal}
            />

            {isStepAdded ? <AddedPlansView /> : <SuggestedPlansView />}

            {productList?.filter((item) => !item.suggested).length ? <div styleName={"styles.explorePlansWrapper"} onClick={handleExploreOtherPlans}>
                <div styleName={"styles.leftWrap"}>
                    <p styleName={"styles.explorePlans"}>Explore other plans</p>
                    <p styleName={"styles.planDuration"}>{additionalInfoText} {isFinanceUser && categoryKey === "VAS_POPULAR_BUNDLES" ? `(${makePriceLabelRound(leastRepaymentsPerWeek)}/week)` : ""}</p>
                </div>
                <img src={ArrowImage} />
            </div> : null
            }
            {!isStepAdded && (
                <div styleName={"styles.buttonWrapper"}>
                    <button
                        styleName={`${selectedPlanData === null ? "styles.disabledButton" : ""}`}
                        disabled={selectedPlanData === null}
                        onClick={() => onAddClick(selectedSection?.vasPackage?.findIndex((el) => el.key === selectedPlanData?.key))}
                    >
                        {isFinanceUser ? "Add to loan" : "Add"}
                        <img src={ProceedBtn} />
                    </button>
                    <div styleName={"styles.skipText"} onClick={onSkipClick}>
                        {skipText}
                    </div>
                </div>
            )}
            {
                productList?.filter((item) => !item.suggested).length ? (
                    <ExploreOtherPlansModal
                        productList={productList}
                        onProceedClick={onProceedClick}
                        onRemoveClick={onRemoveClick}
                        selectedProductIndex={productList?.findIndex((item) => item.key === selectedPlanData?.key)}
                        setSelectedProductIndex={(index) => setSelectedPlanData(productList[index])}
                        isOpen={showExploreOtherPlansModal}
                        onClose={() => setShowExploreOtherPlansModal(false)}
                        handleKnowMoreModal={handleKnowMoreModal}
                        categoryKey={categoryKey}
                        bannerText={bannerText}
                        bannerImage={bannerImage}
                        isFinanceUser={isFinanceUser}
                        isStepAdded={isStepAdded}
                        addedPlansData={addedPlansData}
                        selectedPlanData={selectedPlanData}
                        setSelectedPlanData={setSelectedPlanData}
                    />
                ) : null
            }

            {/* {type === "servicing" && <div styleName="styles.servicingLocation"><img src={LocationIcon} alt="Location"/>Center - {servicingLocation} </div>} */}
        </div>
    );
};

PlatinumCoverExpandedSection.propTypes = {
    isExpanded: PropTypes.bool,
    productList: PropTypes.array,
    selectedProductIndex: PropTypes.number,
    setSelectedProductIndex: PropTypes.func,
    type: PropTypes.string,
    clearSelection: PropTypes.func,
    addVASItemConnect: PropTypes.func,
    removeVASItemConnect: PropTypes.func,
    setCounterAnimationConnect: PropTypes.func,
    financeFirstCheckoutEnabled: PropTypes.bool,
    updateLoanQuoteConnect: PropTypes.func,
    orderId: PropTypes.string,
    year: PropTypes.string,
    egc: PropTypes.string,
    appointmentId: PropTypes.string,
    showToastConnect: PropTypes.func,
    addedPlansData: PropTypes.object,
    selfPickUp: PropTypes.array,
    expandedSection: PropTypes.func,
    planKey: PropTypes.string,
    openExploreSection: PropTypes.func,
    setExpandedSection: PropTypes.func,
    skipText: PropTypes.string,
    postBcLandingPlans: PropTypes.object,
    isStepSkipped: PropTypes.bool,
    isStepAdded: PropTypes.bool,
    paymentOption: PropTypes.string,
    additionalInfoText: PropTypes.string,
    postBcUpdateStepConnect: PropTypes.func,
    getPostBcLandingPlansConnect: PropTypes.func,
    updatePostBcStepConnect: PropTypes.func,
    handleKnowMoreModal: PropTypes.func,
    categoryKey: PropTypes.string,
    getPaymentSummaryConnect: PropTypes.func,
    bannerText: PropTypes.string,
    bannerImage: PropTypes.string,
    isFinanceUser: PropTypes.bool,
    setShowAddonsNudge: PropTypes.func,
    leastRepaymentsPerWeek: PropTypes.string
};

export default PlatinumCoverExpandedSection;

