/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState, useEffect } from "react";
import styles from "./styles.css";
import CheckoutLayout from "../checkout-layout";
import PropTypes from "prop-types";
// import DriveAwayPriceModal from "../drive-away-price-modal/component";
// import Cars24PlatinumCoverTheBestModal from "../cars24-platinum-cover-the-best-modal/component";
import PlatinumCoverPlans from "../platinum-cover-plans";
import { getCheckoutPageURL } from "../../../utils/helpers/get-detail-page-url";
import { useHistory } from "react-router";
import { CHECKOUT_STEPS, CHECKOUT_STEPS_STATUS } from "../../../constants/checkout-constants";
import { SCREEN_TYPE } from "../add-ons/reducers";
import Cars24CoverRightPanel from "../cars24-cover-right-panel/component";
import Button from "../../shared/button";
import SeeWhatIsCoveredModal from "../see-what-is-covered-modal/component";
import Cars24PlatinumCoverTheBestModal from "../cars24-platinum-cover-the-best-modal/component";
import { NUMBER, VAS_PAGE_SCREEN_TYPE } from "../../../constants/app-constants";
import CustomiseYourCoverModal from "../customise-your-cover-modal/component";
import { filterCartItems } from "../../../utils/helpers/checkout-util";
import { PLATINUM_COVER_GA_EVENTS } from "./tracking";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import TrackImpression from "../../shared/track-impression";
import LoaderScreen from "../loader-screen/component";

const getWarrantyText = (warranty, warrantyEndDate) => {
    if (warranty === "Active") {
        return `active till ${warrantyEndDate}.`;
    }
    return "no longer active.";
};

const Cars24PlatinumCover = ({
    routeData,
    content,
    order = {},
    vasCartData,
    getCarCoverBundleConnect,
    servicingPriceConfig,
    clearVASCartConnect,
    setAddonScreenTypeConnect,
    updateCheckoutStepsConnect,
    carCoverData,
    removeVASItemConnect,
    setCounterAnimationConnect,
    selfPickUp,
    getVASCartConnect
}) => {
    const { orderId } = order;
    const vasCartItems = (vasCartData || {}).items || [];
    const [showWhatsCoveredModal, setShowWhatsCoveredModal] = useState(false);
    const [whyUsModal, setWhyUsModal] = useState(false);
    const carCoverDetails = carCoverData?.carCoverDetails;
    const { egc: egcPrice, appointmentId, year, make, model } = content || {};
    const vasCartProducts = filterCartItems(vasCartItems);
    const history = useHistory();
    const { warranty, warrantyEndDate } = carCoverData || {};
    const [cartLoading, setCartLoading] = useState(false);
    const [showCustomiseCoverModal, setShowCustomiseCoverModal] = useState(false);
    const { interestRate = 0, comparisonRate = 0, personalised = false } = carCoverDetails || {};
    const servicingLocation = selfPickUp?.[0]?.pickUpLocation?.locationAddress;
    const [bundleLoading, setBundleLoading] = useState(false);

    const handleWhatsCoveredModal = () => {
        setShowWhatsCoveredModal((prev) => !prev);
    };
    const handleWhyUsModal = () => {
        setWhyUsModal((prev) => !prev);
    };

    const fetchBundleData = async () => {
        setBundleLoading(true);
        await getCarCoverBundleConnect();
        setBundleLoading(false);
    };

    useEffect(() => {
        if (!servicingPriceConfig?.loading && servicingPriceConfig?.data?.experiment) {
            fetchBundleData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [servicingPriceConfig]);

    useEffect(() => {
        if (orderId) {
            getVASCartConnect({orderId});
        }
    }, [getVASCartConnect, orderId]);

    if (bundleLoading) {
        return <LoaderScreen />;
    }

    const stepData = {
        appointmentId: content.appointmentId,
        step: CHECKOUT_STEPS.CARCOVER,
        state: CHECKOUT_STEPS_STATUS.COMPLETE
    };

    const navigateToNextScreen = (skipped) => {
        if (vasCartItems.length > 0) setAddonScreenTypeConnect({ screenType: SCREEN_TYPE.CART_VIEW });
        else setAddonScreenTypeConnect({ screenType: SCREEN_TYPE.LANDING_PAGE });
        if (skipped) updateCheckoutStepsConnect({ ...stepData, state: CHECKOUT_STEPS_STATUS.SKIPPED });
        else updateCheckoutStepsConnect(stepData);

        const { relativeURL: nextURL } = getCheckoutPageURL(content, routeData.defaultNext);
        history.push(nextURL);
    };

    // eslint-disable-next-line no-unused-vars
    const onProceedCart = () => {
        navigateToNextScreen();
    };

    const handleSkip = async () => {
        await clearVASCartConnect(orderId, VAS_PAGE_SCREEN_TYPE.CAR_COVER);
        navigateToNextScreen(true);
    };

    const onItemRemove = async (product) => {
        const { key: productKey, type: productType } = product;
        setCartLoading(true);
        const { items } = await removeVASItemConnect({
            appointmentId,
            key: productKey,
            type: productType,
            orderId,
            year,
            price: egcPrice
        });
        if (!items.length) {
            setShowCustomiseCoverModal(false);
        }
        setCartLoading(false);
        setCounterAnimationConnect(true);
        const gaEventLabel = PLATINUM_COVER_GA_EVENTS.CART_PAGE_PRODUCT_REMOVED.eventLabel.replace("$product", productKey).replace("$duration", "months");
        trackDesktopCustomEventsAU("", { ...PLATINUM_COVER_GA_EVENTS.CART_PAGE_PRODUCT_REMOVED, eventLabel: gaEventLabel });
    };

    return (
        <CheckoutLayout removePaddingBottom routeData={routeData} showFooter={false}>
            <div styleName={"styles.wrapper"}>
                <div className="row">
                    <div className="col-7">
                        <p styleName={"styles.heading"}>
                            {`Your ${make} ${model}’s manufacturer warranty is`}&nbsp;
                            <span>{getWarrantyText(warranty, warrantyEndDate)}</span>
                        </p>
                        <PlatinumCoverPlans
                            navigateToNextScreen={navigateToNextScreen}
                            handleWhatsCoveredModal={handleWhatsCoveredModal}
                            handleWhyUsModal={handleWhyUsModal}
                        />
                        <div styleName={"styles.bottomWrapper"}>
                            <p styleName={"styles.text"}>
                                CARS24 Cover™ is a suite of products designed to offer you various protection for your
                                vehicle. Some products may be offered as an insurance policy and some may not be. Please
                                always read the relevant terms and conditions before purchasing.
                            </p>
                            <p styleName={"styles.text"}>
                                {personalised
                                    ? "Weekly payments are based on the below rates received during your pre-approval and are subject to change."
                                    : "Weekly repayments calculated based on"}
                                <br />
                                {interestRate.toFixed(NUMBER.TWO)}% interest rate (p.a) {!personalised && " and"}
                                <br />
                                {comparisonRate.toFixed(NUMBER.TWO)}% comparison rate
                                {!personalised && <br />}
                                {!personalised && " Rates are subject to change, per your profile."}
                            </p>
                            <TrackImpression event={{ data: PLATINUM_COVER_GA_EVENTS.SKIP_BUTTONS_SEEN }}>
                                <button onClick={handleSkip} styleName={"styles.skipButton"}>
                                    Skip CARS24 Cover
                                </button>
                            </TrackImpression>
                        </div>
                        <SeeWhatIsCoveredModal isOpen={showWhatsCoveredModal} onClose={handleWhatsCoveredModal} servicingLocation={servicingLocation} />
                        <Cars24PlatinumCoverTheBestModal isOpen={whyUsModal} onClose={handleWhyUsModal} />
                        {vasCartProducts.length > 0 && (
                            <div styleName={"styles.buttonWrapper"}>
                                <div styleName={"styles.cartWrap"}>
                                    <p styleName={"styles.item"}>
                                        {`${vasCartProducts.length} ${vasCartProducts.length === 1 ? "item" : "items"}`} added
                                    </p>
                                    <div onClick={() => setShowCustomiseCoverModal(true)}>
                                        <p styleName={"styles.viewCart"}>View cart</p>
                                    </div>
                                </div>
                                <Button onClick={onProceedCart} text="Proceed" />
                                <CustomiseYourCoverModal
                                    loading={cartLoading}
                                    vasCartItems={vasCartProducts}
                                    isOpen={showCustomiseCoverModal}
                                    onClose={() => setShowCustomiseCoverModal(false)}
                                    onItemRemove={onItemRemove}
                                />
                            </div>
                        )}
                    </div>
                    <div className="col-5" styleName={"styles.nopadding"}>
                        <Cars24CoverRightPanel />
                        <p onClick={handleWhyUsModal} styleName={"styles.whyCars24"}>
                            Why is CARS24 Platinum cover the best?
                        </p>
                    </div>
                </div>
            </div>
        </CheckoutLayout>
    );
};

Cars24PlatinumCover.propTypes = {
    routeData: PropTypes.object,
    content: PropTypes.object,
    order: PropTypes.object,
    egcData: PropTypes.object,
    deliveryMode: PropTypes.string,
    pickupCharges: PropTypes.object,
    tradeInData: PropTypes.object,
    getCarCoverBundleConnect: PropTypes.func,
    servicingPriceConfig: PropTypes.object,
    carCoverData: PropTypes.object,
    updateWarrantyPlansConnect: PropTypes.func,
    updateCheckoutStepsConnect: PropTypes.func,
    setAddonScreenTypeConnect: PropTypes.func,
    vasCartData: PropTypes.object,
    clearVASCartConnect: PropTypes.func,
    removeVASItemConnect: PropTypes.func,
    setCounterAnimationConnect: PropTypes.func,
    selfPickUp: PropTypes.array,
    getVASCartConnect: PropTypes.func
};

export default Cars24PlatinumCover;
