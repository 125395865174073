export const getPageSectionByName = (cmsSections, sectionName) => {
    if (!cmsSections) {
        return null;
    }

    const section = cmsSections.find(
        item => item.pageSection.name === sectionName
    );

    return section ? section.pageSection : null;
};

