
/* eslint-disable max-len */
import { combineReducers } from "redux";
import { resettableReducer } from "reduxsauce";
import { resetReducerKeys } from "../constants/app-constants";
import userReducer from "../components/au.configuration/reducers";
import carDetailsReducer from "../components/au.desktop/car-details/reducers";
import carListReducer from "../components/au.desktop/car-listing/reducers";
import myWishlist from "../components/au.desktop/my-wishlist/reducers";
import configReducer from "../components/au.desktop/config-ssr/reducers";
import locationReducer from "../components/au.desktop/location-picker-popup/reducers";
import toastMessageReducer from "../components/shared/toast-message/reducers";
import filtersReducer from "../components/au.desktop/filters/reducers";
import footerReducer from "../components/au.desktop/footer/reducers";
import citiesReducer from "../components/au.desktop/location-picker/reducers";
import checkoutReducer from "../components/au.desktop/checkout-info/reducers";
import myBookings from "../components/au.desktop/my-bookings/reducers";
import deliveryInfoReducer from "../components/au.desktop/delivery-info/reducers";
import homeCarList from "../components/au.desktop/home/reducers";
import sitemapDataList from "../components/au.desktop/sitemap-info/reducers";
import nudgeAlertList from "../components/au.desktop/nudge-cards/reducers";
import preApprovalLoan from "../components/au.desktop/pre-approval-financing-flow/pre-approval-loan-wrapper/reducers";
import bookInspection from "../au-c2b/au.desktop/components/book-inspection-wrapper/reducers";
import auc2bTradein from "../au-c2b/au.desktop/components/trade-in-wrapper/reducers";
import abExperimentReducers from "../components/shared/ab-experiment/reducers";
import financePostPaymentReducer from "../components/au.desktop/finance-post-payment-loan-application/reducer";
import tradeInReducer from "../components/au.desktop/trade-in-your-car-wrapper/reducers";
import userRegoVinReducer from "../au-c2b/au.desktop/components/c2b-no-kbb-layout/reducers";
import bookedTaskListReducer from "../components/au.mobile/booked-tasks/reducer";
import supportWidgetReducer from "../components/au.desktop/support-widget/reducer";
import guestLoginReducer from "../components/au.desktop/guest-login/reducer";
import paymentConfigurationReducer from "../components/au.mobile/adyen-payment/reducer";
import addOnsReducer from "../components/au.desktop/add-ons/reducers";
import clpDataReducer from "../components/au.desktop/car-list-faq/reducers";
import financeLandingReducer from "../components/au.desktop/finance-landing-page-revamp/reducers";
import testDriveReducer from "../components/au.desktop/test-drive-v2/reducers";
import carValuaionCalculatorReducer from "../au-c2b/au.desktop/container/get-valuation/reducer";
import priceCommunicationReducer from "../au-c2b/au.desktop/container/price-communication-to-customer/reducer";
import roadWorthyCertificateReducer from "../components/au.desktop/rwc-landing-page/reducers";

// listen for the action type of 'RESET', you can change this.
const resettableUserReducer = resettableReducer(resetReducerKeys.RESET_USER);
const resettableCarList = resettableReducer(resetReducerKeys.RESET_CAR_LIST);
const resettableCarDetailsReducer = resettableReducer(resetReducerKeys.RESET_CAR_DETAILS);
const resettableMyWishlist = resettableReducer(resetReducerKeys.RESET_WISHLISTED_CARS);
const resettableConfig = resettableReducer(resetReducerKeys.RESET_CONFIG);
const resettableToastMessageReducer = resettableReducer(resetReducerKeys.RESET_TOAST_MESSAGE);
const resettableFilters = resettableReducer(resetReducerKeys.RESET_FILTERS);
const resettableLocationPicker = resettableReducer(resetReducerKeys.RESET_LOCATION_PICKER_MODAL);
const resettableFooter = resettableReducer(resetReducerKeys.RESET_FOOTER);
const resettableCities = resettableReducer(resetReducerKeys.RESET_CITIES);
const resettableMyBookings = resettableReducer(resetReducerKeys.RESET_MY_BOOKINGS);
const resettableDeliveryInfoReducer = resettableReducer(resetReducerKeys.RESET_DELIVERY_INFO);
const resettableCheckoutReducer = resettableReducer(resetReducerKeys.RESET_CHECKOUT);
const resettableHomeCarList = resettableReducer(resetReducerKeys.RESET_HOME_LIST);
const resettableSitemapDataList = resettableReducer(resetReducerKeys.RESET_SITEMAP_LIST);
const resettableNudgeList = resettableReducer(resetReducerKeys.RESET_NUDGE_LIST);
const resettableBookInspection = resettableReducer(resetReducerKeys.RESET_BOOK_INSPECTION);
const resettableTradein = resettableReducer(resetReducerKeys.RESET_AUC2B_TRADE_IN);
const resettablePreApprovalLoan = resettableReducer(resetReducerKeys.PRE_APPROVAL_LOAN);
const resettableAbExperimentsReducer = resettableReducer(resetReducerKeys.RESET_AB_EXPERIMENTS);
const resettableTradeInReducer = resettableReducer(resetReducerKeys.RESET_TRADE_IN);
const resettableUserRegoVinReducer = resettableReducer(resetReducerKeys.RESET_USER_REGO_VIN);
const resettableFinancePostPaymentReducer = resettableReducer(resetReducerKeys.RESET_FINANCE_POST_PAYMENT_REDUCER);
const resettableBookedTasksReducer = resettableReducer(resetReducerKeys.RESET_FINANCE_POST_PAYMENT_REDUCER);
const resettableSupportWidgetReducer = resettableReducer(resetReducerKeys.RESET_SUPPORT_WIDGET);
const resettableGuestLoginReducer = resettableReducer(resetReducerKeys.RESET_GUEST_LOGIN);
const resettablePaymentConfigReducer = resettableReducer(resetReducerKeys.RESET_PAYMENT_CONFIG);
const resettableAddOns = resettableReducer(resetReducerKeys.RESET_ADDONS);
const resettableClpData = resettableReducer(resetReducerKeys.RESET_CLP_DATA);
const resettablefinanceLandingReducer = resettableReducer(resetReducerKeys.RESET_FINANCE_LANDING_PAGE);
const resettableTestDriveReducer = resettableReducer(resetReducerKeys.RESET_TEST_DRIVE);
const resettableCarValuationCalculator = resettableReducer(resetReducerKeys.RESET_CAR_VALUATION_CALCULATOR);
const resettablePriceCommunicationReducer = resettableReducer(resetReducerKeys.RESET_PRICE_COMMUNICATION_PAGE);
const resettableRoadWorthyCertificateReducer = resettableReducer(resetReducerKeys.RESET_ROADWORHTY_CERTIFICATE);

export default combineReducers({
    user: resettableUserReducer(userReducer),
    config: resettableConfig(configReducer),
    location: resettableLocationPicker(locationReducer),
    carListing: resettableCarList(carListReducer),
    carDetails: resettableCarDetailsReducer(carDetailsReducer),
    myWishlist: resettableMyWishlist(myWishlist),
    toast: resettableToastMessageReducer(toastMessageReducer),
    filters: resettableFilters(filtersReducer),
    footer: resettableFooter(footerReducer),
    cities: resettableCities(citiesReducer),
    myBookings: resettableMyBookings(myBookings),
    checkout: resettableCheckoutReducer(checkoutReducer),
    deliveryInfo: resettableDeliveryInfoReducer(deliveryInfoReducer),
    homeCarList: resettableHomeCarList(homeCarList),
    sitemapDataList: resettableSitemapDataList(sitemapDataList),
    nudgeAlertList: resettableNudgeList(nudgeAlertList),
    bookInspection: resettableBookInspection(bookInspection),
    auc2bTradein: resettableTradein(auc2bTradein),
    preApprovalLoan: resettablePreApprovalLoan(preApprovalLoan),
    abExperiment: resettableAbExperimentsReducer(abExperimentReducers),
    tradeIn: resettableTradeInReducer(tradeInReducer),
    userRegoVin: resettableUserRegoVinReducer(userRegoVinReducer),
    financePostPayment: resettableFinancePostPaymentReducer(financePostPaymentReducer),
    bookedTasksList: resettableBookedTasksReducer(bookedTaskListReducer),
    supportWidget: resettableSupportWidgetReducer(supportWidgetReducer),
    guestLogin: resettableGuestLoginReducer(guestLoginReducer),
    paymentConfig: resettablePaymentConfigReducer(paymentConfigurationReducer),
    addOns: resettableAddOns(addOnsReducer),
    clpData: resettableClpData(clpDataReducer),
    financeLandingData: resettablefinanceLandingReducer(financeLandingReducer),
    testDrive: resettableTestDriveReducer(testDriveReducer),
    carValuationCalculator: resettableCarValuationCalculator(carValuaionCalculatorReducer),
    priceCommunication: resettablePriceCommunicationReducer(priceCommunicationReducer),
    roadWorthyCertificate: resettableRoadWorthyCertificateReducer(roadWorthyCertificateReducer)
});
