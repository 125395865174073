import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_CAR_LIST
    FETCH_CAR_LIST_SUCCESS
    FETCH_CAR_LIST_FAILURE
     
    FETCH_CONFIG
    FETCH_CONFIG_SUCCESS
    FETCH_CONFIG_FAILURE

    SEND_APP_LINK
    SEND_APP_LINK_SUCCESS
    SEND_APP_LINK_FAILURE

    SEO_HEADER_MENU
    SEO_HEADER_MENU_SUCCESS
    SEO_HEADER_MENU_FAILURE

    SET_LOGO_URL
    SET_HOME_SSR

    FETCH_TOTAL_CAR_SUCCESS
    FETCH_TOTAL_CAR_FAILURE

    FETCH_SEARCH_PAGE_INIT
    FETCH_SEARCH_PAGE_SUCCESS
    FETCH_SEARCH_PAGE_FAILURE
`,
    {
        prefix: "home/"
    }
);
