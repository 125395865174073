/* eslint-disable no-unused-expressions */
import Types from "./types";
import { BffDataAU, CheckoutService } from "../../../service";

const getMyBookingsInitiated = () => ({
    type: Types.GET_MY_BOOKINGS_INITIATED
});

const getMyBookingsSuccess = (data, params) => ({
    type: Types.GET_MY_BOOKINGS_SUCCESS,
    data,
    params
});

const getMyBookingsFailure = (error) => ({
    type: Types.GET_MY_BOOKINGS_FAILURE,
    error
});

const refreshMyBookingsInitiated = () => ({
    type: Types.REFRESH_MY_BOOKINGS_INITIATED
});

const getMyPaymentSummarySuccess = (data) => ({
    type: Types.GET_MY_PAYMENT_SUMMARY,
    data
});

const updatePaymentSummaryLoadingState = (loading) => ({
    type: Types.PAYMENT_SUMMARY_LOADING,
    loading
});

const updateDeliveryModeChangeLoadingState = (loading) => ({
    type: Types.UPDATE_DELIVERY_MODE_CHANGE_LOADING,
    loading
});

const updatePaymentSummaryLoading = (params) => (dispatch) => {
    dispatch(updatePaymentSummaryLoadingState(params));
};

const updateCachePaymentSummaryData = (data, selectedMode) => ({
    type: Types.UPDATE_PAYMENT_SUMMARY_CACHE_DATA,
    data,
    selectedMode
});

const resetCachePaymentSummaryData = () => ({
    type: Types.RESET_PAYMENT_SUMMARY_CACHE_DATA
});

const getPaymentSummary = (params, addToCache = false) => (dispatch, getState) => {
    const {
        user: { secureToken },
        checkout: {
            order: { orderId },
            deliveryMode
        }
    } = getState();

    dispatch(updatePaymentSummaryLoading(true));
    return new Promise((resolve, reject) => {
        CheckoutService.getPaymentSummary(orderId, secureToken, params)
            .then(response => {
                dispatch(getMyPaymentSummarySuccess(response.data));
                if (addToCache) {
                    dispatch(updateCachePaymentSummaryData(response.data, deliveryMode));
                }
                resolve(response.data);
            })
            .catch(error => {
                // dispatch(getPaymentHistoryFailure(error));
                reject(error);
            })
            .finally(() => {
                dispatch(updatePaymentSummaryLoading(false));
            });
    });
};
const getMyBookings = (token, params = {}, state = {isRefresh: false}) => async (dispatch) => {
    state.isRefresh ? dispatch(refreshMyBookingsInitiated()) : dispatch(getMyBookingsInitiated());
    try {
        const bookingResponse =  await CheckoutService.getMyBookings(token, params);
        const testDriveBookingResponse = await CheckoutService.getMyTestDriveBookings(token, params);
        const mergeBookingData = {...bookingResponse.data, testDriveCars: testDriveBookingResponse.data};
        dispatch(getMyBookingsSuccess(mergeBookingData, params));
        Promise.resolve(mergeBookingData);

    } catch (error) {
        dispatch(getMyBookingsFailure(error));
        Promise.reject(error);
    }
};
const cancelBookingInitiated = () => ({
    type: Types.BOOKING_CANCELLATION_INITIATED
});

const cancelBookingSuccess = (data) => ({
    type: Types.BOOKING_CANCELLATION_SUCCESS,
    data
});

const cancelBookingFailure = (error) => ({
    type: Types.BOOKING_CANCELLATION_FAILURE,
    error
});

const cancelBooking = (orderId, token, params) => (dispatch) => {
    dispatch(cancelBookingInitiated());
    return new Promise((resolve, reject) => {
        BffDataAU.cancelOrder(orderId, token, params).then((response) => {
            dispatch(cancelBookingSuccess(response.data));
            return resolve(response.data);
        }).catch((error) => {
            dispatch(cancelBookingFailure(error));
            reject(error);
        });
    });
};

const updateSelectedCar = (carId) => ({
    type: Types.UPDATE_SELECTED_CAR,
    carId
});

export {
    getMyBookings,
    cancelBooking,
    updateSelectedCar,
    getPaymentSummary,
    updatePaymentSummaryLoading,
    updateDeliveryModeChangeLoadingState,
    updateCachePaymentSummaryData,
    resetCachePaymentSummaryData
};
