/* eslint-disable max-params */

import Types from "./types";
import { BffDataAU, ListingServiceAU } from "../../../service";
import { resetReducerKeys } from "../../../constants/app-constants";
import { DELIVERY_TYPES } from "../../../constants/checkout-constants";

const resetShippingPrice = (data = {price: 0}) => ({
    type: Types.RESET_SHIPPING_PRICE,
    data
});

const getDeliveryInfoSuccess = (data, pincode, currentDeliveryStep, shippingPrice) => ({
    type: Types.GET_DELIVERY_INFO_SUCCESS,
    data,
    pincode,
    currentDeliveryStep,
    shippingPrice
});

const getDeliveryInfoFailure = (error, pincode) => ({
    type: Types.GET_DELIVERY_INFO_FAILURE,
    error,
    pincode
});

const setDeliveryInfoFetch = (data) => ({
    type: Types.DELIVERY_INFO_FETCHING,
    deliveryInfoFetched: data
});

const getDeliveryInfo = (appointmentId, pincode, origincity, pickUpFromParams = null, originState, emiEnabled) => (dispatch, getState) => {
    dispatch(setDeliveryInfoFetch(false));
    const {carDetails = {}, checkout: {
        order,
        currentDeliveryStep
    },
    user: { secureToken }} = getState();
    const { deliveryMode, shippingCharge = 0 } = order || {};
    // eslint-disable-next-line no-unused-vars
    const pickUp = pickUpFromParams !== null ? pickUpFromParams : deliveryMode === DELIVERY_TYPES.PickUp;
    const {cityCode, stateCode} = carDetails.content || {};
    return new Promise((resolve, reject) => {
        BffDataAU.getDeliveryAvailability(secureToken, appointmentId, pincode, origincity || cityCode, false, originState || stateCode, emiEnabled).then((response) => {
            dispatch(getDeliveryInfoSuccess(response.data, pincode, currentDeliveryStep, shippingCharge));
            resolve(response.data);
        }).catch((error) => {
            dispatch(getDeliveryInfoFailure(error, pincode));
            reject(error);
        });
    });
};

const getVehiclePickUpCitiesSuccess = (data) => ({
    type: Types.GET_PICKUP_CITIES_SUCCESS,
    data
});

const getVehiclePickUpCitiesFailure = (error) => ({
    type: Types.GET_PICKUP_CITIES_FAILURE,
    error
});

const getVehiclePickUpCitiesInit = (data) => ({
    type: Types.GET_PICKUP_CITIES_INIT,
    pickUpStateLoading: data
});

const getVehiclePickUpCities = (states) => (dispatch) => {
    dispatch(getVehiclePickUpCitiesInit(false));
    return new Promise((resolve, reject) => {
        ListingServiceAU.getVehiclePickUpCities(states).then((response) => {
            dispatch(getVehiclePickUpCitiesSuccess(response.data));
            resolve(response.data);
        }).catch((error) => {
            dispatch(getVehiclePickUpCitiesFailure(error));
            reject(error);
        });
    });
};

const resetDeliveryInfo = () => dispatch => {
    dispatch({ type: resetReducerKeys.RESET_DELIVERY_INFO });
};

export {
    getDeliveryInfo,
    resetDeliveryInfo,
    setDeliveryInfoFetch,
    getVehiclePickUpCities,
    resetShippingPrice
};
