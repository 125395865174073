/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { NUMBER } from "../../../constants/app-constants";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";

const Tooltip = ({
    customStyleHeading = false,
    children,
    heading,
    body,
    defaultTootlipStyle,
    onClickCallback = () => {},
    showComponent = false,
    displayComponent = <></>,
    displayComponentProp = {},
    trackEvents = () => {},
    showOnHover = false
}) => {
    const targetRef = useRef(null);
    const tooltipRef = useRef(null);
    const [tootlipStyle, setTooltipStyle] = useState(defaultTootlipStyle || "styles.wrapper");
    const [toolTip, setTooltip] = useState(false);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (!showOnHover) {
            const handleClickOutside = (event) => {
                if (targetRef.current && !targetRef.current.contains(event.target)) {
                    if (toolTip) {
                        trackEvents(false);
                        setTooltip(false);
                    }
                }
            };

            if (!document.__tooltipClickListenerAttached) {
                document.addEventListener("click", handleClickOutside, false);
                document.__tooltipClickListenerAttached = true;
            }

            return () => {
                document.removeEventListener("click", handleClickOutside, false);
                document.__tooltipClickListenerAttached = false;
            };
        }
    }, [toolTip, showOnHover]);

    const getThePositionStyle = () => {
        const targetRect = targetRef.current.getBoundingClientRect();
        const targetDistanceFromBottom = window.innerHeight - targetRect.top;
        const tooltipHeight = (tooltipRef.current && tooltipRef.current.offsetHeight || NUMBER.TWO_HUNDRED) +
        targetRef.current.offsetHeight;
        if (!defaultTootlipStyle) {
            if (targetDistanceFromBottom > tooltipHeight) {
                setTooltipStyle("styles.bottom");
            } else {
                setTooltipStyle("styles.top");
            // tooltipRef.current.style.marginTop = `-${tooltipHeight + NUMBER.TEN}px`;
            }
        }
    };

    const onClick = async (e) => {
        if (!showOnHover) {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            await yieldToMain();
            setTooltip(!toolTip);
            getThePositionStyle();
            onClickCallback();
            trackEvents(!toolTip);
        }
    };

    // Execute position calculation and add active class after tooltip state is updated
    useEffect(() => {
        if (tooltipRef.current) {
            if (toolTip) {
                getThePositionStyle();
                // Add active class instead of directly manipulating style
                tooltipRef.current.classList.add("active");
            } else {
                // Remove active class when tooltip is hidden
                tooltipRef.current.classList.remove("active");
            }
        }
    }, [toolTip]);

    const handleMouseEnter = async () => {
        if (showOnHover) {
            await yieldToMain();
            setTooltip(true);
            onClickCallback(); // Using the same callback for consistency
            trackEvents(true);
        }
    };

    const handleMouseLeave = (e) => {
        if (showOnHover) {
            // Check if we're moving from target to tooltip
            // Only hide tooltip if we're not moving to the tooltip itself
            const relatedTarget = e.relatedTarget;
            if (!tooltipRef.current || !tooltipRef.current.contains(relatedTarget)) {
                setTooltip(false);
                trackEvents(false);
            }
        }
    };

    const renderPara = (line) => {
        if (React.isValidElement(line)) {
            return line;
        }
        return <p dangerouslySetInnerHTML={{__html: line}} />;
    };
    const Component = displayComponent;

    return (
        <div styleName="styles.tooltip">
            <span
                ref={targetRef}
                styleName={"styles.target"}
                tabIndex="0"
                onClick={onClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {children}
            </span>
            {toolTip && <div
                className={`tooltipContainer ${toolTip ? "active" : ""}`}
                styleName={tootlipStyle}
                ref={tooltipRef}
                tabIndex="0"
                onMouseLeave={handleMouseLeave}
            >
                {!!heading && <div styleName={customStyleHeading ? "styles.customHeading" : "styles.heading"}>{heading}</div>}
                {!!body && <div styleName={"styles.body"}>{body.map((line) => renderPara(line))}</div>}
                <i />
                {showComponent && <Component {...displayComponentProp} />}
            </div>
            }
        </div>
    );
};

Tooltip.propTypes = {
    customStyleHeading: PropTypes.bool,
    children: PropTypes.object,
    heading: PropTypes.string,
    body: PropTypes.array,
    onClickCallback: PropTypes.func,
    defaultTootlipStyle: PropTypes.string,
    displayComponent: PropTypes.string,
    displayComponentProp: PropTypes.object,
    showComponent: PropTypes.bool,
    trackEvents: PropTypes.func,
    showOnHover: PropTypes.bool
};

export default React.memo(Tooltip);
