/* eslint-disable complexity */
/* eslint-disable max-statements */
import { NUMBER } from "../../constants/app-constants";
import { BOOL_STATE, EMPLOYEMENT_TYPES_VALUES, LENDER_DISPLAY_NAME, RELATIONSHIP_STATUS } from "../../constants/checkout-constants";
import { differenceInMonths } from "date-fns";
import { monthNamesValues } from "../../constants/finance-constants/month-names";
import { appUrl } from "../../constants/url-constants";
//import { DRIVA_MIN_AMOUNT, ZERO_DP_MINIMUM_DEPOSIT } from "../../config/driva-config";

const checkCompletedItems = (config) => {
    const completedData = {
        totalQuestions: 0,
        goToPageNo: 0,
        goToStageNo: 0,
        completedPercentage: 0,
        totalCompletedQuestions: 0,
        totalPages: 0,
        totalCompletedPages: 0,
        completedPagePercentage: 0
    };
    let incompleteDataFound = false;
    for (let i = 0; i < config.stages.length; i++) {
        const currentStage = config.stages[i];
        const lastStage = (i === config.stages.length - 1);
        if (!currentStage.isHidden) {
            for (let j = 0; j < currentStage.forms.length; j++) {
                const currentPage = currentStage.forms[j];
                const lastForm = (j === currentStage.forms.length - 1);
                /* checking for all data if isCompleted false then initializing all page and questions to resume journey */
                if (!currentPage.isCompleted() && !incompleteDataFound && !currentPage.isHidden) {
                    incompleteDataFound = true;
                    completedData.goToPageNo = j + 1;
                    completedData.goToStageNo = i + 1;
                    completedData.totalCompletedQuestions = completedData.totalQuestions;
                    completedData.totalCompletedPages = completedData.totalPages;
                }
                completedData.totalQuestions += (currentPage.questions || []).length;
                /* if for entire form no isCompleted data found then setting page to last and total completed questions */
                completedData.totalPages += 1;
                if (lastStage && lastForm && !incompleteDataFound) {
                    completedData.goToPageNo = j + 1;
                    completedData.goToStageNo = i + 1;
                    completedData.totalCompletedQuestions = completedData.totalQuestions;
                    completedData.totalCompletedPages = completedData.totalPages;
                }
            }
        }
    }
    // completedData.completedPercentage = parseInt((completedData.totalCompletedQuestions / completedData.totalQuestions) * NUMBER.HUNDRED);
    completedData.completedPagePercentage = parseInt(((completedData.totalCompletedPages) / completedData.totalPages) * NUMBER.HUNDRED);
    completedData.completedPercentage = completedData.completedPagePercentage;
    return completedData;
};

const checkCompletedItemsV2 = (config) => {
    const completedData = {
        stageProgress: 0,
        completedPagePercentage: 0,
        completedPercentage: 0,
        goToPageNo: 1,
        goToStageNo: 1,
        goToQuestionNo: 1,
        totalCompletedStages: 0,
        totalCompletedPages: 0,
        totalCompletedQuestions: 0,
        totalPages: 0,
        totalStages: 0,
        totalQuestions: 0,
        questionsLeft: 0,
        currentQuestion: {}
    };

    ((config || {}).stages || []).filter(Boolean).forEach(({ forms = [], isStageCompleted = () => {} }) => {
        completedData.totalStages += 1;
        if (isStageCompleted()) {
            completedData.totalCompletedStages += 1;
        }

        (forms.filter(Boolean).filter(({ isVisible }) => isVisible) || [])
            .forEach(({ questions = [] }) => {
                (questions.filter(({ isVisible }) => isVisible) || [])
                    .forEach(({ isQuestionCompleted = () => { } }) => {
                        completedData.totalQuestions += 1;
                        if (isQuestionCompleted()) {
                            completedData.totalCompletedQuestions += 1;
                        }
                    });
            });
    });

    const stages = config.stages.filter(Boolean) || [];
    const currentStageIndex = stages.findIndex(({ isStageCompleted = () => { } }) => !isStageCompleted());
    const lastStage = stages.length;

    const currentStageForms = ((stages[currentStageIndex] || {}).forms || []).filter(Boolean) || [];
    const visibleFormsOfCurrentStage = currentStageForms.filter(({ isVisible }) => isVisible) || [];
    const currentFormIndex = visibleFormsOfCurrentStage.findIndex(({ isCompleted = () => { } }) => !isCompleted());
    const lastForm = visibleFormsOfCurrentStage.length;

    const currentFormsQuestions = (visibleFormsOfCurrentStage[currentFormIndex] || {}).questions || [];
    const visibleQuestionsOfCurrentForm = currentFormsQuestions.filter(({ isVisible }) => isVisible) || [];
    const currentQuestionIndex = visibleQuestionsOfCurrentForm.findIndex(({ isQuestionCompleted = () => { } }) => !isQuestionCompleted());
    const lastQuestion = visibleQuestionsOfCurrentForm.length;

    // FORM LAYOUT V1 Component handle its own current stage and form
    // There is one scenario where the entire form is hidden if isPurposeBusiness === "YES"
    // The following code will handle this scenario
    //completedData.currentStage = config.stages[currentQuestionIndex] || null;
    //completedData.currentForm = visibleFormsOfCurrentStage[currentFormIndex] || null;
    completedData.currentQuestion = visibleQuestionsOfCurrentForm[currentQuestionIndex] || null;
    completedData.questionsLeft = completedData.totalQuestions - completedData.totalCompletedQuestions;
    completedData.completedPercentage = parseInt(((completedData.totalCompletedQuestions) / completedData.totalQuestions) * NUMBER.HUNDRED);
    completedData.stageProgress = (completedData.totalCompletedStages / completedData.totalStages) * NUMBER.HUNDRED;

    if (currentStageIndex === -1) {
        completedData.goToStageNo = lastStage;
    } else {
        completedData.goToStageNo = currentStageIndex + 1;
    }

    if (currentFormIndex === -1) {
        // redirect to first form of last stage
        completedData.goToPageNo = lastForm;
    } else {
        completedData.goToPageNo = currentFormIndex + 1;
    }

    if (currentQuestionIndex === -1) {
        // redirect to first question of first form of last stage
        completedData.goToQuestionNo = lastQuestion + 1;
    } else {
        completedData.goToQuestionNo = currentQuestionIndex + 1;
    }
    return completedData;
};

const getPreApprovalConfigData = (data, configData) => {
    const config = configData({formData: data });
    const completedData = (config && checkCompletedItems(config)) || {};
    const currentStage = (Array.isArray((config || {}).stages) && (config || {}).stages[completedData.goToStageNo - 1]) || {};
    return {
        completedData,
        currentStage,
        config
    };
};

const getPreApprovalConfigDataV2 = (data, config) => {
    const completedData = (config && checkCompletedItemsV2(config)) || {};
    //const { currentStageIndex, currentFormIndex } = completedData;
    //const currentStage = (Array.isArray((config || {}).stages) && (config || {}).stages[currentStageIndex]) || {};
    //const { forms = [] } = currentStage || {};
    //const currentForm = forms[currentFormIndex];

    return {
        completedData,
        //currentStage,
        //currentForm,
        config
    };
};

const getPreApprovalConfigDataMsiteV2 = ({data}) => {
    const { formData = {}, config = {}} = data || {};
    return getPreApprovalConfigDataV2(formData, config);
};

const getPreApprovalConfigDataDesktopV2 = ({data}) => {
    const { formData = {}, config = {}} = data || {};
    return getPreApprovalConfigDataV2(formData, config);
};

const resetQuestionBeforePost = ({apiResponse, resetDataArray}) => {
    (resetDataArray || []).map((value) => {
        if (!apiResponse[value] || apiResponse[value].isOpted) {
            apiResponse = apiResponse;
        } else if (apiResponse[value].isOpted === false) {
            apiResponse[value] = {
                isOpted: false
            };
        }
    });
    return apiResponse;
};

const getFinanceLendersPrivacyDiscliamers = (
    lenderName
) => {
    const {
        AutoPay,
        FirstMac,
        Money3,
        PLENTI,
        Wisr,
        Greenlight,
        Resimac,
        Pepper,
        AngleFinance,
        Liberty
    } = LENDER_DISPLAY_NAME;
    const PRIVACY_POLICY_LINK = `${appUrl()}/privacy-policy`;
    const FEE_DISCLOSURE_GUIDE = "https://drive.google.com/file/d/13ZWRTvaEmgKO7xqqLHhfgnqTHDPZlahf/view?usp=sharing";
    const PRIVACY_DISCLOSURE = "https://drive.google.com/file/d/1ptusGsnvRcOcWZkiqBesU60xSVnWshQz/view?usp=sharing";
    const CREDIT_GUIDE_PRIVACY_DISCLOSURE = "https://drive.google.com/file/d/1zVt3O_7iwkmtQTS0Lakf5y9twJsObjfZ/view?usp=sharing";
    switch (lenderName) {
    case Money3 :
    case FirstMac :
    case Resimac :
    case AngleFinance :
        return {
            questionLabel: `I have opened, read and agreed to the <a href=${PRIVACY_POLICY_LINK} target="_blank">Privacy policy</a>, <a href=${CREDIT_GUIDE_PRIVACY_DISCLOSURE} target="_blank">Privacy disclosure statement and credit guide</a>`,
            apiKey: "declarationForPrivacyPolicyCreditGuide"
        };
    case Wisr :
    case AutoPay :
    case PLENTI :
    case Pepper :
    case Greenlight :
    case Liberty :
        return {
            questionLabel: `I have opened, read and agreed to the <a href=${PRIVACY_POLICY_LINK} target="_blank">Privacy policy</a>, <a href=${PRIVACY_DISCLOSURE} target="_blank">Privacy disclosure statement</a> and <a href=${FEE_DISCLOSURE_GUIDE} target="_blank">Fee disclosure guide</a>`,
            apiKey: "declarationForPrivacyPolicyFeeGuide"
        };
    default:
        return null;
    }
};

const getFinanceData = ({response = {}, storeData}) => {
    const { loan, user, loanType, extra } = response && response.loanOfferRequest || {};
    const financeData = {
        ...(response || {}).loanOfferRequest,
        selectedQuote: (response || {}).selectedQuote,
        loan: loan || {
            ...storeData.loan
            //deposit: isUserZeroDpVariant ? ZERO_DP_MINIMUM_DEPOSIT : DRIVA_MIN_AMOUNT
        },
        user: {
            ...(user || storeData.user),
            currentAddressDurationBelow3Years: !!(((user || storeData.user) || {}).previousAddress || {}).full
        },
        loanType: loanType || storeData.loanType,
        extra: extra || storeData.extra
    };
    return financeData;
};

const getEditFlowQuestionsProgress = (apiData = {}, formData = {}) => {
    const { loanType: apiLoanType, user: apiUser } = apiData || {};
    const { isPurposeBusiness: apiIsPurposeBusiness, isGstRegistered: apiIsGstRegistered } = apiLoanType || {};
    const { employment: apiEmployment, relationshipStatus: apiRelationshipStatus  } = apiUser || {};

    const { loanType: formLoanType, user: formUser } = formData || {};
    const { isPurposeBusiness: formIsPurposeBusiness, isGstRegistered: formIsGstRegistered } = formLoanType || {};
    const { employment: formEmployment, relationshipStatus: formRelationshipStatus  } = formUser || {};

    // stage 1 form 1 - business yes/no
    // stage 1 form 1 - gst yes/no
    // stage 2 form 1 - employment type
    // stage 2 form 1 - current job less than 6 months
    // stage 3 form 3 - relationship status
    // stage 3 form 4 - address less than 3 / temp visa

    let isQuestionChanged = false;

    if (apiIsPurposeBusiness !== formIsPurposeBusiness) {
        isQuestionChanged = true;
    } else if (apiIsPurposeBusiness === formIsPurposeBusiness && (formIsGstRegistered !== BOOL_STATE.NA && apiIsGstRegistered !== formIsGstRegistered)) {
        isQuestionChanged = true;
    } else if (formIsPurposeBusiness !== BOOL_STATE.YES && (apiEmployment || [])[0].type !== (formEmployment || [])[0].type) {
        // ONLY VALUES WERE CHANGED, OVERALL QUESTIONS COUNT ARE SAME
        //if (
        //    ![EMPLOYEMENT_TYPES_VALUES.UNEMPLOYED, EMPLOYEMENT_TYPES_VALUES.PENSION].includes((apiEmployment || [])[0].type) &&
        //    [EMPLOYEMENT_TYPES_VALUES.UNEMPLOYED, EMPLOYEMENT_TYPES_VALUES.PENSION].includes((formEmployment || [])[0].type)
        //) {
        //    isQuestionChanged = true;
        //} else
        if (
            [EMPLOYEMENT_TYPES_VALUES.UNEMPLOYED, EMPLOYEMENT_TYPES_VALUES.PENSION].includes((apiEmployment || [])[0].type) &&
            ![EMPLOYEMENT_TYPES_VALUES.UNEMPLOYED, EMPLOYEMENT_TYPES_VALUES.PENSION].includes((formEmployment || [])[0].type)
        ) {
            isQuestionChanged = true;
        } else {
            isQuestionChanged = false;
        }
    } else if (formIsPurposeBusiness !== BOOL_STATE.YES &&
        ((apiEmployment || [])[0].startYear !== (formEmployment || [])[0].startYear ||
        (apiEmployment || [])[0].startMonth !== (formEmployment || [])[0].startMonth)
    ) {
        const { startYear: apiStartYear, startMonth: apiStartMonth } = apiEmployment[0];
        const { startYear: formStartYear, startMonth: formStartMonth } = formEmployment[0];
        const apiLessThanSix = differenceInMonths(new Date(), new Date(+apiStartYear, monthNamesValues.indexOf(apiStartMonth)), NUMBER.ONE) < NUMBER.SIX;
        const formLessThanSix = differenceInMonths(new Date(), new Date(+formStartYear, monthNamesValues.indexOf(formStartMonth)), NUMBER.ONE) < NUMBER.SIX;

        if ((!apiLessThanSix && formLessThanSix) || (apiLessThanSix && !formLessThanSix)) {
            isQuestionChanged = true;
        } else {
            isQuestionChanged = false;
        }
    } else if (apiRelationshipStatus !== formRelationshipStatus) {
        //if (
        //    [RELATIONSHIP_STATUS.MARRIED, RELATIONSHIP_STATUS.DEFACTO].includes(apiRelationshipStatus) &&
        //    ![RELATIONSHIP_STATUS.MARRIED, RELATIONSHIP_STATUS.DEFACTO].includes(formRelationshipStatus)
        //) {
        //    isQuestionChanged = true;
        //} else
        if (
            ![RELATIONSHIP_STATUS.MARRIED, RELATIONSHIP_STATUS.DEFACTO].includes(apiRelationshipStatus) &&
            [RELATIONSHIP_STATUS.MARRIED, RELATIONSHIP_STATUS.DEFACTO].includes(formRelationshipStatus)
        ) {
            isQuestionChanged = true;
        } else {
            isQuestionChanged = false;
        }
    }

    return isQuestionChanged;
};

export {
    resetQuestionBeforePost,
    getFinanceLendersPrivacyDiscliamers,
    checkCompletedItems,
    getPreApprovalConfigData,
    getFinanceData,
    getPreApprovalConfigDataMsiteV2,
    getPreApprovalConfigDataDesktopV2,
    getEditFlowQuestionsProgress,
    checkCompletedItemsV2
};
