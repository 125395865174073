/* eslint-disable max-statements */
/* eslint-disable complexity */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import InputText from "../../../../components/shared/input-text";
import Search from "../../../../components/shared/search";
import Button from "../../../../components/shared/button";
import { getYearInBackOrder } from "../../../../utils/helpers/checkout-util";
import { NUMBER, ERROR_STATUS_MAP } from "../../../constants/app-constants";
import { useHistory } from "react-router";
import { trackC2bMobileCustomEvents } from "../../../tracking";
import { C2B_AU_MOBILE_EVENTS } from "../../../tracking/c2b-mobile-events";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import { getDeviceType } from "../../../../utils/helpers/get-device-type";
import vinValidator from "../../../../utils/validators/vin-validator";
import { GUEST_POPUP_TYPES, TRADE_STATE } from "../../../constants/app-constants";
import { getObjectByValue } from "../../../../utils/helpers/get-keys-by-value";
import {  GA_EVENT_NAMES } from "./tracking";
import { PAGE_SOURCE } from "../../../../constants/app-constants";
import parseCookie from "../../../../utils/helpers/parse-cookie";
import BuildYearModal from "../../../../components/au.desktop/build-year-modal/component";
import infoIcon from "./images/info.svg";
import infoIconWhite from "./images/info-white.svg";
import { EXPERIMENT_TYPE } from "../../../../constants/optimize-constants";
import { getAbExpirementVariant } from "../../../../utils/helpers/get-ab-expirement-variant";
import useExperiments from "../../../../hooks/use-statsig-experiment";
import { EXPERIMENT_NAME } from "../../../../utils/statsig-ab-utils/constant";
import { TRADE_IN_GA_EVENTS } from "../../../../components/au.desktop/trade-in-your-car-card-revamp/tracking";
import { yieldToMain } from "../../../../utils/helpers/yield-to-main";

const GA_EVENT_CATEGORY = {
    [PAGE_SOURCE.CLP]: "Cars24_listing_page",
    [PAGE_SOURCE.CDP]: "Cars24_detail_page",
    [PAGE_SOURCE.HOME_PAGE]: "Cars24_home_page",
    [PAGE_SOURCE.SELL]: "C2B_flow"
};

const yearData = getYearInBackOrder(parseInt(new Date().getFullYear()), 0, NUMBER.TWENTY_FIVE);
const TABS = {
    registration: {
        heading: "Rego",
        inputPlaceHolder: "ENTER REGO",
        searchPlaceholder: "State",
        inputKey: "regNo",
        searchKey: "state",
        searchData: [],
        key: "registration"
    },
    vehicle: {
        heading: "VIN",
        inputPlaceHolder: "Enter VIN",
        searchPlaceholder: "Build year",
        inputKey: "vin",
        searchKey: "year",
        searchData: yearData,
        key: "vehicle"
    }
};

const C2bLandingAtfForm = ({
    tradeInData,
    tradeInStates,
    isLoggedIn,
    isGuestLogin,
    rejectModal,
    getTradeInStateConnect,
    updateTradeInConnect,
    getTradeInDetailsConnect,
    createTradeInConnect,
    setUserUpdatingConnect,
    setMaxStepConnect,
    handleRejectModalConnect,
    getTradeVehicleDetailsConnect,
    gaId,
    getSellOrderDetailsConnect,
    saveTradeInRegoandVinConnect,
    regoInputRef = null,
    guestLoginProps,
    showVinToggleSection,
    checkVinActive,
    ctaText,
    formSource,
    customStyles,
    customDropDownStyle,
    placeHolderText,
    c2bSellLandingMobileNumber,
    updateFinanceDetailConnect,
    paymentOption,
    orderId,
    getTradeInToggleVisibleConnect,
    mobile
}) => {

    const history = useHistory();
    const isCheckoutFlow = formSource === PAGE_SOURCE.POST_BC_FLOW;

    const [isVinActive, setIsVinActive] = React.useState(false);
    const [data, setData] = useState(tradeInData);
    const [loader, setLoader] = useState(false);
    const [showVinWarning, setShowVinWarning] = useState(false);
    const [showBuildYearModal, setShowBuildYearModal] = React.useState(false);
    const [ododmeter, setOdometer] = React.useState(tradeInData.odometerReading || "");
    useExperiments({ experimentKeys: [EXPERIMENT_NAME.c2bSellLandingMobileNumber] });
    const { tradeInServiceProviderId } = tradeInData || {};
    const { location } = history;
    const { state: placeholderState = "State", year: placeholderYear = "Build Year" } = placeHolderText || {};
    const queryParams = parseLocationSearchParams(location.search);
    const utmToken = queryParams.utm_token;
    const utmSource = queryParams.utm_source;
    const utmMedium = queryParams.utm_medium;
    const utmCampaign = queryParams.utm_campaign;

    const utmParamters = {
        utmToken,
        utmSource,
        utmMedium,
        utmCampaign
    };
    const { setOnGuestEmailEnteredCallback, openGuestLoginConnect } = guestLoginProps;
    const { clientId = "" } = JSON.parse(parseCookie("trackingConsent")) || {};
    const navigateToRoute = (route) => {
        if (formSource !== PAGE_SOURCE.POST_BC_FLOW) {
            history.push(route);
        }
    };
    useEffect(() => {
        if (rejectModal.isRejectModalVisible) {    // reset the error screen incase it is true
            handleRejectModalConnect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getTradeInStateConnect();
        if (isLoggedIn || isGuestLogin) {
            getTradeInDetailsConnect()
                .then((response) => {
                    if (response?.vin || response?.regNo) {
                        setIsVinActive(response.vin ? true : false);
                        setData(response);
                    }
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, isGuestLogin]);

    const handleTab = () => {
        trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.C2B_LANDING_REVIEW_CAR_DETAILS, {
            eventLabel: `{{options ${isVinActive ? "rego_number" : "vin"}}}`
        });
        setIsVinActive(!isVinActive);
    };

    const handleOnChange = (value, questionKey) => {
        setData({ ...data, [questionKey]: value });
        if (questionKey === TABS.vehicle.searchKey) {
            trackC2bMobileCustomEvents(GA_EVENT_NAMES.year_entered, {
                ...GA_EVENT_NAMES.year_entered,
                eventCategory: GA_EVENT_CATEGORY[formSource],
                eventLabel: `{{options ${value} }}`,
                ...(isCheckoutFlow && { eventCategory: "Trade-in" }),
                ...(isCheckoutFlow && { eventName: "Trade-in_Post_BC" }),
                ...(isCheckoutFlow && { eventLabel: `${paymentOption}_${orderId}` })
            });
        } else if (questionKey === TABS.registration.searchKey) {
            trackC2bMobileCustomEvents(GA_EVENT_NAMES.state_entered, {
                ...GA_EVENT_NAMES.state_entered,
                eventCategory: GA_EVENT_CATEGORY[formSource],
                eventLabel: `{{options ${value} }}`,
                ...(isCheckoutFlow && { eventCategory: "Trade-in" }),
                ...(isCheckoutFlow && { eventName: "Trade-in_Post_BC" }),
                ...(isCheckoutFlow && { eventLabel: `${paymentOption}_${orderId}` })
            });
        }
    };

    const handleOnBlur = (value, questionKey) => {
        if (questionKey === TABS.registration.inputKey) {
            trackC2bMobileCustomEvents(GA_EVENT_NAMES.rego_entered, {
                ...GA_EVENT_NAMES.rego_entered,
                eventCategory: GA_EVENT_CATEGORY[formSource],
                ...(isCheckoutFlow && { eventCategory: "Trade-in" }),
                ...(isCheckoutFlow && { eventName: "Trade-in_Post_BC" }),
                ...(isCheckoutFlow && { eventLabel: `${paymentOption}_${orderId}` })
            });
        } else if (questionKey === TABS.vehicle.inputKey) {
            trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.VIN_ENTERED, {
                eventLabel: `NA`
            });
            trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.C2B_LANDING_VIN_ENTERED, {
                eventLabel: tradeInServiceProviderId
            });
            const isValid = vinValidator(value);
            setShowVinWarning(!isValid);
        }
    };

    const gotoDetailsForm = (orderID) => {
        setLoader(false);
        trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.C2B_FLOW_STARTED);
        trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.SELL_MOBILE_LOGIN_PROCEEDED, {
            eventLabel: orderID
        });
        trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.C2B_LEAD_CREATED, {
            eventLabel: orderID
        });
        if (orderID && formSource !== PAGE_SOURCE.POST_BC_FLOW) {
            // const payload = {
            //     tradeinOpted: true,
            //     interestedInTradein: true
            // };
            // updateTradeInConnect(payload);
            navigateToRoute(`/sell-your-car/car-details/${orderID}`);
        }
    };

    const redirectToErrorScreen = (errorType) => {
        handleRejectModalConnect(errorType);
        navigateToRoute("/sell-your-car/error-screen/");
    };

    // eslint-disable-next-line complexity

    const createTradeIn = async (createRequest) => {
        try {
            const response = await getTradeVehicleDetailsConnect(createRequest);
            const { vehicleMetaDataList = [], userSelectedState = "", state = "" } = response.data || {};
            if (response.responseStatus && (response.responseStatus.status === NUMBER.ONE_THOUSAND_THIRTEEN)) {
                saveTradeInRegoandVinConnect(createRequest);
                const orderData = await createTradeInConnect({
                    ...createRequest,
                    clientId: gaId || clientId,
                    utmParams: {
                        "LANDING_PAGE": utmParamters
                    },
                    ...(isCheckoutFlow && {isPostBcFlow: true}),
                    "source": getDeviceType()
                });
                if (orderData.status === NUMBER.TWO_HUNDRED) {
                    const { screenStep = "make" } = orderData.mmvyUserInputs || {};
                    trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.SELL_MOBILE_LOGIN_PROCEEDED, {
                        eventLabel: orderData.c2bOrderId
                    });
                    trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.C2B_LEAD_CREATED, {
                        eventLabel: orderData.c2bOrderId
                    });
                    navigateToRoute(`/sell-your-car/trade-in-car-make/${screenStep}/${orderData.c2bOrderId}`);
                    const payload = {
                        tradeinOpted: true,
                        interestedInTradein: true
                    };
                    if (formSource === PAGE_SOURCE.POST_BC_FLOW) {
                        await updateFinanceDetailConnect(payload);
                    }
                } else {
                    redirectToErrorScreen(ERROR_STATUS_MAP[orderData.status]);
                }
            } else if (vehicleMetaDataList && vehicleMetaDataList.length) {
                const orderData = await createTradeInConnect({
                    ...createRequest,
                    clientId: gaId || clientId,
                    state: state ? state : createRequest.state,
                    userSelectedState,
                    tradeInServiceProviderId: vehicleMetaDataList[0].id,
                    utmParams: { "LANDING_PAGE": utmParamters },
                    "source": getDeviceType()
                });
                if (orderData.status === NUMBER.TWO_HUNDRED) {
                    gotoDetailsForm(orderData.c2bOrderId);
                } else {
                    redirectToErrorScreen(ERROR_STATUS_MAP[orderData.status]);
                }
            } else {
                redirectToErrorScreen("default");
            }
        } catch (err) {
            if (err.errorCode === NUMBER.ONE_THOUSAND_THIRTEEN) {
                saveTradeInRegoandVinConnect(createRequest);
                const orderData = await createTradeInConnect({
                    ...createRequest,
                    clientId: gaId || clientId,
                    utmParams: {
                        "LANDING_PAGE": utmParamters
                    },
                    ...(isCheckoutFlow && {isPostBcFlow: true}),
                    "source": getDeviceType()
                });
                if (orderData.status === NUMBER.TWO_HUNDRED) {
                    const payload = {
                        tradeinOpted: true,
                        interestedInTradein: true
                    };
                    if (formSource === PAGE_SOURCE.POST_BC_FLOW) {
                        await updateFinanceDetailConnect(payload);
                    }
                }
            }
            redirectToErrorScreen("default");
        } finally {
            setUserUpdatingConnect(false);
            setLoader(false);
        }
    };

    // eslint-disable-next-line complexity
    const updateTradeIn = async (createRequest) => {
        try {
            const response = await getTradeVehicleDetailsConnect(createRequest);
            const { vehicleMetaDataList = [], userSelectedState = "", state = createRequest.state } = response.data || {};
            if (response.responseStatus && (response.responseStatus.status === NUMBER.ONE_THOUSAND_THIRTEEN)) {
                saveTradeInRegoandVinConnect(createRequest);
                const orderData = await updateTradeInConnect({
                    ...createRequest,
                    clientId: gaId || clientId,
                    utmParams: {
                        "LANDING_PAGE": utmParamters
                    },
                    "source": getDeviceType()
                });
                if (orderData.status === NUMBER.TWO_HUNDRED) {
                    const orderID = orderData.data && orderData.data.c2bOrderId;
                    trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.SELL_MOBILE_LOGIN_PROCEEDED, {
                        eventLabel: orderID
                    });
                    trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.C2B_LEAD_CREATED, {
                        eventLabel: orderID
                    });
                    const { screenStep = "make" } = (orderData.data && orderData.data.mmvyUserInputs) || {};
                    await updateFinanceDetailConnect({
                        tradeinOpted: true,
                        interestedInTradein: true
                    });
                    navigateToRoute(`/sell-your-car/trade-in-car-make/${screenStep}/${orderID}`);
                } else {
                    redirectToErrorScreen(ERROR_STATUS_MAP[orderData.status]);
                }
            } else if (vehicleMetaDataList && vehicleMetaDataList.length) {
                const orderData = await updateTradeInConnect({
                    ...createRequest,
                    userSelectedState,
                    clientId: gaId || clientId,
                    state: state ? state : createRequest.state,
                    tradeInServiceProviderId: vehicleMetaDataList[0].id,
                    utmParams: {
                        "LANDING_PAGE": utmParamters
                    },
                    "source": getDeviceType(),
                    ...(isCheckoutFlow && {isPostBcFlow: true})
                });
                if (orderData.status === NUMBER.TWO_HUNDRED) {
                    const orderID = orderData.data && orderData.data.c2bOrderId;
                    gotoDetailsForm(orderID);
                } else {
                    redirectToErrorScreen(ERROR_STATUS_MAP[orderData.status]);
                }
            } else {
                redirectToErrorScreen("default");
            }
        } catch (err) {
            if (err?.errorCode === NUMBER.ONE_THOUSAND_THIRTEEN && isCheckoutFlow) {
                saveTradeInRegoandVinConnect(createRequest);
                const orderData = await updateTradeInConnect({
                    ...createRequest,
                    clientId: gaId || clientId,
                    utmParams: {
                        "LANDING_PAGE": utmParamters
                    },
                    "source": getDeviceType(),
                    ...(isCheckoutFlow && {isPostBcFlow: true})
                });
                if (orderData.status === NUMBER.TWO_HUNDRED) {
                    const orderID = orderData.data && orderData.data.c2bOrderId;
                    const { screenStep = "make" } = (orderData.data && orderData.data.mmvyUserInputs) || {};
                    await updateFinanceDetailConnect({
                        tradeinOpted: true,
                        interestedInTradein: true
                    });
                    navigateToRoute(`/sell-your-car/trade-in-car-make/${screenStep}/${orderID}`);
                } else {
                    redirectToErrorScreen(ERROR_STATUS_MAP[orderData.status]);
                }
            }
            redirectToErrorScreen("default");
        } finally {
            setLoader(false);
        }
    };

    const decideFlow = async (createRequest) => {
        setLoader(true);
        try {
            let response;
            if (isCheckoutFlow) {
                response = await getTradeInToggleVisibleConnect();
            } else {
                response = await getSellOrderDetailsConnect(createRequest);
            }
            const { c2bOrderId, makeId, modelId, year } = response;
            if (c2bOrderId) {
                const reqObj = {
                    ...createRequest,
                    c2bOrderId,
                    ...(makeId && { makeId }),
                    ...(modelId && { modelId }),
                    ...(year && { year }),
                    ...(response.tradeInServiceProviderId && { tradeInServiceProviderId: response.tradeInServiceProviderId }),
                    ...(ododmeter && formSource === PAGE_SOURCE.POST_BC_FLOW && { odometerReading: ododmeter }),
                    ...(isCheckoutFlow && { omsOrderId: orderId })
                };
                updateTradeIn(reqObj);
            } else {   // incase we did not receive orderId from backend then create new sell order
                createTradeIn(createRequest);
            }
        } catch (err) {
            if (err.errorCode === NUMBER.FOUR_HUNDRED_FOUR) {  // incase no existing sell order found then create new sell order
                createTradeIn(createRequest);
            }
        }
    };

    const handleClick = async () => {
        setLoader(true);
        const payload = { ...data };
        let createRequest = {};
        if (!isVinActive) {
            createRequest = {
                regNo: payload.regNo,
                year: payload.year,
                state: payload.state,
                buildYear: payload.year,
                ...(isCheckoutFlow && {  firstTradeMode: null }),
                ...(ododmeter && isCheckoutFlow && { odometerReading: ododmeter }),
                ...(isCheckoutFlow && { omsOrderId: orderId })

            };
        } else {
            createRequest = {
                vin: payload.vin,
                year: payload.year,
                state: payload.state,
                buildYear: payload.year,
                ...(isCheckoutFlow && {  firstTradeMode: null }),
                ...(isCheckoutFlow && { omsOrderId: orderId }),
                ...(ododmeter && isCheckoutFlow && { odometerReading: ododmeter })
            };
        }
        setMaxStepConnect("");
        // trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.C2B_LANDING_GET_STARTED);
        trackC2bMobileCustomEvents(GA_EVENT_NAMES.cta_clicked, {
            ...GA_EVENT_NAMES.cta_clicked,
            eventCategory: GA_EVENT_CATEGORY[formSource]
        });
        if (isCheckoutFlow) {
            trackC2bMobileCustomEvents(TRADE_IN_GA_EVENTS.nextClicked, {
                ...TRADE_IN_GA_EVENTS.nextClicked,
                eventLabel: isCheckoutFlow ? `${paymentOption}_${orderId}` : ""
            });
        } else {
            trackC2bMobileCustomEvents(GA_EVENT_NAMES.cta_clicked, {
                ...GA_EVENT_NAMES.cta_clicked,
                eventCategory: GA_EVENT_CATEGORY[formSource]
            });
        }
        if (isLoggedIn && mobile) {
            await decideFlow(createRequest);
        } else {
            setLoader(false);
            trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.SELL_MOBILE_LOGIN_OPENED);
            const isMobileMandatory = getAbExpirementVariant(c2bSellLandingMobileNumber, EXPERIMENT_TYPE.VARIANT_B);
            openGuestLoginConnect(GUEST_POPUP_TYPES.EMAIL, isMobileMandatory);
            setOnGuestEmailEnteredCallback(async (infoObj = {}) => {
                await decideFlow({ ...createRequest, ...infoObj });
            });
        }
    };

    TABS.registration.searchData = tradeInStates;
    const currentTab = isVinActive ? TABS.vehicle : TABS.registration;

    const getStartedCtaDisabled = () => {
        const disabled = !isVinActive ? !(data.regNo && data.year && data.state) : !(data.vin && data.year && data.state);
        const checkoutFlowDisabled = disabled || !ododmeter;
        return formSource === PAGE_SOURCE.POST_BC_FLOW ? checkoutFlowDisabled : disabled;
    };

    const fontHeightStyles = () => {
        if (!checkVinActive) {
            return "";
        } else if (isVinActive) {
            return "styles.atfForm styles.autoHeight";
        } else {
            return "styles.atfForm";
        }
    };
    const formTypeStyle = () => {
        if (customStyles) {
            return `styles.bannerForm styles.${customStyles}`;
        }
        if (!checkVinActive) {
            return "styles.bannerForm";
        } else if (isVinActive) {
            return "styles.vinForm";
        } else {
            return "styles.regoForm";
        }
    };

    const showBuildYearModalHandler = async () => {
        await yieldToMain();
        setShowBuildYearModal(true);
        trackC2bMobileCustomEvents(C2B_AU_MOBILE_EVENTS.BUILD_YEAR_TOOLTIP_CLICKED, {
            eventCategory: GA_EVENT_CATEGORY[formSource],
            eventLabel: formSource === PAGE_SOURCE.SELL ? "landing_page" : "sell_banner"
        });
    };
    const handleOdometerChange = (e) => {
        setOdometer(e.target.value);
    };

    const trackOdoEvents = () => {
        trackC2bMobileCustomEvents(TRADE_IN_GA_EVENTS.odoEntered, {
            ...TRADE_IN_GA_EVENTS.odoEntered,
            eventLabel: isCheckoutFlow ? `${paymentOption}_${orderId}` : ""
        });
    };

    return (
        <div styleName={fontHeightStyles()}>
            <div styleName={checkVinActive ? "styles.formContainer" : ""}>
                <div styleName={formTypeStyle()}>
                    <InputText
                        value={data[currentTab.inputKey] ? data[currentTab.inputKey] : ""}
                        placeholder={currentTab.inputPlaceHolder}
                        onBlurCallback={(value) => {
                            handleOnBlur(value, currentTab.inputKey);
                        }}
                        inputRef={regoInputRef}
                        onChange={
                            (e) => {
                                if (e.target.value.length > NUMBER.SIXTEEN + 1) return;
                                handleOnChange(e.target.value, currentTab.inputKey);
                            }
                        }
                    />
                    {!isVinActive && Array(NUMBER.FOUR).fill({}).map(() => (
                        <span styleName={"styles.circle"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                <circle cx="2" cy="2" r="2" fill="#D9D9D9" />
                                <path d="M3.20078 1.77407H2.25019V0.799951H1.75137V1.77407H0.800781V2.22583H1.75137V3.19995H2.25019V2.22583H3.20078V1.77407Z" fill="#5B5B5B" />
                            </svg>
                        </span>
                    ))}
                    {isVinActive && showVinWarning && (
                        <div styleName={"styles.vinWarning"}>
                            Recheck your VIN. It is usually composed of
                            17 alphanumeric characters
                        </div>
                    )}
                </div>
                <div styleName={`styles.buildYearStateWrap ${!checkVinActive && formSource === PAGE_SOURCE.CLP ? "styles.buildYearStateWrapClp" : ""}`}>
                    <div styleName={`styles.searchWrap ${customDropDownStyle ? `styles.${customDropDownStyle}` : ""}`}>
                        {formSource === PAGE_SOURCE.CLP && <p styleName={"styles.inputLabel"}>Build year</p>}
                        <Search
                            value={data.year ? { label: data.year, value: data.year } : ""}
                            placeholder={placeholderYear}
                            isSearchable={true}
                            data={yearData}
                            onChange={(input) => {
                                handleOnChange(input.value, "year");
                            }}
                            tabAccessibility
                        />
                        <p styleName={"styles.informationWrapper"}>As on your build plate<span onClick={showBuildYearModalHandler}><img src={formSource === PAGE_SOURCE.CLP ? infoIconWhite : infoIcon} alt="As on your build plate info icon" /></span></p>
                        <BuildYearModal open={showBuildYearModal} hideModal={() => setShowBuildYearModal(false)} />
                    </div>
                    {<div styleName={`styles.searchWrap ${customDropDownStyle ? `styles.${customDropDownStyle}` : ""}`}>
                        {formSource === PAGE_SOURCE.CLP && <p styleName={"styles.inputLabel"}>State</p>}
                        <Search
                            value={data.state ? getObjectByValue(TRADE_STATE, "value", data.state) : ""}
                            placeholder={placeholderState}
                            isSearchable={true}
                            data={TRADE_STATE}
                            onChange={(input) => {
                                handleOnChange(input.value, "state");
                            }}
                            tabAccessibility
                        />
                    </div>}
                </div>
                <div styleName={`styles.buttonWrap ${formSource === PAGE_SOURCE.POST_BC_FLOW ? "styles.postBcButtonWrap" : ""}`}>
                    <Button
                        text={ctaText || "Start valuation"} onClick={handleClick}
                        // customDisabled={true}
                        isLoading={loader}
                        disabled={getStartedCtaDisabled()}
                        customStyle={formSource === PAGE_SOURCE.POST_BC_FLOW ? ["inter-btn-design"] : []}
                    />
                </div>
                {showVinToggleSection && <div styleName={"styles.silderWrapper"}>
                    <span>Enter VIN instead</span>
                    <label styleName={"styles.switch"}>
                        <input checked={isVinActive} onChange={handleTab} type="checkbox" />
                        <span styleName={"styles.slider styles.round"} />
                    </label>
                </div>}
                {formSource === PAGE_SOURCE.POST_BC_FLOW && <div styleName={"styles.odometerWrapper"}>
                    <p styleName={"styles.kilometer"}>km</p>
                    <InputText value={ododmeter} onChange={handleOdometerChange} placeholder="Odometer reading" type="number" onBlurCallback={trackOdoEvents} />
                </div>}
            </div>
        </div>
    );
};

C2bLandingAtfForm.propTypes = {
    tradeInStates: PropTypes.object,
    tradeInData: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    rejectModal: PropTypes.object,
    getTradeInStateConnect: PropTypes.func,
    guestLoginProps: PropTypes.object,
    updateTradeInConnect: PropTypes.func,
    getTradeInDetailsConnect: PropTypes.func,
    createTradeInConnect: PropTypes.func,
    setUserUpdatingConnect: PropTypes.func,
    setMaxStepConnect: PropTypes.func,
    getTradeVehicleDetailsConnect: PropTypes.func,
    handleRejectModalConnect: PropTypes.func,
    getSellOrderDetailsConnect: PropTypes.func,
    saveTradeInRegoandVinConnect: PropTypes.func,
    regoInputRef: PropTypes.object,
    heading: PropTypes.string,
    gaId: PropTypes.string,
    isGuestLogin: PropTypes.bool,
    showVinToggleSection: PropTypes.bool,
    checkVinActive: PropTypes.bool,
    ctaText: PropTypes.text,
    formSource: PropTypes.text,
    customStyles: PropTypes.string,
    customDropDownStyle: PropTypes.string,
    placeHolderText: PropTypes.object,
    c2bSellLandingMobileNumber: PropTypes.object,
    updateFinanceDetailConnect: PropTypes.func,
    paymentOption: PropTypes.string,
    orderId: PropTypes.string,
    getTradeInToggleVisibleConnect: PropTypes.func,
    mobile: PropTypes.string
};

export default C2bLandingAtfForm;
