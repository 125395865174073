/* eslint-disable complexity */
/* eslint-disable new-cap */
import Types from "./types";
import { CheckoutDrivaService, B2cFinanceService } from "../../../../service";
import { getItem } from "../../../../utils/helpers/storage-crud";
import { DEFAULT_CAR_PRICE, DEFAULT_CAR_YEAR, FINANCE_TYPE } from "../../../../constants/checkout-constants";
import { getLoanOfferDetailsSuccess, populateCheckoutPersonalData } from "../../checkout-info/actions";
import { showToast } from "../../../shared/toast-message/actions";
import { resetReducerKeys } from "../../../../constants/app-constants";
import { resetUnapplicableFinanceParameters } from "../../../../utils/helpers/reset-unapplicable-finance-parameters";
import jwtDecode from "jwt-decode";
import { getEmptyLoanOfferDetailsSuccess } from "../../checkout-info/reducers";

const setLeavePreApprovalPopUpStatus = (data) => {
    return {
        type: Types.SET_LEAVE_PRE_APPROVAL_POP_UP_STATUS,
        data
    };
};

const setIsIntroCoachMarkVisible = (data) => {
    return {
        type: Types.SET_INTRO_COACH_MARK_VISIBLE,
        data
    };
};

const setPreApprovalEditRedirectionRoute = (data) => {
    return {
        type: Types.SET_PRE_APPROVAL_EDIT_REDIRECTION_ROUTE,
        data
    };
};

const setFinanceData = (data) => {
    return {
        type: Types.SET_PRE_APPROVAL_FINANCE_DATA,
        data
    };
};

const resetFinanceData = () => {
    return {
        type: Types.RESET_FINANCE_DATA
    };
};

const setPreApprovalDetails = (data) => {
    return {
        type: Types.SET_PRE_APPROVAL_DETAILS,
        newData: data
    };
};

const setPreApprovalType = (data) => {
    return {
        type: Types.SET_PRE_APPROVAl_LOAN_TYPE,
        newData: data
    };
};

const setPreApprovalEditMode = (data) => {
    return {
        type: Types.SET_PRE_APPROVAL_EDIT_MODE,
        data
    };
};

const setFinancingScreen = ({ activeScreen }) => ({
    type: Types.SET_PRE_APPROVAL_FINANCE_SCREEN,
    activeScreen
});

const setLoanTenureValue = ({ loanTenure }) => {
    return {
        type: Types.SET_LOAN_TENURE_VALUE,
        loanTenure
    };
};

const setFinancePersonalDetails = (data) => ({
    type: Types.SET_PRE_APPROVAL_PERSONAL_DETAILS,
    data
});

const setMaxStep = (maxStep) => ({type: Types.SET_PRE_APPROVAL_MAX_STEP, maxStep});

const getPreApprovalLoanDetailsRequest = () => ({
    type: Types.GET_PRE_APPROVAL_LOAN_REQUEST
});

const getPreApprovalLoanDetailsSuccess = (data) => ({
    type: Types.GET_PRE_APPROVAL_LOAN_SUCCESS,
    data
});

const getInhousePreApprovalLoanDetailsSuccess = (data, isUserZeroDpVariant) => ({
    type: Types.GET_INHOUSE_PRE_APPROVAL_LOAN_SUCCESS,
    data,
    isUserZeroDpVariant
});

const getPreApprovalLoanDetailsFailure = (error) => ({
    type: Types.GET_PRE_APPROVAL_LOAN_FAILURE,
    error
});

const setEligibilityForInhouse = (data) => ({
    type: Types.SET_ELIGIBITLITY_FOR_INHOUSE,
    data
});

const getDrivaPreApprovalLoanDetails = () =>
    // eslint-disable-next-line max-statements
    async (dispatch, getState) => {
        dispatch(getPreApprovalLoanDetailsRequest());
        const {
            user: {
                secureToken,
                firstName,
                lastName,
                middleName,
                mobile
            },
            preApprovalLoan: {
                financeUniqueId
            }
        } = getState();
        const uuid = financeUniqueId || getItem("financeUniqueId");
        try {
            let response = {};
            if (secureToken) {
                response = await CheckoutDrivaService.getPreApprovalLoanDetailsAuthenticated(uuid, secureToken);
            } else {
                response = await CheckoutDrivaService.getPreApprovalLoanDetails(uuid, secureToken);
            }
            if (response.data.preApprovalRequest) {
                dispatch(getPreApprovalLoanDetailsSuccess(response.data));
                const { user = {}} = response.data.preApprovalRequest;
                dispatch(populateCheckoutPersonalData({
                    firstName: firstName || user.firstName,
                    lastName: lastName || user.lastName,
                    middleName: middleName || user.middleName,
                    profileMobile: mobile || user.mobile
                }));
            } else {
                dispatch(getPreApprovalLoanDetailsFailure());
            }
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getPreApprovalLoanDetailsFailure(error));
            return  Promise.reject(error);
        }
    };

const getInhousePreApprovalLoanDetails = ({reset = false, fetchListingDetails = false} = {}) =>
    // eslint-disable-next-line max-statements
    async (dispatch, getState) => {   // eslint-disable-line complexity
        dispatch(getPreApprovalLoanDetailsRequest());
        const {
            user: {
                secureToken,
                firstName,
                lastName,
                middleName,
                mobile,
                isUserZeroDpVariant,
                email
            }
        } = getState();
        try {
            const response = await B2cFinanceService.getInhousePreApprovalLoanDetails(secureToken, reset, fetchListingDetails);
            if (response.data.loanOfferRequest) {
                dispatch(getInhousePreApprovalLoanDetailsSuccess(response.data, isUserZeroDpVariant));
                const { user = {}} = response.data.loanOfferRequest || {};

                // to get the guest email when user choose to checkout as a guest user
                const guestLoginResponse = secureToken ?  jwtDecode(secureToken) : {};
                dispatch(populateCheckoutPersonalData({
                    firstName: firstName || (user && user.firstName),
                    lastName: lastName ||  (user && user.lastName),
                    middleName: middleName ||  (user && user.middleName),
                    profileMobile: mobile ||  (user && user.mobile),
                    userEmail: email || (user || {}).email || (guestLoginResponse || {}).email
                }));
            } else {
                dispatch(getPreApprovalLoanDetailsFailure());
            }
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getPreApprovalLoanDetailsFailure(error));
            return  Promise.reject(error);
        }
    };

const getPreApprovalLoanDetails = ({fetchListingDetails = false} = {}) =>
    // eslint-disable-next-line max-statements
    async (dispatch, getState) => {
        dispatch(getPreApprovalLoanDetailsRequest());
        const {
            user: {
                secureToken
            }
        } = getState();

        try {
            dispatch(getInhousePreApprovalLoanDetails({fetchListingDetails}));
            const eligibilityResponse = await B2cFinanceService.getInhouseEligibility(secureToken);
            dispatch(setEligibilityForInhouse(eligibilityResponse.data));
            // return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getPreApprovalLoanDetailsFailure(error));
            // return  Promise.reject(error);
        }
    };

const createPreApprovalLoanRequest = () => ({
    type: Types.CREATE_PRE_APPROVAL_LOAN_REQUEST
});

const createPreApprovalLoanSuccess = (data) => ({
    type: Types.CREATE_PRE_APPROVAL_LOAN_SUCCESS,
    data
});

const createPreApprovalLoanFailure = (error) => ({
    type: Types.CREATE_PRE_APPROVAL_LOAN_FAILURE,
    error
});

const createPreApprovalLoan = () =>
    (dispatch, getState) => {
        dispatch(createPreApprovalLoanRequest());
        const {
            user: {
                secureToken
            },
            preApprovalLoan: {
                financeData
            }
        } = getState();
        const params = {
            ...financeData
        };
        return new Promise((resolve, reject) => {
            CheckoutDrivaService.createPreApprovalLoan(secureToken, params)
                .then((response) => {
                    dispatch(createPreApprovalLoanSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(createPreApprovalLoanFailure(error));
                    dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
                    reject(error);
                });
        });
    };

const updatePreApprovalLoanRequest = () => ({
    type: Types.UPDATE_PRE_APPROVAL_LOAN_REQUEST
});

const updatePreApprovalLoanSuccess = (data) => {
    return {
        type: Types.UPDATE_PRE_APPROVAL_LOAN_SUCCESS,
        data
    };
};

const updateInhousePreApprovalLoanSuccess = (data) => {
    return {
        type: Types.UPDATE_INHOUSE_PRE_APPROVAL_LOAN_SUCCESS,
        data
    };
};

const updatePreApprovalLoanFailure = (error) => ({
    type: Types.UPDATE_PRE_APPROVAL_LOAN_FAILURE,
    error
});

const setPostFinanceScreen = (data) => {
    return {
        type: Types.SET_POST_FINANCE_SCREEN,
        newData: data
    };
};

const setShowAllOffers = (data) => {
    return {
        type: Types.SET_SHOW_ALL_OFFERS,
        newData: data
    };
};

const updatePreApprovalLoan = (params = { submitted: false }, token) =>
    (dispatch, getState) => {
        dispatch(updatePreApprovalLoanRequest());
        const {
            user: { secureToken, isGuestLogin },
            preApprovalLoan: {
                financeData,
                financeUniqueId
            },
            abExperiment: {
                financeFirstCheckoutRevamp
            }
        } = getState();
        const { carInfo = {} } = financeData || {};

        let payload = {
            ...financeData,
            carInfo: {
                ...financeData.carInfo,
                carPrice: ((carInfo || {}).known === false && params.submitted) ? DEFAULT_CAR_PRICE : (carInfo || {}).carPrice,
                carYear: ((carInfo || {}).known === false && params.submitted) ? DEFAULT_CAR_YEAR : (carInfo || {}).carYear
            },
            preApprovalType: "FINANCE_FIRST",
            ...params,
            financeType: FINANCE_TYPE.CARS24,
            uniqueId: financeUniqueId
        };
        payload = resetUnapplicableFinanceParameters(payload);
        const accessToken = token || secureToken;
        return new Promise((resolve, reject) => {
            if (!params.submitted) {
                B2cFinanceService.updateInhousePreApprovalLoan(isGuestLogin ? null : accessToken, financeUniqueId, payload, financeFirstCheckoutRevamp?.data)
                    .then((response) => {
                        dispatch(updateInhousePreApprovalLoanSuccess(response.data));
                        resolve(response.data);
                    })
                    .catch((error) => {
                        dispatch(updatePreApprovalLoanFailure(error));
                        dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
                        reject(error);
                    });
            } else if (params.submitted) {
                B2cFinanceService.submitInhousePreApprovalLoan(accessToken, financeUniqueId, payload)
                    .then((response) => {
                        dispatch(updateInhousePreApprovalLoanSuccess(response.data));
                        resolve(response.data);
                    })
                    .catch((error) => {
                        dispatch(updatePreApprovalLoanFailure(error));
                        dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
                        reject(error);
                    });
            }
        });
    };

const updateLoanQuote = (orderId) =>
    async (dispatch, getState) => {   // eslint-disable-line complexity
        dispatch(getPreApprovalLoanDetailsRequest());
        const {
            user: {
                secureToken,
                isUserZeroDpVariant
            }
        } = getState();

        try {
            const response = await B2cFinanceService.updateInhouseLoanQuote(secureToken, orderId);
            const updatedResponse = {
                data: {
                    ...response.data,
                    selectedQuote: {
                        ...response.data.selectedQuote,
                        totalLoanAmount: response.data.loanOfferRequest.loan.amount + response.data.selectedQuote.totalFee
                    }
                }
            };
            if (updatedResponse.data !== "") {
                dispatch(getLoanOfferDetailsSuccess(updatedResponse.data, true, isUserZeroDpVariant));
            } else {
                dispatch(getEmptyLoanOfferDetailsSuccess());
            }
            return Promise.resolve(updatedResponse.data);
        } catch (error) {
            dispatch(getPreApprovalLoanDetailsFailure(error));
            return  Promise.reject(error);
        }
    };

const getPreApprovalFormProgress = (data) =>
    ({
        type: Types.GET_PRE_APPROVAL_FORM_PROGRESS,
        data
    });

const updatePreApprovalSelectedQuote = (params, token) =>
    (dispatch, getState) => {
        dispatch(updatePreApprovalLoanRequest());
        const {
            user: { secureToken }
        } = getState();
        return new Promise((resolve, reject) => {
            CheckoutDrivaService.updatePreApprovalSelectedOffer(secureToken || token, params)
                .then((response) => {
                    dispatch(updatePreApprovalLoanSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(updatePreApprovalLoanFailure(error));
                    dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
                    reject(error);
                });
        });
    };

const getDepositRangeRequest = () => ({
    type: Types.GET_DEPOSIT_RANGE_REQUEST
});

const getDepositRangeSuccess = (data) => {
    return {
        type: Types.GET_DEPOSIT_RANGE_SUCCESS,
        data
    };
};

const getDepositRangeFailure = (error) => ({
    type: Types.GET_DEPOSIT_RANGE_FAILURE,
    error
});

const getDepositRangeValue = () =>
    (dispatch, getState) => {
        dispatch(getDepositRangeRequest());
        const {
            preApprovalLoan: {
                inHouseFinanceEligibility
            }
        } = getState();
        return new Promise((resolve, reject) => {
            (inHouseFinanceEligibility ? B2cFinanceService.getDepositRangeValueInHouse() : CheckoutDrivaService.getDepositRangeValue())
                .then((response) => {
                    dispatch(getDepositRangeSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(getDepositRangeFailure(error));
                    reject(error);
                });
        });
    };

const getInhouseEligibility = () =>
    (dispatch, getState) => {
        // dispatch(getDepositRangeRequest());
        const {
            user: { secureToken }
        } = getState();
        return new Promise((resolve, reject) => {
            B2cFinanceService.getInhouseEligibility(secureToken)
                .then((response) => {
                    if (response.data) {
                        dispatch(setEligibilityForInhouse(response.data));
                    }
                    resolve(response.data);
                })
                .catch((error) => {
                    // dispatch(getDepositRangeFailure(error));
                    reject(error);
                });
        });
    };

const setPreApprovedOrigin = (preApporvedOrigin) => ({
    type: Types.PRE_APPROVED_ORIGIN,
    preApporvedOrigin
});

const setPreApprovalCoachMarkDisplay = (showPreApprovalCoachmark) => {
    return {
        type: Types.SET_PRE_APPROVAL_COACHMARK_DISPLAY,
        showPreApprovalCoachmark
    };
};

const updateBatchPriceRequest = (data) => {
    return {
        type: Types.UPDATE_BATCH_PRICE_REQUEST,
        data
    };
};

const updateBatchPriceSuccess = (data) => {
    return {
        type: Types.UPDATE_BATCH_PRICE_SUCCESS,
        data
    };
};

const updateBatchPriceFailure = (error) => ({
    type: Types.UPDATE_BATCH_PRICE_FAILURE,
    error
});

const updateBatchPrice = (token) =>
    (dispatch, getState) => {
        dispatch(updateBatchPriceRequest());
        const {
            user: { secureToken }
        } = getState();
        return new Promise((resolve, reject) => {
            CheckoutDrivaService.updateBatchPrice(secureToken || token)
                .then((response) => {
                    dispatch(updateBatchPriceSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(updateBatchPriceFailure(error));
                    reject(error);
                });
        });
    };

const setCurrentActiveTab = (tab) => ({
    type: Types.SET_CURRENT_ACTIVE_TAB,
    currentActiveTab: tab
});

const setCarInformation = (data) => ({
    type: Types.SET_CAR_INFORMATION,
    data
});

const resetPreApproval = () => dispatch => {
    dispatch({ type: resetReducerKeys.PRE_APPROVAL_LOAN });
};

const resetPreApprovalData = () => {
    return {
        type: Types.RESET_PRE_APPROVAL_DATA
    };
};

const postPreApprovalRequestCallbackRequest = (data) => {
    return {
        type: Types.PRE_APPROVAL_REQUEST_CALLBACK_INTI,
        data
    };
};

const postPreApprovalRequestCallbackSuccess = (data) => {
    return {
        type: Types.PRE_APPROVAL_REQUEST_CALLBACK_SUCCESS,
        data
    };
};

const postPreApprovalRequestCallbackFailure = (error) => ({
    type: Types.PRE_APPROVAL_REQUEST_CALLBACK_FAILURE,
    error
});

const postPreApprovalRequestCallback = (payload = {}) =>  (dispatch, getState) => {
    dispatch(postPreApprovalRequestCallbackRequest(true));
    const {
        user: { secureToken },
        preApprovalLoan: {
            financeUniqueId
        }
    } = getState();
    const urlParam = {
        requested: true
    };
    return new Promise((resolve, reject) => {
        B2cFinanceService.postPreApprovalRequestCallback(secureToken, {uuid: financeUniqueId}, urlParam, payload)
            .then((response) => {
                dispatch(postPreApprovalRequestCallbackSuccess(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(postPreApprovalRequestCallbackFailure(error));
                dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
                reject(error);
            });
    });
};

const getPreApprovalQuestionsRequest = () => ({
    type: Types.GET_PRE_APPROVAL_QUESTIONS_REQUEST
});

const getPreApprovalQuestionsSuccess = (data) => ({
    type: Types.GET_PRE_APPROVAL_QUESTIONS_SUCCESS,
    data
});

const getPreApprovalQuestionsFailure = (error) => ({
    type: Types.GET_PRE_APPROVAL_QUESTIONS_FAILURE,
    error
});

const getPreApprovalQuestions = () =>
    async (dispatch, getState) => {
        dispatch(getPreApprovalQuestionsRequest());
        const {
            user: {
                secureToken,
                isGuestLogin
            }
        } = getState();
        try {
            const questionResponse = await B2cFinanceService.getPreApprovalQuestions({ token: isGuestLogin ? null : secureToken });
            const financeResponse = await B2cFinanceService.getPreApprovalQuestions({ token: isGuestLogin ? null : secureToken, scenario: "Finance_Form_Rules" });

            const response = {
                ...questionResponse.data,
                ...financeResponse.data
            };
            dispatch(getPreApprovalQuestionsSuccess(response));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getPreApprovalQuestionsFailure(error));
            dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
            return  Promise.reject(error);
        }
    };

const getInterestRateForPriceCalculatorRequest = () => ({
    type: Types.GET_INTEREST_RATE_FOR_PRICE_CALCULATOR_REQUEST
});

const getInterestRateForPriceCalculatorSuccess = (data) => ({
    type: Types.GET_INTEREST_RATE_FOR_PRICE_CALCULATOR_SUCCESS,
    data
});

const getInterestRateForPriceCalculatorFailure = (error) => ({
    type: Types.GET_INTEREST_RATE_FOR_PRICE_CALCULATOR_FAILURE,
    error
});

const getInterestRateForPriceCalculator = (params) => async (dispatch, getState) => {
    const { user: { secureToken}} = getState();
    dispatch(getInterestRateForPriceCalculatorRequest());

    try {
        const response = await B2cFinanceService.getInterestRateForPriceCalculator({
            token: secureToken,
            ...params
        });
        dispatch(getInterestRateForPriceCalculatorSuccess(response.data));
        return Promise.resolve(response.data);
    } catch (error) {
        dispatch(getInterestRateForPriceCalculatorFailure(error));
        dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
        return Promise.reject(error);
    }
};

const getMinMaxInterestRatesRequest = () => ({
    type: Types.GET_MIN_MAX_INTEREST_RATES_REQUEST
});

const getMinMaxInterestRatesSuccess = (data) => ({
    type: Types.GET_MIN_MAX_INTEREST_RATES_SUCCESS,
    data
});

const getMinMaxInterestRatesFailure = (error) => ({
    type: Types.GET_MIN_MAX_INTEREST_RATES_FAILURE,
    error
});

const getMinMaxInterestRates = () => async (dispatch) => {
    dispatch(getMinMaxInterestRatesRequest());

    try {
        const response = await B2cFinanceService.getMinMaxInterestRates();
        dispatch(getMinMaxInterestRatesSuccess(response.data));
        return Promise.resolve(response.data);
    } catch (error) {
        dispatch(getMinMaxInterestRatesFailure(error));
        dispatch(showToast({ variant: "black", text: "Something went wrong. Please try again.", position: "middle"}));
        return Promise.reject(error);
    }
};

export {
    setFinancingScreen,
    setFinanceData,
    setPreApprovalDetails,
    setPreApprovalType,
    setPreApprovalEditMode,
    setLoanTenureValue,
    setMaxStep,
    getPreApprovalLoanDetails,
    createPreApprovalLoan,
    updatePreApprovalLoan,
    setFinancePersonalDetails,
    getDepositRangeValue,
    setPreApprovedOrigin,
    setPreApprovalCoachMarkDisplay,
    updatePreApprovalSelectedQuote,
    updateBatchPrice,
    resetFinanceData,
    setCurrentActiveTab,
    getPreApprovalLoanDetailsSuccess,
    getInhousePreApprovalLoanDetailsSuccess,
    setCarInformation,
    setPostFinanceScreen,
    resetPreApproval,
    resetPreApprovalData,
    setShowAllOffers,
    getInhouseEligibility,
    getInhousePreApprovalLoanDetails,
    setPreApprovalEditRedirectionRoute,
    getDrivaPreApprovalLoanDetails,
    setLeavePreApprovalPopUpStatus,
    setIsIntroCoachMarkVisible,
    postPreApprovalRequestCallback,
    getPreApprovalQuestions,
    getPreApprovalFormProgress,
    getInterestRateForPriceCalculator,
    getMinMaxInterestRates,
    updateLoanQuote
};
