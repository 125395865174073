import { createReducer } from "reduxsauce";
import Types from "./types";
export const INITIAL_STATE = {
    loading: false,
    error: null,
    cmsContent: {},
    isSSR: false,
    showInspectionForm: false,
    inspectionFormLoader: false
};
const fetchCmsDataInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        loading: true
    };
};
const fetchCmsDataSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        loading: false,
        cmsContent: data,
        error: null
    };
};
const fetchCmsDataFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        loading: false,
        error
    };
};
const updateShowInspectionForm = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        showInspectionForm: data
    };
};

const updateInspectionFormLoader = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        inspectionFormLoader: data
    };
};
export const HANDLERS = {
    [Types.FETCH_CMS_CONTENT_INIT]: fetchCmsDataInit,
    [Types.FETCH_CMS_CONTENT_SUCCESS]: fetchCmsDataSuccess,
    [Types.FETCH_CMS_CONTENT_FAILURE]: fetchCmsDataFailure,
    [Types.UPDATE_SHOW_INSPECTION_FORM]: updateShowInspectionForm,
    [Types.UPDATE_INSPECTION_FORM_LOADER]: updateInspectionFormLoader
};
export default createReducer(INITIAL_STATE, HANDLERS);
