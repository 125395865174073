import { ListingServiceAU as ListingService, ListingServiceAUV2} from "../../../service";
import Types from "./types";
import { updateAppliedFilters} from "../filters/actions";
import { resetCityLocation } from "../location-picker-popup/actions";
import { FINANCE_TYPE } from "../../../constants/checkout-constants";
import { ZERO_DP_VARIANTS } from "../../../constants/optimize-constants";

const fetchCarListInit = () => ({
    type: Types.FETCH_CAR_LIST
});

const fetchCarListSuccess = (data, params) => ({
    type: Types.FETCH_CAR_LIST_SUCCESS,
    data,
    params
});

const fetchSimilarCarSuccess = (data, params) => ({
    type: Types.FETCH_SIMILAR_CAR_LIST_SUCCESS,
    data,
    params
});

const fetchCarListFailure = (error) => ({ type: Types.FETCH_CAR_LIST_FAILURE, error });

const fetchCarList = (params, queryString) => (dispatch, getState) => {
    dispatch(fetchCarListInit());
    const {
        user: { secureToken, isUserZeroDpVariant, isGuestLogin }
    } = getState();
    let cityObj = null;
    params = {...params, zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP };
    if (queryString && queryString.indexOf("userFinanceType") < 0) {
        queryString = `${queryString}&userFinanceType=${FINANCE_TYPE.CARS24}`;
    }
    return new Promise((resolve, reject) => {
        ListingService.fetchListing(params, queryString, isGuestLogin ? null : secureToken)
            .then(response => {
                dispatch(fetchCarListSuccess(response.data, params));
                dispatch(updateAppliedFilters(response.data.facets));
                if (response.data.metaContent.cityInfo) {
                    cityObj = {
                        id: response.data.metaContent.cityInfo.id,
                        name: response.data.metaContent.cityInfo.name,
                        code: response.data.metaContent.cityInfo.code
                    };
                } else {
                    cityObj = {};
                }
                dispatch(resetCityLocation(cityObj));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchCarListFailure(error));
                reject(error);
            });
    });
};

const fetchCarListV2 = (params, queryString) => (dispatch, getState) => {
    dispatch(fetchCarListInit());
    const {
        user: { secureToken, isUserZeroDpVariant, isGuestLogin }
    } = getState();
    let cityObj = null;
    params = {...params, zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP };
    if (queryString && queryString.indexOf("userFinanceType") < 0) {
        queryString = `${queryString}&userFinanceType=${FINANCE_TYPE.CARS24}`;
    }
    return new Promise((resolve, reject) => {
        ListingServiceAUV2.fetchListingV2(params, queryString, isGuestLogin ? null : secureToken)
            .then(response => {
                dispatch(fetchCarListSuccess(response.data, params));
                dispatch(updateAppliedFilters(response.data.facets));
                if (response.data.metaContent.cityInfo) {
                    cityObj = {
                        id: response.data.metaContent.cityInfo.id,
                        name: response.data.metaContent.cityInfo.name,
                        code: response.data.metaContent.cityInfo.code
                    };
                } else {
                    cityObj = {};
                }
                dispatch(resetCityLocation(cityObj));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchCarListFailure(error));
                reject(error);
            });
    });
};

const fetchSimilarCarListInit = () => ({
    type: Types.FETCH_SIMILAR_CAR_LIST
});
const fetchSimilarCarListFailure = (error) => ({ type: Types.FETCH_SIMILAR_CAR_LIST_FAILURE, error });

const fetchSimilarCarListV2 = (params, queryString) => (dispatch, getState) => {
    dispatch(fetchSimilarCarListInit());
    const {
        user: { secureToken, isUserZeroDpVariant, isGuestLogin }
    } = getState();
    if (queryString && queryString.indexOf("userFinanceType") < 0) {
        const userFinanceType = FINANCE_TYPE.CARS24;
        queryString = `${queryString}&userFinanceType=${userFinanceType}`;
    }
    let cityObj = null;
    params = {zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP, ...params};
    return new Promise((resolve, reject) => {
        ListingServiceAUV2.fetchListingV2(params, queryString, isGuestLogin ? null : secureToken)
            .then((response) => {
                dispatch(fetchSimilarCarSuccess(response.data, params));
                dispatch(updateAppliedFilters(response.data.facets));
                if (response.data.metaContent.cityInfo) {
                    cityObj = {
                        id: response.data.metaContent.cityInfo.id,
                        name: response.data.metaContent.cityInfo.name,
                        code: response.data.metaContent.cityInfo.code
                    };
                } else {
                    cityObj = {};
                }
                dispatch(resetCityLocation(cityObj));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(fetchSimilarCarListFailure({error}));
                reject(error);
            });
    });
};

const updateSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

const resetCarList = ()  => ({
    type: Types.RESET_CAR_LIST
});

const reloadCarList = (shouldReloadList) => ({
    type: Types.RELOAD_CAR_LIST,
    shouldReloadList
});

// Fetch FAQ Data
const fetchClpDataInit = () => ({
    type: Types.FETCH_FAQ_DATA
});

const fetchClpDataSuccess = (data, params) => ({
    type: Types.FETCH_FAQ_DATA_SUCCESS,
    data,
    params
});

const fetchClpDataFailure = (error) => ({ type: Types.FETCH_FAQ_DATA_FAILURE, error });

const fetchClpData = (params, queryString) => (dispatch, getState) => {
    dispatch(fetchClpDataInit());
    const {
        user: { secureToken, isUserZeroDpVariant, isGuestLogin }
    } = getState();
    params = {...params, zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP };

    return new Promise((resolve, reject) => {
        ListingService.fetchClpData(params, queryString, isGuestLogin ? null : secureToken)
            .then(response => {
                dispatch(fetchClpDataSuccess(response.data, params));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchClpDataFailure(error));
                reject(error);
            });
    });
};
export {
    fetchCarList,
    fetchCarListV2,
    fetchSimilarCarListV2,
    updateSSRStatus,
    resetCarList,
    reloadCarList,
    fetchClpData
};
